import { IUsersState } from "src/redux/users";

export const addUserRequest = (state: IUsersState): IUsersState => {
  return {
    ...state,
    loading: true,
  };
};

export const addUserSuccess = (state: IUsersState): IUsersState => {
  return {
    ...state,
    loading: false,
  };
};

export const addUserFailure = (state: IUsersState): IUsersState => {
  return {
    ...state,
    loading: false,
  };
};
