import { EquityFormatType } from "src/helpers/types/Owner";

export const attributes: Record<string, string> = {
  Фамилия: "lastName",
  Имя: "firstname",
  имя2: "firstName", // временно, пока на беке отличаются атрибуты
  Отчество: "middleName",
  "Электронная почта": "eMail",
  емейл2: "email", // то же самое :/
  "Номер телефона для ФНС": "fnsPhoneNum",
  "Серия и номер паспорта": "snp",
  "Дата выдачи паспорта": "issueDate",
  "Код подразделения": "kodPodrazdelenia",
  "Дата рождения": "dob",
  Пол: "gender",
  "Место рождения": "pob",
  "Кем выдан паспорт": "issuedBy",

  //для иностранного гражданина
  иностранец: "inostr",
  "Государство гражданства": "citizenship",
  "Фамилия (латиница)": "lastnameLatin",
  "Имя (латиница)": "firstnameLatin",
  "Отчество (латиница)": "middleNameLatin",
  "Номер документа": "foreignNum",
  "Дата выдачи": "foreignIssuedDate",
  "Срок действия": "foreignExpDate",
  "Документ иностранца бессрочный": "foreign_doc_term",
  "Кем выдан": "foreignIssuedBy",
  "Основание для проживания на территории РФ": "live_vid",

  // ИНН СНИЛС
  СНИЛС: "snils",
  ИНН: "inn",
  КПП: "kpp",

  // Адрес регистрации

  "Полный адрес": "address",
  Субъект: "subjectCode",
  "Код рег. органа": "sono_area",
  кодРегОргана2: "sono_area2",
  "Код терр. ФНС": "sono",
  "Код адреса": "kladr_id",
  "Муниципальное образование (верхнее)": "area_abbr",
  "Наименование муниципального образования (верхнее)": "areaCode",
  "Муниципальное образование (нижнее)": "city_abbr",
  "Наименование муниципального образования (нижнее)": "city",
  "Населенный пункт": "nasel_abbr",
  "Наименование населенного пункта": "naselPunkt",
  "Элемент планировочной структуры": "element",
  "Наименование элемента планировочной структуры": "element_big",
  "Элемент улично-дорожной сети": "street_abbr",
  "Наименование элемента улично-дорожной сети": "street",
  "Здание/сооружение (первый столбец)": "house_abbr",
  "Номер здания/сооружения (первый столбец)": "home",
  "Здание/сооружение (второй столбец)": "house_2",
  "Номер здания/сооружения (второй столбец)": "house_num2",
  "Здание/сооружение (третий столбец)": "house_3",
  "Номер здания/сооружения (третий столбец)": "house_num3",
  "Квартира/офис": "appartment_abbr",
  "Номер квартиры/офиса": "flat",
  Помещение: "pom",
  "Номер помещения": "pom_num",

  // Корпус: "corpus_abbr",
  // "Номер корпуса": "corpus",

  // параметры, для которых нет инпутов, но они отправляются на сервер
  "Наименование региона": "region",
  "Регион (сокр.)": "region_abbr",
  "Регион (полн.)": "region_abbr_full",

  // ФАЙЛЫ
  "Скан паспорта": "passport",
  "Скан СНИЛС": "snils",
  "Скан прописки": "passport2",
  Сертификат: "zayavkep",
  "Фото клиента": "clientphoto",
  Устав: "ustav",
  "Юр. адрес": "uraddress",
  Решение: "reshenie",
  Другое: "drug",

  // ОСТАЛЬНОЕ
  "Система налогобложения": "tax_type",
  "Выдать документы на бумажном носителе": "vidat",
  "Идентификация курьерской службой": "sign_courier_identification",
  "Комментарий для курьера": "delivery_comment",
  "Адрес для встречи с курьером": "postaddress",
  "Адрес для встречи с курьером (полн.)": "postaddr_full",
  "Регион доставки": "postregion",
  "Город доставки": "postcity",

  основнойОКВЭД: "primaryOkved",
  допОКВЭД: "okveds",

  //ссылка на подписание
  ссылкаНаПодписание: "1registr_signlink",

  // документооборот для заявки, отправленной в ФНС
  контейнерДляФНС: "1registr_fnsdocurl",
  расписка: "1registr_fnsreceipt",
  документыИзФНС: "1registr_fnsresult",

  "Сертификат передан в Бюро": "cert_buro",
  "Сертификат передан в УЦ": "cert_signme",

  // ООО
  "Имя компании": "name",
  "Сокращенное наименование": "shortOOO",
  Наименование: "shortooo",
  "Полное наименование": "fullOOO",
  "Сокращенное наименование на английском языке": "shortOOOEng",
  "Полное наименование на английском языке": "fullOOOEng",
  "Есть наименование на английском языке": "eng_bool",
  "Дата создания": "OOO_creation",
  адресКомпании: "caddress",
  "Адрес для документов": "custom_address",
  "Уставный капитал": "OOO_capital",
  "Указывать полный адрес в документах": "fullAddressSelected",
  "Общество не использует печать": "stampSelected",
  "Общество использует типовой Устав": "ustav_checkbox",
  "Размер доли": "equity_cost",
  "Доля (в процентах)": "equity",
  "Доля (простая дробь)": "equity_simple_ratio",
  "Наименование должности": "executive_title",
  "Срок полномочий": "ceo_time",
  Бессрочно: "ceo_time_inf",
  Администратор: "owner",
  учредители: "owners",
  максIdучредителя: "maxOwnerId",
  ЕИО: "executives",
  Телефон: "phone",
  Баланс: "balance",
  // данный атрибут не отправляются на сервер
  "Назначить исполнительным органом": "isEIO",
  "Вид указания размера уставного капитала": "equity_format",
  "Указывать данные компании у заявителя": "applicant_description",
  "Ключ Точки": "tochkaKey",

  // вспомогательные
  ссылкаНаПодписаниеУчредителя: "signlink",
  ссылкаНаЗаявлениеКЭП: "1registr_crt_link",
  ссылкаНаАктивацию: "1registr_company_activate",

  // ИЗМЕНЕНИЕ ООО и ИП
  ОГРН: "ogrn",
  ОГРНИП: "ogrnip",
  "Причина представления заявления": "change",
  "Форма изменений в учредительный документ": "ustav_form",
  "Номер типового Устава": "ustav_number",
  // ГРН: "grn",
  "Изменения в целях приведения устава в соответствие": "privded_ustav",
  ИННкомпании: "company_inn",
  наименованиеДолжности: "cjob",
  изменениеМестонахождения: "changeRegistration",
  изменениеОКВЭД: "changeOKVED",

  // изменение пароля
  "Текущий пароль": "currentPassword",
  "Новый пароль": "newPassword",
  "Подтверждение нового пароля": "newPasswordConfirm",

  // для компании
  "Коммерческое наименование": "oooshort",
  "Регистраций в месяц": "quantity",
  "Род деятельности": "activity",
  статусПользователя: "userStatus",
  Сайт: "web",

  "Полное наименование компании": "ooofull",

  Пароль: "password",
  "Подтверждение пароля": "confirm_password",

  ошибкаОКВЭД: "okved_error",

  // для отправки форм в маркетплейсе
  ФИО: "name",
  Город: "city",
  Компания: "company_name",
  "Фамилия маркетплейс": "last_name",
  "Имя маркетплейс": "first_name",
  "Отчество маркетплейс": "second_name",
  "Дата рождения маркетплейс": "birthday",
  "Пол маркетплейс": "sex",
  "Кем выдан паспорт маркетплейс": "issuedBy",
  "СНИЛС маркетплейс": "snils",
  "Номер паспорта маркетплейс": "number",
  "Серия паспорта маркетплейс": "serial",
  "Полный адрес маркетплейс": "address",
  "Электронная почта маркетплейс": "email",
  // используется только на фронте, перед отправкой на бек разбивается на серию и номер
  "Серия и номер паспорта маркетплейс": "snp",
  "Дата выдачи паспорта маркетплейс": "dateStart",

  // пользователи сервиса
  Роль: "status",
  "ID компании": "companyId",
  id: "id",

  токенДляСсылкиЗагрузкиФайлов: "identification_id",
} as const;

// для логина
export const loginAttributes = {
  "Электронная почта": "email",
  Пароль: "password",
};

export const equityFormatToAttr: Record<EquityFormatType, string> = {
  percent: attributes["Доля (в процентах)"],
  simple: attributes["Доля (простая дробь)"],
};
