import { updateValidateFileError } from "src/redux/files";
import { errorsTypes } from "src/constants";
import { UploadFile } from "antd/lib/upload/interface";
import { AppState } from "src/redux/AppState";

// конвертируте из Blob в base64
export const convertBlobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });

// проверяет, что файл является изображением
export const isImage = (file: UploadFile) => {
  return file.type === "image/jpeg" || file.type === "image/png";
};

// проверяет, что файл является документом
export const isPdfOrImage = (file: UploadFile) => {
  return isImage(file) || file.type === "application/pdf";
};

// проверяет, что файл является tif
export const isTif = (file: UploadFile) => {
  return file?.name?.endsWith(".tif");
};

// проверяет, что файл является tiff
export const isTiff = (file: UploadFile) => {
  return file?.name?.endsWith(".tiff");
};

export const wasFileLoaded = (state: AppState, dispatch: Function, fileName: string) => {
  const { loaded } = state.files;
  if (!loaded[fileName]) {
    dispatch(updateValidateFileError(fileName, errorsTypes.requiredField));
    return false;
  }
  dispatch(updateValidateFileError(fileName, ""));
  return true;
};

export const getBase64 = (img: Blob, callback: Function) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
