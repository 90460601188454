import { filesConstants } from "./../files.constants";
import { message } from "antd";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { removeOtherFile } from "./removeOtherFile";

export const removeFile = (entryId: string | number, requestAttribute: string) => {
  const fileInGroup = requestAttribute.match(/drug(13|14)?_\d/g);

  const { FILE_REMOVE_REQUEST, FILE_REMOVE_SUCCESS, FILE_REMOVE_FAILURE } = filesConstants;
  const request = () => ({ type: FILE_REMOVE_REQUEST });
  const success = (requestAttribute: string) => ({ type: FILE_REMOVE_SUCCESS, requestAttribute });
  const failure = () => ({ type: FILE_REMOVE_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      await API.delete(`${endpoints.files.remove}/${entryId}/${requestAttribute}`);
      dispatch(success(requestAttribute));
      if (fileInGroup) {
        dispatch(removeOtherFile(requestAttribute));
      }
    } catch (err: any) {
      message.error(`Не удалось удалить файл ${err}`);
      dispatch(failure());
    }
  };
};
