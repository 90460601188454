import { store } from "src/redux/store";
import { getFormValues, submit, isValid, setSubmitFailed, hasSubmitSucceeded, hasSubmitFailed } from "redux-form";
import { formsNames, attributes } from "src/constants";
import { cloneDeep } from "lodash";
import { wasFileLoaded, validateOkveds, getChangedOkveds, trimSpaces, history, isScene } from "src/helpers";
import { updateEntryValidateError } from "src/redux/entries";
import qs from "query-string";
import { AppState } from "src/redux/AppState";

export const validateChangeIPModal = (dispatch: Function): boolean => {
  let state = store.getState();

  // проводим валидацию, только если проведена валидация остальных форм

  if (hasSubmitSucceeded("INNandOGRN")(state) || hasSubmitFailed("INNandOGRN")(state)) {
    dispatch(submit(formsNames["common"]));
    dispatch(setSubmitFailed(formsNames["common"]));
    dispatch(submit(formsNames["delivery"]));
    dispatch(setSubmitFailed(formsNames["delivery"]));
    dispatch(submit(formsNames["passport"]));
    dispatch(setSubmitFailed(formsNames["passport"]));
    dispatch(submit(formsNames["registration"]));
    dispatch(setSubmitFailed(formsNames["registration"]));

    // после сабмита стор обновляется
    state = store.getState();

    const commonValid = isValid("common")(state);
    const deliveryValid = isValid("delivery")(state);
    const passportValid = isValid("passport")(state);
    const registrationValid = isValid("registration")(state);

    const modalValid = commonValid && deliveryValid && passportValid && registrationValid;

    if (!modalValid) {
      dispatch(updateEntryValidateError("applicantError", true));
    } else {
      dispatch(updateEntryValidateError("applicantError", false));
    }

    return modalValid;
  }

  return false;
};

export const validateChangeIP = (dispatch: Function) => {
  // эмулируем сабмит всех форм
  dispatch(submit(formsNames["INNandOGRN"]));
  dispatch(setSubmitFailed(formsNames["INNandOGRN"]));

  // извлекаем информацию об ошибках всех форм
  const state = store.getState();
  const INNandOGRNValid = isValid("INNandOGRN")(state);

  // узнаём, отметил ли пользователь чекбокс
  const changeOKVED = state?.entries?.formattedEntry?.changeOKVED;

  if (!changeOKVED) {
    dispatch(updateEntryValidateError("changeOKVEDError", true));
  } else {
    dispatch(updateEntryValidateError("changeOKVEDError", false));
  }

  // проверка, что пользователь выбрал основной оквэд
  const okvedsOK = validateOkveds(state, dispatch);

  // валидация форм в модалке
  const modalValid = validateChangeIPModal(dispatch);

  return modalValid && okvedsOK && INNandOGRNValid;
};

export const validateChangeIPFiles = (dispatch: Function): boolean => {
  const state = store.getState();
  let filesOK = true;
  filesOK = wasFileLoaded(state, dispatch, attributes["Скан паспорта"]) && filesOK;
  filesOK = wasFileLoaded(state, dispatch, attributes["Скан прописки"]) && filesOK;
  filesOK = wasFileLoaded(state, dispatch, attributes["Скан СНИЛС"]) && filesOK;

  if (!filesOK) {
    dispatch(updateEntryValidateError("applicantError", true));
  } else {
    dispatch(updateEntryValidateError("applicantError", false));
  }

  const {
    entries: { currentEntry },
  } = state;

  // если требуется активация после шага СМЭВ, то нужно убедиться, что пользователь загрузил Сертификат и Фото клиента
  if (isScene("Активация КЭП", currentEntry!.scene)) {
    filesOK = wasFileLoaded(state, dispatch, attributes["Сертификат"]) && filesOK;
    filesOK = wasFileLoaded(state, dispatch, attributes["Фото клиента"]) && filesOK;
  }

  return filesOK;
};

export const extractChangeIPData = (state: AppState) => {
  // соберем formData из всех форм
  const INNandOGRNValues = getFormValues(formsNames["INNandOGRN"])(state);
  const docsValues: Record<string, any> = getFormValues(formsNames["docsFormat"])(state);

  // формы в модальном окне заявителя
  const passportValues = getFormValues(formsNames["passport"])(state);
  const commonValues = getFormValues(formsNames["common"])(state);
  const deliveryValues = getFormValues(formsNames["delivery"])(state);
  const registrationValues = getFormValues(formsNames["registration"])(state);

  // извлекаем ОКВЭДы
  const { changeOKVED } = state.entries.formattedEntry!;
  const { selectedActivities } = state.activities;

  const [okvedAddition, okvedDeletion, primaryOkvedAddition, primaryOkvedDeletion] =
    getChangedOkveds(selectedActivities);

  const allData = cloneDeep(
    Object.assign(
      {},
      docsValues,
      passportValues,
      commonValues,
      deliveryValues,
      registrationValues,
      INNandOGRNValues,
      { okvedAddition },
      { okvedDeletion },
      { primaryOkvedAddition },
      { primaryOkvedDeletion },
      { changeOKVED },
    ),
  );

  const primaryOkved = selectedActivities.filter((it) => it["main"]);
  const secondaryOkveds = selectedActivities.filter((it) => !it["main"]);

  allData[attributes["основнойОКВЭД"]] = primaryOkved.map((it) => it.code).join(",");
  allData[attributes["допОКВЭД"]] = secondaryOkveds.map((it) => it.code).join(",");

  const id = qs.parse(history.location.search)["id"];
  allData["id"] = id;
  allData[attributes["Выдать документы на бумажном носителе"]] = docsValues[
    attributes["Выдать документы на бумажном носителе"]
  ]
    ? "1"
    : "";
  // берём значения, которые подставились после ввода ОГРН
  //@ts-ignore
  allData[attributes["Код терр. ФНС"]] = state.entries.formattedEntry[attributes["Код терр. ФНС"]];
  //@ts-ignore
  allData[attributes["Код рег. органа"]] = state.entries.formattedEntry[attributes["Код рег. органа"]];

  trimSpaces(allData);

  return allData;
};
