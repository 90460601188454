import { marketplaceConstants } from "./../marketplace.constants";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { message } from "antd";
import { IMarketPlaceRequest } from "src/helpers/types/MarketplaceCard";

export const getRequests = () => {
  const { GET_REQUESTS_REQUEST, GET_REQUESTS_SUCCESS, GET_REQUESTS_FAILURE } = marketplaceConstants;
  const request = () => ({ type: GET_REQUESTS_REQUEST });
  const success = (requests: IMarketPlaceRequest[]) => ({ type: GET_REQUESTS_SUCCESS, requests });
  const failure = () => ({ type: GET_REQUESTS_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      const requests: IMarketPlaceRequest[] = await API.get(`${endpoints.marketplace.getRequests}`);
      dispatch(success(requests));
    } catch (err: any) {
      message.error(`Не удалось запросить список заявок в маркетплейс ${err}`);
      dispatch(failure());
    }
  };
};
