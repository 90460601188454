import { attributes } from "src/constants";

export const getInitialValues = (entryData: Record<string, any>) => {
  if (entryData) {
    return {
      [attributes["Выдать документы на бумажном носителе"]]:
        entryData[attributes["Выдать документы на бумажном носителе"]] === "1" ? true : false,
    };
  }

  return {
    [attributes["Выдать документы на бумажном носителе"]]: false,
  };
};
