import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import { Divider } from "antd";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { attributes } from "src/constants";
import { fileFields } from "./constants";
import "./Forms.scss";
import { CommonForm, PassportForm, FilesForm, RegistrationForm, ForeignForm } from "src/forms";
import { isAdmin } from "src/helpers";
import { AppState } from "src/redux/AppState";
import { IProps } from "./interface";

const sf = cn("site-forms");
const b = cn("passportChange-forms");

class Forms extends Component<IProps> {
  render() {
    const { loading, wasLoaded, inostr, disabled, currentPassport, userCompanyId } = this.props;

    return (
      <div className={`${sf({ "with-files": true })} ${b()}`}>
        <div className={sf("forms")}>
          <CommonForm
            disabled={disabled}
            passportChange={!isAdmin(userCompanyId)}
            data={currentPassport}
            showNalog={false}
            showFnsPhone={false}
          />
          <Divider />
          <PassportForm passportChange={true} disabled={disabled} data={currentPassport} />
          <Divider />
          {inostr === "true" && (
            <div>
              <ForeignForm disabled={disabled} data={currentPassport} /> <Divider />
            </div>
          )}
          <RegistrationForm disabled={disabled} data={currentPassport} />
        </div>
        <FilesForm fileFields={fileFields} disabled={disabled} loading={loading} wasLoaded={wasLoaded} />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { loading, wasLoaded } = state.passports;
  const userCompanyId = state.userReducer.user?.company?.id;
  const passportValues = formValueSelector("passport");
  const inostr = passportValues(state, attributes["иностранец"]);
  return { loading, wasLoaded, inostr, userCompanyId };
};

const connectedComponent = connect(mapStateToProps)(Forms);

export { connectedComponent as Forms };
