import { entriesConstants } from "./../entries.constants";
import { extractOwnerData } from "src/helpers";
import { updateEIO, clearEIO, clearMakeEIO } from "./";
import { store } from "src/redux/store";
import { IOwner } from "src/helpers/types/Owner";

export const updateOwner = (id: number) => {
  const extractedFromFormsOwnerData = extractOwnerData(id);

  return (dispatch: Function) => {
    extractedFromFormsOwnerData["id"] = id;
    if (extractedFromFormsOwnerData.isEIO) {
      dispatch(clearMakeEIO(id));
      dispatch(updateEIO(extractedFromFormsOwnerData));
    } else {
      // находим учредителя с данным id в ещё не обновлённом сторе
      // и если у него стоит галочка "Назначить ЕИО" и в новых данных
      // данный учредитель не назначен ЕИО, то очищаем данные ЕИО
      const state = store.getState();
      //@ts-ignore
      const owners = state["entries"]["formattedEntry"]["owners"];
      const currentOwner = owners!.filter((it: IOwner) => it.id === id)[0];
      if (currentOwner.isEIO && !extractedFromFormsOwnerData.isEIO) {
        dispatch(clearEIO());
      }
    }
    dispatch({ type: entriesConstants.ENTRY_UPDATE_OWNER, id, data: extractedFromFormsOwnerData });
  };
};
