import { cloneDeep } from "lodash";
import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";
import { ICurrentEntry } from "src/helpers/types/Entry";

interface IAction extends Action {
  fieldName: keyof ICurrentEntry;
  value: string;
}

export const changeCurrentEntry = (state: IEntriesState, action: IAction): IEntriesState => {
  const { fieldName, value } = action;
  const currentEntry = cloneDeep(state.currentEntry);
  if (currentEntry) {
    //@ts-ignore
    currentEntry[fieldName] = value;
  }
  return { ...state, currentEntry };
};
