import { Action } from "redux";
import { IInputState } from "src/redux/input";

interface IAction extends Action {}

export const loadINNRequest = (state: IInputState, action: IAction): IInputState => {
  return {
    ...state,
  };
};

export const loadINNSuccess = (state: IInputState, action: IAction): IInputState => {
  return {
    ...state,
  };
};

export const loadINNFailure = (state: IInputState, action: IAction): IInputState => {
  return {
    ...state,
  };
};
