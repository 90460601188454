import { billsConstants } from "./../bills.constants";
import { message } from "antd";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { IPayment } from "src/helpers/types/Payment";

export const getBills = () => {
  const { GET_BILLS_REQUEST, GET_BILLS_SUCCESS, GET_BILLS_FAILURE } = billsConstants;
  const request = () => ({ type: GET_BILLS_REQUEST });
  const success = (payments: IPayment[]) => ({ type: GET_BILLS_SUCCESS, payments });
  const failure = () => ({ type: GET_BILLS_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      const payments: IPayment[] = await API.get(`${endpoints.bills.getBills}`);
      dispatch(success(payments));
    } catch (err: any) {
      message.error(`Не удалось загрузить счета ${err}`);
      dispatch(failure());
    }
  };
};
