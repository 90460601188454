import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./Marketplace.scss";
import { Loader } from "src/components";
import { Tabs } from "antd";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { getCompanies } from "src/redux/companies";
import { getCards, getRequests } from "src/redux/marketplace";
import { MarketplaceGrid } from "./components/MarketplaceGrid/MarketplaceGrid";
import { MarketplaceTable } from "./components/MarketplaceTable/MarketplaceTable";
import { isAdmin } from "src/helpers";
import { IProps } from "./interface";
import { AppState } from "src/redux/AppState";

const { TabPane } = Tabs;

const b = cn("marketplace-page");
const sp = cn("site-page");

class Marketplace extends Component<IProps> {
  state = {
    currentTab: "offers",
  };

  UNSAFE_componentWillMount() {
    const { getCards, getRequests, getCompanies } = this.props;
    getCards();
    getRequests();
    getCompanies();
  }

  handleTabChange = (currentTab: string) => {
    this.setState({ currentTab });
  };

  renderTabs = () => {
    const { userCompanyId } = this.props;
    return (
      <Tabs onChange={this.handleTabChange} defaultActiveKey="offers" type="card">
        <TabPane tab={<div className={sp("tab-name")}>Предложения</div>} key="offers" />
        {isAdmin(userCompanyId) ? (
          <TabPane tab={<div className={sp("tab-name")}>Отправленные заявки</div>} key="requests" />
        ) : null}
      </Tabs>
    );
  };

  render() {
    const { loading, cards, requests, companies } = this.props;
    const { currentTab } = this.state;

    return (
      <div className={`${b()} ${sp()}`}>
        <Helmet>
          <title>Маркетплейс</title>
        </Helmet>
        {loading && <Loader />}
        <div className={sp("tabs")}>{this.renderTabs()}</div>
        <div className={sp("content")}>
          {currentTab === "offers" ? (
            <MarketplaceGrid data={cards} />
          ) : (
            <MarketplaceTable data={{ requests, cards, companies }} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { loading, cards, requests } = state.marketplace;
  const companies = state.companies.companies?.result;
  const user = state?.userReducer?.user;
  const userCompanyId = user?.company?.id;

  return { loading, cards, requests, companies, userCompanyId };
};

const mapDispatchToProps = {
  getCards,
  getRequests,
  getCompanies,
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(Marketplace);

export { connectedComponent as Marketplace };
