import { usersConstants } from "../users.constants";
import { createReducer } from "@reduxjs/toolkit";
import { getUsersRequest, getUsersSuccess, getUsersFailure } from "./getUsers";
import { addUserRequest, addUserSuccess, addUserFailure } from "./addUser";
import { updateUserRequest, updateUserSuccess, updateUserFailure } from "./updateUser";
import { changeUserPasswordRequest, changeUserPasswordSuccess, changeUserPasswordFailure } from "./changeUserPassword";
import { deactivateUserRequest, deactivateUserSuccess, deactivateUserFailure } from "./deactivateUser";
import { IUser } from "src/helpers/types/User";

export interface IUsersState {
  users?: IUser[];
  loading: boolean;
}

const initialState: IUsersState = { users: undefined, loading: false };

export const users = createReducer(initialState, {
  [usersConstants.GET_USERS_REQUEST]: getUsersRequest,
  [usersConstants.GET_USERS_SUCCESS]: getUsersSuccess,
  [usersConstants.GET_USERS_FAILURE]: getUsersFailure,

  [usersConstants.ADD_USER_REQUEST]: addUserRequest,
  [usersConstants.ADD_USER_SUCCESS]: addUserSuccess,
  [usersConstants.ADD_USER_FAILURE]: addUserFailure,
  //
  [usersConstants.UPDATE_USER_REQUEST]: updateUserRequest,
  [usersConstants.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [usersConstants.UPDATE_USER_FAILURE]: updateUserFailure,

  [usersConstants.CHANGE_USER_PASSWORD_REQUEST]: changeUserPasswordRequest,
  [usersConstants.CHANGE_USER_PASSWORD_SUCCESS]: changeUserPasswordSuccess,
  [usersConstants.CHANGE_USER_PASSWORD_FAILURE]: changeUserPasswordFailure,

  [usersConstants.DEACTIVATE_USER_REQUEST]: deactivateUserRequest,
  [usersConstants.DEACTIVATE_USER_SUCCESS]: deactivateUserSuccess,
  [usersConstants.DEACTIVATE_USER_FAILURE]: deactivateUserFailure,
});
