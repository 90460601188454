import React from "react";
import { Link } from "react-router-dom";
import { cn } from "src/helpers/bem";
import { message } from "antd";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import "./UploadLink.scss";
import { CopyToClipboardButton } from "src/components/CopyToClipboardButton/CopyToClipboardButton";

const b = cn("upload-link");

interface IProps {
  id: number;
  narrow: boolean;
  ownerNum?: number;
  identificationId?: string;
}

function generateUploadLink({
  identificationId,
  entryId,
}: {
  identificationId?: string;
  entryId: number;
}): string | undefined {
  if (!identificationId) {
    return undefined;
  }

  return `upload-docs?id=${entryId}&token=${identificationId}`;
}

export class UploadLink extends React.Component<IProps, { uploadLink?: string; isLoading: boolean }> {
  static defaultProps = {
    narrow: false,
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      uploadLink: generateUploadLink({ identificationId: this.props.identificationId, entryId: this.props.id }),
      isLoading: false,
    };
  }

  getUploadLink = async () => {
    const { id, ownerNum } = this.props;

    this.setState({ isLoading: true });

    try {
      const result: { generatedUrl: { expiredMillis: number; id: string } } = await API.post(
        `${endpoints.entries.getUploadLink}/${id}${typeof ownerNum === "number" ? `?ownerNum=${ownerNum}` : ""}`
      );

      this.setState({
        uploadLink: `upload-docs?id=${id}&token=${result.generatedUrl.id}`,
      });
    } catch (err) {
      const error = JSON.parse(err as string);
      message.error(error.reason);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { narrow } = this.props;

    return this.state.uploadLink ? (
      <div className={b("row")}>
        <Link to={this.state.uploadLink} className={b("", { narrow })} target="blank">
          Ссылка на&nbsp;загрузку
        </Link>
        <CopyToClipboardButton textToCopy={`${API.defaults.baseURL}/a/${this.state.uploadLink}`} />
      </div>
    ) : (
      <button className={b("", { narrow })} disabled={this.state.isLoading} onClick={this.getUploadLink}>
        Получить ссылку на&nbsp;загрузку
      </button>
    );
  }
}
