import React from "react";
import { cn } from "src/helpers/bem";
import { Header, NavMenu, Footer } from "src/components";
import "./DashboardLayout.scss";
import { IProps } from "./interface";

const b = cn("dashboard-layout");

export class DashboardLayout extends React.Component<IProps> {
  render() {
    // @ts-ignore
    const { children } = this.props;

    return (
      <div className={b()}>
        {
          //@ts-ignore
          <Header fixed outer />
        }
        <NavMenu className={b("menu")} />
        <div className={b("container")}>
          <main className={b("main-wrapper")}>{children}</main>
          <Footer />
        </div>
      </div>
    );
  }
}
