import { usersConstants } from "../users.constants";
import { endpoints } from "src/constants";
import { API } from "src/helpers";
import { message } from "antd";
import { Dispatch } from "redux";

export interface IAddUserParams {
  activated: boolean;
  avatarURL: string;
  companyId: number;
  email: string;
  firstName: string;
  fullName: string;
  lastName: string;
  middleName: string;
  password: string;
  status: string;
  userID: string;
}

export const addUser = (data: IAddUserParams) => {
  const { ADD_USER_REQUEST, ADD_USER_SUCCESS, ADD_USER_FAILURE } = usersConstants;
  const request = () => ({ type: ADD_USER_REQUEST });
  const success = () => ({ type: ADD_USER_SUCCESS });
  const failure = () => ({ type: ADD_USER_FAILURE });

  return async function (dispatch: Dispatch) {
    dispatch(request());
    try {
      await API.post(endpoints.users.users, data);
      message.success("Пользователь добавлен");
      dispatch(success());
    } catch (err: any) {
      message.error(`Не удалось добавить пользователя ${err}`);
      dispatch(failure());
    }
  };
};
