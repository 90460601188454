import { IForm } from "src/helpers/types/Form";
import { formsNames } from "src/constants";
import { ICompanyExtended } from "src/helpers/types/Company";

export interface IProps extends IForm {
  className?: string;
  opened: boolean;
  companyInfo: ICompanyExtended;
  changeFieldValue: (field: string, value: any) => void;
  formValid: boolean;
  updateCompany: (
    initialData: ICompanyExtended,
    newData: Pick<
      ICompanyExtended,
      "balance" | "id" | "inn" | "kpp" | "name" | "ogrn" | "owner" | "shortooo" | "tochkaKey"
    >,
  ) => Promise<void>;
  formValues: Pick<
    ICompanyExtended,
    "balance" | "id" | "inn" | "kpp" | "name" | "ogrn" | "owner" | "shortooo" | "tochkaKey"
  >;
  changeCompanySettings: (
    companyId: number,
    flags: { public_balance?: boolean; reqActivate?: boolean; activated?: boolean },
  ) => Promise<void>;
  onUpdate: (clearPaginationPage?: boolean) => void;
}

export const defaultProps = {
  form: formsNames["companySettings"],
};
