import { IEntriesState, SearchEntriesResponseType } from "src/redux/entries";
import { Action } from "redux";

interface IAction extends Action {
  entries: SearchEntriesResponseType;
}

export const searchEntriesRequest = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    loading: true,
  };
};

export const searchEntriesSuccess = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    loading: false,
    //@ts-ignore
    entries: { ...state.entries, data: action.entries },
    error: false,
  };
};

export const searchEntriesFailure = (state: IEntriesState, action: IAction): IEntriesState => {
  return { ...state, error: true, loading: false };
};
