import React from "react";
import { cn } from "src/helpers/bem";
import "./SiteHeader.scss";
import logo from "../../assets/logo.svg";
import { Link } from "react-router-dom";
import { pages } from "src/constants";

const b = cn("landing-header");

export const SiteHeader = () => {
  return (
    <header className={b()}>
      <div className="main-container">
        <div className={b("container")}>
          <div className={b("logo-wrapper")}>
            <img src={logo} alt="Логотип Бюро." width="90" height="22" />
          </div>
          <nav className={b("nav-wrapper")}>
            <ul className={b("nav-list")}>
              <li className={b("nav-item")}>
                <a href="#how-it-works-section" className={b("nav-link")}>
                  Как это работает?
                </a>
              </li>
              <li className={b("nav-item")}>
                <a href="#price-section" className={b("nav-link")}>
                  Сколько стоит?
                </a>
              </li>
              <li className={b("nav-item")}>
                <Link to={pages.login} className={b("nav-link")}>
                  Войти
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};
