import { IUserState } from "src/redux/user";
import { Action } from "redux";
import { IUserInfo } from "src/helpers/types/User";

interface IAction extends Action {
  user: IUserInfo;
}

export const getUserInfoRequest = (state: IUserState, action: IAction): IUserState => {
  return {
    ...state,
    loading: true,
  };
};

export const getUserInfoSuccess = (state: IUserState, action: IAction): IUserState => {
  return {
    ...state,
    loading: false,
    user: action.user,
  };
};

export const getUserInfoFailure = (state: IUserState, action: IAction): IUserState => {
  return { ...state, loading: false };
};
