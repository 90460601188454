import { IUsersState } from "src/redux/users";

export const changeUserPasswordRequest = (state: IUsersState): IUsersState => {
  return {
    ...state,
    loading: true,
  };
};

export const changeUserPasswordSuccess = (state: IUsersState): IUsersState => {
  return {
    ...state,
    loading: false,
  };
};

export const changeUserPasswordFailure = (state: IUsersState): IUsersState => {
  return {
    ...state,
    loading: false,
  };
};
