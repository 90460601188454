import { attributes } from "src/constants";
import { IFileField } from "src/helpers/types/Files";

export const getFileFields = (token: string): IFileField[] => [
  {
    attribute: attributes["Скан паспорта"],
    title: "Скан паспорта",
    label: "Паспорт",
    fileType: "fastUpload",
    params: { token },
  },
  {
    attribute: attributes["Скан прописки"],
    title: "Скан прописки",
    label: "Прописка",
    fileType: "fastUpload",
    params: { token },
  },
  {
    attribute: attributes["Скан СНИЛС"],
    title: "Скан СНИЛС",
    label: "СНИЛС",
    fileType: "fastUpload",
    params: { token },
  },
  {
    attribute: attributes["Сертификат"],
    title: "Сертификат",
    label: "Сертификат",
    fileType: "fastUpload",
    params: { token },
  },
  {
    attribute: attributes["Фото клиента"],
    title: "Фото клиента",
    label: "Фото клиента",
    fileType: "fastUpload",
    params: { token },
  },
];
