import { entriesConstants } from "./../entries.constants";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { message } from "antd";
import { ICurrentEntry } from "src/helpers/types/Entry";

export type SearchEntriesResponseType = Array<
  Pick<ICurrentEntry, "attributes" | "companyId" | "createdAt" | "entryType" | "id" | "phone" | "scene">
>;

export const searchEntries = (searchQuery: string, count: number, page: number, type: string) => {
  const { ENTRIES_SEARCH_REQUEST, ENTRIES_SEARCH_SUCCESS, ENTRIES_SEARCH_FAILURE } = entriesConstants;
  const request = () => ({ type: ENTRIES_SEARCH_REQUEST });
  const success = (entries: SearchEntriesResponseType) => ({ type: ENTRIES_SEARCH_SUCCESS, entries });
  const failure = () => ({ type: ENTRIES_SEARCH_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      const entries: SearchEntriesResponseType = await API.get(
        `${endpoints.entries.search}?search=${encodeURIComponent(
          searchQuery,
        )}&li=${count}&page=${page}&entryType=${type}`,
      );
      dispatch(success(entries));
    } catch (err: any) {
      message.error(`Не удалось найти заявки ${err}`);
      dispatch(failure());
    }
  };
};
