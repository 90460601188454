import { IInputState } from "src/redux/input";
import { Action } from "redux";

interface IAction extends Action {
  meta: { field: string; form: string };
}

export const reduxFormFocus = (state: IInputState, action: IAction): IInputState => {
  let field = "",
    form = "";
  if (action.meta) {
    field = action.meta.field;
    form = action.meta.form;
  }

  return {
    ...state,
    lastFocusedForm: form,
    lastFocusedField: field,
    inputsColors: Object.assign({}, state.inputsColors, { [`${form}-${field}`]: "" }),
  };
};
