import { userConstants } from "./../user.constants";
import { createReducer } from "@reduxjs/toolkit";
import { getUserInfoRequest, getUserInfoSuccess, getUserInfoFailure } from "./getUserInfo";
import { changePasswordRequest, changePasswordSuccess, changePasswordFailure } from "./changePassword";
import {
  changeCompanySettingsRequest,
  changeCompanySettingsSuccess,
  changeCompanySettingsFailure,
} from "./changeCompanySettings";
import { logout } from "./logout";
import { signUpRequest, signUpSuccess, signUpFailure } from "./signUp";
import { getTokenRequest, getTokenSuccess, getTokenFailure } from "./getToken";
import { IUserInfo, USER_STATUS } from "src/helpers/types/User";
import { EMPTY_NUMBER_VALUE, EMPTY_BOOLEAN_VALUE, EMPTY_STRING_VALUE } from "src/constants/emptyInitialVlaues";

export const emptyUser = {
  balance: EMPTY_NUMBER_VALUE,
  block_session: EMPTY_BOOLEAN_VALUE,
  company: {
    activated: EMPTY_BOOLEAN_VALUE,
    balance: EMPTY_NUMBER_VALUE,
    createdAt: EMPTY_STRING_VALUE,
    creator: EMPTY_STRING_VALUE,
    id: EMPTY_NUMBER_VALUE,
    inn: EMPTY_STRING_VALUE,
    kpp: EMPTY_STRING_VALUE,
    name: EMPTY_STRING_VALUE,
    ogrn: EMPTY_STRING_VALUE,
    owner: EMPTY_STRING_VALUE,
    reqActivate: EMPTY_BOOLEAN_VALUE,
    shortooo: EMPTY_STRING_VALUE,
    tochkaAccess: EMPTY_BOOLEAN_VALUE,
    tochkaKey: EMPTY_STRING_VALUE,
  },
  company_settings: {
    activated: EMPTY_BOOLEAN_VALUE,
    companyId: EMPTY_NUMBER_VALUE,
    dbrain: EMPTY_BOOLEAN_VALUE,
    public_balance: EMPTY_BOOLEAN_VALUE,
    reqActivate: EMPTY_BOOLEAN_VALUE,
    scoped: EMPTY_BOOLEAN_VALUE,
    session_limit: EMPTY_BOOLEAN_VALUE,
    spheraAccess: EMPTY_BOOLEAN_VALUE,
  },
  public_balance: EMPTY_BOOLEAN_VALUE,
  registrationsLeft: EMPTY_NUMBER_VALUE,
  session_limit: EMPTY_BOOLEAN_VALUE,
  user: {
    activated: EMPTY_BOOLEAN_VALUE,
    status: USER_STATUS.OBSERVER,
  },
  avatarURL: EMPTY_STRING_VALUE,
  email: EMPTY_STRING_VALUE,
  firstName: EMPTY_STRING_VALUE,
  fullName: EMPTY_STRING_VALUE,
  lastName: EMPTY_STRING_VALUE,
  loginInfo: {
    providerID: EMPTY_STRING_VALUE,
  },
  providerKey: EMPTY_STRING_VALUE,
  userID: EMPTY_STRING_VALUE,
  user_hash: EMPTY_STRING_VALUE,
};

export interface IUserState {
  loginError: boolean;
  user: IUserInfo;
  loading: boolean;
}

const initialState: IUserState = {
  loginError: false,
  user: emptyUser,
  loading: false,
};

export const userReducer = createReducer(initialState, {
  [userConstants.SIGNUP_REQUEST]: signUpRequest,
  [userConstants.SIGNUP_SUCCESS]: signUpSuccess,
  [userConstants.SIGNUP_FAILURE]: signUpFailure,

  [userConstants.GET_TOKEN_REQUEST]: getTokenRequest,
  [userConstants.GET_TOKEN_SUCCESS]: getTokenSuccess,
  [userConstants.GET_TOKEN_FAILURE]: getTokenFailure,

  [userConstants.GET_USER_INFO_REQUEST]: getUserInfoRequest,
  [userConstants.GET_USER_INFO_SUCCESS]: getUserInfoSuccess,
  [userConstants.GET_USER_INFO_FAILURE]: getUserInfoFailure,

  [userConstants.CHANGE_PASSWORD_REQUEST]: changePasswordRequest,
  [userConstants.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [userConstants.CHANGE_PASSWORD_FAILURE]: changePasswordFailure,

  [userConstants.CHANGE_COMPANY_SETTINGS_REQUEST]: changeCompanySettingsRequest,
  [userConstants.CHANGE_COMPANY_SETTINGS_SUCCESS]: changeCompanySettingsSuccess,
  [userConstants.CHANGE_COMPANY_SETTINGS_FAILURE]: changeCompanySettingsFailure,

  [userConstants.USER_LOGOUT]: logout,
});
