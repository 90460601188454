import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";

interface IAction extends Action {
  smevPoolingDescriptor: NodeJS.Timer;
}

export const smevPoolingRequest = (state: IEntriesState, action: IAction): IEntriesState => {
  clearInterval(state.smevPoolingDescriptor!);
  return {
    ...state,
    smevPoolingDescriptor: action.smevPoolingDescriptor,
    // loading: true,
  };
};

export const smevPoolingOnlyPassportLeft = (state: IEntriesState): IEntriesState => {
  return {
    ...state,
    smevOnlyPassportLeft: true,
  };
};

export const smevPoolingSuccess = (state: IEntriesState, action: IAction): IEntriesState => {
  clearInterval(state.smevPoolingDescriptor!);
  return {
    ...state,
    smevPoolingDescriptor: null,
    smevOnlyPassportLeft: false,
    // loading: false,
    error: false,
  };
};

export const smevPoolingFailure = (state: IEntriesState, action: IAction): IEntriesState => {
  clearInterval(state.smevPoolingDescriptor!);
  return { ...state, smevPoolingDescriptor: null, error: true, loadingWithoutLogo: false, loading: false };
};
