import { inputConstants } from "./../input.constants";
import { message } from "antd";
import { fetchSonoAreaHelper } from "src/helpers";

export const loadSonoArea = (tax_office: string) => {
  const { SONO_AREA_LOAD_REQUEST, SONO_AREA_LOAD_SUCCESS, SONO_AREA_LOAD_FAILED } = inputConstants;
  const request = () => ({ type: SONO_AREA_LOAD_REQUEST });
  const success = (sono: string) => ({ type: SONO_AREA_LOAD_SUCCESS, sono });
  const failure = () => ({ type: SONO_AREA_LOAD_FAILED });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      if (tax_office) {
        const sono_area = await fetchSonoAreaHelper(tax_office);
        dispatch(success(sono_area));
        return sono_area;
      } else {
        message.error("Не удалось определить регистрирущий орган, обратитесь в поддержку");
      }
    } catch (err: any) {
      message.error(`Не удалось определить регистрирущий орган, обратитесь в поддержку ${err}`);
      dispatch(failure());
    }
  };
};
