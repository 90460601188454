import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./CommonCompany.scss";
import { AInput, ARadio, ARadioGroup } from "src/helpers";
import { connect } from "react-redux";
import { Field, reduxForm, submit } from "redux-form";
import { attributes } from "src/constants";
import validate from "./validate";
import { defaultProps, IProps } from "./inteface";

const sf = cn("site-form");
const b = cn("common-company-form");

class CommonCompanyForm extends Component<IProps> {
  renderActivityRadio = (disabled: boolean) => {
    return (
      <React.Fragment>
        <Field name={attributes["Род деятельности"]} component={ARadioGroup} buttonStyle="solid">
          <Field
            type="radio"
            name={attributes["Род деятельности"]}
            value="1"
            title="Регистратор"
            component={ARadio}
            disabled={disabled}
          />
          <Field
            type="radio"
            name={attributes["Род деятельности"]}
            value="2"
            title="Бухгалтер"
            component={ARadio}
            disabled={disabled}
          />
          <Field
            type="radio"
            name={attributes["Род деятельности"]}
            value="3"
            title="Банк"
            component={ARadio}
            disabled={disabled}
          />
          <Field
            type="radio"
            name={attributes["Род деятельности"]}
            value="4"
            title="Таксопарк"
            component={ARadio}
            disabled={disabled}
          />
          <Field
            type="radio"
            name={attributes["Род деятельности"]}
            value="5"
            title="Другое"
            component={ARadio}
            disabled={disabled}
          />
        </Field>
      </React.Fragment>
    );
  };

  render() {
    const { handleSubmit, disabled } = this.props;

    return (
      <form onSubmit={handleSubmit} className={`${sf()} ${b()}`}>
        <div className={sf("section-wrapper")}>
          <section className={sf("section")}>
            <h2 className={sf("title")}>Информация о компании</h2>
            <div className={sf("inputs-grid")}>
              <Field
                title="Коммерческое наименование (под каким названием работает компания):"
                name={attributes["Коммерческое наименование"]}
                component={AInput}
                disabled={disabled}
                className={b("company-name")}
                maxLength={12}
              />
              <Field
                title="Регистраций в месяц:"
                name={attributes["Регистраций в месяц"]}
                type="number"
                min={0}
                component={AInput}
                disabled={disabled}
                className={b("companies-count")}
              />
              <Field
                title="Адрес сайта:"
                name={attributes["Сайт"]}
                component={AInput}
                disabled={disabled}
                className={b("company-site")}
              />
            </div>
            <div className={sf("radio")}>
              <label className={sf("radio-title")}>Сфера деятельности:</label>
              {this.renderActivityRadio(disabled)}
            </div>
          </section>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  validate,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
})(CommonCompanyForm as any);

connectedComponent = connect(null, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as CommonCompanyForm };
