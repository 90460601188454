import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";

interface IAction extends Action {
  descriptor: ReturnType<typeof setTimeout>;
}

export const linkPoolingRequest = (state: IEntriesState, action: IAction): IEntriesState => {
  return { ...state, linkPoolingDescriptor: action.descriptor };
};

export const linkPoolingSuccess = (state: IEntriesState, action: IAction): IEntriesState => {
  return state;
};

export const linkPoolingFailure = (state: IEntriesState, action: IAction): IEntriesState => {
  return state;
};
