export const billsConstants = {
  GET_BILLS_REQUEST: "GET_BILLS_REQUEST",
  GET_BILLS_SUCCESS: "GET_BILLS_SUCCESS",
  GET_BILLS_FAILURE: "GET_BILLS_FAILURE",

  CHANGE_BILL_STATUS_REQUEST: "CHANGE_BILL_STATUS_REQUEST",
  CHANGE_BILL_STATUS_SUCCESS: "CHANGE_BILL_STATUS_SUCCESS",
  CHANGE_BILL_STATUS_FAILURE: "CHANGE_BILL_STATUS_FAILURE",

  COMPANY_BILL_REQUEST: "COMPANY_BILL_REQUEST",
  COMPANY_BILL_SUCCESS: "COMPANY_BILL_SUCCESS",
  COMPANY_BILL_FAILURE: "COMPANY_BILL_FAILURE",

  CHANGE_BALANCE_REQUEST: "CHANGE_BALANCE_REQUEST",
  CHANGE_BALANCE_SUCCESS: "CHANGE_BALANCE_SUCCESS",
  CHANGE_BALANCE_FAILURE: "CHANGE_BALANCE_FAILURE",

  SHOW_BALANCE_REQUEST: "SHOW_BALANCE_REQUEST",
  SHOW_BALANCE_SUCCESS: "SHOW_BALANCE_SUCCESS",
  SHOW_BALANCE_FAILURE: "SHOW_BALANCE_FAILURE",

  RECOVER_BILL_REQUEST: "RECOVER_BILL_REQUEST",
  RECOVER_BILL_SUCCESS: "RECOVER_BILL_SUCCESS",
  RECOVER_BILL_FAILURE: "RECOVER_BILL_FAILURE",

  SET_DATE_BILL_REQUEST: "SET_DATE_BILL_REQUEST",
  SET_DATE_BILL_SUCCESS: "SET_DATE_BILL_SUCCESS",
  SET_DATE_BILL_FAILURE: "SET_DATE_BILL_FAILURE",
};
