import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./ChangeCommonOOO.scss";
import { AInput, ARadio, ARadioGroup } from "src/helpers";
import { connect } from "react-redux";
import { Field, reduxForm, submit, change, formValueSelector } from "redux-form";
import { attributes } from "src/constants";
import validate from "./validate";
import { getInitialValues } from "./initialValues";
import { AppState } from "src/redux/AppState";
import { IProps, defaultProps } from "./interface";

const sf = cn("site-form");
const b = cn("change-common-form-ooo");

// здесь куча комментариев - остатки разделения заявки изм. ООО
// на Устав и ЕГРЮЛ. пока не удаляю (вдруг что-то пригодится)

class ChangeCommonOOOForm extends Component<IProps> {
  handleRadioChange = () => {
    const { changeFieldValue, form } = this.props;

    changeFieldValue(form, attributes["Номер типового Устава"], "");
    changeFieldValue(form, attributes["Форма изменений в учредительный документ"], "");
  };

  renderRadio = () => {
    const { disabled } = this.props;

    return (
      <React.Fragment>
        <div className={sf("radio-title")}>Причина представления заявления:</div>
        <Field
          name={attributes["Причина представления заявления"]}
          component={ARadioGroup}
          buttonStyle="solid"
          disabled={disabled}
          className={b("radio-inner-wrapper")}
          onChange={this.handleRadioChange}
        >
          <Field
            type="radio"
            name={attributes["Причина представления заявления"]}
            value="1"
            title="Внесение изменений в учредительный документ"
            component={ARadio}
          />
          <Field
            type="radio"
            name={attributes["Причина представления заявления"]}
            value="2"
            title="Внесение изменений в ЕГРЮЛ"
            component={ARadio}
          />
          <Field
            type="radio"
            name={attributes["Причина представления заявления"]}
            value="3"
            title="Изменение сведений о типовом уставе и иных"
            component={ARadio}
          />
          <Field
            type="radio"
            name={attributes["Причина представления заявления"]}
            value="4"
            title="Исправление ошибок, допущенных в ранее представленном заявлении"
            component={ARadio}
          />
        </Field>
      </React.Fragment>
    );
  };

  renderSubradio = () => {
    const { disabled } = this.props;

    return (
      <React.Fragment>
        <div className={sf("radio-title")}>Форма изменений в учредительный документ:</div>
        <Field
          name={attributes["Форма изменений в учредительный документ"]}
          component={ARadioGroup}
          buttonStyle="solid"
          disabled={disabled}
          className={b("radio-inner-wrapper")}
        >
          <Field
            type="radio"
            name={attributes["Форма изменений в учредительный документ"]}
            value="1"
            title="Учредительный документ в новой редакции"
            component={ARadio}
          />
          <Field
            type="radio"
            name={attributes["Форма изменений в учредительный документ"]}
            value="2"
            title="Изменения, внесенные в учредительный документ"
            component={ARadio}
          />
        </Field>
      </React.Fragment>
    );
  };

  render() {
    const { handleSubmit, disabled, changeReason, subtype } = this.props;

    return (
      <div className={`${sf()} ${b()}`}>
        <div className={sf("section-wrapper")}>
          <section className={sf("section")}>
            <h2 className={sf("title")}>Общая информация</h2>
            {/*@ts-ignore*/}
            {this.props.children}
            <form onSubmit={handleSubmit}>
              <div className={`${sf("inputs-grid")} ${b("inputs-grid")}`}>
                <Field
                  title="Сокращенное наименование:"
                  name={attributes["Сокращенное наименование"]}
                  component={AInput}
                  disabled={disabled}
                  className={b("ooo-input")}
                />
                <Field
                  title="Полное наименование:"
                  className={b("ooo-input")}
                  name={attributes["Полное наименование"]}
                  component={AInput}
                  disabled={disabled}
                />
                <div className={b("radio-outer-wrapper", { top: true })}>{this.renderRadio()}</div>
                <div className={b("additional-fields")}>
                  {changeReason == "1" && this.renderSubradio()}
                  {changeReason == "3" && (
                    <>
                      <Field
                        title="Номер типового Устава"
                        name={attributes["Номер типового Устава"]}
                        component={AInput}
                        disabled={disabled}
                        className={b("field", { ustav: true })}
                      />
                    </>
                  )}
                </div>

                {/* {subtype === "egrul" && (
                  <>
                    <Field
                      className={b("ooo-checkbox")}
                      title="Указывать данные компании у заявителя"
                      name={attributes["Указывать данные компании у заявителя"]}
                      component={ACheckbox}
                      disabled={disabled}
                    />                   
                  </>
                )} */}

                {/* {changeReason == "4" && (
                  <Field
                    title="ГРН записи:"
                    name={attributes["ГРН"]}
                    component={AInput}
                    disabled={disabled}
                    mask={"999999999999999"}
                  />
                )} */}

                {/* {subtype === "ustav" && (
                  <Field
                    className={b("ooo-checkbox")}
                    title="Изменения в целях приведения устава в соответствие"
                    name={attributes["Изменения в целях приведения устава в соответствие"]}
                    component={ACheckbox}
                    disabled={disabled}
                  />
                )} */}
              </div>
            </form>
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { data, form } = ownProps;
  const initialValues = data ? getInitialValues(data) : {};
  const formValues = formValueSelector(form);
  const changeReason = formValues(state, attributes["Причина представления заявления"]);
  return { initialValues, changeReason };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    changeFieldValue: (form: string, field: string, value: string) => dispatch(change(form, field, value)),
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  validate,
  shouldError: () => true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(ChangeCommonOOOForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as ChangeCommonOOOForm };
