export const activitiesConstants = {
  GET_ACTIVITIES_REQUEST: "GET_ACTIVITIES_REQUEST",
  GET_ACTIVITIES_SUCCESS: "GET_ACTIVITIES_SUCCESS",
  GET_ACTIVITIES_FAILURE: "GET_ACTIVITIES_FAILURE",

  ACTIVITY_VALIDATE_ERROR: "ACTIVITY_VALIDATE_ERROR",

  ADD_ACTIVITY: "ADD_ACTIVITY",

  REMOVE_ACTIVITY: "REMOVE_ACTIVITY",

  RESTORE_ACTIVITY: "RESTORE_ACTIVITY",

  TOGGLE_ACTIVITY_STAR: "TOGGLE_ACTIVITY_STAR",

  SET_SELECTED_ACTIVITIES: "SET_SELECTED_ACTIVITIES",

  CLEAR_ACTIVITIES: "CLEAR_ACTIVITIES",
};
