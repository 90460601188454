import React from "react";
import { entriesConstants } from "../entries.constants";
import { changeFormattedEntryAttribute } from "./";
import { validateEntry, showLoadingMessage, API } from "src/helpers";
import { endpoints, errorsTypes, attributes, formsNames, entriesTypes } from "src/constants";
import { updateEntry, kepPooling } from ".";
import { store } from "src/redux/store";
import { message } from "antd";
import { cloneDeep, findKey } from "lodash";
import { formValueSelector } from "redux-form";
import { IGetKepResponse } from "src/helpers/types/ResponsesFromBackend";
import { IOwner } from "src/helpers/types/Owner";

export const getKep = (id: number, entryType: entriesTypes) => {
  const { ENTRY_KEP_REQUEST, ENTRY_KEP_SUCCESS, ENTRY_KEP_FAILURE } = entriesConstants;
  const request = () => ({ type: ENTRY_KEP_REQUEST });
  const success = () => ({ type: ENTRY_KEP_SUCCESS });
  const failure = () => ({ type: ENTRY_KEP_FAILURE });

  function getLoadingText({ entryType }: { entryType: entriesTypes }): string {
    if (entryType === entriesTypes.ИзмененияООО) {
      return "Формируем документы";
    }

    return "Сейчас выпускается КЭП";
  }
  return async function (dispatch: Function) {
    // проверяем, что все нужные поля заполнены
    const formCorrect = validateEntry(dispatch);

    if (!formCorrect) {
      message.error(errorsTypes.requiredFields);
    } else {
      showLoadingMessage(getLoadingText({ entryType }), "Обычно это занимает 1-2 минуты.");
      // для начала сохраним заявку
      await dispatch(updateEntry("new_signme", false, true));
      dispatch(request());
      try {
        // @ts-ignore
        const res: IGetKepResponse = await API.post(`${endpoints.entries[`kep_${entryType}`]}/${id}`);
        dispatch(success());
        if (entryType === entriesTypes["РегистрацияООО"] && res["owners"]) {
          // на бэке идёт пересчёт id учредителей, нужно подхватить
          dispatch(changeFormattedEntryAttribute(attributes["учредители"], res["owners"]));
          await dispatch(updateEntry("new_signme", false, true));
        }
        dispatch(kepPooling(id, entryType));
      } catch (err: any) {
        message.destroy();
        if (err["status"] === "Error") {
          message.destroy();

          const state = store.getState();
          if (entryType === entriesTypes["РегистрацияООО"]) {
            // сначала проверим ошибки в учредителях
            if (err["errors"]) {
              // в поле errors находится массив из объектов учредителей вида:
              // [{owner0: "Ошибка УЦ", {owner2: "..."}] - если ошибка из УЦ
              // [{owner0: {lastName: "Тимофеев", firstName: "Евгений"}}, {owner2: {phone: ...}}] - если другая ошибка
              err["errors"].forEach((owner: IOwner) => {
                const ownerForm = Object.keys(owner)[0];
                const ownerValues = Object.values(owner)[0];
                const ownerName = formValueSelector(`${formsNames["common"]}-${ownerForm}`)(
                  state,
                  attributes["Фамилия"]
                );
                if (typeof ownerValues === "string") {
                  message.error(
                    <>
                      {ownerValues} у клиента <span style={{ fontWeight: 500 }}>{ownerName}</span>
                    </>,
                    10
                  );
                } else if (typeof ownerValues === "object") {
                  Object.keys(ownerValues).forEach((errorField: string) => {
                    message.error(
                      <>
                        При создании заявки произошла ошибка. У клиента{" "}
                        {<span style={{ fontWeight: 500 }}>{ownerName}</span>} поле{" "}
                        {findKey(attributes, (attrValue) => attrValue === errorField)} отличается от указанного в
                        предыдущей заявке: {ownerValues[errorField]}
                      </>,
                      10
                    );
                  });
                }
              });
            }
          }

          // определим фамилию клиента (не_учредителя), у которого произошла ошибка
          const lastName = formValueSelector(formsNames["common"])(state, attributes["Фамилия"]);

          // вычленим названия полей с ошибками из ответа сервера
          const errors = cloneDeep(err);
          delete errors["status"];
          delete errors["errors"];
          const error = errors["message"];
          if (typeof error === "string") {
            message.error(
              <>
                {error} у клиента <span style={{ fontWeight: 500 }}>{lastName}</span>
              </>,
              10
            );
          } else if (typeof error === "object") {
            Object.keys(error)?.forEach((errorField) => {
              message.error(
                <>
                  При создании заявки произошла ошибка. У клиента {<span style={{ fontWeight: 500 }}>{lastName}</span>}{" "}
                  поле {findKey(attributes, (attrValue) => attrValue === errorField)} отличается от указанного в
                  предыдущей заявке: {error[errorField]}
                </>,
                10
              );
            });
          }
        } else {
          message.error(`Не удалось выпустить КЭП ${err}`);
        }
        dispatch(failure());
      }
    }
  };
};
