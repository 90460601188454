export const companiesConstants = {
  GET_COMPANIES_REQUEST: "GET_COMPANIES_REQUEST",
  GET_COMPANIES_SUCCESS: "GET_COMPANIES_SUCCESS",
  GET_COMPANIES_FAILURE: "GET_COMPANIES_FAILURE",

  UPDATE_COMPANY_REQUEST: "UPDATE_COMPANY_REQUEST",
  UPDATE_COMPANY_SUCCESS: "UPDATE_COMPANY_SUCCESS",
  UPDATE_COMPANY_FAILURE: "UPDATE_COMPANY_FAILURE",

  SHOW_COMPANIES: "SHOW_COMPANIES",
};
