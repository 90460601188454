import React from "react";
import { isEmpty } from "lodash";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./NavMenu.scss";
import { cn } from "src/helpers/bem";
import classNames from "classnames/dedupe";
import { Menu, Modal } from "antd";
import { SettingsContainer } from "src/components";
import { Link } from "react-router-dom";
import { pages } from "src/constants";
import { isAdmin, canUserViewMarketPlace } from "src/helpers";
import { IProps, IState } from "./interface";
import { AppState } from "src/redux/AppState";
import { USER_STATUS } from "src/helpers/types/User";

const b = cn("site-navmenu");
const { SubMenu } = Menu;

class NavMenu extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      settingsModalVisible: false,
      footerModalVisible: false,
      openedKey: this.props.location.pathname,
      lastMenuItem: this.props.location.pathname,
    };
  }

  menuKeys = {
    entries: [pages.FNSregistration],
  };

  componentDidMount() {
    const { pathname } = this.props.location;
    this.setOpenedKey(pathname);
  }

  componentDidUpdate(prevProps: IProps) {
    const { pathname } = this.props.location;

    if (prevProps.location.pathname !== pathname) {
      this.setOpenedKey(pathname);
    }
  }

  setOpenedKey = (childKey: string) => {
    for (let i = 0; i < Object.entries(this.menuKeys).length; i++) {
      if (Object.entries(this.menuKeys)[i][1].indexOf(childKey) !== -1) {
        this.setState({ openedKey: Object.entries(this.menuKeys)[i][0], lastMenuItem: childKey });
        return;
      }
    }

    this.setState({ openedKey: childKey, lastMenuItem: childKey });
    return;
  };

  handleSettingsModalVisible = () => {
    this.setState({
      settingsModalVisible: !this.state.settingsModalVisible,
    });
  };

  handleKeyChange = (data: any) => {
    const { key } = data;
    this.setOpenedKey(key);
  };

  renderSettingsModal = () => {
    return (
      <Modal
        className={classNames("common-modal", b("settings-modal"))}
        visible={this.state.settingsModalVisible}
        title="Данные доступа"
        onCancel={this.handleSettingsModalVisible}
        footer={null}
      >
        <SettingsContainer />
      </Modal>
    );
  };

  render() {
    const { openedKey, lastMenuItem } = this.state;
    const { className, isCurrentCompanyAdminOrOwner, user, userCompanyId } = this.props;

    const isUserAdmin = isAdmin(userCompanyId);

    return (
      <div className={classNames(b(), className)}>
        <Link to="fns-registration" className={b("logo-link")}>
          <div className={b("logo-wrapper")}>
            <img src="./images/logo.svg" alt="Логотип" />
          </div>
        </Link>

        {!isEmpty(user) && (
          <Menu
            onSelect={this.handleKeyChange}
            className={b("menu-wrapper")}
            mode={"inline"}
            openKeys={[openedKey]}
            theme={"light"}
            selectedKeys={[lastMenuItem]}
          >
            <SubMenu onTitleClick={this.handleKeyChange} key="entries" title="Заявки">
              <Menu.Item key={pages.FNSregistration}>
                <Link style={{ color: "inherit" }} to={pages.FNSregistration}>
                  Регистрация в ФНС
                </Link>
              </Menu.Item>
            </SubMenu>

            {isUserAdmin && (
              <Menu.Item key={pages.clients}>
                <Link style={{ color: "inherit" }} to={pages.clients}>
                  Клиенты
                </Link>
              </Menu.Item>
            )}

            <Menu.Item key={pages.businessCheck}>
              <Link style={{ color: "inherit" }} to={pages.businessCheck}>
                Проверка бизнеса
              </Link>
            </Menu.Item>

            {(isCurrentCompanyAdminOrOwner || isUserAdmin) && (
              <Menu.Item key={pages.personalAccount}>
                <Link style={{ color: "inherit" }} to={pages.personalAccount}>
                  Лицевой счет
                </Link>
              </Menu.Item>
            )}

            {canUserViewMarketPlace() && (
              <Menu.Item key={pages.marketplace}>
                <Link style={{ color: "inherit" }} to={pages.marketplace}>
                  Маркетплейс
                </Link>
              </Menu.Item>
            )}

            {isUserAdmin && (
              <Menu.Item key={pages.users}>
                <Link style={{ color: "inherit" }} to={pages.users}>
                  Пользователи
                </Link>
              </Menu.Item>
            )}

            <Menu.Item key={pages.crtCheck}>
              <Link style={{ color: "inherit" }} to={pages.crtCheck}>
                Проверка сертификата
              </Link>
            </Menu.Item>

            {isUserAdmin && (
              <Menu.Item key="4">
                <a
                  style={{ color: "inherit" }}
                  href="https://www.notion.so/aa316e6461c3405cb7afc57c24e86b6d"
                  target="blank"
                >
                  Библиотека
                </a>
              </Menu.Item>
            )}

            <Menu.Item key="5" onClick={this.handleSettingsModalVisible}>
              Настройки
            </Menu.Item>
          </Menu>
        )}

        <a href="https://t.me/buro_news" target="_blank" rel="noopener noreferrer" className={b("social-icon")} />
        {/*<SystemsStatuses className={b("statuses-block")} />*/}

        {this.renderSettingsModal()}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const user = state.userReducer.user;
  const userStatus = user?.user?.status;
  const userCompanyId = user?.company?.id;
  const isCurrentCompanyAdminOrOwner = userStatus === USER_STATUS.ADMIN || user?.email === user?.company.owner;

  return {
    user,
    isCurrentCompanyAdminOrOwner,
    userCompanyId,
  };
};
const Component = connect(mapStateToProps)(withRouter(NavMenu));

export { Component as NavMenu };
