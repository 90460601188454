import { IInputState } from "src/redux/input";
import { Action } from "redux";

interface IAction extends Action {}

export const checkPassportDataRequest = (state: IInputState, action: IAction): IInputState => {
  return {
    ...state,
  };
};

export const checkPassportDataSuccess = (state: IInputState, action: IAction): IInputState => {
  return {
    ...state,
  };
};

export const checkPassportDataFailure = (state: IInputState, action: IAction): IInputState => {
  return {
    ...state,
  };
};
