import { objValuesAsTypeHelper } from "src/helpers/typescript";

export const TaxTypes = objValuesAsTypeHelper({
  "ОСНО (с НДС)": "0",
  "УСН (доходы)": "1",
  "УСН (доходы минус расходы)": "2",
});

type TaxTypesKeys = keyof typeof TaxTypes;
export type TaxTypesValues = (typeof TaxTypes)[TaxTypesKeys];
