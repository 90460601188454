import React from "react";
import { cn } from "src/helpers/bem";
import "./Header.scss";
import { API, getInitials, history, isAdmin, isScene, validateEmail } from "src/helpers";
import { baseURL, pages, entriesTypes, endpoints } from "src/constants";
import { connect } from "react-redux";
import { logout } from "src/redux/user";
import { getEntry, updateEntry, deleteEntries, copyEntries } from "src/redux/entries";
import { updatePassport, removePassports } from "src/redux/passports";
import { clearFileState } from "src/redux/files";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { MoreOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Button, Avatar, message, Modal, AutoComplete } from "antd";
import { IProps, IState, defaultProps } from "./interface";
import { AppState } from "src/redux/AppState";
import { store } from "src/redux/store";
import { getUsers } from "src/redux/users";
import { getCompanies } from "src/redux/companies";
import { IUser } from "src/helpers/types/User";
import { ICompany } from "src/helpers/types/Company";
import classNames from "classnames/dedupe";

const { Option } = AutoComplete;

const b = cn("site-header");

class Header extends React.Component<IProps, IState> {
  static defaultProps = defaultProps;

  constructor(props: IProps) {
    super(props);
    this.state = {
      dropdownOpened: false,
      pinEntryModalVisible: false,
      pinEntryValue: "",
    };
  }

  componentDidUpdate() {
    const { companies, userCompanyId } = this.props;
    // список компаний нужен для перезакрепления заявок, которое доступно только админам
    if (isAdmin(userCompanyId) && (!companies || companies.length === 0)) {
      store.dispatch(getCompanies() as any);
    }
  }

  handleDropdownClick = () => {
    this.setState({ dropdownOpened: !this.state.dropdownOpened });
  };

  handleEntryRemove = async () => {
    const { deleteEntries, currentEntryId, removePassports, currentPassportId, history } = this.props;
    if (currentEntryId) {
      await deleteEntries([currentEntryId]);
      history.push(pages.FNSregistration);
    } else if (currentPassportId) {
      await removePassports([currentPassportId]);
      history.push(pages.clients);
    }
  };

  handleEntryCopy = async (option?: string) => {
    const { clearFileState, copyEntries, history, entryType, currentEntryId, getEntry } = this.props;
    const newCopiedId = await copyEntries([currentEntryId!], option);
    if (newCopiedId) {
      await getEntry(newCopiedId);
      message.destroy();
      clearFileState();
      history.push(`${pages.newEntry}?type=${entryType}&id=${newCopiedId}`);
    }
  };

  changePinEntryModalVisible = (visible: boolean) => this.setState({ pinEntryModalVisible: visible });

  searchUsers = (query: string) => {
    this.handlePinEntryValueChange(query);
    store.dispatch(getUsers({ search: query, status: "", activated: "", companyId: "" }));
  };

  handlePinEntryValueChange = (email: string) => this.setState({ pinEntryValue: email });

  pinEntry = async () => {
    const { pinEntryValue } = this.state;
    const { currentEntryId } = this.props;

    let errors = { email: "" };
    errors = validateEmail("email", pinEntryValue, errors, true) as { email: string };

    if (!errors.email) {
      try {
        await API.post(`${endpoints.entries.pin}/${currentEntryId}?email=${pinEntryValue}`);
        message.success(`Заявка успешно перезакреплена на email: ${pinEntryValue}`);
        this.changePinEntryModalVisible(false);
        this.handlePinEntryValueChange("");
      } catch (e) {
        message.error(`Не удалось перезакрепить заявку: ${e}`);
      }
    } else {
      message.error(`Ошибка валидации email: ${errors.email}`);
    }
  };

  renderPinEntryModal = () => {
    const { pinEntryModalVisible } = this.state;
    const { users = [], companies } = this.props;

    const usersWithCompanyData: Array<IUser & { company?: ICompany }> = users.map((usr) => {
      const userCompany = companies.filter((cmp) => cmp.id === usr.companyId);
      if (userCompany.length > 0) {
        return { ...usr, company: userCompany[0] };
      }

      return usr;
    });

    return (
      <Modal
        className={b("pin-entry-modal")}
        visible={pinEntryModalVisible}
        onCancel={() => this.changePinEntryModalVisible(false)}
        footer={null}
      >
        <div className={b("pin-entry-modal-title")}>Перезакрепить заявку</div>
        <AutoComplete
          placeholder="ФИО или email пользователя"
          style={{ width: "100%" }}
          onSearch={this.searchUsers}
          onChange={this.handlePinEntryValueChange}
        >
          {usersWithCompanyData.map((it) => (
            <Option value={it.email}>{`${it.firstName} ${it.lastName} - ${
              it.company ? it.company.name : "Компания неизвестна"
            } - ${it.email}`}</Option>
          ))}
        </AutoComplete>
        <button
          onClick={this.pinEntry}
          className={classNames("custom-button", "custom-button_long", b("pin-entry-modal-btn"))}
        >
          Закрепить
        </button>
      </Modal>
    );
  };

  getDropdownOptions = () => {
    const { scene, entryType, userCompanyId } = this.props;
    /* В рег. ООО после получения из ФНС отказа 
    становятся доступны новые опции */
    const additionalItemsVisible = entryType === entriesTypes["РегистрацияООО"] && isScene("Отказано", scene);

    return (
      <Menu>
        {isAdmin(userCompanyId) ? (
          <Menu.Item onClick={() => this.changePinEntryModalVisible(true)}>Закрепить</Menu.Item>
        ) : null}
        <Menu.Item onClick={() => this.handleEntryCopy()}>Копировать</Menu.Item>
        {additionalItemsVisible && [
          <Menu.Item onClick={() => this.handleEntryCopy("date")}>Создать повторно</Menu.Item>,
          <Menu.Item onClick={() => this.handleEntryCopy("address")}>Создать повторно с заменой адреса</Menu.Item>,
        ]}
        <Menu.Item
          disabled={isScene("Выпущена КЭП", scene) && !isAdmin(userCompanyId)}
          onClick={this.handleEntryRemove}
        >
          Удалить
        </Menu.Item>
      </Menu>
    );
  };

  // внутренние страницы заявок
  getEntryPageHeader = () => {
    const {
      updateEntry,
      getEntryError,
      entriesLoading,
      passportLoading,
      scene,
      updatePassport,
      isEntryPage,
      location,
    } = this.props;
    const showSaveButton = !(getEntryError || entriesLoading || isScene("Выпущена КЭП", scene) || passportLoading);

    const PAGES_WITHOUT_CONTROLS = [pages.crtCheck];

    return (
      <div className={b("inner-content")}>
        <Button icon={<ArrowLeftOutlined />} className={b("back")} onClick={() => history.push("/fns-registration")}>
          Назад в панель управления
        </Button>
        {PAGES_WITHOUT_CONTROLS.includes(location.pathname) ? null : (
          <div className={b("controls-wrapper")}>
            {showSaveButton && (
              <Button
                size="small"
                className={b("save")}
                onClick={() => (isEntryPage ? updateEntry() : updatePassport())}
              >
                Сохранить
              </Button>
            )}
            <Dropdown
              className={b("dropdown")}
              overlay={this.getDropdownOptions()}
              trigger={["click"]}
              disabled={getEntryError || entriesLoading}
            >
              <a className="ant-dropdown-link">
                <MoreOutlined style={{ fontSize: "16px", color: "#000" }} />
              </a>
            </Dropdown>
          </div>
        )}
      </div>
    );
  };

  renderUserInfo = () => {
    const { logout, userBalance, userAvatar, inner, firstName, lastName, isCurrentCompanyAdmin, public_balance } =
      this.props;
    const { dropdownOpened } = this.state;

    const userMenu = (
      <Menu>
        <Menu.Item key="0">
          <a
            onClick={() => {
              logout();
              history.push("/login");
            }}
          >
            Выход
          </a>
        </Menu.Item>
      </Menu>
    );

    /* изображение может прийти либо в формате base64,
    либо может прийти относительный путь, либо абсолютный */
    // TODO - убрать проверку с base64
    const avatarUrl = userAvatar
      ? userAvatar.includes("base64")
        ? userAvatar
        : `${baseURL}${userAvatar.replace("https://buro.app", "")}`
      : "";

    return (
      firstName && (
        <div className={b("user-info")}>
          {(isCurrentCompanyAdmin || public_balance) && !inner && (
            <div className={b("balance", { low: userBalance < 3000 })}>
              {userBalance?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽
            </div>
          )}
          <Avatar className={b("avatar")} src={avatarUrl}>
            {!avatarUrl && `${getInitials({ firstName, lastName })}`}
          </Avatar>
          <Dropdown overlay={userMenu} trigger={["click"]}>
            <a className={b("link")} onClick={this.handleDropdownClick}>
              <div className={b("username")}>{`${firstName} ${lastName}`}</div>
              <i className={b("arrow-icon", { opened: dropdownOpened })} />
            </a>
          </Dropdown>
        </div>
      )
    );
  };

  render() {
    const { fixed, outer, inner } = this.props;

    return (
      <header className={b({ fixed, outer })}>
        <div className={b("wrapper")}>
          <Link to="fns-registration" className={b("logo-link")}>
            <img src="./images/logo.svg" alt="Бюро" />
          </Link>
          {inner && this.getEntryPageHeader()}
          {this.renderUserInfo()}
        </div>

        {this.renderPinEntryModal()}
      </header>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { userReducer, entries, passports, users: usersState } = state;
  const { users } = usersState;
  const companies = state.companies.companies?.result;
  const currentEntry = entries.currentEntry;
  const currentEntryId = currentEntry?.id;
  const entryType = currentEntry?.entryType;
  const scene = currentEntry?.scene;
  const { getEntryError, loading: entriesLoading, wasLoaded: isEntryPage } = entries;
  const { loading: passportLoading } = passports;
  const currentPassportId = passports.currentPassport?.id;
  const { user } = userReducer;
  const userCompanyId = user?.company?.id;
  const firstName = user?.user?.firstName;
  const lastName = user?.user?.lastName;
  const userBalance = user?.balance;
  const userAvatar = user?.user?.avatarURL;
  const public_balance = user?.public_balance;
  // const isAdmin = user?.user?.status === "admin";

  const companyAdmin = user?.company?.owner;
  const userEmail = user?.user?.email;
  const isCurrentCompanyAdmin = companyAdmin === userEmail;

  return {
    getEntryError,
    user,
    public_balance,
    userCompanyId,
    firstName,
    lastName,
    currentEntryId,
    entriesLoading,
    passportLoading,
    currentPassportId,
    scene,
    entryType,
    userBalance,
    userAvatar,
    isCurrentCompanyAdmin,
    isEntryPage,
    users,
    companies,
  };
};

const mapDispatchToProps = {
  logout,
  clearFileState,
  getEntry,
  updateEntry,
  deleteEntries,
  copyEntries,
  updatePassport,
  removePassports,
};

//@ts-ignore
const connectedComponent = withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

export { connectedComponent as Header };
