import { entriesConstants } from "./../entries.constants";
import { getEntriesFailure, getEntriesRequest, getEntriesSuccess } from "./getEntries";
import { searchEntriesFailure, searchEntriesRequest, searchEntriesSuccess } from "./searchEntries";
import { deleteEntriesFailure, deleteEntriesRequest, deleteEntriesSuccess } from "./deleteEntries";
import { copyEntriesFailure, copyEntriesRequest, copyEntriesSuccess } from "./copyEntries";
import { createEntryFailure, createEntryRequest, createEntrySuccess } from "./createEntry";
import { updateEntryFailure, updateEntryRequest, updateEntrySuccess } from "./updateEntry";
import { getEntryFailure, getEntryRequest, getEntrySuccess } from "./getEntry";
import { getSimplifiedEntryRequest, getSimplifiedEntrySuccess, getSimplifiedEntryFailure } from "./getSimplifiedEntry";
import { getKepFailure, getKepRequest, getKepSuccess } from "./getKep";
import { kepPoolingFailure, kepPoolingRequest, kepPoolingSuccess } from "./kepPooling";
import { activateKepFailure, activateKepRequest, activateKepSuccess } from "./activateKep";
import { getEntryLinkFailure, getEntryLinkRequest, getEntryLinkSuccess } from "./createLink";
import { linkPoolingFailure, linkPoolingRequest, linkPoolingSuccess } from "./linkPooling";
import { activationPoolingFailure, activationPoolingRequest, activationPoolingSuccess } from "./activationPooling";
import { updateOwner } from "./updateOwner";
import { updateApplicant } from "./updateApplicant";
import { addOwner } from "./addOwner";
import { removeOwner } from "./removeOwner";
import { updateEIO } from "./updateEIO";
import { clearEIO } from "./clearEIO";
import { clearMakeEIO } from "./clearMakeEIO";
import { updateEntryValidateError } from "./updateEntryValidateError";
import { sendToFNSFailure, sendToFNSRequest, sendToFNSuccess } from "./sendToFNS";
import { fetchByOGRNorINNFailure, fetchByOGRNorINNRequest, fetchByOGRNorINNSuccess } from "./fetchByOGRNorINN";
import { changeFormattedEntryAttribute } from "./changeFormattedEntryAttribute";
import { clearEntry } from "./clearEntry";
import { changeCurrentEntry } from "./changeCurrentEntry";
import { changeFormattedEntry } from "./changeFormattedEntry";
import { ICurrentEntry, IFormattedEntry, ISimplyfiedEntry } from "src/helpers/types/Entry";
import { IEntriesResponse, IKepPoolingResponse } from "src/helpers/types/ResponsesFromBackend";
import { createReducer } from "@reduxjs/toolkit";
import {
  smevPoolingRequest,
  smevPoolingSuccess,
  smevPoolingFailure,
  smevPoolingOnlyPassportLeft,
} from "src/redux/entries/reducer/smevPooling";

export const EMPTY_FORMATTED_ENTRY: IFormattedEntry = {
  "1registr_crt_link": "",
  address: "",
  appartment_abbr: "",
  areaCode: "",
  area_abbr: "",
  citizenship: "",
  city: "",
  city_abbr: "",
  containerIsReadyToGet: "",
  containerIsSigned: "",
  custom_address: "",
  dob: "",
  eMail: "",
  element: "",
  element_big: "",
  firstname: "",
  firstnameLatin: "",
  flat: "",
  fnsPhoneNum: "",
  gender: "",
  home: "",
  house_2: "",
  house_3: "",
  house_abbr: "",
  house_num2: "",
  house_num3: "",
  inn: "",
  inostr: "",
  issueDate: "",
  issuedBy: "",
  kodPodrazdelenia: "",
  lastName: "",
  lastnameLatin: "",
  makeLinkInProgress: "",
  makeLinkIsInactive: "",
  makeLinkIsReady: "",
  middleName: "",
  middleNameLatin: "",
  naselPunkt: "",
  nasel_abbr: "",
  okveds: "",
  phone: "",
  pob: "",
  pom: "",
  pom_num: "",
  primaryOkved: "",
  readyToPayPoshlina: "",
  readyToSendFNS: "",
  region: "",
  region_abbr: "",
  region_abbr_full: "",
  reset_status: "",
  sendToFNSProgress: "",
  sendedToFNS: "",
  showPoshlinaUpload: "",
  signIsInProgress: "",
  signIsNotReadyToMake: "",
  signIsReady: "",
  signIsReadyToMake: "",
  snils: "",
  snp: "",
  sono: "",
  sono_area: "",
  street: "",
  street_abbr: "",
  subjectCode: "",
  tax_type: "",
  vidat: "",
  zayavlenieReady: "",
  cjob: "",
  owners: [],
};

export interface IEntriesState {
  entries: IEntriesResponse;
  wasLoaded: boolean;
  loading: boolean;
  loadingWithoutLogo: boolean;
  error: boolean;
  getEntryError: boolean;
  validateErrors: Record<string, any>;
  formattedEntry?: IFormattedEntry;
  currentEntry?: ICurrentEntry;
  simplifiedEntry?: ISimplyfiedEntry;
  activationPoolingDescriptor: NodeJS.Timer | null;
  linkPoolingDescriptor?: NodeJS.Timer | null;
  kepData?: IKepPoolingResponse;
  smevPoolingDescriptor: NodeJS.Timer | null;
  smevOnlyPassportLeft: boolean;
}

const initialState: IEntriesState = {
  smevPoolingDescriptor: null,
  activationPoolingDescriptor: null,
  entries: {
    //@ts-ignore
    data: undefined,
    all: 0,
    count: 0,
    countInArchive: 0,
    countInTotalIP: 0,
    countInTotalOOO: 0,
    countInTotalOOOEdit: 0,
    ended: 0,
    inwork: 0,
    registration: 0,
  },
  getEntryError: false,
  wasLoaded: false,
  loading: false,
  loadingWithoutLogo: false,
  error: false,
  validateErrors: {},
  formattedEntry: EMPTY_FORMATTED_ENTRY,
  smevOnlyPassportLeft: false,
};

export const entries = createReducer(initialState, {
  [entriesConstants.ENTRIES_REQUEST]: getEntriesRequest,
  [entriesConstants.ENTRIES_SUCCESS]: getEntriesSuccess,
  [entriesConstants.ENTRIES_FAILURE]: getEntriesFailure,

  [entriesConstants.ENTRY_GET_REQUEST]: getEntryRequest,
  [entriesConstants.ENTRY_GET_SUCCESS]: getEntrySuccess,
  [entriesConstants.ENTRY_GET_FAILURE]: getEntryFailure,

  [entriesConstants.GET_SIMPLIFIED_ENTRY_REQUEST]: getSimplifiedEntryRequest,
  [entriesConstants.GET_SIMPLIFIED_ENTRY_SUCCESS]: getSimplifiedEntrySuccess,
  [entriesConstants.GET_SIMPLIFIED_ENTRY_FAILURE]: getSimplifiedEntryFailure,

  [entriesConstants.ENTRIES_SEARCH_REQUEST]: searchEntriesRequest,
  [entriesConstants.ENTRIES_SEARCH_SUCCESS]: searchEntriesSuccess,
  [entriesConstants.ENTRIES_SEARCH_FAILURE]: searchEntriesFailure,

  [entriesConstants.ENTRIES_DELETE_REQUEST]: deleteEntriesRequest,
  [entriesConstants.ENTRIES_DELETE_SUCCESS]: deleteEntriesSuccess,
  [entriesConstants.ENTRIES_DELETE_FAILURE]: deleteEntriesFailure,

  [entriesConstants.ENTRIES_COPY_REQUEST]: copyEntriesRequest,
  [entriesConstants.ENTRIES_COPY_SUCCESS]: copyEntriesSuccess,
  [entriesConstants.ENTRIES_COPY_FAILURE]: copyEntriesFailure,

  [entriesConstants.ENTRY_CREATE_REQUEST]: createEntryRequest,
  [entriesConstants.ENTRY_CREATE_SUCCESS]: createEntrySuccess,
  [entriesConstants.ENTRY_CREATE_FAILURE]: createEntryFailure,

  [entriesConstants.ENTRY_UPDATE_REQUEST]: updateEntryRequest,
  [entriesConstants.ENTRY_UPDATE_SUCCESS]: updateEntrySuccess,
  [entriesConstants.ENTRY_UPDATE_FAILURE]: updateEntryFailure,

  [entriesConstants.ENTRY_KEP_REQUEST]: getKepRequest,
  [entriesConstants.ENTRY_KEP_SUCCESS]: getKepSuccess,
  [entriesConstants.ENTRY_KEP_FAILURE]: getKepFailure,

  [entriesConstants.ENTRY_KEP_POOLING_REQUEST]: kepPoolingRequest,
  [entriesConstants.ENTRY_KEP_POOLING_SUCCESS]: kepPoolingSuccess,
  [entriesConstants.ENTRY_KEP_POOLING_FAILURE]: kepPoolingFailure,

  [entriesConstants.ACTIVATE_KEP_REQUEST]: activateKepRequest,
  [entriesConstants.ACTIVATE_KEP_SUCCESS]: activateKepSuccess,
  [entriesConstants.ACTIVATE_KEP_FAILURE]: activateKepFailure,

  [entriesConstants.ENTRY_LINK_REQUEST]: getEntryLinkRequest,
  [entriesConstants.ENTRY_LINK_SUCCESS]: getEntryLinkSuccess,
  [entriesConstants.ENTRY_LINK_FAILURE]: getEntryLinkFailure,

  [entriesConstants.ENTRY_LINK_POOLING_REQUEST]: linkPoolingRequest,
  [entriesConstants.ENTRY_LINK_POOLING_SUCCESS]: linkPoolingSuccess,
  [entriesConstants.ENTRY_LINK_POOLING_FAILURE]: linkPoolingFailure,

  [entriesConstants.ENTRY_ACTIVATION_POOLING_REQUEST]: activationPoolingRequest,
  [entriesConstants.ENTRY_ACTIVATION_POOLING_SUCCESS]: activationPoolingSuccess,
  [entriesConstants.ENTRY_ACTIVATION_POOLING_FAILURE]: activationPoolingFailure,

  [entriesConstants.ENTRY_ADD_OWNER]: addOwner,
  [entriesConstants.ENTRY_REMOVE_OWNER]: removeOwner,
  [entriesConstants.ENTRY_UPDATE_OWNER]: updateOwner,

  [entriesConstants.ENTRY_UPDATE_EIO]: updateEIO,
  [entriesConstants.ENTRY_CLEAR_EIO]: clearEIO,
  [entriesConstants.ENTRY_CLEAR_MAKE_EIO]: clearMakeEIO,

  [entriesConstants.UPDATE_APPLICANT]: updateApplicant,

  [entriesConstants.ENTRY_UPDATE_VALIDATE_ERROR]: updateEntryValidateError,

  [entriesConstants.ENTRY_SEND_FNS_REQUEST]: sendToFNSRequest,
  [entriesConstants.ENTRY_SEND_FNS_SUCCESS]: sendToFNSuccess,
  [entriesConstants.ENTRY_SEND_FNS_FAILURE]: sendToFNSFailure,

  [entriesConstants.FETCH_BY_OGRN_OR_INN_REQUEST]: fetchByOGRNorINNRequest,
  [entriesConstants.FETCH_BY_OGRN_OR_INN_SUCCESS]: fetchByOGRNorINNSuccess,
  [entriesConstants.FETCH_BY_OGRN_OR_INN_FAILURE]: fetchByOGRNorINNFailure,

  [entriesConstants.CHANGE_FORMATTED_ENTRY]: changeFormattedEntryAttribute,

  [entriesConstants.CLEAR_CURRENT_ENTRY]: clearEntry,

  [entriesConstants.CHANGE_CURRENT_ENTRY]: changeCurrentEntry,

  [entriesConstants.OVERWRITE_FORMATTED_ENTRY]: changeFormattedEntry,

  [entriesConstants.ENTRY_SMEV_POOLING_REQUEST]: smevPoolingRequest,
  [entriesConstants.ENTRY_SMEV_POOLING_SUCCESS]: smevPoolingSuccess,
  [entriesConstants.ENTRY_SMEV_POOLING_FAILURE]: smevPoolingFailure,
  [entriesConstants.ENTRY_SMEV_POOLING_ONLY_PASSPORT_LEFT]: smevPoolingOnlyPassportLeft,
});
