import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, submit, change, getFormValues, isValid } from "redux-form";
import classNames from "classnames/dedupe";
import { Divider, message } from "antd";
import { cn } from "src/helpers/bem";
import { attributes, errorsTypes } from "src/constants";
import { AInput } from "src/helpers";
import { changeCompanySettings } from "src/redux/user";
import { updateCompany } from "src/redux/companies";
import "./CompanySettings.scss";
import validate from "./validate";
import { ReactComponent as CheckCircleIcon } from "src/assets/images/check-circle-icon.svg";
import { ReactComponent as EmptyCircleIcon } from "src/assets/images/empty-circle-icon.svg";
import { AppState } from "src/redux/AppState";
import { IProps, defaultProps } from "./interface";

const sf = cn("site-form");
const b = cn("company-settings-form");

class CompanySettingsForm extends Component<IProps> {
  componentDidMount() {
    this.updateValues();
  }

  componentDidUpdate(prevProps: IProps) {
    if (!prevProps.opened && this.props.opened) {
      this.updateValues();
    }
  }

  updateValues = () => {
    const { changeFieldValue, companyInfo } = this.props;

    changeFieldValue(attributes["id"], companyInfo.id);
    changeFieldValue(attributes["Имя компании"], companyInfo.name);
    changeFieldValue(attributes["Администратор"], companyInfo.owner);
    changeFieldValue(attributes["Баланс"], companyInfo.balance);
    changeFieldValue(attributes["ИНН"], companyInfo.inn);
    changeFieldValue(attributes["КПП"], companyInfo.kpp);
    changeFieldValue(attributes["ОГРН"], companyInfo.ogrn);
    changeFieldValue(attributes["Наименование"], companyInfo.shortooo);
    changeFieldValue(attributes["Ключ Точки"], companyInfo.tochkaKey);
  };

  handleButtonClick = async () => {
    const { formValid, updateCompany, companyInfo, formValues } = this.props;

    if (formValid) {
      await updateCompany(companyInfo, formValues);
    } else {
      message.error(errorsTypes.requiredFields);
    }
  };

  render() {
    const { changeCompanySettings, onUpdate, handleSubmit, disabled, companyInfo } = this.props;

    return (
      <form onSubmit={handleSubmit} className={classNames(sf(), b())}>
        <section className={sf("section")}>
          <div className={classNames(sf("inputs-grid"), b("common-inputs"))}>
            <Field title="ID" name={attributes["id"]} component={AInput} disabled />
            <Field title="Имя компании" name={attributes["Имя компании"]} component={AInput} disabled={disabled} />
            <Field title="Администратор" name={attributes["Администратор"]} component={AInput} disabled />
            <Field title="Баланс" name={attributes["Баланс"]} component={AInput} disabled />
            <div className={b("row")}>
              <span className={b("icon-label")}>Активирован</span>
              {companyInfo.activated ? (
                <CheckCircleIcon className={b("icon")} />
              ) : (
                <>
                  <EmptyCircleIcon className={b("icon")} />
                  <button
                    className={classNames(b("activation-button"), "custom-button")}
                    type="button"
                    onClick={async () => {
                      await changeCompanySettings(companyInfo.id, { activated: true });
                      onUpdate();
                    }}
                  >
                    Активировать
                  </button>
                </>
              )}
            </div>
          </div>
        </section>
        <Divider />
        <section className={sf("section")}>
          <h2 className={sf("title")}>Банки</h2>
          <div className={b("row")}>
            <span className={b("icon-label")}>Точка</span>
            {companyInfo.tochkaAccess ? (
              <>
                <CheckCircleIcon className={b("icon")} />
                <Field
                  name={attributes["Ключ Точки"]}
                  component={AInput}
                  disabled={disabled}
                  className={b("key-input")}
                />
              </>
            ) : (
              <EmptyCircleIcon className={b("icon")} />
            )}
          </div>
        </section>
        <Divider />
        <section className={sf("section")}>
          <h2 className={sf("title")}>Реквизиты</h2>
          <div className={classNames(sf("inputs-grid"), b("requisites-inputs"))}>
            <Field title="ИНН" name={attributes["ИНН"]} component={AInput} disabled={disabled} mask="9999999999" />
            <Field title="КПП" name={attributes["КПП"]} component={AInput} disabled={disabled} mask="999999999" />
            <Field title="ОГРН" name={attributes["ОГРН"]} component={AInput} disabled={disabled} mask="9999999999999" />
          </div>
          <Field title="Наименование" name={attributes["Наименование"]} component={AInput} disabled={disabled} />
        </section>
        <button className={classNames(b("submit-button"), "custom-button")} onClick={this.handleButtonClick}>
          Сохранить
        </button>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { form } = ownProps;

  const formValid = isValid(form)(state);
  const formValues = getFormValues(form)(state);

  return { formValid, formValues };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;

  return {
    updateCompany: (initialData: any, newData: any) => dispatch(updateCompany(initialData, newData)),
    changeCompanySettings: (companyId: any, flags: any) => dispatch(changeCompanySettings(companyId, flags)),
    changeFieldValue: (field: any, value: any) => dispatch(change(form, field, value)),
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  validate,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(CompanySettingsForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as CompanySettingsForm };
