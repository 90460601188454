import { useEffect, useRef } from "react";

export const useHideElement = ({ querySelector }: { querySelector: string }) => {
  const mutationObserverRef = useRef<MutationObserver>(
    new MutationObserver(() => {
      const carrotQuestDomNode = document.querySelector(querySelector) as HTMLDivElement;
      if (carrotQuestDomNode) {
        mutationObserverRef.current.disconnect();
        carrotQuestDomNode.style.display = "none";
      }
    })
  );

  useEffect(() => {
    mutationObserverRef.current.observe(document, { subtree: true, childList: true });
    return () => {
      mutationObserverRef.current.disconnect();
    };
  }, []);
};
