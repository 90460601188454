import { IBillsState } from "src/redux/bills";
import { Action } from "redux";

interface IAction extends Action {}

export const changeBalanceRequest = (state: IBillsState, action: IAction): IBillsState => {
  return { ...state, loading: true };
};

export const changeBalanceSuccess = (state: IBillsState, action: IAction): IBillsState => {
  return { ...state, loading: false };
};

export const changeBalanceFailure = (state: IBillsState, action: IAction): IBillsState => {
  return { ...state, loading: false };
};
