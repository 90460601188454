import { cloneDeep } from "lodash";
import { attributes } from "src/constants";
import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";

interface IAction extends Action {}

export const clearEIO = (state: IEntriesState, action: IAction): IEntriesState => {
  const formattedEntry: any = cloneDeep(state.formattedEntry);
  formattedEntry[attributes["ЕИО"]] = {};

  return {
    ...state,
    formattedEntry,
  };
};
