import { ColumnsType } from "antd/lib/table";

export const requestColumns: ColumnsType<any> = [
  {
    title: "Компания",
    dataIndex: "company",
    ellipsis: true,
    width: 120,
  },
  {
    title: "Заявка",
    dataIndex: "client",
    ellipsis: true,
  },
  {
    title: "Тип заявки",
    dataIndex: "requestTypeTitle",
    ellipsis: true,
    width: 153,
  },
  {
    title: "ИНН",
    dataIndex: "inn",
    ellipsis: true,
    width: 130,
  },

  {
    title: "Создано",
    ellipsis: true,
    dataIndex: "dateCreated",
    width: 105,
  },
  // {
  //   title: "Получено",
  //   dataIndex: "dateGet",
  //   ellipsis: true,
  //   width: 96
  // },
  {
    title: "Телефон",
    dataIndex: "phone",
    ellipsis: true,
    width: 144,
  },
  {
    title: "Статус",
    dataIndex: "status",
    ellipsis: true,
    width: 165,
  },
];
