import { billsConstants } from "./../bills.constants";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { getBills } from "./getBills";
import { message } from "antd";

export const changeBillStatus = (id: number, status: string) => {
  const { CHANGE_BILL_STATUS_REQUEST, CHANGE_BILL_STATUS_SUCCESS, CHANGE_BILL_STATUS_FAILURE } = billsConstants;
  const request = () => ({ type: CHANGE_BILL_STATUS_REQUEST });
  const success = () => ({ type: CHANGE_BILL_STATUS_SUCCESS });
  const failure = () => ({ type: CHANGE_BILL_STATUS_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      await API.post(`${endpoints.bills.changeBillStatus}/${id}/${status}`);
      dispatch(success());
      dispatch(getBills());
    } catch (err: any) {
      message.error(`Не удалось изменить статус счёта ${err}`);
      dispatch(failure());
    }
  };
};
