import { CARD_NAMES } from "src/constants/marketplace";
import { extractMarketplaceHHFormData, validateMarketplaceHHFormData } from "src/helpers/marketplace/hhForms";
import { store } from "src/redux/store";
import { extractMarketplaceBankFormData, validateMarketplaceBankFormData } from "src/helpers/marketplace/bankForms";
import { Dispatch } from "redux";

export const extractMarketplaceCardData = (cardName: string) => {
  const state = store.getState();
  if (cardName === CARD_NAMES.HH) {
    return extractMarketplaceHHFormData(state);
  } else if (cardName === CARD_NAMES.HISE || cardName === CARD_NAMES.TOCHKA) {
    return extractMarketplaceBankFormData(state);
  }
};

export const validateMarketplaceCardData = (dispatch: Dispatch, cardName: string) => {
  if (cardName === CARD_NAMES.HH) {
    return validateMarketplaceHHFormData(dispatch);
  } else if (cardName === CARD_NAMES.HISE || cardName === CARD_NAMES.TOCHKA) {
    return validateMarketplaceBankFormData(dispatch);
  }
};
