import { IForm } from "src/helpers/types/Form";
import { formsNames } from "src/constants";

export interface IProps extends IForm {
  data: Record<string, any>;
  changeFieldValue: (form: string, field: string, value: string) => void;
  fieldsNames: Record<string, string>;
  checked: string;
  autoCompleteData: Record<string, any>;
}

export interface IState {
  deliveryStatus: "default" | "enabled" | "disabled";
}

export const defaultProps = {
  form: formsNames["delivery"],
};
