import React, { Component } from "react";
import classNames from "classnames/dedupe";
import { Upload, message, Modal, Avatar } from "antd";
import { UploadOutlined, EyeOutlined } from "@ant-design/icons";
import { checkIsDefaultAvatar, cn, getBase64, getInitials } from "src/helpers";
import "./AvatarUpload.scss";
import { IState, IProps, defaultProps } from "./interface";

const b = cn("avatar-upload");

export class AvatarUpload extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      uploadedFile: null,
      modalVisible: false,
    };
  }

  static defaultProps = defaultProps;

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.url && this.props.url === "") {
      this.setState({ uploadedFile: null });
    }
  }

  handleModal = () => {
    this.setState({ modalVisible: !this.state.modalVisible });
  };

  render() {
    const { modalVisible, uploadedFile } = this.state;
    const { url, disabled, onChange, username } = this.props;
    const isDefaultAvatar = checkIsDefaultAvatar(url);

    return (
      <div className={b()}>
        <label className={b("upload-title")}>Аватар:</label>
        <div className={b("upload-wrapper")}>
          <Modal visible={modalVisible} footer={null} onCancel={this.handleModal}>
            <img className={b("modal-preview")} src={url} alt="Аватар пользователя" />
          </Modal>
          <div className={b("avatar-wrapper")}>
            <Avatar size={70} className={b("avatar-preview")} src={!isDefaultAvatar ? url : ""}>
              {isDefaultAvatar && getInitials(username)}
            </Avatar>
            {/* если есть аватар и пользователь активный, появляется возможность просмотра и удаления */}
            {!isDefaultAvatar && (
              <>
                <EyeOutlined className={b("eye-icon")} onClick={!disabled ? this.handleModal : () => {}} />
                <button
                  className={b("clear-button")}
                  aria-label="Удалить"
                  onClick={() => {
                    onChange("");
                    this.setState({ uploadedFile: null });
                  }}
                  disabled={disabled}
                />
              </>
            )}
          </div>
          <Upload
            disabled={disabled}
            customRequest={() => {}}
            accept={"image/*"}
            beforeUpload={(file) => {
              if (file.size <= 1024000 && file.type === "image/png") {
                getBase64(file, (url: string) => onChange(url));
                return true;
              } else {
                message.error("Можно загружать изображения формата PNG размером до 1Мб");
                return false;
              }
            }}
            showUploadList={false}
            onChange={(info) =>
              this.setState({
                uploadedFile: info.fileList[info.fileList.length - 1],
              })
            }
          >
            <button type="button" className={classNames("custom-button", b("upload-button"))} disabled={disabled}>
              {uploadedFile ? (
                <span className={b("file-name")}>{uploadedFile.name}</span>
              ) : (
                <>
                  <UploadOutlined />
                  Загрузить
                </>
              )}
            </button>
          </Upload>
        </div>
      </div>
    );
  }
}
