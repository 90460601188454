//@ts-nocheck
export const getFnsStatusRequest = (state) => {
  return {
    ...state,
  };
};

export const getFnsStatusSuccess = (state, action) => {
  return {
    ...state,
    fns: {
      status: action.status,
      descriptor: action.descriptor,
    },
  };
};

export const getFnsStatusFailure = (state) => {
  return {
    ...state,
  };
};
