import React, { Component } from "react";
import { withRouter } from "react-router";
import { cn } from "src/helpers/bem";
import "./PersonalAccount.scss";
import { getCompanies } from "src/redux/companies";
import {
  getBills,
  changeBillStatus,
  companyBill,
  changeBalance,
  showBalance,
  recoverBill,
  setDateBill,
} from "src/redux/bills";
import {
  personalAccountOperationsColumns,
  personalAccountPaymentsColumns,
  personalAccountTabsTitles,
  errorsTypes,
} from "src/constants";
import { Loader, Input, AntdDatepickerWithMask } from "src/components";
import { isAdmin, parseSum } from "src/helpers";
import classNames from "classnames/dedupe";
import { PlusOutlined } from "@ant-design/icons";
import { Tabs, Select, Table, Modal, Radio, message } from "antd";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { operationsFromServerFormat, paymentsFromServerFormat, downloadFile } from "src/helpers";
import moment from "moment";
import { IPayment } from "src/helpers/types/Payment";
import { IProps, IState } from "./interface";
import { AppState } from "src/redux/AppState";
import { ICompanyExtended } from "src/helpers/types/Company";
import { ColumnsType } from "antd/es/table";

const { TabPane } = Tabs;
const { Option } = Select;

const b = cn("personal-account");
const sp = cn("site-page");
const sf = cn("site-form");

class PersonalAccount extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      pageSize: 40,
      currentTab: "operations",
      currentAction: "all",
      currentPaginationPage: 1,
      currentCompany: "all", // компания, отображаемая в таблице
      billInputValue: "",
      billInputError: "",
      billInputTouched: false,
      companiesSelectValue: undefined, // компания, для которой будет получен счёт
      modalSelectValue: undefined, // компания, для которой будет изменён баланс
      modalInputValue: "",
      balanceRadioValue: "fill",
      reasonRadioValue: "",
      recalculationReasonInputValue: "",
      otherReasonInputValue: "",
      balanceModalVisible: false,
      getBillModalVisible: false,
      setDateBillModalVisible: false,
      billLink: "",
      setDateBillValue: "",
      currentBill: { id: -1 },
    };
  }

  componentDidMount() {
    const { getCompanies, companies, getBills, billsLoading, showBalance } = this.props;
    if (!billsLoading) {
      showBalance();
    }

    if (!billsLoading) {
      getBills();
    }

    if (typeof companies !== "undefined") {
      const defaultCompany = companies[0].id;
      this.setState({
        companiesSelectValue: defaultCompany,
        modalSelectValue: defaultCompany,
      });
    } else {
      getCompanies();
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (typeof prevProps.companies === "undefined" && this.props.companies) {
      const defaultCompany = this.props.companies[0].id;
      this.setState({
        companiesSelectValue: defaultCompany,
        modalSelectValue: defaultCompany,
      });
    }
  }

  handleBillInputChange = (evt: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const v = evt.target.value;
    //@ts-ignore
    this.setState({ [fieldName]: v ? parseSum(v).toLocaleString() + " ₽" : "" });
  };

  handleFieldChange = (fieldName: string, value: any) => {
    //@ts-ignore
    this.setState({ [fieldName]: value });
  };

  handleFilterChange = (filterName: string, value: any) => {
    const { currentTab } = this.state;

    if (currentTab === "operations" && (filterName === "currentAction" || filterName === "currentCompany")) {
      // временно, пока нет пагинации
      //@ts-ignore
      this.setState({ [filterName]: value }, () => this.updateOperations());
    } else {
      //@ts-ignore
      this.setState({ [filterName]: value });
    }

    if (filterName === "currentTab") {
      this.setState({ currentCompany: "all" }, () => {
        if (currentTab === "payments") {
          this.updateOperations();
        }
      });
    }
  };

  handleCompanyBillClick = async () => {
    const { billInputValue, companiesSelectValue } = this.state;
    const { companyBill, userCompanyId, getBills } = this.props;

    if (billInputValue !== "") {
      if (parseSum(billInputValue) < 10000 && !isAdmin(userCompanyId)) {
        this.setState({ billInputError: errorsTypes.forbiddenSum, billInputTouched: true });
      } else {
        this.setState({ billInputError: "" });
        try {
          // если пользователь админ - берем значение из селекта, иначе - айди компании пользователя
          const billLink = await companyBill(
            isAdmin(userCompanyId) ? companiesSelectValue! : userCompanyId,
            parseSum(billInputValue)
          );
          getBills();
          this.setState({ billLink });
          this.handleGetBillModalVisible();
        } catch {
          this.setState({ billLink: "" });
        }
      }
    } else {
      message.error(errorsTypes.requiredFields);
    }
  };

  handleBalanceModalVisible = () => this.setState({ balanceModalVisible: !this.state.balanceModalVisible });

  handleGetBillModalVisible = () => this.setState({ getBillModalVisible: !this.state.getBillModalVisible });

  handleSetDateBillModalVisible = () => this.setState({ setDateBillModalVisible: !this.state.setDateBillModalVisible });

  handleSetDateBillValueChange = (date: string) => {
    this.setState({ setDateBillValue: date });
  };

  handleModalButtonClick = async () => {
    const {
      modalInputValue,
      modalSelectValue,
      balanceRadioValue,
      reasonRadioValue,
      recalculationReasonInputValue,
      otherReasonInputValue,
    } = this.state;
    const { changeBalance } = this.props;

    let reason;
    switch (reasonRadioValue) {
      case "other":
        reason = otherReasonInputValue;
        break;
      case "recalculation":
        reason = `Пересчет заявки ${recalculationReasonInputValue}`;
        break;
      default:
        reason = reasonRadioValue;
    }

    if (modalInputValue !== "" && reason) {
      await changeBalance(modalSelectValue!, balanceRadioValue, parseSum(modalInputValue), reason);
      this.updateOperations();

      this.setState({ balanceModalVisible: false });
    } else {
      message.error(errorsTypes.requiredFields);
    }
  };

  handlePaymentsButtonClick = async (id: number, status: "payed" | "acted") => {
    const { changeBillStatus, payments, changeBalance } = this.props;
    // при нажатии на "Оплачен" (status будет равен 'payed') находим это счёт и пополняем баланс данной компании на сумму счета
    if (status === "payed") {
      const clickedPayment = payments?.filter((it) => it.id === id)[0];
      await changeBalance(clickedPayment!.companyId, "fill", clickedPayment!.sum, "Пополнение счета");
      await changeBillStatus(id, status);
    }
    if (status === "acted") {
      await changeBillStatus(id, status);
    }
  };

  filterPaymentsBySelect = (payments?: IPayment[]) => {
    // фильтрация в таблице оплат происходит на фронте
    const { currentCompany } = this.state;

    if (currentCompany !== "all") {
      return payments?.filter((it) => it.companyId === currentCompany);
    }

    return payments;
  };

  updateOperations = () => {
    // фильтрация в таблице оплат происходит на беке
    const { showBalance } = this.props;
    const { currentAction, currentCompany } = this.state;

    showBalance(currentAction, currentCompany);
  };

  renderTabs = (tabs: Record<string, string>) => {
    const { userCompanyId } = this.props;
    const { currentTab } = this.state;

    return (
      <Tabs
        onChange={(value) => this.handleFilterChange("currentTab", value)}
        defaultActiveKey="0"
        type="card"
        activeKey={currentTab}
      >
        {Object.entries(tabs).map((item) => {
          if (item[0] !== "payments" || isAdmin(userCompanyId)) {
            return <TabPane tab={<div className={sp("tab-name")}>{item[1]}</div>} key={item[0]} />;
          }
          return undefined;
        })}
      </Tabs>
    );
  };

  renderCompaniesSelect = (selectName: string, value: any, companies: ICompanyExtended[]) => {
    return (
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        defaultValue={companies[0].id}
        value={value}
        onChange={(value) => this.handleFieldChange(selectName, value)}
        className={classNames(b("bill-item"), b("control-item"))}
      >
        {companies.map((opt, index: number) => (
          <Option value={opt.id} key={`companies-select-option-${index}`}>
            {opt.name}
          </Option>
        ))}
      </Select>
    );
  };

  renderControls = () => {
    const {
      currentTab,
      currentAction,
      currentCompany,
      billInputValue,
      billInputError,
      billInputTouched,
      companiesSelectValue,
    } = this.state;
    const { companies, userCompanyId } = this.props;
    return (
      <div className={sp("controls")}>
        <div className={b("bill-wrapper")}>
          <Input
            className={classNames(b("input"), b("bill-item"), b("control-item"))}
            placeholder="10 000 ₽"
            onChange={(evt) => this.handleBillInputChange(evt, "billInputValue")}
            value={billInputValue}
            //@ts-ignore
            meta={{ error: billInputError, touched: billInputTouched }}
            size="middle"
          />

          {isAdmin(userCompanyId) &&
            typeof companies !== "undefined" &&
            this.renderCompaniesSelect("companiesSelectValue", companiesSelectValue, companies)}

          <button
            type="button"
            onClick={this.handleCompanyBillClick}
            className={classNames("custom-button", b("control-item"))}
          >
            <PlusOutlined />
            Получить счёт
          </button>

          {isAdmin(userCompanyId) && (
            <button type="button" className="custom-button" onClick={this.handleBalanceModalVisible}>
              Изменить баланс
            </button>
          )}
        </div>

        <div className={sp("control-items")}>
          {isAdmin(userCompanyId) && typeof companies !== "undefined" && (
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={currentCompany}
              style={{ width: 145 }}
              onChange={(value) => this.handleFilterChange("currentCompany", value)}
              className={sp("control")}
            >
              <Option value={"all"}>Все компании</Option>
              {companies.map((opt, index: number) => (
                <Option value={opt.id} key={`companies-select-option-${index}`}>
                  {opt.name}
                </Option>
              ))}
            </Select>
          )}

          {currentTab === "operations" && (
            <Select
              value={currentAction}
              style={{ width: 145 }}
              onChange={(value) => this.handleFilterChange("currentAction", value)}
              className={sp("control")}
            >
              <Option value={"all"}>Все операции</Option>
              <Option value={"charge"}>Списания</Option>
              <Option value={"fill"}>Пополнения</Option>
            </Select>
          )}

          {/* <Select
            defaultValue={20}
            value={pageSize}
            style={{ width: 113 }}
            onChange={(value) => this.handleFilterChange("pageSize", value)}
            disabled={loading}
            className={sp("control", { last: true })}
          >
            <Option value={20}>20</Option>
            <Option value={40}>40</Option>
            <Option value={80}>80</Option>
          </Select> */}
        </div>
      </div>
    );
  };

  renderSetDateBillModal = () => {
    const { setDateBillModalVisible, setDateBillValue, currentBill } = this.state;
    const { setDateBill } = this.props;
    return (
      <Modal
        className={b("set-bill-modal")}
        visible={setDateBillModalVisible}
        onCancel={this.handleSetDateBillModalVisible}
        footer={null}
      >
        <div className={b("set-date-bill-title")}>Акт № {currentBill.id}</div>
        <AntdDatepickerWithMask
          title="Дата акта:"
          //@ts-ignore
          value={setDateBillValue}
          onChange={this.handleSetDateBillValueChange}
        />
        <button
          className={classNames("custom-button", "custom-button_long", b("set-date-bill-save-btn"))}
          onClick={async () => {
            // проверим, что введена корректная дата
            if (moment(setDateBillValue, "DD-MM-YYYY").isValid()) {
              await setDateBill(currentBill.id, moment(setDateBillValue, "DD-MM-YYYY").format("yyyy-MM-DD"));
              this.handleSetDateBillModalVisible();
            } else {
              message.error("Введите корректную дату");
            }
          }}
        >
          Сохранить
        </button>
      </Modal>
    );
  };

  renderGetBillModal = () => {
    const { getBillModalVisible, billLink } = this.state;

    return (
      <Modal
        visible={getBillModalVisible}
        onCancel={this.handleGetBillModalVisible}
        footer={null}
        className={b("bill-modal")}
      >
        <div className={b("bill-success")}>Счет сформирован</div>
        <a
          href="javascript:void(0)"
          className={classNames("custom-button", b("get-bill-button"))}
          onClick={() => downloadFile(billLink!)}
          target="_blank"
          rel="noopener noreferrer"
        >
          Скачать
        </a>
      </Modal>
    );
  };

  renderBalanceModal = () => {
    const {
      balanceModalVisible,
      modalSelectValue,
      modalInputValue,
      balanceRadioValue,
      reasonRadioValue,
      recalculationReasonInputValue,
      otherReasonInputValue,
    } = this.state;
    const { companies } = this.props;

    return (
      <Modal
        visible={balanceModalVisible}
        onCancel={this.handleBalanceModalVisible}
        footer={null}
        className={classNames("common-modal", sf(), b("modal"))}
      >
        <div className={b("modal-row")}>
          {
            // @ts-ignore
            <Input
              className={classNames(b("input"), b("bill-item"), b("control-item"))}
              placeholder="10 000 ₽"
              onChange={(evt) => this.handleBillInputChange(evt, "modalInputValue")}
              value={modalInputValue}
              size="middle"
            />
          }
          {typeof companies !== "undefined" &&
            this.renderCompaniesSelect("modalSelectValue", modalSelectValue, companies)}
          <Radio.Group
            value={balanceRadioValue}
            buttonStyle="solid"
            onChange={(e) => this.handleFieldChange("balanceRadioValue", e.target.value)}
            className={b("modal-tabs")}
          >
            <Radio.Button value="fill">Добавить</Radio.Button>
            <Radio.Button value="charge">Списать</Radio.Button>
          </Radio.Group>
        </div>
        <Radio.Group
          value={reasonRadioValue}
          className={b("radio")}
          onChange={(e) => this.handleFieldChange("reasonRadioValue", e.target.value)}
        >
          <Radio value="Пополнение счета">Пополнение счета</Radio>
          <Radio value="recalculation">Пересчет</Radio>
          {reasonRadioValue === "recalculation" && (
            //@ts-ignore
            <Input
              value={recalculationReasonInputValue}
              onChange={(e) => this.handleFieldChange("recalculationReasonInputValue", e.target.value)}
              placeholder="# заявки"
            />
          )}
          <Radio value="Бонус">Бонус</Radio>
          <Radio value="other">Другое</Radio>
          {reasonRadioValue === "other" && (
            //@ts-ignore
            <Input
              value={otherReasonInputValue}
              onChange={(e) => this.handleFieldChange("otherReasonInputValue", e.target.value)}
            />
          )}
        </Radio.Group>
        <button onClick={this.handleModalButtonClick} className={classNames("custom-button", b("modal-button"))}>
          Изменить
        </button>
      </Modal>
    );
  };

  setCurrentBill = (currentBill: IPayment) => this.setState({ currentBill });

  renderTable = (columns: ColumnsType<any>, tableData: Record<string, any>[]) => {
    const { pageSize, currentTab } = this.state;
    const { operations, billsLoading, companies, companiesLoading, loadingWithoutLogo } = this.props;

    return (
      <Table
        className={classNames(sp("table"), b("table", { payments: currentTab === "payments" }))}
        columns={columns}
        dataSource={tableData}
        scroll={{ y: "100%" }}
        pagination={{ showSizeChanger: false, pageSize, hideOnSinglePage: true }} // временно :О
        loading={{
          spinning: Boolean(
            loadingWithoutLogo || ((billsLoading || companiesLoading) && operations?.length && companies?.length)
          ),
          indicator: <Loader fixed={false} hideLogo={loadingWithoutLogo} />,
        }}
      />
    );
  };

  render() {
    const { operations, payments, companies, userCompanyId, recoverBill } = this.props;
    const { currentTab } = this.state;

    const operationsTableData = operationsFromServerFormat(operations, companies);
    const paymentsTableData = paymentsFromServerFormat(
      this.filterPaymentsBySelect(payments),
      companies,
      this.handlePaymentsButtonClick,
      b,
      recoverBill,
      this.handleSetDateBillModalVisible,
      this.setCurrentBill
    );
    const paymentsColumns = isAdmin(userCompanyId)
      ? [
          ...personalAccountPaymentsColumns,
          {
            title: "Управление",
            dataIndex: "settings",
            ellipsis: true,
          },
        ]
      : personalAccountPaymentsColumns;

    return (
      <div className={`${b()} ${sp()}`}>
        <Helmet>
          <title>Лицевой счет</title>
        </Helmet>
        <div className={sp("tabs")}>{this.renderTabs(personalAccountTabsTitles)}</div>
        <div className={sp("content")}>
          {this.renderControls()}
          {this.renderBalanceModal()}
          {this.renderGetBillModal()}
          {this.renderSetDateBillModal()}
          {currentTab === "operations" && this.renderTable(personalAccountOperationsColumns, operationsTableData)}
          {currentTab === "payments" && this.renderTable(paymentsColumns, paymentsTableData)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { userReducer } = state;
  const { user } = userReducer;
  const userCompanyId = user?.company?.id;
  const { loading: companiesLoading } = state.companies;
  const companies = state.companies.companies?.result?.slice().sort((a, b) => a.name.localeCompare(b.name));
  const { operations, payments, loading: billsLoading, loadingWithoutLogo } = state.bills;

  return {
    userCompanyId,
    companies,
    companiesLoading,
    operations,
    payments,
    billsLoading,
    loadingWithoutLogo,
  };
};

const mapDispatchToProps = {
  getBills,
  changeBillStatus,
  companyBill,
  changeBalance,
  showBalance,
  getCompanies,
  recoverBill,
  setDateBill,
};

//@ts-ignore
const connectedComponent = withRouter(connect(mapStateToProps, mapDispatchToProps)(PersonalAccount));

export { connectedComponent as PersonalAccount };
