import { IBillsState } from "src/redux/bills";
import { Action } from "redux";

interface IAction extends Action {}

export const companyBillRequest = (state: IBillsState, action: IAction): IBillsState => {
  return {
    ...state,
    loadingWithoutLogo: true,
  };
};

export const companyBillSuccess = (state: IBillsState, action: IAction): IBillsState => {
  return {
    ...state,
    loadingWithoutLogo: false,
  };
};

export const companyBillFailure = (state: IBillsState, action: IAction): IBillsState => {
  return {
    ...state,
    loadingWithoutLogo: false,
  };
};
