import { Action } from "redux";
import { IInputState } from "src/redux/input";

interface IAction extends Action {
  loadAutoCompleteOnNextChange: boolean;
}

export const toggleNextAutoCompleteLoad = (state: IInputState, action: IAction): IInputState => {
  return {
    ...state,
    loadAutoCompleteOnNextChange: action.loadAutoCompleteOnNextChange,
  };
};
