import { IPassportsState } from "src/redux/passports";

export const updatePassportRequest = (state: IPassportsState) => {
  return {
    ...state,
    loading: true,
  };
};

export const updatePassportSuccess = (state: IPassportsState) => {
  return {
    ...state,
    loading: false,
  };
};

export const updatePassportFailure = (state: IPassportsState) => {
  return {
    ...state,
    loading: false,
  };
};
