import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";
import { IKepPoolingResponse } from "src/helpers/types/ResponsesFromBackend";

interface IAction extends Action {
  kepData: IKepPoolingResponse;
}

export const kepPoolingRequest = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    loading: true,
    loadingWithoutLogo: true,
  };
};

export const kepPoolingSuccess = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    loading: false,
    loadingWithoutLogo: false,
    kepData: action.kepData,
    // kepError: "",
    error: false,
  };
};

export const kepPoolingFailure = (state: IEntriesState, action: IAction): IEntriesState => {
  return { ...state, loading: false, loadingWithoutLogo: false };
};
