import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";
import { IFormattedEntry } from "src/helpers/types/Entry";

interface IAction extends Action {
  info: Partial<IFormattedEntry>;
}

export const fetchByOGRNorINNRequest = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    loading: true,
  };
};

export const fetchByOGRNorINNSuccess = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    formattedEntry: Object.assign({}, state.formattedEntry, action.info),
    loading: false,
    error: false,
  };
};

export const fetchByOGRNorINNFailure = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    loading: false,
  };
};
