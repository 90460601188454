import { entriesConstants } from "./../entries.constants";
import { message } from "antd";
import { API } from "src/helpers";
import { endpoints, entriesTypes, entrySubTypes } from "src/constants";
import { EntrySubType, EntryType, ICreateEntry } from "src/helpers/types/Entry";

export const createEntry = (type: EntryType, subtype?: EntrySubType) => {
  const { ENTRY_CREATE_REQUEST, ENTRY_CREATE_SUCCESS, ENTRY_CREATE_FAILURE } = entriesConstants;
  const request = () => ({ type: ENTRY_CREATE_REQUEST });
  const success = (id: number) => ({ type: ENTRY_CREATE_SUCCESS, id });
  const failure = () => ({ type: ENTRY_CREATE_FAILURE });

  const entryData: ICreateEntry = {
    fill: {
      entryType: type,
    },
  };

  if (type === entriesTypes["ИзмененияООО"]) {
    entryData["attributes"] = [];
    if (subtype === entrySubTypes["ЕГРЮЛ"]) {
      entryData["attributes"].push({ attribute: "packageType", value: "P14001" });
    } else if (subtype === entrySubTypes["УСТАВ"]) {
      entryData["attributes"].push({ attribute: "packageType", value: "P13001" });
    }
  }

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      const res: any = await API.post(`${endpoints.entries.create}`, JSON.stringify(entryData));
      dispatch(success(res.id));
      return res.id;
    } catch (err: any) {
      message.error(`Не удалось создать заявку ${err}`);
      dispatch(failure());
    }
  };
};
