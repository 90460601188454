//@ts-nocheck
export const getSignmeStatusRequest = (state) => {
  return {
    ...state,
  };
};

export const getSignmeStatusSuccess = (state, action) => {
  return {
    ...state,
    signme: {
      status: action.status,
      descriptor: action.descriptor,
    },
  };
};

export const getSignmeStatusFailure = (state) => {
  return {
    ...state,
  };
};
