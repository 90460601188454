import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import { attributes } from "src/constants";
import { ACheckbox } from "src/helpers";
import { Field, reduxForm, submit } from "redux-form";
import { connect } from "react-redux";
import { getInitialValues } from "./initialValues";
import "./CertificatesStatus.scss";
import { IProps, defaultProps } from "./interface";
import { AppState } from "src/redux/AppState";

const b = cn("certificates-statuses");

class CertificatesStatusForm extends Component<IProps> {
  render() {
    const { handleSubmit, disabled } = this.props;

    return (
      <div className={b()}>
        <form onSubmit={handleSubmit}>
          <Field
            className={b("checkbox")}
            title="Сертификат передан в Бюро"
            name={attributes["Сертификат передан в Бюро"]}
            component={ACheckbox}
            disabled={disabled}
          />
          <Field
            className={b("checkbox")}
            title="Сертификат передан в УЦ"
            name={attributes["Сертификат передан в УЦ"]}
            component={ACheckbox}
            disabled={disabled}
          />
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { data } = ownProps;
  const initialValues = getInitialValues(data);
  return { initialValues };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
})(CertificatesStatusForm as any);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as CertificatesStatusForm };
