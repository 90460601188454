import { ICompaniesState } from "src/redux/companies";
import { Action } from "redux";
import { ICompanyExtended } from "src/helpers/types/Company";

interface IAction extends Action {
  companies: { count: number; result: ICompanyExtended[] };
  filtered: boolean;
  error: boolean;
}

export const getCompaniesRequest = (state: ICompaniesState, action: IAction): ICompaniesState => {
  return {
    ...state,
    loading: true,
  };
};

export const getCompaniesSuccess = (state: ICompaniesState, action: IAction): ICompaniesState => {
  if (action.filtered) {
    return {
      ...state,
      loading: false,
      filteredCompanies: action.companies,
    };
  }

  return {
    ...state,
    loading: false,
    companies: action.companies,
  };
};

export const getCompaniesFailure = (state: ICompaniesState, action: IAction): ICompaniesState => {
  return { ...state, error: true, loading: false };
};
