import { statusesConstants } from "../statuses.constants";
import { createReducer } from "@reduxjs/toolkit";
import { getFnsStatusRequest, getFnsStatusSuccess, getFnsStatusFailure } from "./getFnsStatus";
import { getSignmeStatusRequest, getSignmeStatusSuccess, getSignmeStatusFailure } from "./getSignmeStatus";
import { clearStatusesIntervals } from "./clearStatusesIntervals";

const initialState = {
  fns: { status: false, descriptor: null },
  signme: { status: false, descriptor: null },
};

export const statuses = createReducer(initialState, {
  [statusesConstants.GET_FNS_STATUS_REQUEST]: getFnsStatusRequest,
  [statusesConstants.GET_FNS_STATUS_SUCCESS]: getFnsStatusSuccess,
  [statusesConstants.GET_FNS_STATUS_FAILURE]: getFnsStatusFailure,

  [statusesConstants.GET_SIGNME_STATUS_REQUEST]: getSignmeStatusRequest,
  [statusesConstants.GET_SIGNME_STATUS_SUCCESS]: getSignmeStatusSuccess,
  [statusesConstants.GET_SIGNME_STATUS_FAILURE]: getSignmeStatusFailure,

  [statusesConstants.CLEAR_STATUSES_INTERVALS]: clearStatusesIntervals,
});
