import { isFieldExist, validateRusPassport, validateDate, validateSnils } from "src/helpers";
import { attributes } from "src/constants";
import { IProps } from "./interface";

export default function validate(values: Record<string, any>, props: IProps): Record<string, string> {
  const { fieldsNames } = props;

  // ошибки для валидации
  let errors: Record<string, string> = {};

  errors = validateRusPassport(values[fieldsNames[attributes["Серия и номер паспорта"]]], errors);
  errors = isFieldExist(
    fieldsNames[attributes["Серия и номер паспорта"]],
    values[fieldsNames[attributes["Серия и номер паспорта"]]],
    errors,
  );
  errors = validateDate(
    fieldsNames[attributes["Дата выдачи паспорта"]],
    values[fieldsNames[attributes["Дата выдачи паспорта"]]],
    errors,
  );
  errors = validateDate(
    fieldsNames[attributes["Дата рождения"]],
    values[fieldsNames[attributes["Дата рождения"]]],
    errors,
  );
  errors = isFieldExist(
    fieldsNames[attributes["Дата рождения"]],
    values[fieldsNames[attributes["Дата рождения"]]],
    errors,
  );
  errors = validateSnils(fieldsNames[attributes["СНИЛС"]], values[fieldsNames[attributes["СНИЛС"]]], errors);

  return errors;
}
