import { ColumnsType } from "antd/lib/table";

export const usersColumns: ColumnsType<any> = [
  { title: "Компания", dataIndex: "company", ellipsis: true, width: 140 },
  { title: "", dataIndex: "avatar", ellipsis: false, width: 42 },
  { title: "ФИО", dataIndex: "name", ellipsis: true, className: "users-table__fio-column" },
  { title: "Email", dataIndex: "email", ellipsis: true },
  { title: "Статус", dataIndex: "status", ellipsis: true },
  { title: "Дата регистрации", dataIndex: "date", ellipsis: true },
];

export const companiesColumns: ColumnsType<any> = [
  { title: "Компания", dataIndex: "name", ellipsis: true },
  { title: "Баланс", dataIndex: "balance", ellipsis: true, width: 96 },
  { title: "Муниципальное образование (нижнее)", dataIndex: "city", ellipsis: true, width: 110 },
  { title: "Активирован", dataIndex: "activated", ellipsis: true, width: 120 },
  { title: "Сегмент", dataIndex: "segment", ellipsis: true, width: 120 },
  { title: "Подключён", dataIndex: "createdAt", ellipsis: true, width: 120 },
  { title: "Активность", dataIndex: "activity", ellipsis: true, width: 120 },
  { title: "Рейт", dataIndex: "rate", ellipsis: true, width: 80 },
  { title: "Точка", dataIndex: "tochkaAccess", ellipsis: true, width: 78 },
  // { title: "ТКС", dataIndex: "tinkoff", ellipsis: true, width: 80 },
  { title: "Сфера", dataIndex: "spheraAccess", ellipsis: true, width: 78 },
  { title: "Подключил", dataIndex: "creator", ellipsis: true, width: 140 },
];

export const usersTabsTitles = {
  users: "Пользователи",
  companies: "Компании",
};
