import { store } from "src/redux/store";
import { getFormValues, submit, isValid, setSubmitFailed, hasSubmitSucceeded, hasSubmitFailed } from "redux-form";
import { formsNames, attributes } from "src/constants";
import { cloneDeep } from "lodash";
import { wasFileLoaded, validateOkveds, getChangedOkveds, trimSpaces, history } from "src/helpers";
import { updateEntryValidateError } from "src/redux/entries";
import qs from "query-string";
import { AppState } from "src/redux/AppState";

export const validateChangeOOOModal = (dispatch: Function) => {
  let state = store.getState();

  // проводим валидацию, только если проведена валидация остальных форм
  if (hasSubmitSucceeded("applicant")(state) || hasSubmitFailed("applicant")(state)) {
    dispatch(submit(formsNames["common"]));
    dispatch(setSubmitFailed(formsNames["common"]));
    dispatch(submit(formsNames["passport"]));
    dispatch(setSubmitFailed(formsNames["passport"]));
    // dispatch(submit(formsNames["registration"]));
    // dispatch(setSubmitFailed(formsNames["registration"]));

    // после сабмита стор обновляется
    state = store.getState();

    const commonValid = isValid("common")(state);
    const passportValid = isValid("passport")(state);
    // const registrationValid = isValid("registration")(state);

    // const modalValid = commonValid && passportValid && registrationValid;
    const modalValid = commonValid && passportValid;

    if (!modalValid) {
      dispatch(updateEntryValidateError("applicantError", true));
    } else {
      dispatch(updateEntryValidateError("applicantError", false));
    }

    return modalValid;
  }

  return false;
};

// проверяет, что у заявки на изменение ООО заполнены все обязательные поля
export const validateChangeOOO = (dispatch: Function): boolean => {
  // эмулируем сабмит всех форм
  dispatch(submit(formsNames["INNandOGRN"]));
  dispatch(setSubmitFailed(formsNames["INNandOGRN"]));
  dispatch(submit(formsNames["changeCommonOOO"]));
  dispatch(setSubmitFailed(formsNames["changeCommonOOO"]));
  dispatch(submit(formsNames["changeOOORegistration"]));
  dispatch(setSubmitFailed(formsNames["changeOOORegistration"]));

  dispatch(submit(formsNames["applicant"]));
  dispatch(setSubmitFailed(formsNames["applicant"]));

  // извлекаем информацию об ошибках
  const state = store.getState();
  const INNandOGRNValid = isValid("INNandOGRN")(state);
  const changeCommonOOOValid = isValid("changeCommonOOO")(state);
  const changeOOORegistrationValid = isValid("changeOOORegistration")(state);

  const applicantValid = isValid("applicant")(state);
  const modalValid = applicantValid && validateChangeOOOModal(dispatch);

  // узнаём, отметил ли пользователь хотя бы один чекбокс - только для ЕГРЮЛ
  // const packageType = state.entries.formattedEntry.packageType;
  // if (packageType === "P14001") {
  //   const changeOKVED = state?.entries?.formattedEntry?.changeOKVED;
  //   const changeRegistration = state?.entries?.formattedEntry?.changeRegistration;
  //   if (!changeOKVED) {
  //     dispatch(updateEntryValidateError("changeOKVEDError", true));
  //   } else {
  //     dispatch(updateEntryValidateError("changeOKVEDError", false));
  //   }
  //   if (!changeRegistration) {
  //     dispatch(updateEntryValidateError("changeRegistrationError", true));
  //   } else {
  //     dispatch(updateEntryValidateError("changeRegistrationError", false));
  //   }
  // }

  const okvedsValid = validateOkveds(state, dispatch);

  return INNandOGRNValid && changeCommonOOOValid && changeOOORegistrationValid && okvedsValid && modalValid;
};

export const validateChangeOOOFiles = (dispatch: Function): boolean => {
  const state = store.getState();
  const packageType = state.entries.formattedEntry!.packageType;
  let filesOK = true;
  // let modalFilesValid = true;
  //
  // modalFilesValid = wasFileLoaded(state, dispatch, attributes["Скан паспорта"]) && modalFilesValid;
  // modalFilesValid = wasFileLoaded(state, dispatch, attributes["Скан прописки"]) && modalFilesValid;
  // modalFilesValid = wasFileLoaded(state, dispatch, attributes["Скан СНИЛС"]) && modalFilesValid;

  if (packageType === "P13001") {
    filesOK = wasFileLoaded(state, dispatch, attributes["Устав"]) && filesOK;
    filesOK = wasFileLoaded(state, dispatch, attributes["Решение"]) && filesOK;
  }

  // if (!modalFilesValid) {
  //   dispatch(updateEntryValidateError("applicantError", true));
  // } else {
  //   dispatch(updateEntryValidateError("applicantError", false));
  // }

  // const {
  //   entries: { currentEntry },
  // } = state;

  // если требуется активация после шага СМЭВ, то нужно убедиться, что пользователь загрузил Сертификат и Фото клиента
  // if (isScene("Активация КЭП", currentEntry!.scene)) {
  //   filesOK = wasFileLoaded(state, dispatch, attributes["Сертификат"]) && filesOK;
  //   filesOK = wasFileLoaded(state, dispatch, attributes["Фото клиента"]) && filesOK;
  // }

  // filesOK = modalFilesValid && filesOK;
  filesOK = filesOK;

  return filesOK;
};

export const extractChangeOOOData = (state: AppState) => {
  // соберем formData из всех форм
  const changeСommonОООValues = getFormValues(formsNames["changeCommonOOO"])(state);
  const INNandOGRNValues = getFormValues(formsNames["INNandOGRN"])(state);
  const registrationOOOValues = getFormValues(formsNames["changeOOORegistration"])(state);
  const applicantValues = getFormValues(formsNames["applicant"])(state);

  const passportValues = getFormValues(formsNames["passport"])(state);
  const commonValues = getFormValues(formsNames["common"])(state);
  // const registrationValues = getFormValues(formsNames["registration"])(state);
  const docsValues: Record<string, any> = getFormValues(formsNames["docsFormat"])(state);

  const { formattedEntry } = state.entries;
  //@ts-ignore
  const { changeOKVED } = formattedEntry;
  //@ts-ignore
  const { changeRegistration } = formattedEntry;
  const { selectedActivities } = state.activities;

  const [okvedAddition, okvedDeletion, primaryOkvedAddition, primaryOkvedDeletion] =
    getChangedOkveds(selectedActivities);

  let allData = cloneDeep(
    Object.assign(
      {},
      INNandOGRNValues,
      changeСommonОООValues,
      passportValues,
      commonValues,
      // registrationValues,
      registrationOOOValues,
      applicantValues,
      docsValues,
      { changeOKVED },
      { changeRegistration },
      { okvedAddition },
      { okvedDeletion },
      { primaryOkvedAddition },
      { primaryOkvedDeletion },
      {
        [attributes["адресКомпании"]]:
          //@ts-ignore
          formattedEntry[attributes["адресКомпании"]],
      }
    )
  );

  const primaryOkved = selectedActivities.filter((it) => it["main"]);
  const secondaryOkveds = selectedActivities.filter((it) => !it["main"]);

  allData[attributes["основнойОКВЭД"]] = primaryOkved.map((it) => it.code).join(",");
  allData[attributes["допОКВЭД"]] = secondaryOkveds.map((it) => it.code).join(",");

  const id = qs.parse(history.location.search)["id"];
  allData["id"] = id;
  //@ts-ignore
  allData[attributes["Выдать документы на бумажном носителе"]] = docsValues[
    attributes["Выдать документы на бумажном носителе"]
  ]
    ? "1"
    : "";
  if (allData[attributes["Номер типового Устава"]]) {
    allData[attributes["Номер типового Устава"]] =
      allData[attributes["Номер типового Устава"]] < 10
        ? `0${parseInt(allData[attributes["Номер типового Устава"]], 10)}`
        : parseInt(allData[attributes["Номер типового Устава"]], 10);
  }
  // берём значения, которые подставились после ввода ОГРН
  //@ts-ignore
  allData[attributes["Код терр. ФНС"]] = state.entries.formattedEntry[attributes["Код терр. ФНС"]];
  //@ts-ignore
  allData[attributes["Код рег. органа"]] = state.entries.formattedEntry[attributes["Код рег. органа"]];

  if (!changeOKVED) {
    allData["changeOKVED"] = "";
  }
  if (!changeRegistration) {
    allData["changeRegistration"] = "";
  }

  trimSpaces(allData);

  return allData;
};
