import { entriesConstants } from "./../entries.constants";
import { message } from "antd";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { ISimplyfiedEntry } from "src/helpers/types/Entry";

export const getSimplifiedEntry = (id: number, token: string) => {
  const { GET_SIMPLIFIED_ENTRY_REQUEST, GET_SIMPLIFIED_ENTRY_SUCCESS, GET_SIMPLIFIED_ENTRY_FAILURE } = entriesConstants;
  const request = () => ({ type: GET_SIMPLIFIED_ENTRY_REQUEST });
  const success = (simplifiedEntry: ISimplyfiedEntry) => ({
    type: GET_SIMPLIFIED_ENTRY_SUCCESS,
    simplifiedEntry,
  });
  const failure = () => ({ type: GET_SIMPLIFIED_ENTRY_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());

    try {
      const simplifiedEntry: ISimplyfiedEntry = await API.get(
        `${endpoints.entries.getSimplifiedById}?id=${id}&token=${token}`,
      );

      dispatch(success({ ...simplifiedEntry, token, id: +id }));
    } catch (err) {
      message.error("Ссылка не действительна. Пожалуйста, запросите новую");

      dispatch(failure());
    }
  };
};
