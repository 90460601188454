import { isFieldExist, validateEmail } from "src/helpers";
import { loginAttributes } from "src/constants";

export default function validate(values: Record<string, any>) {
  // ошибки для валидации
  let errors: Record<string, string> = {};

  errors = validateEmail(
    loginAttributes["Электронная почта"],
    values[loginAttributes["Электронная почта"]]?.trim(),
    errors,
  );
  errors = isFieldExist(loginAttributes["Пароль"], values[loginAttributes["Пароль"]], errors);

  return errors;
}
