import { IFilesState } from "src/redux/files";
import { Action } from "redux";
import { IFormattedEntry } from "src/helpers/types/Entry";

interface IAction extends Action {
  formattedEntry: IFormattedEntry;
}

export const fillOtherFiles = (state: IFilesState, action: IAction): IFilesState => {
  // проверим, пришли ли файлы типа "Другой" с бека
  const files = Object.keys(action.formattedEntry).filter(
    (attr) => attr.match(/drug(13|14)?_\d/g) && action.formattedEntry[attr as keyof IFormattedEntry],
  );
  const maxFileId = files.length > 0 ? +files.slice().sort()[files.length - 1].split("_")[2] : 0;

  return {
    ...state,
    otherFiles: {
      maxFileId,
      files: files.map((file) => file.replace(/^file_/, "")),
    },
  };
};
