import React, { Component, ReactNode } from "react";
import ReactToPrint from "react-to-print";
import { cn } from "src/helpers/bem";
import classNames from "classnames/dedupe";
import "./Activity.scss";
import { connect } from "react-redux";
import { Modal, Tree, Checkbox, Select } from "antd";
import { ActivityOption, Loader, Tooltip } from "src/components";
import {
  getActivities,
  addActivity,
  removeActivity,
  toggleActivityStar,
  restoreActivity,
  clearActivities,
} from "src/redux/activities";
import { attributes, entriesTypes } from "src/constants";
import { getActivitiesBySearchQuery, isScene } from "src/helpers";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, ListRowProps } from "react-virtualized";
import { IProps, IState, defaultProps } from "./interface";
import { IActivity } from "src/helpers/types/Activity";
import { AppState } from "src/redux/AppState";

const b = cn("activity-form");
const sf = cn("site-form");

const { TreeNode } = Tree;

let cache = new CellMeasurerCache({
  defaultHeight: 40,
  fixedWidth: true,
  minHeight: 40,
});

class ActivityForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      OKVEDModalVisible: false,
      searchQuery: "",
      searchResult: [],
    };
  }

  list = null;

  static defaultProps = defaultProps;

  componentWillUnmount() {
    this.props.clearActivities();
  }

  handleOKVEDClose = () => {
    this.setState({
      OKVEDModalVisible: false,
    });
  };

  handleOKVEDShow = () => {
    this.setState({ OKVEDModalVisible: true });
  };

  handleSearch = (searchQuery: string) => {
    const { activities } = this.props;
    const searchResult = getActivitiesBySearchQuery(activities, searchQuery);
    this.setState({ searchQuery, searchResult }, () => {
      cache.clearAll();
      if (this.list !== null) {
        // @ts-ignore
        this.list.recomputeRowHeights();
      }
    });
  };

  handleSearchCheckbox = (checked: boolean, okved: IActivity) => {
    const { removeActivity, addActivity, mode } = this.props;
    const { code } = okved;
    // если галочка была снята, значит данный ОКВЭД уже есть среди отмеченных
    if (!checked) {
      removeActivity(mode, code);
    } else {
      //данного ОКВЭДа нет среди отмеченных
      addActivity(mode, code);
    }
    cache.clearAll();
    if (this.list !== null) {
      // @ts-ignore
      this.list.recomputeRowHeights();
    }
  };

  onActivityCheck = (checkedKeys: any, info: any) => {
    const { mode, addActivity, removeActivity, selectedActivities, restoreActivity } = this.props;
    const { checked } = info;
    const { eventKey } = info.node.props;

    // если в selectedActivities уже данный ОКВЭД со статусом "removed", то нужно восстановить данный ОКВЭД
    if (selectedActivities.findIndex((it) => it["code"] === eventKey && it["status"] === "removed") !== -1) {
      restoreActivity(eventKey);
    } else {
      if (checked) {
        addActivity(mode, eventKey);
      } else {
        removeActivity(mode, eventKey);
      }
    }
  };

  handleRemoveActivity = (code: string) => {
    const { removeActivity, mode } = this.props;
    removeActivity(mode, code);
  };

  handleRestoreActivity = (code: string) => {
    const { restoreActivity } = this.props;
    restoreActivity(code);
  };

  handleActivityStarClicked = (isMain: boolean) => {
    return (code: string) => {
      const { toggleActivityStar, mode, selectedActivities, restoreActivity } = this.props;
      if (selectedActivities.some((it) => it["code"] === code && it["main"]) && !isMain) {
        restoreActivity(code);
      } else {
        toggleActivityStar(mode, code);
      }
    };
  };

  renderTreeNodes = (data?: IActivity[]): ReactNode => {
    if (data) {
      return data.map((item) => {
        if (item.title) {
          return (
            <TreeNode
              selectable={false}
              checkable={Boolean(item.code && item.code.replace(".", "").length >= 4)}
              title={`${item.code} ${item.title}`}
              key={item.code}
            >
              {this.renderTreeNodes(item.childs)}
            </TreeNode>
          );
        } else if (item && item.childs) {
          return this.renderTreeNodes(item.childs);
        }
      });
    }
  };

  rowRenderer = (params: ListRowProps) => {
    const { searchResult } = this.state;
    const { selectedActivities } = this.props;
    const { key, index, style, parent } = params;
    const item = searchResult[index];

    return (
      <CellMeasurer parent={parent} key={key} cache={cache} columnIndex={0} rowIndex={index}>
        <div className={b("search-option")} key={key} style={style}>
          <Checkbox
            checked={selectedActivities.some((it) => it["code"] === item["code"] && it["status"] !== "removed")}
            onChange={(e) => this.handleSearchCheckbox(e.target.checked, searchResult[index])}
          />
          <span className={b("search-item-title")}>{`${item.code} ${item.title}`}</span>
        </div>
      </CellMeasurer>
    );
  };

  renderModal = (searchResult: IActivity[]) => {
    const { activities, selectedActivities, loading } = this.props;
    const { OKVEDModalVisible } = this.state;

    return (
      <Modal
        title="Справочник ОКВЭД"
        visible={OKVEDModalVisible}
        onCancel={this.handleOKVEDClose}
        footer={null}
        className={b("modal")}
      >
        {loading && <Loader />}
        {
          <Select
            allowClear
            showSearch
            className={b("search-input")}
            placeholder="Поиск по ОКВЭД"
            onSearch={this.handleSearch}
            dropdownRender={(menu) => (
              <div className={b("search-result")} onMouseDown={(e) => e.preventDefault()}>
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      //@ts-ignore
                      ref={(list) => (this.list = list)}
                      width={width}
                      height={height}
                      rowCount={searchResult.length}
                      rowHeight={cache.rowHeight}
                      deferredMeasurementCache={cache}
                      rowRenderer={this.rowRenderer}
                    />
                  )}
                </AutoSizer>
              </div>
            )}
          />
        }

        <div className={b("OKVED-list")}>
          <Tree
            checkedKeys={selectedActivities.filter((it) => it["status"] !== "removed").map((it) => it.code)}
            checkStrictly={true}
            checkable
            onCheck={this.onActivityCheck}
          >
            {this.renderTreeNodes(activities)}
          </Tree>
        </div>
      </Modal>
    );
  };

  renderSelectedActivities = (isStarFilled: boolean) => {
    const { selectedActivities, disabled, entryType } = this.props;

    return selectedActivities.map((it) => {
      const indicator = isStarFilled ? it.main : !it.main;

      return indicator ? (
        <ActivityOption
          key={it.code + it.status}
          removeLabel={
            entryType === entriesTypes["ИзмененияИП"] || entryType === entriesTypes["ИзмененияООО"] ? "text" : "button"
          }
          activityCode={it.code}
          activityTitle={it.title}
          isStarFilled={isStarFilled}
          onStarClicked={
            it.status === "removed" ? () => {} : (code: string) => this.handleActivityStarClicked(isStarFilled)(code)
          }
          onRemove={(code: string) => this.handleRemoveActivity(code)}
          onRestore={this.handleRestoreActivity}
          disabled={disabled}
          status={it.status}
          main={it.main}
        />
      ) : (
        ""
      );
    });
  };

  isShowTip = () => {
    const { selectedActivities } = this.props;
    if (
      selectedActivities.length !== 0 &&
      selectedActivities.findIndex((it) => it.main && it.status !== "removed") === -1
    ) {
      return true;
    }

    return false;
  };

  render() {
    const { selectedActivities, disabled, scene, validateErrors, showTitle, showPrintButton } = this.props;
    const { searchResult } = this.state;

    return (
      <>
        <form className={b()}>
          <div className={sf("section-wrapper")}>
            <section className={classNames(sf("section"), b("list"))} ref="printRef">
              {showTitle && <h2 className={classNames(sf("title"), b("title"))}>Виды деятельности</h2>}
              {!isScene("Выпущена КЭП", scene) && (
                <button
                  type="button"
                  className={classNames("custom-button custom-button_large custom-button_long", b("OKVED-btn"))}
                  onClick={this.handleOKVEDShow}
                  disabled={disabled}
                >
                  Выбрать ОКВЭД
                </button>
              )}

              {this.renderModal(searchResult)}

              {this.isShowTip() && (
                <div className={b("tip")}>
                  <div className={b("tip-star")} />
                  <div className={b("tip-text")}>Выберите основной вид деятельности из списка</div>
                </div>
              )}

              <div className={b("selected-activity")}>
                {selectedActivities.length > 0 && selectedActivities.some((it) => it.main) && (
                  <>
                    <h3 className={classNames(sf("subtitle"), b("subtitle"))}>Основной вид деятельности</h3>
                    {this.renderSelectedActivities(true)}
                  </>
                )}
              </div>

              <div className={b("additional-activities")}>
                {selectedActivities.length > 0 && selectedActivities.some((it) => !it.main) && (
                  <>
                    <h3 className={classNames(sf("subtitle"), b("subtitle"))}>Дополнительные виды деятельности</h3>
                    {this.renderSelectedActivities(false)}
                  </>
                )}
              </div>
            </section>
          </div>

          <Tooltip
            //@ts-ignore
            title={validateErrors[attributes["ошибкаОКВЭД"]]}
            //@ts-ignore
            visible={validateErrors[attributes["ошибкаОКВЭД"]] && validateErrors[attributes["ошибкаОКВЭД"]] !== ""}
          />
        </form>
        {showPrintButton && selectedActivities.length > 0 && (
          <ReactToPrint
            trigger={() => (
              <button type="button" className="custom-button" style={{ marginTop: 20 }}>
                Распечатать
              </button>
            )}
            content={() => this.refs.printRef}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { selectedActivities, loading, validateErrors, activities } = state.activities;
  const entryType = state.entries.currentEntry?.entryType;

  return { selectedActivities, activities, loading, validateErrors, entryType };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    getActivities: () => dispatch(getActivities()),
    clearActivities: () => dispatch(clearActivities()),
    addActivity: (mode: string, activityCode: string) => dispatch(addActivity(mode, activityCode)),
    removeActivity: (mode: string, activityCode: string) => dispatch(removeActivity(mode, activityCode)),
    toggleActivityStar: (mode: string, activityCode: string) => dispatch(toggleActivityStar(mode, activityCode)),
    restoreActivity: (activityCode: string) => dispatch(restoreActivity(activityCode)),
  };
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(ActivityForm);

export { connectedComponent as ActivityForm };
