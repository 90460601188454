import React from "react";
import "./Tooltip.scss";
import { cn } from "src/helpers/bem";
import { IProps } from "./interface";

const b = cn("site-tooltip");

export const Tooltip = (props: IProps) => {
  const { title, visible, className } = props;
  return <div className={`${b({ visible })} ${className}`}>{title}</div>;
};

Tooltip.defaultProps = {
  visible: false,
  className: "",
};
