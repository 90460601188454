import { ColumnsType } from "antd/lib/table";

export const defaultClientsColumns: ColumnsType<any> = [
  {
    title: "Компания",
    dataIndex: "company",
    ellipsis: true,
    width: 120,
  },
  {
    title: "Клиент",
    dataIndex: "client",
    ellipsis: true,
  },
  {
    title: "ИНН",
    dataIndex: "inn",
    ellipsis: true,
    width: 130,
  },
  {
    title: "СНИЛС",
    dataIndex: "snils",
    ellipsis: true,
    width: 150,
  },
  {
    title: "Дата",
    dataIndex: "created_at",
    ellipsis: true,
    width: 105,
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    ellipsis: true,
    width: 144,
  },
  {
    title: "Серт.",
    dataIndex: "cert_buro",
    ellipsis: true,
    width: 84,
  },
];

export const extendedClientsColumns: ColumnsType<any> = [
  ...defaultClientsColumns,
  {
    title: "Серт. УЦ",
    dataIndex: "cert_signme",
    ellipsis: true,
    width: 84,
  },
];
