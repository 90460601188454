import { store } from "src/redux/store";
import {
  getFormValues,
  submit,
  setSubmitFailed,
  isValid,
  formValueSelector,
  getFormSyncErrors,
  hasSubmitSucceeded,
  hasSubmitFailed,
} from "redux-form";
import { formsNames, attributes, errorsTypes, equityFormatToAttr } from "src/constants";
import { cloneDeep } from "lodash";
import { wasFileLoaded, validateOkveds, trimSpaces, history, isScene } from "src/helpers";
import { updateEntryValidateError } from "src/redux/entries";
import { updateInputColor } from "src/redux/input";
import qs from "query-string";
import { EquityFormatType, IOwner } from "src/helpers/types/Owner";
import { AppState } from "src/redux/AppState";

const { updateSyncErrors } = require("redux-form/lib/actions").default;

export const validateEIOModal = (dispatch: Function) => {
  let state = store.getState();

  // проводим валидацию, только если проведена валидация остальных форм
  if (hasSubmitSucceeded("eio")(state) || hasSubmitFailed("eio")(state)) {
    dispatch(submit(`${formsNames["common"]}-${formsNames["eio"]}`));
    dispatch(submit(`${formsNames["passport"]}-${formsNames["eio"]}`));
    // dispatch(submit(`${formsNames["registration"]}-${formsNames["eio"]}`));

    const commonEIOValid = isValid(`${formsNames["common"]}-${formsNames["eio"]}`)(state);
    const passportEIOValid = isValid(`${formsNames["passport"]}-${formsNames["eio"]}`)(state);
    // const registrationEIOValid = isValid(`${formsNames["registration"]}-${formsNames["eio"]}`)(state);

    const modalValid = commonEIOValid && passportEIOValid;

    if (!modalValid) {
      dispatch(updateEntryValidateError("eioError", true));
    } else {
      dispatch(updateEntryValidateError("eioError", false));
    }

    return modalValid;
  }

  return false;
};

// проверяет, что у заявки заполнены все обязательные поля
export const validateOOOregistation = (dispatch: Function): boolean => {
  const state = store.getState();

  // эмулируем сабмит всех форм
  dispatch(submit(formsNames["commonOOO"]));
  dispatch(setSubmitFailed(formsNames["commonOOO"]));
  dispatch(submit(formsNames["eio"]));
  dispatch(setSubmitFailed(formsNames["eio"]));
  dispatch(submit(formsNames["OOOregistration"]));
  dispatch(setSubmitFailed(formsNames["OOOregistration"]));

  // извлекаем информацию об ошибках
  const commonOOOValid = isValid("commonOOO")(state);
  const eioValid = isValid("eio")(state);
  const OOOregistrationValid = isValid("OOOregistration")(state);

  // сабмит всех форм вcех учредителей, для этого нам понадобятся их id
  //@ts-ignore
  const owners: IOwner[] = state.entries.formattedEntry[attributes["учредители"]];
  const ownerErrorIds: number[] = [];
  let ownersTotalValid = true;

  let ownerFormsValid = true;
  if (owners?.length > 0) {
    dispatch(updateEntryValidateError("ownersError", false));

    const equityFormat: EquityFormatType = formValueSelector(formsNames["commonOOO"])(
      state,
      attributes["Вид указания размера уставного капитала"],
    );
    const equityAttr = equityFormatToAttr[equityFormat];
    const ownersIds = owners.map((it) => it.id);
    let ownersTotalPercents = 0;
    let ownersTotalSum = 0;
    const capital = parseInt(
      formValueSelector(formsNames["commonOOO"])(state, attributes["Уставный капитал"])?.replace(/ /g, ""),
    );

    ownersIds.forEach((id) => {
      const currentOwnerForm = `${formsNames["owner"]}${id}`;
      // сабмит формы, где указывается доля
      dispatch(submit(currentOwnerForm));

      // считаем общую сумму процентов долей (должна быть равна 100 (для процентов) или 1 (для дробей))
      if (equityFormat === "simple") {
        ownersTotalPercents += eval(formValueSelector(currentOwnerForm)(state, equityAttr)?.replace(/ /g, ""));
      } else {
        ownersTotalPercents += parseFloat(formValueSelector(currentOwnerForm)(state, equityAttr)?.replace(/ /g, ""));
      }
      // считаем общую сумму размеров долей (должна быть равна уставному капиталу)
      ownersTotalSum += parseInt(
        formValueSelector(currentOwnerForm)(state, attributes["Размер доли"])?.replace(/ /g, ""),
      );
    });

    if (ownersTotalSum !== capital) {
      ownersTotalValid = false;

      const errorText = ownersIds.length > 1 ? errorsTypes.forbiddenEquityPart : errorsTypes.forbiddenEquitySum;
      ownersIds.forEach((id) => {
        ownerErrorIds.push(id);
        /* updateSyncErrors взята из исходников редакса и используется
        для изменения текста ошибки в формах учредителей в зависимости от их количества
        и в зависимости от размера уставного капитала */
        const ownerErrors = getFormSyncErrors(`${formsNames["owner"]}${id}`)(state);
        dispatch(
          updateSyncErrors(`${formsNames["owner"]}${id}`, {
            ...ownerErrors,
            [attributes["Размер доли"]]: errorText,
          }),
        );
        dispatch(updateInputColor(`${formsNames["owner"]}${id}`, attributes["Размер доли"], "red"));
      });
    } else {
      ownersIds.forEach((id) => {
        const ownerErrors = getFormSyncErrors(`${formsNames["owner"]}${id}`)(state);
        // удаляем ранее проставленную ошибку
        //@ts-ignore
        delete ownerErrors[attributes["Размер доли"]];
        dispatch(
          updateSyncErrors(`${formsNames["owner"]}${id}`, {
            ...ownerErrors,
          }),
        );
        dispatch(updateInputColor(`${formsNames["owner"]}${id}`, attributes["Размер доли"], ""));
      });
    }

    if (
      (equityFormat === "percent" && ownersTotalPercents !== 100) ||
      (equityFormat === "simple" && ownersTotalPercents !== 1)
    ) {
      ownersTotalValid = false;
      ownersIds.forEach((id) => {
        ownerErrorIds.push(id);
        dispatch(updateInputColor(`${formsNames["owner"]}${id}`, equityAttr, "red"));
      });
      const commonOOOErrors = getFormSyncErrors(formsNames["commonOOO"])(state);
      dispatch(
        updateSyncErrors(formsNames["commonOOO"], {
          ...commonOOOErrors,
          [attributes["Уставный капитал"]]: errorsTypes.forbiddenEquityPercents,
        }),
      );
    } else {
      ownersIds.forEach((id) => {
        dispatch(updateInputColor(`${formsNames["owner"]}${id}`, equityAttr, ""));
      });
      const commonOOOErrors = getFormSyncErrors(formsNames["commonOOO"])(state);
      //@ts-ignore
      delete commonOOOErrors[attributes["Уставный капитал"]];
      dispatch(
        updateSyncErrors(formsNames["commonOOO"], {
          ...commonOOOErrors,
        }),
      );
    }

    ownersIds.forEach((id) => {
      const currentOwnerForm = `${formsNames["owner"]}${id}`;
      let ownerModalFormValid = true;

      // сабмит форм, которые находятся в модалке учредителя
      dispatch(submit(`${formsNames["common"]}-${formsNames["owner"]}${id}`));
      dispatch(submit(`${formsNames["delivery"]}-${formsNames["owner"]}${id}`));
      dispatch(submit(`${formsNames["passport"]}-${formsNames["owner"]}${id}`));
      dispatch(submit(`${formsNames["registration"]}-${formsNames["owner"]}${id}`));

      if (!isValid(currentOwnerForm)(state)) {
        ownerFormsValid = false;
      }

      if (!isValid(`${formsNames["owner"]}${id}`)(state)) {
        ownerModalFormValid = false;
      }

      if (!isValid(`${formsNames["common"]}-${formsNames["owner"]}${id}`)(state)) {
        ownerModalFormValid = false;
      }

      if (!isValid(`${formsNames["delivery"]}-${formsNames["owner"]}${id}`)(state)) {
        ownerModalFormValid = false;
      }

      if (!isValid(`${formsNames["passport"]}-${formsNames["owner"]}${id}`)(state)) {
        ownerModalFormValid = false;
      }

      if (!isValid(`${formsNames["registration"]}-${formsNames["owner"]}${id}`)(state)) {
        ownerModalFormValid = false;
      }

      if (!ownerModalFormValid) {
        ownerErrorIds.push(id);
      }
    });

    dispatch(updateEntryValidateError("ownerErrorIds", ownerErrorIds));
  } else {
    dispatch(updateEntryValidateError("ownersError", true));
    ownerFormsValid = false;
  }

  //проверка, что пользователь выбрал основной оквэд
  const okvedsOK = validateOkveds(state, dispatch);

  // валидация форм в модалке исполнительного органа
  const eioModalValid = validateEIOModal(dispatch);

  return (
    commonOOOValid &&
    eioValid &&
    eioModalValid &&
    OOOregistrationValid &&
    okvedsOK &&
    ownerErrorIds.length === 0 &&
    ownersTotalValid &&
    ownerFormsValid
  );
};

export const validateOOOregistationFiles = (dispatch: Function): boolean => {
  const state = store.getState();
  const {
    entries: { currentEntry },
  } = state;
  //@ts-ignore
  const owners: IOwner[] = state.entries.formattedEntry[attributes["учредители"]];
  let filesOK = true;
  const ownerErrorIds: number[] = [];

  if (owners?.length > 0) {
    const ownersIds = owners.map((it) => it.id);
    ownersIds.forEach((id) => {
      filesOK = wasFileLoaded(state, dispatch, `${attributes["Скан паспорта"]}_${id}`) && filesOK;
      filesOK = wasFileLoaded(state, dispatch, `${attributes["Скан прописки"]}_${id}`) && filesOK;
      filesOK = wasFileLoaded(state, dispatch, `${attributes["Скан СНИЛС"]}_${id}`) && filesOK;

      // если требуется активация после шага СМЭВ, то нужно убедиться, что пользователь загрузил Сертификат и Фото клиента
      if (isScene("Активация КЭП", currentEntry!.scene)) {
        filesOK = wasFileLoaded(state, dispatch, `${attributes["Сертификат"]}_${id}`) && filesOK;
        filesOK = wasFileLoaded(state, dispatch, `${attributes["Фото клиента"]}_${id}`) && filesOK;
      }

      if (!filesOK) {
        ownerErrorIds.push(id);
      }
    });

    dispatch(updateEntryValidateError("ownerErrorIds", ownerErrorIds));
  }

  return filesOK;
};

export const extractOOOdata = (state: AppState) => {
  // извлекаем ОКВЭДы
  const { selectedActivities } = state.activities;

  const commonValues = getFormValues(formsNames["commonOOO"])(state);
  const docsValues: Record<string, any> = getFormValues(formsNames["docsFormat"])(state);
  const OOOregistration = getFormValues(formsNames["OOOregistration"])(state);
  let EIOvalues = getFormValues(formsNames["eio"])(state);

  const formattedEntry = cloneDeep(state.entries.formattedEntry);

  //@ts-ignore
  if (formattedEntry[attributes["учредители"]]) {
    //@ts-ignore
    formattedEntry[attributes["учредители"]] = formattedEntry[attributes["учредители"]].map((it: IOwner) => {
      const ownerValues = getFormValues(`${formsNames["owner"]}${it.id}`)(state);

      it = Object.assign({}, it, ownerValues);
      return it;
    });
  }

  //@ts-ignore
  EIOvalues = Object.assign({}, formattedEntry[attributes["ЕИО"]], EIOvalues);
  // Если в сроке полномочий стоит галочка "бессрочно", ceo_time д.б. равным 0
  //@ts-ignore
  if (EIOvalues[attributes["Бессрочно"]]) {
    //@ts-ignore
    EIOvalues[attributes["Срок полномочий"]] = "0";
  }

  const allData = cloneDeep(
    Object.assign(
      {},
      commonValues,
      docsValues,
      OOOregistration,
      {
        [attributes["ЕИО"]]: JSON.stringify(EIOvalues),
      },
      {
        //@ts-ignore
        [attributes["учредители"]]: JSON.stringify(formattedEntry[attributes["учредители"]] || []),
      },
      //@ts-ignore
      { [attributes["максIdучредителя"]]: formattedEntry[attributes["максIdучредителя"]] },
    ),
  );

  const primaryOkved = selectedActivities.filter((it) => it["main"]);
  const okveds = selectedActivities.filter((it) => !it["main"]);

  allData[attributes["основнойОКВЭД"]] = primaryOkved.map((it) => it.code).join(",");
  allData[attributes["допОКВЭД"]] = okveds.map((it) => it.code).join(",");

  const id = qs.parse(history.location.search)["id"];
  allData["id"] = id;
  allData[attributes["Выдать документы на бумажном носителе"]] = docsValues[
    attributes["Выдать документы на бумажном носителе"]
  ]
    ? "1"
    : "";
  if (allData[attributes["Номер типового Устава"]]) {
    allData[attributes["Номер типового Устава"]] =
      allData[attributes["Номер типового Устава"]] < 10
        ? `0${parseInt(allData[attributes["Номер типового Устава"]], 10)}`
        : parseInt(allData[attributes["Номер типового Устава"]], 10);
  }
  if (allData[attributes["Есть наименование на английском языке"]]) {
    allData["eng_title_code1"] = "016";
  } else {
    allData["eng_title_code1"] = "";
  }
  allData[attributes["Вид указания размера уставного капитала"]] = "percent";

  trimSpaces(allData);

  return allData;
};
