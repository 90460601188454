import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./EIO.scss";
import { connect } from "react-redux";
import { AInput, ACheckbox, parseExecutiveTitle } from "src/helpers";
import { Field, reduxForm, submit, formValueSelector } from "redux-form";
import { attributes } from "src/constants";
import validate from "./validate";
import { getInitialValues } from "./initialValues";
import classNames from "classnames/dedupe";
import { AppState } from "src/redux/AppState";
import { IProps, defaultProps } from "./interface";

const sf = cn("site-form");
const b = cn("eio-form");

class EIOForm extends Component<IProps> {
  render() {
    const { handleSubmit, disabled, ceo_time_inf } = this.props;
    return (
      <form onSubmit={handleSubmit} className={classNames(sf(), b())}>
        <div className={sf("inputs-grid")}>
          <Field
            title="Срок полномочий:"
            name={attributes["Срок полномочий"]}
            placeholder="5 лет"
            component={AInput}
            disabled={disabled || ceo_time_inf}
            format={(v: string) => (v ? parseExecutiveTitle(v.toString()) : "")}
            normalize={(v: string) => (v ? v.replace(/[^0-9]/g, "") : "")}
          />
          <Field
            title="Наименование должности:"
            name={attributes["Наименование должности"]}
            placeholder="Генеральный директор"
            className={b("post")}
            component={AInput}
            disabled={disabled}
          />
        </div>
        <Field
          title="Бессрочно"
          name={attributes["Бессрочно"]}
          component={ACheckbox}
          disabled={disabled}
          className={b("checkbox")}
        />
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { form, data } = ownProps;
  const initialValues = data ? getInitialValues(data) : {};
  const EIOValues = formValueSelector(form);
  const ceo_time_inf = EIOValues(state, attributes["Бессрочно"]);
  return { ceo_time_inf, initialValues };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  validate,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(EIOForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as EIOForm };
