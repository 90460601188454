import { ColumnsType } from "antd/lib/table";

export const personalAccountOperationsColumns: ColumnsType = [
  {
    title: "Компания",
    dataIndex: "company",
    ellipsis: true,
    width: 140,
  },
  {
    title: "Заявка",
    dataIndex: "request",
    ellipsis: true,
    width: 180,
  },
  {
    title: "Тип действия",
    dataIndex: "actionType",
    ellipsis: true,
    width: 180,
  },
  {
    title: "Дата",
    dataIndex: "date",
    ellipsis: true,
    width: 160,
  },
  {
    title: "Сумма",
    dataIndex: "sum",
    ellipsis: true,
    width: 160,
  },
  {
    title: "Основание",
    dataIndex: "administration",
    ellipsis: true,
  },
];

export const personalAccountPaymentsColumns = [
  {
    title: "Компания",
    dataIndex: "company",
    ellipsis: true,
  },
  {
    title: "Номер счёта",
    dataIndex: "account",
    ellipsis: true,
  },
  {
    title: "Дата",
    dataIndex: "date",
    ellipsis: true,
  },
  {
    title: "Сумма",
    dataIndex: "sum",
    ellipsis: true,
  },
  {
    title: "Оплачен",
    dataIndex: "payed",
    ellipsis: true,
  },
  {
    title: "Акт",
    dataIndex: "acted",
    ellipsis: true,
  },
];

export const personalAccountTabsTitles: any = {
  operations: "Операции",
  payments: "Счета",
};

/*
возможно, чтобы избежать этих длиннющих названий, есть смысл сделать 
одну общую константу, в виде объекта :с 
типа: 
const personalAccount = {
  tabTitles: {},
  operationsColumns: {}
}

и т.д.
*/
