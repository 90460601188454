import { IForm } from "src/helpers/types/Form";
import { formsNames } from "src/constants";
import { SceneType } from "src/helpers/types/Scene";

export interface IProps extends IForm {
  data: Record<string, any>;
  changeFieldValue: (form: string, field: string, value: string) => void;
  engName: string;
  scene: SceneType;
  creationDateEnabled: boolean;
  ustav_checkbox: boolean;
}

export const defaultProps = {
  form: formsNames["commonOOO"],
};
