import { message } from "antd";
import { userConstants } from "./../user.constants";
import { endpoints } from "src/constants";
import { setWithExpiry, API, reinitializeAPI, generateGuid } from "src/helpers";
import { IGetTokenResponse } from "src/helpers/types/ResponsesFromBackend";

export const getToken = (email: string, password: string) => {
  const { GET_TOKEN_REQUEST, GET_TOKEN_SUCCESS, GET_TOKEN_FAILURE } = userConstants;
  const request = () => ({ type: GET_TOKEN_REQUEST });
  const success = () => ({ type: GET_TOKEN_SUCCESS });
  const failure = () => ({ type: GET_TOKEN_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      const data: IGetTokenResponse = await API.post(`${endpoints.getToken}`, {
        email: email.trim(),
        password,
        rememberMe: true,
      });
      const { token } = data;
      setWithExpiry("token", token, 1000 * 3600 * 24 * 7);
      // токен браузера для ограничения кол-во сессий у одного пользователя
      const browserToken = generateGuid();
      localStorage.setItem("browser_token", browserToken);
      reinitializeAPI();
      API.post(`${endpoints.resetSession}?hash=${localStorage.getItem("browser_token")}`);
      dispatch(success());
    } catch (err: any) {
      if (err?.error === "error.invalidCredentials") {
        dispatch(failure());
        throw "error";
      } else {
        message.error(`Ошибка при получении токена: ${err}`);
      }
      dispatch(failure());
    }
  };
};
