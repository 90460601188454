//@ts-nocheck
import React from "react";
import { cn } from "src/helpers/bem";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import "./PasswordView.scss";

const b = cn("password-view");

export class PasswordView extends React.Component<any> {
  state = {
    visible: false,
  };

  handleButtonClick = () => {
    this.setState({ visible: !this.state.visible });
  };

  render() {
    const { visible } = this.state;
    const { width, text } = this.props;

    return (
      <div className={b()} style={{ width: width }}>
        <span className={b("text-content", { blurred: !visible })}>{text}</span>
        <button className={b("button")} onClick={this.handleButtonClick}>
          {visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        </button>
      </div>
    );
  }
}
