export const marketplaceConstants = {
  // Получение карточек
  GET_CARDS_REQUEST: "GET_CARDS_REQUEST",
  GET_CARDS_SUCCESS: "GET_CARDS_SUCCESS",
  GET_CARDS_FAILURE: "GET_CARDS_FAILURE",

  // Отправка формы для каждой карточки
  SEND_MARKETPLACE_CARD_FORM_REQUEST: "SEND_MARKETPLACE_CARD_FORM_REQUEST",
  SEND_MARKETPLACE_CARD_FORM_SUCCESS: "SEND_MARKETPLACE_CARD_FORM_SUCCESS",
  SEND_MARKETPLACE_CARD_FORM_FAILURE: "SEND_MARKETPLACE_CARD_FORM_FAILURE",

  GET_REQUESTS_REQUEST: "GET_REQUESTS_REQUEST",
  GET_REQUESTS_SUCCESS: "GET_REQUESTS_SUCCESS",
  GET_REQUESTS_FAILURE: "GET_REQUESTS_FAILURE",
};
