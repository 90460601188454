import { ColumnsType } from "antd/lib/table";

export const marketplaceTableColumns: ColumnsType<any> = [
  { title: "Компания", dataIndex: "company_name", ellipsis: true, width: 140 },
  { title: "Дата", dataIndex: "createdAt", ellipsis: true, width: 120 },
  { title: "Услуга", dataIndex: "service", ellipsis: true },
  { title: "Статус", dataIndex: "status", ellipsis: true, width: 200 },
];

export const marketplaceTopics: any = {
  RKO: "РКО",
  Merchant: "Кассы",
  Accounting: "Бухгалтерия",
  Telecom: "Телеком",
  Services: "Сервисы",
  Other: "Другое",
  Banks: "Банки",
};
