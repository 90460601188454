import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./Clients.scss";
import { defaultClientsColumns, extendedClientsColumns } from "src/constants";
import { getPassports, removePassports } from "src/redux/passports";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { getCompanies } from "src/redux/companies";
import { Tabs, Input, Button, Select, Table } from "antd";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { passportsFromServerFormat, updateQueryParam, getCookie, setCookie, isAdmin } from "src/helpers";
import qs from "query-string";
import { AppState } from "src/redux/AppState";
import { IProps, IState } from "./interface";
// import { RequestsCommonInfo } from "./RequestsCommonInfo/RequestsCommonInfo";

const { TabPane } = Tabs;
const { Option } = Select;

const b = cn("clients");
const sp = cn("site-page");

class Clients extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      pageSize: 20,
      page: 1,
      searchQuery: "",
      searchOnFocus: false,
      currentTab: "all",
      currentPaginationPage: 1,
    };
  }

  componentDidMount() {
    const { getCompanies, companies } = this.props;
    //@ts-ignore
    const { search } = this.props.location;
    const params = qs.parse(search);
    const { page, pageSize } = params;

    this.setState(
      {
        //@ts-ignore
        page: +page || 1,
        //@ts-ignore
        pageSize: +pageSize || +getCookie("clients-page-size") || 20,
      },
      () => this.updatePassports(),
    );

    if (typeof companies === "undefined") {
      getCompanies();
    }
  }

  updatePassports = (clearPaginationPage = false) => {
    const { getPassports } = this.props;
    const { pageSize, page, searchQuery } = this.state;

    if (searchQuery.length >= 3) {
      getPassports(pageSize, page, searchQuery);
    } else if (searchQuery.length === 0) {
      getPassports(pageSize, page);
    }

    if (clearPaginationPage) {
      this.setState({ page: 1 });
    }
  };

  handleTabChange = (currentTab: string) => {
    this.setState({ currentTab });
  };

  handleTableRowSelect = (selectedRowKeys: Array<any>) => {
    this.setState({ selectedRowKeys });
  };

  handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: evt.target.value }, () => this.updatePassports(true));
  };

  handleSearchFocus = () => {
    this.setState({ searchOnFocus: true });
  };

  handleSearchBlur = () => {
    this.setState({ searchOnFocus: false });
  };

  handleFilterChange = (filterName: string, value: string, clearPaginationPage = false) => {
    //@ts-ignore
    const { search } = this.props.location;
    //@ts-ignore
    const { history } = this.props;
    let newSearch = updateQueryParam(search, filterName, value);
    if (clearPaginationPage) {
      newSearch = updateQueryParam(newSearch, "page", "1");
    }
    history.push({ search: `?${newSearch}` });
    //@ts-ignore
    this.setState({ selectedRowKeys: [], [filterName]: value }, () => this.updatePassports(clearPaginationPage));
    if (filterName === "pageSize") {
      setCookie("clients-page-size", value);
    }
  };

  handleClientsDelete = async () => {
    const { removePassports, passports = [] } = this.props;
    const { selectedRowKeys } = this.state;
    const ids = selectedRowKeys.map((item: number, index: number) => passports[selectedRowKeys[index]]["id"]);
    await removePassports(ids);
    this.setState({ selectedRowKeys: [] });
    await this.updatePassports();
  };

  renderTabs = (tabs: []) => {
    const { loading, count } = this.props;
    const { currentTab } = this.state;
    tabs = Object.assign({ all: "Все" }, tabs);
    // пока что таб всего один, но заготовка на случай, если появятся ещё 🌚
    return (
      <Tabs onChange={this.handleTabChange} defaultActiveKey="0" type="card" activeKey={currentTab}>
        {Object.entries(tabs).map((item: any[]) => (
          <TabPane
            disabled={loading}
            tab={
              <div className={sp("tab-wrapper")}>
                <span className={sp("tab-name")}>{item[1]}</span>
                <span className={sp("tab-value")}>{count}</span>
              </div>
            }
            key={item[0]}
          />
        ))}
      </Tabs>
    );
  };

  renderControls = () => {
    const { pageSize, selectedRowKeys, searchOnFocus } = this.state;
    const { loading } = this.props;
    return (
      <div className={sp("controls")}>
        <Input
          placeholder="Поиск..."
          className={sp("search")}
          onChange={this.handleSearchChange}
          onFocus={this.handleSearchFocus}
          onBlur={this.handleSearchBlur}
          disabled={loading && !searchOnFocus}
        />

        <div className={sp("control-items")}>
          {selectedRowKeys.length > 0 && (
            <div>
              <Button icon={<CopyOutlined />} className={sp("control")} disabled={loading} />
              <Button
                icon={<DeleteOutlined />}
                className={sp("control")}
                disabled={loading}
                onClick={this.handleClientsDelete}
              />
              {/* <Button icon={<DownloadOutlined />} className={sp("control")} disabled={loading}>
                Выгрузить отчет
              </Button> */}
            </div>
          )}

          <Select
            defaultValue={20}
            value={pageSize}
            style={{ width: 113 }}
            onChange={(value) => this.handleFilterChange("pageSize", value.toString(), false)}
            disabled={loading}
            className={sp("control", { last: true })}
          >
            <Option value={20}>20 заявок</Option>
            <Option value={40}>40 заявок</Option>
            <Option value={80}>80 заявок</Option>
          </Select>

          {/*<div className={sp("controls-divider")} />*/}
          {/*<Button icon={<FilterOutlined />} className={sp("button-filter")}></Button>*/}
          {/*<Button icon={<MoreOutlined />}></Button>*/}
        </div>
      </div>
    );
  };

  renderTable = () => {
    const { selectedRowKeys, pageSize, searchQuery, page } = this.state;
    const { passports, count, companies, userCompanyId } = this.props;
    const tableData = passportsFromServerFormat(passports, companies);
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleTableRowSelect,
      columnWidth: "40px",
    };

    // определяем, откуда брать информацию о количестве заявок - из countAll (если это обычный запрос, а не поиск)
    // или, если это поиск, то из длины пришедшего массива
    const total = searchQuery.length >= 3 ? tableData.length : count;

    return (
      <Table
        className={sp("table")}
        rowSelection={rowSelection}
        columns={isAdmin(userCompanyId) ? extendedClientsColumns : defaultClientsColumns}
        dataSource={tableData}
        scroll={{ y: "100%" }}
        pagination={{
          showSizeChanger: false,
          pageSize,
          total,
          current: page,
          onChange: (value) => this.handleFilterChange("page", value.toString(), false),
          hideOnSinglePage: true,
        }}
      />
    );
  };

  render() {
    return (
      <div className={`${b()} ${sp()}`}>
        <Helmet>
          <title>Клиенты</title>
        </Helmet>
        <div className={sp("tabs")}>{this.renderTabs([])}</div>
        <div className={sp("content")}>
          {this.renderControls()}
          {this.renderTable()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppState) {
  const userCompanyId = state.userReducer.user?.company?.id;
  const companies = state.companies.companies?.result;
  const { entries, loading } = state.passports;
  const passports = entries?.passports;
  const count = entries?.count;
  return { passports, count, loading, companies, userCompanyId };
}

const mapDispatchToProps = {
  getPassports,
  removePassports,
  getCompanies,
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(Clients);

export { connectedComponent as Clients };
