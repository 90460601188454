import { IActivitiesState } from "./../reducer/activities.reducer";
import { Action } from "redux";

interface IAction extends Action {}

export const clearActivities = (state: IActivitiesState, action: IAction): IActivitiesState => {
  return {
    ...state,
    selectedActivities: [],
    loading: false,
    error: false,
    validateErrors: {},
  };
};
