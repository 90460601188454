import { passportsConstants } from "./../passports.constants";
import { createReducer } from "@reduxjs/toolkit";
import { getPassportsRequest, getPassportsSuccess, getPassportsFailure } from "./getPassports";
import { getPassportRequest, getPassportSuccess, getPassportFailure } from "./getPassport";
import { updatePassportRequest, updatePassportSuccess, updatePassportFailure } from "./updatePassport";
import { removePassportRequest, removePassportSuccess, removePassportFailure } from "./removePassports";
import { IPassportsResponse } from "src/helpers/types/ResponsesFromBackend";
import { IPassport } from "src/helpers/types/Passport";

export interface IPassportsState {
  entries?: IPassportsResponse;
  loading: boolean;
  wasLoaded: boolean;
  error: boolean;
  validateErrors: Record<string, any>;
  currentPassport?: IPassport;
}

const initialState: IPassportsState = {
  entries: undefined,
  loading: false,
  wasLoaded: false,
  error: false,
  validateErrors: {},
};

export const passports = createReducer(initialState, {
  [passportsConstants.PASSPORTS_REQUEST]: getPassportsRequest,
  [passportsConstants.PASSPORTS_SUCCESS]: getPassportsSuccess,
  [passportsConstants.PASSPORTS_FAILURE]: getPassportsFailure,

  [passportsConstants.PASSPORT_GET_REQUEST]: getPassportRequest,
  [passportsConstants.PASSPORT_GET_SUCCESS]: getPassportSuccess,
  [passportsConstants.PASSPORT_GET_FAILURE]: getPassportFailure,

  [passportsConstants.UPDATE_PASSPORT_REQUEST]: updatePassportRequest,
  [passportsConstants.UPDATE_PASSPORT_SUCCESS]: updatePassportSuccess,
  [passportsConstants.UPDATE_PASSPORT_FAILURE]: updatePassportFailure,

  [passportsConstants.REMOVE_PASSPORTS_REQUEST]: removePassportRequest,
  [passportsConstants.REMOVE_PASSPORTS_SUCCESS]: removePassportSuccess,
  [passportsConstants.REMOVE_PASSPORTS_FAILURE]: removePassportFailure,
});
