export const formsNames: any = {
  // логин
  login: "login",

  files: "files",
  activities: "activities",
  common: "common",
  commonOOO: "commonOOO",
  changeCommonOOO: "changeCommonOOO",
  OOOregistration: "OOOregistration",
  changeOOORegistration: "changeOOORegistration",
  foreign: "foreign",
  passport: "passport",
  registration: "registration",
  owner: "owner",
  eio: "eio",
  INNandOGRN: "INNandOGRN",
  applicant: "applicant",
  settings: "settings",
  hhMarketplace: "hhMarketplace",
  crtCheck: "crtCheck",
  certificatesStatus: "certificatesStatus",
  companySettings: "companySettings",
  docsFormat: "docsFormat",
  delivery: "delivery",

  companyAdmin: "companyAdmin",
  commonCompany: "commonCompany",
  companyRequisites: "companyRequisites",
  companyNameAndINN: "companyNameAndINN",
  shortCommon: "shortCommon",
  shortPassport: "shortPassport",
  shortRegistration: "shortRegistration",

  user: "user",
};
