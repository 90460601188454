import { usersConstants } from "../users.constants";
import { endpoints } from "src/constants";
import { API } from "src/helpers";
import { message } from "antd";
import { Dispatch } from "redux";

export const deactivateUser = (id: string) => {
  const { DEACTIVATE_USER_REQUEST, DEACTIVATE_USER_SUCCESS, DEACTIVATE_USER_FAILURE } = usersConstants;
  const request = () => ({ type: DEACTIVATE_USER_REQUEST });
  const success = () => ({ type: DEACTIVATE_USER_SUCCESS });
  const failure = () => ({ type: DEACTIVATE_USER_FAILURE });

  return async function (dispatch: Dispatch) {
    dispatch(request());
    try {
      await API.post(`${endpoints.users.deactivate}/${id}`);
      message.success("Пользователь удалён");
      dispatch(success());
    } catch (err: any) {
      message.error(`Не удалось удалить пользователя ${err}`);
      dispatch(failure());
    }
  };
};
