import { ActivityModeType, IActivity, IActivityInternal } from "src/helpers/types/Activity";
import { EntryType } from "src/helpers/types/Entry";
import { SceneType } from "src/helpers/types/Scene";

export interface IProps {
  removeActivity: (mode: string, activityCode: string) => void;
  addActivity: (mode: string, activityCode: string) => void;
  restoreActivity: (activityCode: string) => void;
  toggleActivityStar: (mode: string, activityCode: string) => void;
  getActivities: () => Promise<void>;
  clearActivities: () => void;
  activities: IActivity[];
  mode: ActivityModeType;
  selectedActivities: IActivityInternal[];
  loading: boolean;
  disabled?: boolean;
  entryType?: EntryType;
  scene?: SceneType;
  validateErrors: Record<string, any>;
  showTitle: boolean;
  showPrintButton: boolean;
}

export interface IState {
  OKVEDModalVisible: boolean;
  searchQuery: string;
  searchResult: IActivity[];
}

export const defaultProps = {
  showTitle: true,
  showPrintButton: false,
  mode: "default" as ActivityModeType,
};
