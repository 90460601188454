//@ts-nocheck
import React from "react";
import { cn } from "src/helpers/bem";
import "./DatePicker.scss";
import moment from "moment";
import { DatePicker as AntdDatePicker } from "antd";
import { Tooltip } from "src/components";
import { errorsTypes } from "src/constants";
import { IProps } from "./interface";

const b = cn("site-datepicker");

export const DatePicker = ({ value, title, meta, className, outlineColor, ...rest }: IProps) => {
  const dateFormat = "DD.MM.YYYY";

  const { touched, error, submitFailed } = meta;

  return (
    <div className={`${b(outlineColor ? { [outlineColor]: true } : {})} ${className}`}>
      <label className={b("title")}>{title}</label>

      <AntdDatePicker
        size="large"
        {...rest}
        format={dateFormat}
        // @ts-ignore
        value={value ? moment(value, "DD.MM.YYYY") : ""}
        // @ts-ignore
        locale={moment.locale("ru")}
      />
      {submitFailed && error === errorsTypes.requiredField && (
        <Tooltip title={error} visible={Boolean(touched && error)} />
      )}
      {error !== errorsTypes.requiredField && <Tooltip title={error} visible={Boolean(touched && error)} />}
    </div>
  );
};

DatePicker.defaultProps = {
  className: "",
};
