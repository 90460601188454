import { IAttribute, ICurrentEntry } from "src/helpers/types/Entry";
import { SceneType } from "src/helpers/types/Scene";
import { IOwner } from "src/helpers/types/Owner";
import { IPassport } from "src/helpers/types/Passport";

export interface IFnsDataResponse {
  code: string;
  form: {
    ifns: string;
    npKind: string;
    step: string;
  };

  ifnsDetails: {
    ifnsAddr: string;
    ifnsCode: string;
    ifnsComment: string;
    ifnsInn: string;
    ifnsKpp: string;
    ifnsName: string;
    ifnsPhone: string;
    sprof: string;
    sprou: string;
  };
  nextStep: 2;
  sprofDetails: {
    ifnsCode: string;
    sproAddr: string;
    sproCode: string;
    sproName: string;
    sproPhone: string;
  };
  sprouDetails: {
    ifnsCode: string;
    sproAddr: string;
    sproCode: string;
    sproName: string;
    sproPhone: string;
  };
  step: number;
}

export interface IEntriesResponse {
  all: number;
  count: number;
  countInArchive: number;
  countInTotalIP: number;
  countInTotalOOO: number;
  countInTotalOOOEdit: number;
  data: ICurrentEntry[];
  ended: number;
  inwork: number;
  registration: number;
}

export interface IGetEntryResponse {
  attributes: IAttribute[];
  fill: ICurrentEntry;
  scene: SceneType;
}

export interface IGetKepResponse {
  message: string;
  owners?: IOwner[];
  status: "SMEV" | string;
}

export interface IKepPoolingResponse {
  crt: Array<Record<string, string>>;
  errors: string;
  s1pool: boolean;
  status: boolean;
  uploaded: boolean;
  company_activate?: boolean;
}

export interface IPassportsResponse {
  passports: IPassport[];
  count: number;
}

export interface IGetTokenResponse {
  token: string;
}

export enum CheckActivationStatus {
  FAILED = "failed",
  WAITING = "waiting",
  SUCCESS = "activated",
}

export type CheckActivationResponse = {
  check_status: CheckActivationStatus;
  message: string;
  not_activated: Record<string, Array<string | "passport">>;
};
