import { usersConstants } from "../users.constants";
import { endpoints } from "src/constants";
import { API } from "src/helpers";
import { message } from "antd";
import { UserStatusesType } from "src/helpers/types/User";
import { Dispatch } from "redux";

export interface IUpdateUserSignature {
  userID: string;
  firstName: string;
  lastName: string;
  middleName: string;
  fullName: string;
  email: string;
  avatarURL: string;
  status: UserStatusesType;
  companyId: number;
  activated: boolean;
}

export const updateUser = (id: string, data: IUpdateUserSignature) => {
  const { UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE } = usersConstants;
  const request = () => ({ type: UPDATE_USER_REQUEST });
  const success = () => ({ type: UPDATE_USER_SUCCESS });
  const failure = () => ({ type: UPDATE_USER_FAILURE });

  return async function (dispatch: Dispatch) {
    dispatch(request());
    try {
      await API.put(`${endpoints.users.users}/${id}`, data);
      message.success("Данные пользователя обновлены");
      dispatch(success());
    } catch (err: any) {
      message.error(`Не удалось обновить данные пользователя ${err}`);
      dispatch(failure());
    }
  };
};
