import { attributes, equityFormatToAttr } from "src/constants";
import { EquityFormatType } from "src/helpers/types/Owner";

export const getInitialValues = (entryData: any, equityFormat: EquityFormatType) => {
  return {
    [equityFormatToAttr[equityFormat]]: entryData[equityFormatToAttr[equityFormat]],
    [attributes["Размер доли"]]: entryData[attributes["Размер доли"]],
    [attributes["Назначить исполнительным органом"]]: entryData[attributes["Назначить исполнительным органом"]],
  };
};
