import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ConfigProvider } from "antd";
import "./antd.less";
import "./index.scss";
import "./custom.scss";
import { Provider } from "react-redux";
import ru_RU from "antd/es/locale-provider/ru_RU";
import moment from "moment";
import "moment/locale/ru";
import { store } from "src/redux/store";
import { App } from "./main";
import { Router } from "react-router-dom";
import { history } from "./helpers";
moment.locale("ru");

Sentry.init({
  dsn: "https://b191c9bd798345ec83bdca8cc9994e1f@o488101.ingest.sentry.io/5547844",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.5,
});

ReactDOM.render(
  <ConfigProvider locale={ru_RU}>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </ConfigProvider>,
  document.getElementById("root"),
);
