import { IInputState } from "src/redux/input";
import { Action } from "redux";

interface IAction extends Action {
  issuedBy: string;
}

export const issuedbyLoadSuccess = (state: IInputState, action: IAction): IInputState => {
  const { issuedBy } = action;
  return {
    ...state,
    issuedBy,
  };
};
export const issuedbyLoadFailed = (state: IInputState, action: IAction): IInputState => {
  return {
    ...state,
    issuedBy: "",
  };
};
