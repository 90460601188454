import { loadAutoComplete } from "src/redux/input";
import { store } from "src/redux/store";
import { attributes, errorsTypes } from "src/constants";
import { validatePhone, innCheck, isFieldExist } from "src/helpers";
import { IProps } from "./interface";

// список полей, которым нужен запрос на сервер при изменении
const serverRequestNeededFields = [attributes["емейл2"]];

export default function validate(values: Record<string, any>, props: IProps): Record<string, string> {
  // ошибки для валидации
  let errors: Record<string, string> = {};
  const { dispatch, form } = props;

  errors = isFieldExist(attributes["ФИО"], values[attributes["ФИО"]], errors);
  errors = isFieldExist(
    attributes["Муниципальное образование (нижнее)"],
    values[attributes["Муниципальное образование (нижнее)"]],
    errors,
  );
  errors = isFieldExist(attributes["емейл2"], values[attributes["емейл2"]], errors);
  errors = isFieldExist(attributes["Компания"], values[attributes["Компания"]], errors);
  errors = validatePhone(attributes["Телефон"], values[attributes["Телефон"]], errors);
  if (!innCheck(values[attributes["ИНН"]])) {
    errors[attributes["ИНН"]] = errorsTypes.forbiddenINN;
  }

  /* заберем из store название последнего  измененого inputа и узнаем, нужно ли грузить ему autocomlete варианты */
  const { lastChangedField, lastChangedValue } = store.getState().input;

  // если пользователь что-то ввел в поле и это поле есть в списке полей, которым нужно обратиться к серверу
  if (lastChangedField && serverRequestNeededFields.indexOf(lastChangedField) !== -1) {
    dispatch(loadAutoComplete(form, lastChangedField, lastChangedValue));
  }

  return errors;
}
