import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./Applicant.scss";
import { connect } from "react-redux";
import { AInput } from "src/helpers";
import { Field, reduxForm, submit } from "redux-form";
import { attributes } from "src/constants";
import validate from "./validate";
import { getInitialValues } from "./initialValues";
import classNames from "classnames/dedupe";
import { IProps, defaultProps } from "./interface";
import { AppState } from "src/redux/AppState";

const sf = cn("site-form");
const b = cn("applicant-form");

class ApplicantForm extends Component<IProps> {
  render() {
    const { handleSubmit, disabled } = this.props;
    return (
      <form onSubmit={handleSubmit} className={classNames(sf(), b())}>
        <div className={sf("inputs-grid")}>
          <Field
            title="Наименование должности:"
            name={attributes["наименованиеДолжности"]}
            placeholder="Генеральный директор"
            component={AInput}
            disabled={disabled}
            className={b("post")}
          />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { data } = ownProps;
  const initialValues = data ? getInitialValues(data) : {};
  return { initialValues };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    onSubmit: () => {
      dispatch(submit(form));
    },
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(ApplicantForm as any);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as ApplicantForm };
