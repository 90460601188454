import { IInputState } from "src/redux/input";
import { Action } from "redux";

interface IAction extends Action {
  meta: { field: string; form: string };
  payload: any;
}

export const reduxFormChange = (state: IInputState, action: IAction): IInputState => {
  let field = "",
    form = "";
  if (action.meta) {
    field = action.meta.field;
    form = action.meta.form;
  }

  return {
    ...state,
    lastChangedForm: form,
    lastChangedField: field,
    lastChangedValue: action.payload,
  };
};
