import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppState } from "src/redux/AppState";
import { AppDispatch } from "src/redux/AppDispatch";
import { API_REQUEST_HEADERS } from "./network";

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export function prepareHeadersForRtkQuery(headers: Headers) {
  Object.keys(API_REQUEST_HEADERS).forEach((headerName) => {
    // @ts-ignore
    headers.set(headerName, API_REQUEST_HEADERS[headerName]);
  });

  return headers;
}
