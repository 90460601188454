import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./CompanyRegistration.scss";
import { connect } from "react-redux";
import { Divider } from "antd";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { CommonCompanyForm, CompanyRequisitesForm, CompanyAdminForm } from "src/forms";
import { signUp } from "src/redux/user";
import { Loader } from "src/components";
import { AppState } from "src/redux/AppState";
import { IProps } from "./interface";

const b = cn("company-registration-page");

class CompanyRegistration extends Component<IProps> {
  render() {
    const { formattedEntry, loading, signUp } = this.props;

    return (
      <div className={b()}>
        {loading && <Loader />}
        <Helmet>
          <title>Регистрация компании</title>
        </Helmet>
        <div className={b("wrapper")}>
          <Link to="/" className={b("logo")}>
            <img src="./images/logo.svg" />
          </Link>
          <div className={b("form")}>
            <CommonCompanyForm data={formattedEntry} />
            <Divider />
            <CompanyRequisitesForm data={formattedEntry} />
            <Divider />
            <CompanyAdminForm data={formattedEntry} />
            <br />
            <button type="button" className="custom-button" onClick={signUp}>
              Отправить заявку
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppState) {
  const { formattedEntry } = state.entries;
  const { loading } = state.userReducer;

  return { formattedEntry, loading };
}

const mapDispatchToProps = {
  signUp,
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(CompanyRegistration);

export { connectedComponent as CompanyRegistration };
