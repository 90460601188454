import { attributes } from "src/constants";
import { loadAutoComplete } from "src/redux/input";
import { store } from "src/redux/store";
import { isFieldExist } from "src/helpers";

import { IProps } from "./interface";

export default function validate(values: Record<string, any>, props: IProps): Record<string, string> {
  let errors: Record<string, string> = {};
  const { dispatch, form } = props;

  errors = isFieldExist(
    attributes["Адрес для встречи с курьером"],
    values[attributes["Адрес для встречи с курьером"]],
    errors
  );

  const serverRequestNeededFields = [attributes["Адрес для встречи с курьером"]];

  const { lastChangedField, lastChangedValue } = store.getState().input;

  if (lastChangedField && serverRequestNeededFields.indexOf(lastChangedField) !== -1) {
    dispatch(loadAutoComplete(form, lastChangedField, lastChangedValue));
  }

  return errors;
}
