import { filesConstants } from "./../files.constants";
import { message } from "antd";
import { API } from "src/helpers";
import { endpoints } from "src/constants";

export const removeFileByToken = (id: number, token: string, fileId: string) => {
  const { REMOVE_FILE_BY_TOKEN_REQUEST, REMOVE_FILE_BY_TOKEN_SUCCESS, REMOVE_FILE_BY_TOKEN_FAILURE } = filesConstants;
  const request = () => ({ type: REMOVE_FILE_BY_TOKEN_REQUEST });
  const success = () => ({ type: REMOVE_FILE_BY_TOKEN_SUCCESS });
  const failure = () => ({ type: REMOVE_FILE_BY_TOKEN_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      await API.delete(`${endpoints.files.removeByToken}?id=${id}&file_id=${fileId}&token=${token}`);

      dispatch(success());
    } catch {
      message.error(`Не удалось удалить файл`);

      dispatch(failure());
    }
  };
};
