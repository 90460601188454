import React, { Component } from "react";
import "./App.scss";
import {
  PageTypeSwitcher,
  CompanyRegistration,
  FNSregistration,
  Login,
  Page404,
  PersonalAccount,
  Clients,
  BusinessCheck,
  CrtCheck,
  Marketplace,
  Users,
  Landing,
  UploadDocs,
  Signing,
} from "./pages";
import { PrivateRoute } from "src/components";
import { ErrorBoundary } from "src/components";
import { Router, Route, Switch, withRouter } from "react-router-dom";
import { history, setWithExpiry, getWithExpiry } from "./helpers";
import { pages } from "src/constants";
import { cn } from "src/helpers/bem";
import { connect } from "react-redux";
import { getUserInfo } from "src/redux/user";
import { DashboardLayout, EntryLayout } from "src/layouts";
import { ADMINS_ID, COMPANIES_IDS_THAT_CAN_VIEW_MARKETPLACE } from "src/constants/permissions";
import { message } from "antd";

message.config({ duration: 5 });

const b = cn("app");

class Main extends Component<any> {
  unlisten = () => {};

  componentWillMount() {
    const { getUserInfo } = this.props;

    // первичный запрос информации о пользователе
    getUserInfo();

    this.unlisten = history.listen(() => {
      /* запрашиваем актуальную информацию о пользователе 
      при переключении между страницами */
      const token = getWithExpiry("token");
      if (token) {
        getUserInfo();
      }
      window.scrollTo(0, 0);
    });
  }

  componentDidMount() {
    const { getUserInfo } = this.props;
    const currentToken = getWithExpiry("token");
    if (currentToken) {
      setWithExpiry("token", currentToken, 1000 * 3600 * 24 * 7);
      getUserInfo();
    }

    window.addEventListener("error", (e) => {
      if (e.message.startsWith("ResizeObserver")) {
        const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
        const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return (
      <Router history={history}>
        <div className={b()}>
          <ErrorBoundary>
            <Switch>
              <Route exact path={"/"} render={(props) => <Landing />} />

              <Route
                exact
                path={pages.login}
                render={(props) => (
                  // @ts-ignore
                  <Login {...props} />
                )}
              />
              <Route exact path={pages.companyRegistration} render={(props) => <CompanyRegistration />} />
              <Route exact path={pages.uploadDocs} render={() => <UploadDocs />} />

              <Route
                path={[
                  pages.FNSregistration,
                  pages.personalAccount,
                  pages.clients,
                  pages.marketplace,
                  pages.users,
                  pages.businessCheck,
                ]}
              >
                <DashboardLayout>
                  <PrivateRoute exact path={pages.FNSregistration} component={FNSregistration} />
                  <PrivateRoute exact path={pages.personalAccount} component={PersonalAccount} />
                  <PrivateRoute exact path={pages.clients} component={Clients} forSomeCompanies={ADMINS_ID} />
                  <PrivateRoute
                    exact
                    path={pages.marketplace}
                    component={Marketplace}
                    forSomeCompanies={COMPANIES_IDS_THAT_CAN_VIEW_MARKETPLACE}
                  />
                  <PrivateRoute exact path={pages.users} component={Users} forSomeCompanies={ADMINS_ID} />
                  <PrivateRoute exact path={pages.businessCheck} component={BusinessCheck} />
                </DashboardLayout>
              </Route>

              <Route path={[pages.newEntry, pages.changeEntry, pages.crtCheck, pages.uploadDocs]}>
                <EntryLayout>
                  <PrivateRoute exact path={pages.newEntry} component={PageTypeSwitcher} />
                  <PrivateRoute exact path={pages.changeEntry} component={PageTypeSwitcher} />
                  <PrivateRoute exact path={pages.crtCheck} component={CrtCheck} />
                </EntryLayout>
              </Route>

              <Route exact path={pages.selfSigning} component={Signing} />

              <Route path="*" component={Page404} />
            </Switch>
          </ErrorBoundary>
        </div>
      </Router>
    );
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    getUserInfo: () => dispatch(getUserInfo()),
  };
};

/*@ts-ignore*/
const connectedComponent = withRouter(
  /*@ts-ignore*/
  connect(null, mapDispatchToProps)(Main)
);
export { connectedComponent as App };
