import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./FNSregistration.scss";
import { requestColumns, entriesTitles, entriesStatuses } from "src/constants";
import { isScene, getCookie, setCookie, isAdmin } from "src/helpers";
import { Loader, NewRequest } from "src/components";
import { getEntries, deleteEntries, copyEntries, searchEntries } from "src/redux/entries";
import { getCompanies } from "src/redux/companies";
import { CopyOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Tabs, Input, Button, Select, Table, Modal } from "antd";
import { connect } from "react-redux";
import { entriesFromServerFormat, updateQueryParam } from "src/helpers";
import qs from "query-string";
import { Helmet } from "react-helmet";
import { IProps, IState } from "./interface";
import { AppState } from "src/redux/AppState";
import { IEntriesResponse } from "src/helpers/types/ResponsesFromBackend";
import { EntryType } from "src/helpers/types/Entry";

const { TabPane } = Tabs;
const { Option } = Select;

const b = cn("fns-registration");
const sp = cn("site-page");

class FNSregistration extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      pageSize: 20,
      searchQuery: "",
      type: "all",
      status: "all",
      companyId: "all",
      page: 1,
      searchOnFocus: false,
      modalVisible: false,
    };
  }

  componentDidMount() {
    const { companies, companiesLoading, location } = this.props;
    const search = location?.search || "";
    const params = qs.parse(search);
    const { page, type, pageSize, status, companyId } = params;

    if (typeof companies === "undefined" && !companiesLoading) {
      this.props.getCompanies();
    }

    this.setState(
      {
        page: page ? +page : 1,
        type: (type as string) || "all",
        //@ts-ignore
        pageSize: +pageSize || +getCookie("fns-registration-page-size") || 20,
        status: (status as string) || "all",
        //@ts-ignore
        companyId: +companyId || "all",
      },
      () => this.updateEntries(),
    );
  }

  componentDidUpdate() {
    const { companyId } = this.state;
    const { companies } = this.props;

    if (!companyId && typeof companies !== "undefined" && companies?.length > 0) {
      this.setState({
        companyId: companies[0].id,
      });
    }
  }

  updateEntries(clearPaginationPage = false) {
    const { getEntries, searchEntries } = this.props;
    const { pageSize, type, page, companyId, status, searchQuery } = this.state;

    if (searchQuery.length >= 3) {
      searchEntries(searchQuery, pageSize, clearPaginationPage ? 1 : page, type);
    } else if (searchQuery.length === 0) {
      getEntries(pageSize, clearPaginationPage ? 1 : page, type, status, companyId);
    }

    if (clearPaginationPage) {
      this.setState({ page: 1 });
    }
  }

  showDeleteButton = (userCompanyId: number, entries: IEntriesResponse, selectedRowKeys: number[]) => {
    if (
      !isAdmin(userCompanyId) &&
      selectedRowKeys.some((it) => {
        const currentScene = entries.data[it].scene;
        return isScene("Выпущена КЭП", currentScene);
      })
    ) {
      return false;
    }
    return true;
  };

  handleModalVisible = () => {
    this.setState({ modalVisible: !this.state.modalVisible });
  };

  handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: evt.target.value }, () => this.updateEntries(true));
  };

  handleSearchFocus = () => {
    this.setState({ searchOnFocus: true });
  };

  handleSearchBlur = () => {
    this.setState({ searchOnFocus: false });
  };

  handleTableRowSelect = (selectedRowKeys: Array<any>) => {
    this.setState({ selectedRowKeys });
  };

  handleFilterChange = (filterName: string, value: any, clearPaginationPage = false) => {
    const search = this.props.location?.search;
    const { history } = this.props;
    let newSearch = updateQueryParam(search!, filterName, value);
    if (clearPaginationPage) {
      newSearch = updateQueryParam(newSearch, "page", "1");
    }
    history.push({ search: `?${newSearch}` });
    // @ts-ignore
    this.setState({ selectedRowKeys: [], [filterName]: value }, () => this.updateEntries(clearPaginationPage));
    if (filterName === "pageSize") {
      setCookie("fns-registration-page-size", value);
    }
  };

  handleEntriesDelete = () => {
    // метод удаления
    const { deleteEntries, entries } = this.props;
    // массив выбранных строк в таблице
    const { selectedRowKeys } = this.state;
    // нам нужен id заявки, которую пользователь выбрал в таблице
    const ids = selectedRowKeys.map((item: number, index: number) => entries["data"][selectedRowKeys[index]]["id"]);
    //вызываем удаление, сразу после него запрашиваем повторно заявки и очищаем выделенные строки в таблице
    deleteEntries(ids).then(() => {
      this.setState({ selectedRowKeys: [] });
      this.updateEntries();
    });
  };

  handleEntriesCopy = () => {
    // метод копирования
    const { copyEntries, entries } = this.props;
    // массив выбранных строк в таблице
    const { selectedRowKeys } = this.state;
    // нам нужен id заявки, которую пользователь выбрал в таблице
    const ids = selectedRowKeys.map((item: number, index: number) => entries["data"][selectedRowKeys[index]]["id"]);
    //вызываем копирование, сразу после него запрашиваем повторно заявки и очищаем выделенные строки в таблице
    copyEntries(ids).then(() => {
      this.setState({ selectedRowKeys: [] });
      this.updateEntries();
    });
  };

  renderTabs = (tabs: Record<string, string>) => {
    const { loading, entries } = this.props;
    const { status } = this.state;
    tabs = Object.assign({ all: "Все" }, tabs);
    return (
      <Tabs
        onChange={(value) => this.handleFilterChange("status", value, true)}
        defaultActiveKey="0"
        type="card"
        activeKey={status}
      >
        {Object.entries(tabs).map((item) => (
          <TabPane
            disabled={loading}
            tab={
              <div className={sp("tab-wrapper")}>
                <span className={sp("tab-name")}>{item[1]}</span>
                {/*@ts-ignore*/}
                <span className={sp("tab-value")}>{entries[item[0] as keyof IEntriesResponse]}</span>
              </div>
            }
            key={item[0]}
          />
        ))}
      </Tabs>
    );
  };

  renderTable = () => {
    const { selectedRowKeys, pageSize, searchQuery, page, status, searchOnFocus } = this.state;
    const { companies, entries, entriesLoading, companiesLoading } = this.props;
    const tableData = entriesFromServerFormat(entries.data, companies);
    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleTableRowSelect,
      columnWidth: "40px",
    };

    // определяем, откуда брать информацию о количестве заявок - из countAll (если это обычный запрос, а не поиск)
    // или, если это поиск, то из длины пришедшего массива
    const total = searchQuery.length >= 3 ? tableData.length : entries[status as keyof IEntriesResponse];

    return (
      <Table
        className={sp("table")}
        rowSelection={rowSelection}
        columns={requestColumns}
        dataSource={tableData}
        scroll={{ y: "100%" }}
        pagination={{
          showSizeChanger: false,
          pageSize,
          //@ts-ignore
          total,
          onChange: (value) => this.handleFilterChange("page", value, false),
          current: page,
          hideOnSinglePage: true,
        }}
        loading={
          /* По требованию заказчика, нужно было показывать скелетон при первой загрузке и лоадер при последующей
                  поэтому приходится перед показов лоадера проверять, что:
                  1) идет загрузка
                  2) до этого были успешно загружены заявки и список компаний */
          {
            spinning: Boolean(
              (entriesLoading || companiesLoading) && entries.data?.length && companies?.length && !searchOnFocus,
            ),
            indicator: <Loader fixed={false} />,
          }
        }
      />
    );
  };

  renderModal = () => {
    const { modalVisible } = this.state;

    return (
      <Modal visible={modalVisible} onCancel={this.handleModalVisible} footer={null} className={b("new-request-modal")}>
        <NewRequest onButtonClick={this.handleModalVisible} />
      </Modal>
    );
  };

  renderControls = () => {
    const { selectedRowKeys, pageSize, type, companyId, searchOnFocus } = this.state;
    const { entries, loading, userCompanyId, companies } = this.props;
    return (
      <div className={sp("controls")}>
        <div className={b("search-wrapper")}>
          <Input
            placeholder="Поиск по телефону, ФИО или названию"
            className={sp("search")}
            onChange={this.handleSearchChange}
            onFocus={this.handleSearchFocus}
            onBlur={this.handleSearchBlur}
            disabled={loading && !searchOnFocus}
          />
          <button type="button" onClick={this.handleModalVisible} className="custom-button">
            <PlusOutlined />
            Новая заявка
          </button>
        </div>
        <div className={sp("control-items")}>
          {selectedRowKeys.length > 0 && (
            <div className={sp("control-items")}>
              <Button
                icon={<CopyOutlined />}
                className={sp("control")}
                onClick={this.handleEntriesCopy}
                disabled={loading}
              />
              {this.showDeleteButton(userCompanyId, entries, selectedRowKeys) && (
                <Button
                  icon={<DeleteOutlined />}
                  className={sp("control")}
                  onClick={this.handleEntriesDelete}
                  disabled={loading}
                />
              )}
              {/* <Button icon={<DownloadOutlined />} className={sp("control")} disabled={loading}>
                Выгрузить отчет
              </Button> */}

              <div className={sp("controls-divider")} />
            </div>
          )}
          {isAdmin(userCompanyId) && typeof companies !== "undefined" && (
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={companyId}
              style={{ width: 145 }}
              onChange={(value) => this.handleFilterChange("companyId", value, true)}
              className={sp("control")}
              disabled={loading}
            >
              <Option value={"all"}>Все компании</Option>
              {companies.map((opt, index: number) => (
                <Option value={opt.id} key={`companies-select-option-${index}`}>
                  {opt.name}
                </Option>
              ))}
            </Select>
          )}
          <Select
            //@ts-ignore
            defaultValue={entriesTitles[0]}
            value={type}
            style={{ width: 145 }}
            onChange={(value) => this.handleFilterChange("type", value, true)}
            className={sp("control")}
            disabled={loading}
          >
            <Option value={"all"}>Все заявки</Option>
            {Object.keys(entriesTitles).map((item, index: number) => {
              return (
                <Option value={item} key={`entries_type${index}`}>
                  {entriesTitles[item as EntryType]}
                </Option>
              );
            })}
          </Select>

          <Select
            defaultValue={20}
            value={pageSize}
            style={{ width: 113 }}
            onChange={(value) => this.handleFilterChange("pageSize", value, true)}
            disabled={loading}
            className={sp("control", { last: true })}
          >
            <Option value={20}>20 заявок</Option>
            <Option value={40}>40 заявок</Option>
            <Option value={80}>80 заявок</Option>
          </Select>
          {/*<div className={sp("controls-divider")} />*/}
          {/*<Button icon={<FilterOutlined />} className={sp("button-filter")}></Button>*/}
          {/*<Button icon={<MoreOutlined />}></Button>*/}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={`${b()} ${sp()}`}>
        <Helmet>
          <title>Регистрация в ФНС</title>
        </Helmet>
        <div className={sp("tabs")}>{this.renderTabs(entriesStatuses)}</div>
        <div className={sp("content")}>
          {this.renderModal()}
          {this.renderControls()}
          {this.renderTable()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { entries, loading } = state.entries;
  const companies = state.companies.companies?.result?.slice().sort((a, b) => a.name.localeCompare(b.name));
  const entriesLoading = state.entries.loading;
  const companiesLoading = state.companies.loading;
  const userCompanyId = state.userReducer.user?.company?.id;
  return { entries, companies, entriesLoading, companiesLoading, loading, userCompanyId };
};

const mapDispatchToProps = {
  getEntries,
  getCompanies,
  deleteEntries,
  copyEntries,
  searchEntries,
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(FNSregistration);

export { connectedComponent as FNSregistration };
