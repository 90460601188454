import { store } from "src/redux/store";
import {
  validateCyrillicField,
  validateIssuedByField,
  validateLatin,
  isFieldExist,
  validateRusPassport,
  validateKodPodrazdelenia,
  validateDate,
  validateSnils,
  validateINN,
} from "src/helpers";
import { attributes } from "src/constants";
import { formValueSelector, change } from "redux-form";
import { loadIssuedBy } from "src/redux/input";
import { IProps } from "./interface";

export default function validate(values: Record<string, any>, props: IProps): Record<string, string> {
  const { dispatch, form } = props;

  /* заберем из store информацию о том, иностранный гражданин, или нет. От этого зависит, какие поля нужно валидировать */
  const state = store.getState();
  const passportValues = formValueSelector(form);
  const inostr = passportValues(state, attributes["иностранец"]);

  // ошибки для валидации
  let errors: Record<string, string> = {};

  //валидация полей иностранца
  if (inostr === "true") {
    errors = validateLatin(attributes["Имя (латиница)"], values[attributes["Имя (латиница)"]], errors);
    errors = validateLatin(attributes["Фамилия (латиница)"], values[attributes["Фамилия (латиница)"]], errors);
    errors = validateLatin(attributes["Имя (латиница)"], values[attributes["Имя (латиница)"]], errors, false);
    errors = validateLatin(attributes["Отчество (латиница)"], values[attributes["Отчество (латиница)"]], errors, false);
    errors = isFieldExist(attributes["Государство гражданства"], values[attributes["Государство гражданства"]], errors);
  }

  //валидация общих для иностранца и россиянина полей
  if (inostr === "false") {
    errors = validateRusPassport(values[attributes["Серия и номер паспорта"]], errors);
    errors = validateKodPodrazdelenia(values[attributes["Код подразделения"]], errors);
  }
  errors = validateIssuedByField(attributes["Кем выдан паспорт"], values[attributes["Кем выдан паспорт"]], errors);
  errors = isFieldExist(attributes["Код подразделения"], values[attributes["Код подразделения"]], errors);

  errors = validateDate(attributes["Дата выдачи паспорта"], values[attributes["Дата выдачи паспорта"]], errors);
  errors = validateDate(attributes["Дата рождения"], values[attributes["Дата рождения"]], errors);

  errors = isFieldExist(attributes["Серия и номер паспорта"], values[attributes["Серия и номер паспорта"]], errors);
  errors = isFieldExist(attributes["Пол"], values[attributes["Пол"]], errors);

  errors = isFieldExist(attributes["Место рождения"], values[attributes["Место рождения"]], errors);
  errors = validateCyrillicField(attributes["Место рождения"], values[attributes["Место рождения"]], errors);

  errors = validateSnils(attributes["СНИЛС"], values[attributes["СНИЛС"]], errors);
  errors = validateINN(values[attributes["ИНН"]], errors);

  /* заберем из store название последнего измененного inputа */
  const { lastChangedField } = store.getState().input;

  // проверка - если пользователь ввел дату выдачи и код подразделения, делаем запрос для определения, кем выдан паспорт
  if (
    (lastChangedField === attributes["Код подразделения"] || lastChangedField === attributes["Дата выдачи паспорта"]) &&
    !errors[attributes["Дата выдачи паспорта"]] &&
    !errors[attributes["Код подразделения"]]
  ) {
    dispatch(loadIssuedBy(values[attributes["Дата выдачи паспорта"]], values[attributes["Код подразделения"]])).then(
      () => {
        const state = store.getState();
        //@ts-ignore
        dispatch(change(form, attributes["Кем выдан паспорт"], state.input[attributes["Кем выдан паспорт"]]));
      }
    );
  }

  return errors;
}
