import React, { FC } from "react";
import classNames from "classnames/dedupe";
import { cn } from "src/helpers/bem";
import { Props } from "./interface";
import "./CopyToClipboardButton.scss";
import { ReactComponent as CopyIcon } from "./images/copy-icon.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import { message } from "antd";

const b = cn("copy-to-clipboard-button");

export const CopyToClipboardButton: FC<Props> = ({ className, textToCopy }) => {
  return (
    <CopyToClipboard text={textToCopy} onCopy={() => message.success("Скопировано в буфер обмена")}>
      <button className={classNames(b(), className)}>
        <CopyIcon />
      </button>
    </CopyToClipboard>
  );
};
