import React from "react";
import { cn } from "src/helpers/bem";
import "./CompanyName.scss";
import { IProps, defaultProps } from "./interface";

const b = cn("company-name");

export const CompanyName = (props: IProps) => {
  const { name } = props;

  return <span className={b()}>{name}</span>;
};

CompanyName.defaultProps = defaultProps;
