export const filesConstants = {
  //ЗАГРУЗКА ФАЙЛА
  FILE_UPLOAD_REQUEST: "FILE_UPLOAD_REQUEST",
  FILE_UPLOAD_SUCCESS: "FILE_UPLOAD_SUCCESS",
  FILE_UPLOAD_FAILURE: "FILE_UPLOAD_FAILURE",

  LOAD_FILE_BY_TOKEN_REQUEST: "LOAD_FILE_BY_TOKEN_REQUEST",
  LOAD_FILE_BY_TOKEN_SUCCESS: "LOAD_FILE_BY_TOKEN_SUCCESS",
  LOAD_FILE_BY_TOKEN_FAILURE: "LOAD_FILE_BY_TOKEN_FAILURE",

  //ПОЛУЧЕНИЕ ФАЙЛА
  FILE_LOAD_REQUEST: "FILE_LOAD_REQUEST",
  FILE_LOAD_SUCCESS: "FILE_LOAD_SUCCESS",
  FILE_LOAD_FAILURE: "FILE_LOAD_FAILURE",

  //УДАЛЕНИЕ ФАЙЛА
  FILE_REMOVE_REQUEST: "FILE_REMOVE_REQUEST",
  FILE_REMOVE_SUCCESS: "FILE_REMOVE_SUCCESS",
  FILE_REMOVE_FAILURE: "FILE_REMOVE_FAILURE",

  REMOVE_FILE_BY_TOKEN_REQUEST: "REMOVE_FILE_BY_TOKEN_REQUEST",
  REMOVE_FILE_BY_TOKEN_SUCCESS: "REMOVE_FILE_BY_TOKEN_SUCCESS",
  REMOVE_FILE_BY_TOKEN_FAILURE: "REMOVE_FILE_BY_TOKEN_FAILURE",

  //ОШИБКИ ПРИ ВАЛИДАЦИИ
  FILE_VALIDATE_ERROR: "FILE_VALIDATE_ERROR",

  //ОЧИСТКА СОСТОЯНИЯ
  FILE_CLEAR_STATE: "FILE_CLEAR_STATE",

  FILE_ADD_TO_ARCHIVE: "FILE_ADD_TO_ARCHIVE",

  // Добавление/удаление файла типа "Другой" в/из группу
  FILL_OTHER_FILES: "FILL_OTHER_FILES",
  ADD_OTHER_FILE: "ADD_OTHER_FILE",
  REMOVE_OTHER_FILE: "REMOVE_OTHER_FILE",
};
