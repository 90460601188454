import { loadAutoComplete } from "src/redux/input";
import { store } from "src/redux/store";
import { attributes } from "src/constants";
import { validateCyrillicField, validateEmail, validatePhone, isFieldExist } from "src/helpers";
import { IProps } from "./interface";

export default function validate(values: Record<string, any>, props: IProps): Record<string, string> {
  // ошибки для валидации
  let errors: Record<string, string> = {};
  const { dispatch, form, fieldsNames } = props;

  // список полей, которым нужен запрос на сервер при изменении
  const serverRequestNeededFields = [
    fieldsNames[attributes["Имя"]],
    fieldsNames[attributes["Фамилия"]],
    fieldsNames[attributes["Отчество"]],
    fieldsNames[attributes["Электронная почта"]],
  ];

  errors = isFieldExist(fieldsNames[attributes["Фамилия"]], values[fieldsNames[attributes["Фамилия"]]], errors);
  errors = isFieldExist(fieldsNames[attributes["Имя"]], values[fieldsNames[attributes["Имя"]]], errors);
  errors = validateCyrillicField(
    fieldsNames[attributes["Фамилия"]],
    values[fieldsNames[attributes["Фамилия"]]],
    errors,
  );
  errors = validateCyrillicField(fieldsNames[attributes["Имя"]], values[fieldsNames[attributes["Имя"]]], errors);
  errors = validateCyrillicField(
    fieldsNames[attributes["Отчество"]],
    values[fieldsNames[attributes["Отчество"]]],
    errors,
  );
  errors = validateEmail(
    fieldsNames[attributes["Электронная почта"]],
    values[fieldsNames[attributes["Электронная почта"]]],
    errors,
  );
  errors = validatePhone(fieldsNames[attributes["Телефон"]], values[fieldsNames[attributes["Телефон"]]], errors);
  errors = isFieldExist(fieldsNames[attributes["Пол"]], values[fieldsNames[attributes["Пол"]]], errors);

  /* заберем из store название последнего  измененого inputа и узнаем, нужно ли грузить ему autocomlete варианты */
  const { lastChangedField, lastChangedValue } = store.getState().input;

  // если пользователь что-то ввел в поле и это поле есть в списке полей, которым нужно обратиться к серверу
  if (lastChangedField && serverRequestNeededFields.indexOf(lastChangedField) !== -1) {
    dispatch(loadAutoComplete(form, lastChangedField, lastChangedValue));
  }

  return errors;
}
