import React, { createRef } from "react";
import { Input, DatePicker } from "antd";
import { Tooltip } from "src/components";
import { errorsTypes } from "src/constants";
import InputMask from "react-input-mask";
import moment from "moment";
import { cn } from "src/helpers/bem";
import { CalendarOutlined } from "@ant-design/icons";
import "./AntdDatepickerWithMask.scss";
import { DATE_REG, isDateValid } from "src/helpers";
import { defaultProps, IProps, IState } from "./interface";

const b = cn("site-datepicker-with-mask");

export class AntdDatepickerWithMask extends React.Component<IProps, IState> {
  static defaultProps = defaultProps;
  private root = createRef<HTMLDivElement>();

  constructor(props: IProps) {
    super(props);
    this.state = {
      open: false,
      value: "",
    };
  }
  componentDidMount() {
    this.root.current?.querySelector("input")?.setAttribute("autocomplete", "new-password");
  }

  handleFocus = () => {
    const { onFocus } = this.props;
    this.setState({ open: true });
    onFocus && onFocus();
  };

  handleBlur = () => {
    const { onBlur } = this.props;
    this.setState({ open: false });
    onBlur && onBlur();
  };

  handleChange = (dateAsString: string | undefined, removeFocus = false) => {
    const { onChange } = this.props;

    if (dateAsString) {
      this.setState({ value: dateAsString });
    }

    onChange(dateAsString);

    if (removeFocus || dateAsString?.match(DATE_REG)) {
      this.handleBlur();
    } else if (!dateAsString?.match(DATE_REG)) {
      this.handleFocus();
    }
  };

  render() {
    const { title, meta, className, outlineColor, size, ...rest } = this.props;
    const { touched, error, submitFailed } = meta;
    const { open, value } = this.state;
    const dateFormat = "DD.MM.YYYY";
    const valueAsDate: any = moment(value, dateFormat);
    const dateValid = isDateValid(valueAsDate);

    return (
      <div className={`${b(outlineColor ? { [outlineColor]: true } : {})} ${className}`} ref={this.root}>
        <label className={b("title")}>{title}</label>

        <InputMask
          {...rest}
          mask="99.99.9999"
          onClick={this.handleFocus}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={(e) => this.handleChange(e.target.value)}
        >
          <Input size={size} suffix={<CalendarOutlined style={{ color: "#c7c7c7" }} />} />
        </InputMask>
        <DatePicker
          tabIndex={-1}
          ref="datepicker"
          open={open}
          value={dateValid ? valueAsDate : moment()}
          onChange={(date) => this.handleChange(date?.format(dateFormat), true)}
        />
        {submitFailed && error === errorsTypes.requiredField && (
          <Tooltip title={error} visible={Boolean(touched && error)} />
        )}
        {error !== errorsTypes.requiredField && <Tooltip title={error} visible={Boolean(touched && !open && error)} />}
      </div>
    );
  }
}
