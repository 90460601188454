import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./INNandOGRN.scss";
import { AInput } from "src/helpers";
import { connect } from "react-redux";
import { Field, reduxForm, submit, change } from "redux-form";
import { attributes, entriesTypes } from "src/constants";
import validate from "./validate";
import { getInitialValues } from "./initialValues";
import { AppState } from "src/redux/AppState";
import { IProps, defaultProps } from "./interface";

const sf = cn("site-form");
const b = cn("inn-and-ogrn-form");

class INNandOGRNForm extends Component<IProps> {
  render() {
    const { handleSubmit, disabled, type, showTitle, fieldsNames } = this.props;

    const masks: Record<string, string> = {
      ipedit: "999999999999999",
      oooedit: "9999999999999",
    };

    const placeholders: Record<string, string> = {
      ipedit: "000000000000000",
      oooedit: "0000000000000",
    };

    const titles: Record<string, string> = {
      ipedit: "ОГРНИП",
      oooedit: "ОГРН",
    };

    return (
      <form onSubmit={handleSubmit} className={`${sf()} ${b()}`}>
        <div className={sf("section-wrapper")}>
          <section className={sf("section")}>
            {showTitle && <h2 className={sf("title")}>Общая информация</h2>}

            <div className={`${sf("inputs-grid")} ${b("inputs-grid")}`}>
              <Field
                title={`${titles[type]}:`}
                className={b("field")}
                name={fieldsNames[attributes["ОГРНИП"]]}
                component={AInput}
                disabled={disabled}
                placeholder={placeholders[type]}
                mask={masks[type]}
              />
              <Field
                title="ИНН:"
                className={b("field")}
                name={fieldsNames[attributes["ИНН"]]}
                component={AInput}
                disabled={disabled}
                placeholder={`0000000000${type === "ipedit" ? "00" : ""}`}
                mask={`9999999999${type === "ipedit" ? "99" : ""}`}
              />
              {(type === entriesTypes["ИзмененияИП"] || type === entriesTypes["ИзмененияООО"]) && (
                <Field
                  name={fieldsNames[attributes["Код рег. органа"]]}
                  component={AInput}
                  style={{ display: "none" }}
                />
              )}
            </div>
          </section>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { data, fieldsNames } = ownProps;
  const initialValues = data ? getInitialValues(fieldsNames, data) : {};
  return { initialValues };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    changeFieldValue: (form: string, field: string, value: string) => dispatch(change(form, field, value)),
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  validate,
  shouldError: () => true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(INNandOGRNForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as INNandOGRNForm };
