import { IFilesState } from "src/redux/files";

export const removeFileByTokenRequest = (state: IFilesState): IFilesState => {
  return {
    ...state,
    loading: true,
  };
};

export const removeFileByTokenSuccess = (state: IFilesState): IFilesState => {
  return {
    ...state,
    loading: false,
    error: false,
  };
};

export const removeFileByTokenFailure = (state: IFilesState): IFilesState => {
  return {
    ...state,
    loading: false,
    error: true,
  };
};
