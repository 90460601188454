import qs from "query-string";
import { IUser } from "src/helpers/types/User";
import { store } from "src/redux/store";
import {
  ADMINS_ID,
  COMPANIES_IDS_THAT_CAN_UPLOAD_FORM,
  COMPANIES_IDS_THAT_CAN_VIEW_MARKETPLACE,
} from "src/constants/permissions";
import { IFnsDataResponse } from "src/helpers/types/ResponsesFromBackend";
import { API } from "src/helpers/network";
import { endpoints } from "src/constants";

// заменяет двойные кавычки на кавычки-ёлочки
export const replaceDoubleQuotes = (str: string) => {
  if (str) {
    let result = str;

    if (str[0] === '"') {
      result = `«${str.slice(1)}`;
    }
    if (str[str.length - 1] === '"') {
      result = `${result.slice(0, result.length - 1)}»`;
    }

    return result.replace(/" /g, "» ").replace(/ "/g, " «");
  }
  return "";
};

// преобразует объект {par1: val1, par2: val2} в строку ?par1=val1&par2=val2
export const parseParams = (params: Record<string, string>) => {
  params = params || {};
  const keysCount = Object.keys(params).length;
  return Object.keys(params)?.reduce((acc, key, index: number): string => {
    if (index === 0) {
      acc += "?";
    }
    return acc + `${key}=${params[key]}${index !== keysCount - 1 ? "&" : ""}`;
  }, "");
};

/*
Функция берет строку вида "?par1=val1&par2=val2"
название параметра, напpимер, "par2"
значение параметра, например, "shit"

Возвращает обновленную строку - "?par1=val1&par2=shit"
P.S. если параметра par2 нет в исходной строке, добавляет его, если есть, заменяет, но не дублирует
*/
export const updateQueryParam = (search: string, param: string, value: string) => {
  // преобразуем search в объект вида {par1: val1, par2: val2}
  const parsedSearch = qs.parse(search);

  // запишем в него параметр со значением
  if (param) {
    parsedSearch[param] = value;
  }

  // сконвертируем обратно в строку и вернем ее
  return qs.stringify(parsedSearch);
};

// проверка города федерального значения
export const isFederalCity = (city: string) => {
  switch (city) {
    case "Москва":
    case "Санкт-Петербург":
    case "Севастополь":
      return true;
    default:
      return false;
  }
};

//генерация инициалов
export const getInitials = (user: Partial<IUser>) => {
  return user ? user.firstName && user.lastName && `${user.firstName[0]}${user.lastName[0]}` : "";
};

//определяет, является ли аватар аватром по умолчанию
export const checkIsDefaultAvatar = (url: string) => {
  return !url || url.includes("default");
};

// определяет, является ли пользователь с данным id админом
export const isAdmin = (userCompanyId: number) => {
  return ADMINS_ID.includes(userCompanyId);
};

/**
 * определяет, может ли пользователь грузить формы P11001 и P11001 после выпуска КЭП в регистрации ООО и изменении ООО
 */
export const canUserUploadForm = (userCompanyId: number) => {
  return COMPANIES_IDS_THAT_CAN_UPLOAD_FORM.includes(userCompanyId);
};

/**
 * определяет, может ли пользователь видеть вкладку marketplace
 */
export const canUserViewMarketPlace = () => {
  const state = store?.getState();
  const user = state?.userReducer?.user;
  const userCompanyId = user?.company?.id;
  return COMPANIES_IDS_THAT_CAN_VIEW_MARKETPLACE.includes(userCompanyId);
};

/**
 * удаляет из объекта ключи с пустыми значениями типа null, пустой строки и undefined
 * */
export const filterNonNull = (obj: Record<string, any>) =>
  Object.fromEntries(Object.entries(obj).filter(([k, v]) => v));

export const fetchSonoAreaHelper = async (sono: string) => {
  // если код территориалки 13ХХ или 31ХХ, то ставить рег орган 1300 и 3100 соответственно
  if (sono.startsWith("13")) {
    return "1300";
  } else if (sono.startsWith("31")) {
    return "3100";
  } else {
    const fnsData2: IFnsDataResponse = await API.get(`${endpoints.sono2}${sono}`);
    const sono_area = fnsData2.sprofDetails?.ifnsCode;
    // если нет в /fns2, то при исходном значении ABCD -> AB00 (два нуля в конце) - Евгений Тимофеев (с) 25.04.2022
    if (!sono_area) {
      return `${sono.slice(0, 2)}00`;
    }

    return sono_area;
  }
};
