import { attributes } from "src/constants";
import { cloneDeep } from "lodash";
import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";

interface IAction extends Action {}

export const addOwner = (state: IEntriesState, action: IAction): IEntriesState => {
  const formattedEntry = cloneDeep(state.formattedEntry);
  let { maxOwnerId = 0 } = formattedEntry!;

  if (
    // @ts-ignore
    typeof formattedEntry[attributes["учредители"]] === "undefined" ||
    // @ts-ignore
    !Array.isArray(formattedEntry[attributes["учредители"]])
  ) {
    // @ts-ignore
    formattedEntry[attributes["учредители"]] = [{ id: maxOwnerId++ }];
    // @ts-ignore
    formattedEntry[attributes["максIdучредителя"]] = maxOwnerId;
  } else {
    // @ts-ignore
    formattedEntry[attributes["учредители"]].push({ id: maxOwnerId++ });
    // @ts-ignore
    formattedEntry[attributes["максIdучредителя"]] = maxOwnerId;
  }

  return {
    ...state,
    formattedEntry,
  };
};
