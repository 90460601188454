import { IForm } from "src/helpers/types/Form";
import { formsNames } from "src/constants";

export interface IProps extends IForm {
  className?: string;
  data: Record<string, any>;
  ceo_time_inf: boolean;
}

export const defaultProps = {
  form: formsNames["eio"],
};
