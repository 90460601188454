import { entriesConstants } from "./../entries.constants";
import { extractEntryData, removeFederalCityFromEntryData, API } from "src/helpers";
import { message } from "antd";
import { endpoints } from "src/constants";
import { store } from "src/redux/store";
import { attributes as formAttributes } from "src/constants";

export const updateEntry = (ca = "new_signme", showMessages = true, loadingWithoutLogo = false) => {
  const { ENTRY_UPDATE_REQUEST, ENTRY_UPDATE_SUCCESS, ENTRY_UPDATE_FAILURE } = entriesConstants;
  const request = (loadingWithoutLogo = false) => ({ type: ENTRY_UPDATE_REQUEST, loadingWithoutLogo });
  const success = () => ({ type: ENTRY_UPDATE_SUCCESS });
  const failure = () => ({ type: ENTRY_UPDATE_FAILURE });

  const entryData = extractEntryData();
  removeFederalCityFromEntryData(entryData);

  const state = store.getState();
  const companyId = state.userReducer.user.company.id;
  const entryType = state.entries.currentEntry?.entryType;
  const { id } = entryData;
  delete entryData.id;

  const attributes = Object.keys(entryData).map((key: string) => ({
    attribute: key,
    value:
      (entryData[key] && entryData[key].toString()) ||
      (typeof entryData[key] === "boolean" && entryData[key].toString()) ||
      "",
  }));

  return async function (dispatch: Function) {
    dispatch(request(loadingWithoutLogo));
    try {
      await API.put(`${endpoints.entries.update}/${id}`, {
        attributes,
        fill: {
          ca,
          companyId,
          identityConfirmRequest: false,
          entryType,
          addressFilled: false,
          identityConfirmApproved: false,
          smsCode: "",
          signRequested: false,
          registered: false,
          filled: false,
          signCreationConfirm: false,
          completed: false,
          filledCorrect: false,
          signMarked: false,
          signCompleted: false,
          phone: entryData[formAttributes["Телефон"]] || "",
          userFilesUploaded: false,
        },
      });
      if (showMessages) {
        dispatch(success());
      }
    } catch (err: any) {
      message.error(`Не удалось сохранить заявку ${err}`);
      dispatch(failure());
    }
  };
};
