import React from "react";
import { cn } from "src/helpers/bem";
import { Link } from "react-router-dom";
import "./Page404.scss";
import { Helmet } from "react-helmet";

const b = cn("page-404");

export const Page404 = () => {
  return (
    <div className={b()}>
      <Helmet>
        <title>404</title>
      </Helmet>
      <h1 className={b("title")}>404</h1>
      <p className={b("text")}>Запрошенная страница не найдена, мы работаем над данной проблемой</p>
      <Link to={"/"} className={b("link")}>
        Вернуться на главную
      </Link>
    </div>
  );
};
