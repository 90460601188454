import React from "react";
import { cn } from "src/helpers/bem";
import { IProps, IState } from "./interface";
import "./ErrorBoundary.scss";

const b = cn("error-page");

export class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { hasError: true };
  }

  async componentDidCatch(error: any, errorInfo: any) {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    // const state = store.getState();
    // API.post(`${endpoints.errors.addError}`, {
    //   log: JSON.stringify({ state, error: error.toString(), errorInfo })
    // });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={b()}>
          <h1 className={b("title-wrapper")}>
            <span className={b("title-text")}>Что-то пошло не так!</span>
            <span className={b("title-emoji")}>😱</span>
          </h1>
          <p>Мы уже работаем над устранением данной проблемы.</p>
          <p>
            <a href="/" className={b("link")}>
              Вернуться на главную
            </a>
          </p>
        </div>
      );
    }

    // @ts-ignore
    return this.props.children;
  }
}
