import React from "react";
import { cn } from "src/helpers/bem";
import "./Status.scss";
import { ColorType, IProps, StatusObjectKeysType, TextType } from "./interface";

const b = cn("status");

const statusObject: Record<StatusObjectKeysType, { color: ColorType; text: TextType }> = {
  // Статусы заявок
  vipusk1: { color: "orange", text: "Заполнение" },
  vipusk2: { color: "orange", text: "Заполнение" },
  smevWaiting: { color: "orange", text: "Проверка СМЭВ" },
  smevFailed: { color: "red", text: "Ошибка СМЭВ" },
  smevActivation: { color: "orange", text: "Активация КЭП" },
  vipusk4: { color: "orange", text: "Выпущена КЭП" },
  fns4: { color: "orange", text: "Подписание" },
  fns5: { color: "orange", text: "Готов к отправке" },
  fns7: { color: "blue", text: "Отправлен в ФНС" },
  fns_registration: { color: "blue", text: "Регистрация в ФНС" },
  fns_pending: { color: "blue", text: "Обработка в ФНС" },
  fns_success: { color: "green", text: "Зарегистрирован" },
  fns_reject: { color: "red", text: "Отказ в регистрации" },
  fns_error: { color: "red", text: "Ошибка в ФНС" },

  // Статусы пользователей
  super: { color: "blue", text: "Суперадмин" },
  admin: { color: "blue", text: "Администратор" },
  user: { color: "blue", text: "Пользователь" },
  observer: { color: "blue", text: "Наблюдатель" },
  deactivated: { color: "red", text: "Удалён" },

  // Сегменты
  registrar: { color: "gray", text: "Регистратор" },
  accounting: { color: "gray", text: "Бухгалтерия" },
  taxi: { color: "gray", text: "Таксопарк" },
  bank: { color: "gray", text: "Банк" },
  other: { color: "gray", text: "Другое" },

  // Заявки в маркетплейсе
  Sended: { color: "blue", text: "Отправлено" },
  Recieved: { color: "green", text: "Клиент получил услугу" },
  Payed: { color: "green", text: "Получена выплата" },

  unknown: { color: "gray", text: "Неизвестен" },
};

export const Status = (props: IProps) => {
  const { status, className } = props;
  const statusFromDictionary = statusObject[status];
  const color = statusFromDictionary ? statusFromDictionary.color : "gray";
  const text = statusFromDictionary ? statusFromDictionary.text : "Неизвестен";
  return <span className={`${b({ [color]: true })} ${className}`}>{text}</span>;
};

Status.defaultProps = {
  status: "unknown",
  className: "",
};
