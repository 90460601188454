import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./CompanyRequisites.scss";
import { AInput, ARadioGroup, ARadio } from "src/helpers";
import { connect } from "react-redux";
import { Field, reduxForm, submit, formValueSelector } from "redux-form";
import { attributes } from "src/constants";
import validate from "./validate";
import { getInitialValues } from "./initialValues";
import { AppState } from "src/redux/AppState";
import { IProps, defaultProps } from "./interface";
import { USER_STATUSES } from "src/constants/user";
import { UserBusinessStatusesType } from "src/helpers/types/User";

const sf = cn("site-form");
const b = cn("company-requisites-form");

class CompanyRequisitesForm extends Component<IProps> {
  render() {
    const { handleSubmit, disabled, userStatus } = this.props;

    const masks: Record<UserBusinessStatusesType, string> = {
      ip: "999999999999999",
      ur: "9999999999999",
    };

    return (
      <form onSubmit={handleSubmit} className={`${sf()} ${b()}`}>
        <div className={sf("section-wrapper")}>
          <section className={sf("section")}>
            <div className={sf("row")}>
              <h2 className={sf("title", { "no-margin": true })}>Реквизиты организации</h2>
              <Field name={attributes["статусПользователя"]} component={ARadioGroup} buttonStyle="solid">
                <Field
                  type="radio"
                  name={attributes["статусПользователя"]}
                  isButton={true}
                  value={USER_STATUSES.ЮЛ}
                  title="ЮЛ"
                  component={ARadio}
                  checked={userStatus === USER_STATUSES.ЮЛ}
                  disabled={disabled}
                />
                <Field
                  type="radio"
                  name={attributes["статусПользователя"]}
                  isButton={true}
                  value={USER_STATUSES.ИП}
                  title="ИП"
                  checked={userStatus === USER_STATUSES.ИП}
                  component={ARadio}
                  disabled={disabled}
                />
              </Field>
            </div>

            <div className={sf("inputs-grid")}>
              <Field
                title="ОГРН:"
                name={attributes["ОГРН"]}
                component={AInput}
                disabled={disabled}
                mask={masks[userStatus]}
              />
              <Field title="ИНН:" name={attributes["ИНН"]} component={AInput} disabled={disabled} />
              {userStatus === "ur" && (
                <Field title="КПП:" name={attributes["КПП"]} component={AInput} disabled={disabled} mask="999999999" />
              )}
              <Field
                title={userStatus === "ip" ? "ФИО индивидуального предпринимателя:" : "Полное наименование:"}
                name={attributes["Полное наименование компании"]}
                component={AInput}
                disabled={disabled}
                className={b("long-input")}
              />
              {/* <Field // "убери его пока из формы" (с) Женя, 22.05.20 12:59 :)
                title="Адрес ведения деятельности:"
                name={attributes["Полный адрес"]}
                options={autoCompleteData[attributes["Полный адрес"]] || []}
                component={AAutoComplete}
                prefix={<PinIcon />}
                disabled={disabled}
                className={classNames(sf("address-input"), b("long-input"))}
              /> */}
            </div>
          </section>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { data, form } = ownProps;
  const initialValues = data ? getInitialValues(data) : {};
  const autoCompleteData = state.input.autoCompleteData[form] || {};
  const userStatus = formValueSelector(form)(state, attributes["статусПользователя"]);
  return { initialValues, autoCompleteData, userStatus };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  validate,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(CompanyRequisitesForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as CompanyRequisitesForm };
