import { attributes } from "src/constants";
import { isFieldExist, validateCyrillicField } from "src/helpers";
import { IProps } from "./interface";

export default function validate(values: Record<string, string>, props: IProps): Record<string, string> {
  let errors: Record<string, string> = {};
  errors = isFieldExist(attributes["наименованиеДолжности"], values[attributes["наименованиеДолжности"]], errors);
  errors = validateCyrillicField(
    attributes["наименованиеДолжности"],
    values[attributes["наименованиеДолжности"]],
    errors,
  );

  return errors;
}
