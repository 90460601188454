import { entriesConstants } from "./../entries.constants";
import { message } from "antd";
import { KEP_POOLING_INTERVAL } from "src/constants";
import { API } from "src/helpers";
import { endpoints, entriesTypes, attributes } from "src/constants";
import { activationPooling, changeFormattedEntryAttribute, getEntry } from "./index";
import { IKepPoolingResponse } from "src/helpers/types/ResponsesFromBackend";

export const kepPooling = (id: number, entryType: string) => {
  const { ENTRY_KEP_POOLING_REQUEST, ENTRY_KEP_POOLING_SUCCESS, ENTRY_KEP_POOLING_FAILURE } = entriesConstants;
  const request = () => ({ type: ENTRY_KEP_POOLING_REQUEST });
  const success = (kepData: IKepPoolingResponse) => ({ type: ENTRY_KEP_POOLING_SUCCESS, kepData });
  const failure = () => ({ type: ENTRY_KEP_POOLING_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    const descriptor = setInterval(async () => {
      try {
        // @ts-ignore
        const res: IKepPoolingResponse = await API.get(`${endpoints.entries[`kepPooling_${entryType}`]}/${id}`);
        const { errors, status, s1pool, uploaded, crt } = res;
        if (!errors && crt && !status && !s1pool && !uploaded) {
          dispatch(success(res));
          message.destroy();

          // забираем ссылку на заявление КЭП
          if (entryType === entriesTypes["РегистрацияООО"]) {
            // у каждого учредителя своя ссылка,
            // и crt - массив вида [{1registr_crt_link_0: "crt.pdf"}, {1registr_crt_link_1: "crt.pdf"}, ...]
            crt.forEach((it) => {
              const ownerAttr = Object.keys(it)[0];
              dispatch(changeFormattedEntryAttribute(ownerAttr, it[ownerAttr]));
            });
          } else {
            dispatch(changeFormattedEntryAttribute(attributes["ссылкаНаЗаявлениеКЭП"], Object.values(crt)[0]));
          }

          // для заявок типа "Закрытие ИП" или "Изменения ИП" или "Изменение ООО"
          // доп. проверка на необходимость активации
          if (
            entryType === entriesTypes["ЗакрытиеИП"] ||
            entryType === entriesTypes["ИзмененияИП"] ||
            entryType === entriesTypes["ИзмененияООО"]
          ) {
            // если есть ссылка на активацию
            if (res["company_activate"]) {
              dispatch(changeFormattedEntryAttribute(attributes["ссылкаНаАктивацию"], res["company_activate"]));
              // запускаем pooling активации
              dispatch(activationPooling(id));
            }
          }
          clearInterval(descriptor);

          // запросим инфу о заявке, чтобы понять, нужно ли запускать пулинг СМЭВ
          await dispatch(getEntry(id));
        } else if (errors) {
          message.destroy();
          message.error(errors);
          dispatch(failure());
          clearInterval(descriptor);
        }
      } catch (err: any) {
        message.destroy();
        message.error(`Ошибка при проверке статуса выпуска КЭП: ${err}`);
        dispatch(failure());
        clearInterval(descriptor);
      }
    }, KEP_POOLING_INTERVAL);
  };
};
