import { SceneType } from "src/helpers/types/Scene";
import { EntryType, IFormattedEntry } from "src/helpers/types/Entry";
import { ReactNode } from "react";
import { IOwner } from "src/helpers/types/Owner";
import { TaxTypesValues } from "src/constants/formValues";

export interface IProps {
  loading: boolean;
  entryType: EntryType;
  owners: Array<IOwner>;
  loadedFiles: Record<string, boolean>;
  getKep: (id: number, entryType: EntryType) => Promise<void>;
  kepPooling: Function;
  activateKep: Function;
  createLink: Function;
  sendToFNS: Function;
  currentEntryId: number;
  signLink: string;
  kepLink: string;
  activationLink: string;
  fnsContainer: string;
  raspiska: string;
  fnsDocs: string;
  userCompanyId: number;
  reqActivate: boolean;
  scene: SceneType;
  showSteps?: boolean;
  ustav_checkbox: string;
  formattedEntry: IFormattedEntry;
  passportKepLink: string;
  additionalContent?: ReactNode;
  taxType: TaxTypesValues;
  isUserObserver: boolean;
  smevOnlyPassportLeft: boolean;
  identificationId?: string;
}

export type State = {
  observerTooltipVisible: boolean;
  // врменное решение
  uploadLink?: string;
  isLoading: boolean;
};

export const defaultProps = {
  showSteps: true,
};
