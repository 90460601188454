////////////////////////// LOONG POOLING ССЫЛКИ

import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";

interface IAction extends Action {
  descriptor: NodeJS.Timer;
}

export const activationPoolingRequest = (state: IEntriesState, action: IAction): IEntriesState => {
  return { ...state, activationPoolingDescriptor: action.descriptor };
};

export const activationPoolingSuccess = (state: IEntriesState, action: IAction): IEntriesState => {
  return { ...state };
};

export const activationPoolingFailure = (state: IEntriesState, action: IAction): IEntriesState => {
  return { ...state };
};
