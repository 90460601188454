import React from "react";
import qs from "query-string";
import { history } from "src/helpers";
import { message } from "antd";

export const loadOrCreateEntry = (entryType: string, createEntry: Function, getEntry: Function) => {
  const queryParams = qs.parse(history.location.search);
  if (typeof queryParams["id"] === "undefined") {
    createEntry(entryType, queryParams["subtype"]).then((id: string) => {
      history.push({ search: `${history.location.search}&id=${id}` });
      getEntry(id);
    });
  } else {
    const { id } = queryParams;
    getEntry(id);
  }
};

export const loadSimplifiedEntry = (getSimplifiedEntry: (id: string, token: string) => Promise<void>) => {
  const queryParams = qs.parse(history.location.search);
  const { id, token } = queryParams;

  if (id && token) {
    getSimplifiedEntry(id as string, token as string);
  }
};

export const showLoadingMessage = (title: string, description?: string) => {
  const messageData = {
    content: (
      <>
        {title}
        <div>{description}</div>
      </>
    ),
    duration: 1000,
  };

  message.loading(messageData);
};
