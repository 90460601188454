import { cloneDeep } from "lodash";
import { IFilesState } from "src/redux/files";
import { Action } from "redux";

interface IAction extends Action {
  requestAttribute: string;
}

export const loadFileRequest = (state: IFilesState, action: IAction): IFilesState => {
  return {
    ...state,
    loading: true,
  };
};

export const loadFileSuccess = (state: IFilesState, action: IAction): IFilesState => {
  const loaded: any = cloneDeep(state.loaded);
  loaded[action.requestAttribute] = true;
  return {
    ...state,
    loading: false,
    error: false,
    loaded,
  };
};

export const loadFileFailure = (state: IFilesState, action: IAction): IFilesState => {
  return {
    ...state,
    loading: false,
    error: true,
  };
};
