import { entriesConstants } from "./../entries.constants";
import { attributes, formsNames } from "src/constants";
import { store } from "src/redux/store";
import { formValueSelector, change } from "redux-form";
import { IActivityInternal } from "src/helpers/types/Activity";

export const changeFormattedEntryAttribute = (attributeName: string, value: any) => {
  return (dispatch: Function) => {
    //если пользователь скрыл форму ОКВЭД, то нужно вернуть все ОКВЭД в исходное состояние - зеленые удалить,
    // а красным дать status default
    if (attributeName === attributes["изменениеОКВЭД"] && !value) {
      const state = store.getState();
      const activitiesValues = formValueSelector(formsNames["activities"]);
      let primaryOkved: IActivityInternal[] = activitiesValues(state, attributes["основнойОКВЭД"]) || [];
      let okveds: IActivityInternal[] = activitiesValues(state, attributes["допОКВЭД"]) || [];
      primaryOkved = primaryOkved.filter((it) => it["status"] !== "new").map((it) => ({ ...it, status: "default" }));

      okveds = okveds.filter((it) => it["status"] !== "new").map((it) => ({ ...it, status: "default" }));

      const changeFieldValue = (formName: string, field: string, value: any) => {
        dispatch(change(formName, field, value));
      };

      changeFieldValue(formsNames["activities"], attributes["основнойОКВЭД"], primaryOkved);
      changeFieldValue(formsNames["activities"], attributes["допОКВЭД"], okveds);
    }
    dispatch({ type: entriesConstants.CHANGE_FORMATTED_ENTRY, attributeName, value });
  };
};
