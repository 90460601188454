import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import classNames from "classnames/dedupe";
import { Table } from "antd";
import { marketplaceTableColumns } from "src/constants";
import { requestsFromServerFormat } from "src/helpers";
import "./MarketplaceTable.scss";

const b = cn("marketplace-table");
const sp = cn("site-page");

export class MarketplaceTable extends Component<any> {
  render() {
    const { cards, requests, companies } = this.props.data;
    const tableData = requestsFromServerFormat(requests, cards, companies);

    return (
      <Table
        className={classNames(sp("table"), b())}
        columns={marketplaceTableColumns}
        dataSource={tableData}
        scroll={{ y: "100%" }}
        pagination={false}
      />
    );
  }
}
