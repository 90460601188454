import React from "react";
import { Route, Redirect } from "react-router";
import { connect } from "react-redux";
import { pages } from "src/constants";
import { getWithExpiry } from "src/helpers";
import { Page404 } from "src/pages";
import { IProps, IState, defaultProps } from "./interface";
import { AppState } from "src/redux/AppState";
import { EMPTY_NUMBER_VALUE } from "src/constants/emptyInitialVlaues";

class PrivateRoute extends React.Component<IProps, IState> {
  static defaultProps = defaultProps;

  render() {
    const { component, forSomeCompanies, userCompanyId = -1, ...rest } = this.props;
    const Component = component;

    return (
      <Route
        {...rest}
        render={(props) => {
          if (getWithExpiry("token")) {
            // если страница может быть доступна только некоторым компаниям
            if (typeof forSomeCompanies !== "undefined") {
              if (forSomeCompanies.indexOf(userCompanyId) !== -1) {
                //@ts-ignore
                return <Component {...props} />;
              } else if (userCompanyId !== EMPTY_NUMBER_VALUE) {
                return <Page404 />;
              }
            }
            //@ts-ignore
            return <Component {...props} />;
          } else {
            return <Redirect to={{ pathname: pages.login, state: { from: props.location } }} />;
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { userReducer } = state;
  const { user } = userReducer;
  const userCompanyId = user?.company?.id;

  return { userCompanyId };
};

const connectedComponent = connect(mapStateToProps, null)(PrivateRoute);

export { connectedComponent as PrivateRoute };
