import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import { input } from "./input";
import { userReducer } from "./user/";
import { entries } from "./entries";
import { passports } from "./passports";
import { companies } from "./companies";
import { users } from "./users";
import { activities } from "./activities";
import { files } from "./files";
import { marketplace } from "./marketplace";
import { bills } from "./bills";
import { statuses } from "./statuses";
import { selfSigningApi } from "src/redux/selfSigning";
import { certificatesApi } from "src/redux/certificates";

const rootReducer = combineReducers({
  form: formReducer,
  input,
  entries,
  companies,
  users,
  activities,
  userReducer,
  files,
  passports,
  marketplace,
  bills,
  statuses,
  [selfSigningApi.reducerPath]: selfSigningApi.reducer,
  [certificatesApi.reducerPath]: certificatesApi.reducer,
});

export default rootReducer;
