import { usersConstants } from "../users.constants";
import { endpoints } from "src/constants";
import { API } from "src/helpers";
import { message } from "antd";
import { IUser } from "src/helpers/types/User";
import { Dispatch } from "redux";
import qs from "query-string";
import { filterNonNull } from "src/helpers";

export interface IGetUsersParams {
  search?: string;
  companyId?: string | number;
  status?: string;
  activated?: string;
}

export const getUsers = ({ search = "", companyId = "", status = "", activated = "" }: IGetUsersParams): any => {
  const { GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE } = usersConstants;
  const request = () => ({ type: GET_USERS_REQUEST });
  const success = (users: IUser[]) => ({ type: GET_USERS_SUCCESS, users });
  const failure = () => ({ type: GET_USERS_FAILURE });

  const searchQuery = qs.stringify(filterNonNull({ search, companyId, status, activated }));

  return async function (dispatch: Dispatch) {
    dispatch(request());
    try {
      const users: IUser[] = await API.get(`${endpoints.users.users}${searchQuery ? `?${searchQuery}` : ""}`);
      dispatch(success(users));
    } catch (err: any) {
      message.error(`Не удалось загрузить список пользователей ${err}`);
      dispatch(failure());
    }
  };
};
