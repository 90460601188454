import React, { FC } from "react";
import classNames from "classnames/dedupe";
import { cn } from "src/helpers/bem";
import { Props } from "./props";
import "./FileView.scss";
import { FileTextOutlined, CloudDownloadOutlined } from "@ant-design/icons";

const b = cn("file-view");

export const FileView: FC<Props> = (props) => {
  const { className, fileName, downloadLink } = props;

  return (
    <div className={classNames(b(), className)}>
      <FileTextOutlined className={b("icon")} />
      <div className={b("filename")}>{fileName}</div>
      <a className={b("link")} download href={downloadLink}>
        <CloudDownloadOutlined className={b("icon", { "download-icon": true })} />
      </a>
    </div>
  );
};
