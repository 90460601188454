//@ts-nocheck
import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { cn } from "src/helpers/bem";
import classNames from "classnames/dedupe";
import { getFnsStatus, getSignmeStatus, clearStatusesIntervals } from "src/redux/statuses";
import { Tooltip } from "src/components";
import "./SystemsStatuses.scss";

const b = cn("systems-statuses");

class SystemsStatuses extends React.Component<any, any> {
  state = {
    fnsTooltipVisible: false,
    signmeTooltipVisible: false,
  };

  componentDidMount() {
    const { getFnsStatus, getSignmeStatus } = this.props;

    getFnsStatus();
    getSignmeStatus();
  }

  componentWillUnmount() {
    const { clearStatusesIntervals } = this.props;

    clearStatusesIntervals();
  }

  handleFnsIndicatorMouseEnter = () => {
    this.setState({
      fnsTooltipVisible: true,
    });
  };

  handleFnsIndicatorMouseLeave = () => {
    this.setState({
      fnsTooltipVisible: false,
    });
  };

  handleSignmeIndicatorMouseEnter = () => {
    this.setState({
      signmeTooltipVisible: true,
    });
  };

  handleSignmeIndicatorMouseLeave = () => {
    this.setState({
      signmeTooltipVisible: false,
    });
  };

  render() {
    const { className, fnsStatus, signmeStatus } = this.props;
    const { fnsTooltipVisible, signmeTooltipVisible } = this.state;

    const fnsStatusModifier = fnsStatus === null ? "warning" : fnsStatus ? "ok" : "error";
    const signmeStatusModifier = signmeStatus === null ? "warning" : signmeStatus ? "ok" : "error";

    const fnsTooltipText =
      fnsStatus === null ? "Сервис работает с перебоями" : fnsStatus ? "Всё работает" : "Сервис не работает";
    const signmeTooltipText =
      signmeStatus === null ? "Сервис работает с перебоями" : signmeStatus ? "Всё работает" : "Сервис не работает";

    return (
      <div className={classNames(b(), className)}>
        <div className={b("status-item", { top: true })}>
          <span
            className={b("indicator", { [fnsStatusModifier]: true })}
            onMouseEnter={this.handleFnsIndicatorMouseEnter}
            onMouseLeave={this.handleFnsIndicatorMouseLeave}
          ></span>
          <Tooltip title={fnsTooltipText} visible={fnsTooltipVisible} />
          <span className={b("text")}>Сервисы ФНС</span>
        </div>
        <div className={b("status-item", { bottom: true })}>
          <span
            className={b("indicator", { [signmeStatusModifier]: true })}
            onMouseEnter={this.handleSignmeIndicatorMouseEnter}
            onMouseLeave={this.handleSignmeIndicatorMouseLeave}
          ></span>
          <Tooltip title={signmeTooltipText} visible={signmeTooltipVisible} />
          <span className={b("text")}>Сервисы УЦ</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const fnsStatus = state.statuses.fns.status;
  const signmeStatus = state.statuses.signme.status;

  return {
    fnsStatus,
    signmeStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFnsStatus: () => dispatch(getFnsStatus()),
    getSignmeStatus: () => dispatch(getSignmeStatus()),
    clearStatusesIntervals: () => dispatch(clearStatusesIntervals()),
  };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(withRouter(SystemsStatuses));

export { Component as SystemsStatuses };
