import { removeCurrentPrimaryOkved, sortSelectedActivities } from "src/helpers";
import { attributes } from "src/constants";
import { cloneDeep } from "lodash";
import { IActivitiesState } from "src/redux/activities";
import { Action } from "redux";
import { ActivityModeType } from "src/helpers/types/Activity";

interface IAction extends Action {
  mode: ActivityModeType;
  activityCode: string;
}

export const toggleActivityStar = (state: IActivitiesState, action: IAction): IActivitiesState => {
  const { mode, activityCode } = action;
  const selectedActivities = cloneDeep(state.selectedActivities);

  if (mode === "default") {
    const validateErrors = cloneDeep(state.validateErrors);
    let updatedActivities = selectedActivities.slice();
    // находим оквэд с данным кодом
    const clickedActivity = {
      ...selectedActivities.filter((it) => it["code"] === activityCode).slice()[0],
    };

    // если данный ОКВЭД основной
    if (clickedActivity.main) {
      // делаем его дополнительным
      clickedActivity["main"] = false;

      // вставляем его в массив обновленных ОКВЭД
      updatedActivities = updatedActivities.map((it) => {
        if (it["code"] === activityCode) {
          return clickedActivity;
        }
        return it;
      });
    }

    // иначе ОКВЭД дополнительный
    else {
      // делаем его основным
      clickedActivity["main"] = true;
      // и удаляем ошибку валидации
      validateErrors[attributes["ошибкаОКВЭД"]] = "";

      // находим основной ОКВЭД
      const primaryOkved = selectedActivities.filter((it) => it["main"]);

      // делаем его дополнительным
      if (primaryOkved.length > 0) {
        primaryOkved[0]["main"] = false;
      }

      // вставляем оба ОКВЭДА  в массив обновленных ОКВЭД
      updatedActivities = updatedActivities.map((it) => {
        if (it["code"] === activityCode) {
          return clickedActivity;
        }
        // @ts-ignore - тут написан ебанный бред, но пока не трогаю
        if (primaryOkved.length > 0 && it["code"] === primaryOkved["code"]) {
          return primaryOkved[0];
        }
        return it;
      });
    }

    return {
      ...state,
      selectedActivities: updatedActivities,
      validateErrors,
    };
  } else if (mode === "holdOriginal") {
    // берем кликнутую деятельность
    const clickedActivity = Object.assign(
      {},
      selectedActivities.filter((it) => it["code"] === activityCode && it["status"] !== "removed")[0],
    );
    let updatedActivities = selectedActivities.slice();

    // если кликнутый ОКВЭД оригинальный
    if (clickedActivity["status"] === "default") {
      // если она основная, создаем зеленую дополнительную копию данного ОКВЭДА
      if (clickedActivity["main"]) {
        updatedActivities = removeCurrentPrimaryOkved(selectedActivities);
      }
      //иначе
      else {
        updatedActivities = removeCurrentPrimaryOkved(selectedActivities);
        const newActivity = Object.assign({}, clickedActivity);
        newActivity["status"] = "new";
        newActivity["main"] = true;
        updatedActivities = updatedActivities.map((it) => {
          if (it["code"] === activityCode && !it["main"]) {
            return { ...clickedActivity, status: "removed" };
          }
          return it;
        });

        updatedActivities.push(newActivity);
      }
    }
    // иначе если ОКВЭД не оригинальный
    else {
      // если основной
      if (clickedActivity["main"]) {
        updatedActivities = removeCurrentPrimaryOkved(selectedActivities);
        if (updatedActivities.findIndex((it) => !it["main"] && it["code"] === clickedActivity["code"]) === -1) {
          updatedActivities.push({ ...clickedActivity, main: false });
        }
      } else {
        // если не основной
        updatedActivities = removeCurrentPrimaryOkved(selectedActivities);
        updatedActivities = updatedActivities.map((it) => {
          if (it["code"] === activityCode && !it["main"]) {
            return { ...clickedActivity, main: true };
          }
          return it;
        });
      }
    }

    return {
      ...state,
      selectedActivities: sortSelectedActivities(updatedActivities),
    };
  }

  return {
    ...state,
    selectedActivities,
  };
};
