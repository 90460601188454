import { activitiesConstants } from "./../activities.constants";
import { concatCategoriesAndActivities, API } from "src/helpers";
import { endpoints } from "src/constants";
import { message } from "antd";
import { IActivity } from "src/helpers/types/Activity";

export const getActivities = () => {
  const { GET_ACTIVITIES_REQUEST, GET_ACTIVITIES_SUCCESS, GET_ACTIVITIES_FAILURE } = activitiesConstants;
  const request = () => ({ type: GET_ACTIVITIES_REQUEST });
  const success = (activities: IActivity[]) => ({ type: GET_ACTIVITIES_SUCCESS, activities });
  const failure = () => ({ type: GET_ACTIVITIES_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      const okveds = await API.get(`${endpoints.activities.get_okved}`);
      const cats = await API.get(`${endpoints.activities.get_okved_cats}`);
      const activities = concatCategoriesAndActivities(okveds, cats);
      dispatch(success(activities));
    } catch (err: any) {
      message.error(`Ошибка при загрузке списка ОКВЭД: ${err}`);
      dispatch(failure());
    }
  };
};
