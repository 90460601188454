import { attributes } from "src/constants";
import { replaceDoubleQuotes } from "src/helpers";

export const getInitialValues = (entryData: Record<string, any>) => {
  return {
    [attributes["Причина представления заявления"]]: entryData[attributes["Причина представления заявления"]],
    [attributes["Номер типового Устава"]]: entryData[attributes["Номер типового Устава"]]
      ? parseInt(entryData[attributes["Номер типового Устава"]], 10)
      : "",
    [attributes["Форма изменений в учредительный документ"]]:
      entryData[attributes["Форма изменений в учредительный документ"]] || "",
    [attributes["Сокращенное наименование"]]:
      replaceDoubleQuotes(entryData[attributes["Сокращенное наименование"]]) || "",
    [attributes["Полное наименование"]]: replaceDoubleQuotes(entryData[attributes["Полное наименование"]]) || "",
    // [attributes["ГРН"]]: entryData[attributes["ГРН"]],
    [attributes["Изменения в целях приведения устава в соответствие"]]:
      entryData[attributes["Изменения в целях приведения устава в соответствие"]],
    [attributes["Указывать данные компании у заявителя"]]:
      entryData[attributes["Указывать данные компании у заявителя"]] || "",
  };
};
