import { attributes } from "src/constants";
import { isFieldExist, noLatinLetters, validateUstavNumber } from "src/helpers";
import { IProps } from "./interface";

export default function validate(values: any, props: IProps): Record<string, string> {
  // ошибки для валидации
  let errors: Record<string, string> = {};

  errors = noLatinLetters(
    attributes["Сокращенное наименование"],
    values[attributes["Сокращенное наименование"]],
    errors,
  );
  errors = isFieldExist(
    attributes["Сокращенное наименование"],
    values[attributes["Сокращенное наименование"]],
    errors,
    "ООО",
  );

  errors = noLatinLetters(attributes["Полное наименование"], values[attributes["Полное наименование"]], errors);
  errors = isFieldExist(attributes["Полное наименование"], values[attributes["Полное наименование"]], errors);
  errors = isFieldExist(
    attributes["Причина представления заявления"],
    values[attributes["Причина представления заявления"]],
    errors,
  );

  errors = validateUstavNumber(values[attributes["Номер типового Устава"]], errors);
  errors = isFieldExist(
    attributes["Форма изменений в учредительный документ"],
    values[attributes["Форма изменений в учредительный документ"]],
    errors,
  );

  // errors = validateGRN(values[attributes["ГРН"]], errors);

  return errors;
}

// const validateGRN = (ogrn: string, errors: any) => {
//   if (typeof ogrn === "undefined" || ogrn.indexOf("_") !== -1) {
//     errors[attributes["ГРН"]] = errorsTypes.forbiddenGRN;
//   }

//   return errors;
// };
