import { IForm } from "src/helpers/types/Form";
import { formsNames } from "src/constants";

export interface IProps extends IForm {
  data: Record<string, any>;
}

export const defaultProps = {
  form: formsNames["certificatesStatus"],
};
