import { IForm } from "src/helpers/types/Form";
import { formsNames } from "src/constants";

export interface IProps extends IForm {
  className?: string;
  data: Record<string, any>;
  innError: string;
  innButtonVisible: boolean;
  innTouched: boolean;
  loadINN: (lastName: string, firstName: string, middleName: string, dob: string, pspn: string) => Promise<any>;
  changeFieldValue: (form: string, field: string, value: string) => void;
  lastName: string;
  firstName: string;
  middleName: string;
  dob: string;
  snp: string;
  inostr: string;
  showSnils: boolean;
  snilsError: string;
  snilsTouched: boolean;
  allowForeign: boolean;
  onSubmit: any;
  inputsColors: Record<string, any>;
  passportChange: boolean;
  userCompanyId: number;
}

export const defaultProps = { form: formsNames["passport"], allowForeign: true };
