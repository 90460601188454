import { textMustBeLessThan } from "src/helpers/validators";
import { attributes } from "src/constants";

/**
 * проверяет, что в поля адресных объектов здания/сооружения и
 помещений введено максимально:
 10 символов для полей “тип”
 20 символов для полей “номер”
 * */
export const customBuildingInfoValidate = (
  values: Record<string, any>,
  errors: Record<string, string>,
  fieldsNames: Record<string, string>,
): Record<string, string> => {
  // здание/сооружение - первый столбец
  errors = textMustBeLessThan(
    fieldsNames[attributes["Здание/сооружение (первый столбец)"]],
    values[fieldsNames[attributes["Здание/сооружение (первый столбец)"]]],
    10,
    errors,
  );
  errors = textMustBeLessThan(
    fieldsNames[attributes["Номер здания/сооружения (первый столбец)"]],
    values[fieldsNames[attributes["Номер здания/сооружения (первый столбец)"]]],
    20,
    errors,
  );

  // здание/сооружение - второй столбец
  errors = textMustBeLessThan(
    fieldsNames[attributes["Здание/сооружение (второй столбец)"]],
    values[fieldsNames[attributes["Здание/сооружение (второй столбец)"]]],
    10,
    errors,
  );
  errors = textMustBeLessThan(
    fieldsNames[attributes["Номер здания/сооружения (второй столбец)"]],
    values[fieldsNames[attributes["Номер здания/сооружения (второй столбец)"]]],
    20,
    errors,
  );

  // здание/сооружение - третий столбец
  errors = textMustBeLessThan(
    fieldsNames[attributes["Здание/сооружение (третий столбец)"]],
    values[fieldsNames[attributes["Здание/сооружение (третий столбец)"]]],
    10,
    errors,
  );
  errors = textMustBeLessThan(
    fieldsNames[attributes["Номер здания/сооружения (третий столбец)"]],
    values[fieldsNames[attributes["Номер здания/сооружения (третий столбец)"]]],
    20,
    errors,
  );

  // квартира/офис
  errors = textMustBeLessThan(
    fieldsNames[attributes["Квартира/офис"]],
    values[fieldsNames[attributes["Квартира/офис"]]],
    10,
    errors,
  );
  errors = textMustBeLessThan(
    fieldsNames[attributes["Номер квартиры/офиса"]],
    values[fieldsNames[attributes["Номер квартиры/офиса"]]],
    20,
    errors,
  );

  // Помещение
  errors = textMustBeLessThan(
    fieldsNames[attributes["Помещение"]],
    values[fieldsNames[attributes["Помещение"]]],
    10,
    errors,
  );
  errors = textMustBeLessThan(
    fieldsNames[attributes["Номер помещения"]],
    values[fieldsNames[attributes["Номер помещения"]]],
    20,
    errors,
  );

  return errors;
};
