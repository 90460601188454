import React from "react";
import { IPregistration, OOOregistration, ChangeOOO, ChangeIP, CloseIP, PassportChange } from "./../";
import { entriesTypes, additionalEntriesTypes } from "src/constants";
import qs from "query-string";
import { IProps } from "./interface";

export class PageTypeSwitcher extends React.Component<IProps> {
  render() {
    const { history } = this.props;
    const queryParams = qs.parse(history.location.search);

    switch (queryParams["type"]) {
      case entriesTypes["РегистрацияИП"]:
        return <IPregistration />;
      case entriesTypes["РегистрацияООО"]:
        return <OOOregistration />;
      case entriesTypes["ИзмененияООО"]:
        return <ChangeOOO />;
      case entriesTypes["ИзмененияИП"]:
        return <ChangeIP />;
      case entriesTypes["ЗакрытиеИП"]:
        return <CloseIP />;
      case additionalEntriesTypes["ИзмененияПаспорта"]:
        return <PassportChange />;
      default:
        return <div />;
    }
  }
}
