import { attributes } from "src/constants";
import { isFieldExist } from "src/helpers";

export default function validate(values: Record<string, any>): Record<string, string> {
  // ошибки для валидации
  let errors: Record<string, string> = {};

  errors = isFieldExist(
    attributes["Коммерческое наименование"],
    values[attributes["Коммерческое наименование"]],
    errors,
  );
  errors = isFieldExist(attributes["Регистраций в месяц"], values[attributes["Регистраций в месяц"]], errors);
  errors = isFieldExist(attributes["Род деятельности"], values[attributes["Род деятельности"]], errors);

  return errors;
}
