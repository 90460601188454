import { IMarketplaceCard } from "src/helpers/types/MarketplaceCard";

export interface IProps {
  className?: string;
  cardData: IMarketplaceCard;
  sendMarketplaceCardForm(cardData: IMarketplaceCard): Promise<boolean | undefined>;
  onSuccessSend: () => void;
}

export interface IState {}

export const defaultProps = {};
