import { loadAutoComplete } from "src/redux/input";
import { store } from "src/redux/store";
import { attributes } from "src/constants";
import { validateCyrillicField, isFieldExist, validateEmail, validatePhone, isPasswordCorrect } from "src/helpers";
import { IProps } from "./interface";

// список полей, которым нужен запрос на сервер при изменении
const serverRequestNeededFields = [
  attributes["имя2"],
  attributes["Фамилия"],
  attributes["Отчество"],
  attributes["емейл2"],
];

export default function validate(values: Record<string, string>, props: IProps): Record<string, string> {
  // ошибки для валидации
  let errors: Record<string, string> = {};
  const { dispatch, form } = props;

  errors = isFieldExist(attributes["Фамилия"], values[attributes["Фамилия"]], errors);
  errors = isFieldExist(attributes["имя2"], values[attributes["имя2"]], errors);
  errors = validateCyrillicField(attributes["Фамилия"], values[attributes["Фамилия"]], errors);
  errors = validateCyrillicField(attributes["имя2"], values[attributes["имя2"]], errors);
  errors = validateCyrillicField(attributes["Отчество"], values[attributes["Отчество"]], errors);
  errors = validateEmail(attributes["емейл2"], values[attributes["емейл2"]], errors);
  errors = validatePhone(attributes["Телефон"], values[attributes["Телефон"]], errors);

  errors = isPasswordCorrect(attributes["Пароль"], values[attributes["Пароль"]], errors);
  errors = isPasswordCorrect(attributes["Подтверждение пароля"], values[attributes["Подтверждение пароля"]], errors);

  if (
    values[attributes["Пароль"]] &&
    values[attributes["Подтверждение пароля"]] &&
    values[attributes["Пароль"]] !== values[attributes["Подтверждение пароля"]]
  ) {
    errors[attributes["Подтверждение пароля"]] = "Пароли должны совпадать";
  }

  /* заберем из store название последнего  измененого inputа и узнаем, нужно ли грузить ему autocomlete варианты */
  const { lastChangedField, lastChangedValue } = store.getState().input;

  // если пользователь что-то ввел в поле и это поле есть в списке полей, которым нужно обратиться к серверу
  if (lastChangedField && serverRequestNeededFields.indexOf(lastChangedField) !== -1) {
    dispatch(loadAutoComplete(form, lastChangedField, lastChangedValue));
  }

  return errors;
}
