import { IFilesState } from "src/redux/files";
import { Action } from "redux";

interface IAction extends Action {
  requestAttribute: string;
}

export const addFileToArchive = (state: IFilesState, action: IAction): IFilesState => {
  return { ...state, inArchive: Object.assign({}, state.inArchive, { [action.requestAttribute]: true }) };
};
