import { activitiesConstants } from "./../activities.constants";
import { getActivitiesRequest, getActivitiesSuccess, getActivitiesFailure } from "./getActivities";
import { activitiesValidateError } from "./updateValidateErrors";
import { addActivity } from "./addActivity";
import { removeActivity } from "./removeActivity";
import { restoreActivity } from "./restoreActivity";
import { toggleActivityStar } from "./toggleActivityStar";
import { setSelectedActivities } from "./setSelectedActivities";
import { clearActivities } from "./clearActivities";
import { IActivity, IActivityInternal } from "src/helpers/types/Activity";
import { createReducer } from "@reduxjs/toolkit";
/*
  activities - это список всех ОКВЭД с сервера
  selectedActivities - те ОКВЭД, которые выбрал пользователь
*/

export interface IActivitiesState {
  activities: IActivity[];
  selectedActivities: IActivityInternal[];
  loading: boolean;
  error: boolean;
  validateErrors: Record<string, any>;
}

const initialState: IActivitiesState = {
  activities: [],
  selectedActivities: [],
  loading: false,
  error: false,
  validateErrors: {},
};

export const activities = createReducer(initialState, {
  [activitiesConstants.GET_ACTIVITIES_REQUEST]: getActivitiesRequest,
  [activitiesConstants.GET_ACTIVITIES_SUCCESS]: getActivitiesSuccess,
  [activitiesConstants.GET_ACTIVITIES_FAILURE]: getActivitiesFailure,

  [activitiesConstants.ACTIVITY_VALIDATE_ERROR]: activitiesValidateError,

  [activitiesConstants.ADD_ACTIVITY]: addActivity,

  [activitiesConstants.REMOVE_ACTIVITY]: removeActivity,

  [activitiesConstants.RESTORE_ACTIVITY]: restoreActivity,

  [activitiesConstants.TOGGLE_ACTIVITY_STAR]: toggleActivityStar,

  [activitiesConstants.SET_SELECTED_ACTIVITIES]: setSelectedActivities,

  [activitiesConstants.CLEAR_ACTIVITIES]: clearActivities,
});
