import { passportsConstants } from "./../passports.constants";
import { extractPassportData, API } from "src/helpers";
import { message } from "antd";
import { endpoints } from "src/constants";

export const updatePassport = () => {
  const { UPDATE_PASSPORT_REQUEST, UPDATE_PASSPORT_SUCCESS, UPDATE_PASSPORT_FAILURE } = passportsConstants;
  const request = () => ({ type: UPDATE_PASSPORT_REQUEST });
  const success = () => ({ type: UPDATE_PASSPORT_SUCCESS });
  const failure = () => ({ type: UPDATE_PASSPORT_FAILURE });

  const passportData = extractPassportData();
  const { id } = passportData;

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      await API.put(`${endpoints.passports.update}/${id}`, passportData);
      dispatch(success());
    } catch (err: any) {
      message.error(`Не удалось обновить данные пользователя ${err}`);
      dispatch(failure());
    }
  };
};
