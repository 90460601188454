import { loadAutoComplete } from "src/redux/input";
import { store } from "src/redux/store";
import { attributes } from "src/constants";
import { validateCyrillicField, validateEmail, validatePhone, isFieldExist } from "src/helpers";
import { IProps } from "./interface";

// список полей, которым нужен запрос на сервер при изменении
const serverRequestNeededFields = [
  attributes["Имя"],
  attributes["Фамилия"],
  attributes["Отчество"],
  attributes["Электронная почта"],
];

export default function validate(values: Record<string, any>, props: IProps): Record<string, string> {
  // ошибки для валидации
  let errors: Record<string, string> = {};
  const { dispatch, form } = props;

  errors = isFieldExist(attributes["Фамилия"], values[attributes["Фамилия"]], errors);
  errors = isFieldExist(attributes["Имя"], values[attributes["Имя"]], errors);
  errors = validateCyrillicField(attributes["Фамилия"], values[attributes["Фамилия"]], errors);
  errors = validateCyrillicField(attributes["Имя"], values[attributes["Имя"]], errors);
  errors = validateCyrillicField(attributes["Отчество"], values[attributes["Отчество"]], errors);
  errors = validateEmail(attributes["Электронная почта"], values[attributes["Электронная почта"]], errors);
  errors = validatePhone(attributes["Телефон"], values[attributes["Телефон"]], errors);
  errors = validatePhone(
    attributes["Номер телефона для ФНС"],
    values[attributes["Номер телефона для ФНС"]],
    errors,
    false,
  );
  errors = isFieldExist(attributes["Система налогобложения"], values[attributes["Система налогобложения"]], errors);

  /* заберем из store название последнего  измененого inputа и узнаем, нужно ли грузить ему autocomlete варианты */
  const { lastChangedField, lastChangedValue } = store.getState().input;

  // если пользователь что-то ввел в поле и это поле есть в списке полей, которым нужно обратиться к серверу
  if (lastChangedField && serverRequestNeededFields.indexOf(lastChangedField) !== -1) {
    dispatch(loadAutoComplete(form, lastChangedField, lastChangedValue));
  }

  return errors;
}
