import { attributes } from "src/constants";
export const getInitialValues = (
  fieldsNames: Record<string, string>,
  entryData: Record<string, any>,
): Record<string, any> => {
  return {
    [fieldsNames[attributes["Наименование региона"]]]: entryData[fieldsNames[attributes["Наименование региона"]]],
    [fieldsNames[attributes["Регион (сокр.)"]]]: entryData[fieldsNames[attributes["Регион (сокр.)"]]],
    [fieldsNames[attributes["Регион (полн.)"]]]: entryData[fieldsNames[attributes["Регион (полн.)"]]],
    [fieldsNames[attributes["Полный адрес"]]]: entryData[fieldsNames[attributes["Полный адрес"]]],
    [fieldsNames[attributes["Субъект"]]]: entryData[fieldsNames[attributes["Субъект"]]],
    [fieldsNames[attributes["Код рег. органа"]]]: entryData[fieldsNames[attributes["Код рег. органа"]]],
    [fieldsNames[attributes["Код терр. ФНС"]]]: entryData[fieldsNames[attributes["Код терр. ФНС"]]],
    [fieldsNames[attributes["Муниципальное образование (верхнее)"]]]:
      entryData[fieldsNames[attributes["Муниципальное образование (верхнее)"]]],
    [fieldsNames[attributes["Наименование муниципального образования (верхнее)"]]]:
      entryData[fieldsNames[attributes["Наименование муниципального образования (верхнее)"]]],
    [fieldsNames[attributes["Муниципальное образование (нижнее)"]]]:
      entryData[fieldsNames[attributes["Муниципальное образование (нижнее)"]]],
    [fieldsNames[attributes["Наименование муниципального образования (нижнее)"]]]:
      entryData[fieldsNames[attributes["Наименование муниципального образования (нижнее)"]]],
    [fieldsNames[attributes["Населенный пункт"]]]: entryData[fieldsNames[attributes["Населенный пункт"]]],
    [fieldsNames[attributes["Наименование населенного пункта"]]]:
      entryData[fieldsNames[attributes["Наименование населенного пункта"]]],
    [fieldsNames[attributes["Элемент планировочной структуры"]]]:
      entryData[fieldsNames[attributes["Элемент планировочной структуры"]]],
    [fieldsNames[attributes["Наименование элемента планировочной структуры"]]]:
      entryData[fieldsNames[attributes["Наименование элемента планировочной структуры"]]],
    [fieldsNames[attributes["Элемент улично-дорожной сети"]]]:
      entryData[fieldsNames[attributes["Элемент улично-дорожной сети"]]],
    [fieldsNames[attributes["Наименование элемента улично-дорожной сети"]]]:
      entryData[fieldsNames[attributes["Наименование элемента улично-дорожной сети"]]],
    [fieldsNames[attributes["Здание/сооружение (первый столбец)"]]]:
      entryData[fieldsNames[attributes["Здание/сооружение (первый столбец)"]]],
    [fieldsNames[attributes["Номер здания/сооружения (первый столбец)"]]]:
      entryData[fieldsNames[attributes["Номер здания/сооружения (первый столбец)"]]],
    [fieldsNames[attributes["Здание/сооружение (второй столбец)"]]]:
      entryData[fieldsNames[attributes["Здание/сооружение (второй столбец)"]]],
    [fieldsNames[attributes["Номер здания/сооружения (второй столбец)"]]]:
      entryData[fieldsNames[attributes["Номер здания/сооружения (второй столбец)"]]],
    [fieldsNames[attributes["Здание/сооружение (третий столбец)"]]]:
      entryData[fieldsNames[attributes["Здание/сооружение (третий столбец)"]]],
    [fieldsNames[attributes["Номер здания/сооружения (третий столбец)"]]]:
      entryData[fieldsNames[attributes["Номер здания/сооружения (третий столбец)"]]],
    [fieldsNames[attributes["Квартира/офис"]]]: entryData[fieldsNames[attributes["Квартира/офис"]]],
    [fieldsNames[attributes["Номер квартиры/офиса"]]]: entryData[fieldsNames[attributes["Номер квартиры/офиса"]]],
    [fieldsNames[attributes["Помещение"]]]: entryData[fieldsNames[attributes["Помещение"]]],
    [fieldsNames[attributes["Номер помещения"]]]: entryData[fieldsNames[attributes["Номер помещения"]]],

    [fieldsNames[attributes["Адрес для документов"]]]: entryData[fieldsNames[attributes["Адрес для документов"]]],
  };
};
