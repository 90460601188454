import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import { Divider, Modal, Checkbox } from "antd";
import { connect } from "react-redux";
import { Tooltip, UserPreview } from "src/components";
import { addOtherFile } from "src/redux/files";
import { attributes, formsNames, entriesTypes, errorsTypes } from "src/constants";
import { mainFields, additionalFields, INNandOGRNFieldsNames } from "./constants";
import "./Forms.scss";
import {
  RegistrationForm,
  FilesForm,
  ChangeCommonOOOForm,
  ApplicantForm,
  CommonForm,
  PassportForm,
  ActivityForm,
  INNandOGRNForm,
  DocsFormatForm,
} from "src/forms";
import { isScene, getSurnameAndInitials, customBuildingInfoValidate, canUserUploadForm } from "src/helpers";
import { changeFormattedEntryAttribute, updateApplicant } from "src/redux/entries";
import { AppState } from "src/redux/AppState";
import { IProps } from "./interface";

const sf = cn("site-forms");
const b = cn("changeOOO-forms");

class Forms extends Component<IProps> {
  state = {
    modalVisible: false,
  };

  handleModal = () => {
    this.setState({
      modalVisible: !this.state.modalVisible,
    });
  };

  handleCheckboxChange = (fieldName: string, checked: boolean) => {
    this.setState({ [fieldName]: checked });
  };

  renderCommonForm = () => {
    const { formattedEntry, disabled, subtype } = this.props;

    return (
      <ChangeCommonOOOForm data={formattedEntry} disabled={disabled} subtype={subtype}>
        <INNandOGRNForm
          showTitle={false}
          type={entriesTypes["ИзмененияООО"]}
          data={formattedEntry}
          disabled={disabled}
          fieldsNames={INNandOGRNFieldsNames}
        />
      </ChangeCommonOOOForm>
    );
  };

  renderPreview = () => {
    const { validateErrors, formattedEntry, disabled } = this.props;
    const cjob = formattedEntry?.cjob;
    const applicantError = validateErrors?.applicantError;

    let previewData = [cjob];
    previewData = previewData.filter((it) => it);

    return (
      <UserPreview
        label={getSurnameAndInitials(formattedEntry) || "Новый заявитель"}
        options={previewData}
        disabled={disabled}
        onEdit={this.handleModal}
        errorVisible={Boolean(applicantError)}
      />
    );
  };

  renderModal = () => {
    const { formattedEntry, disabled, updateApplicant } = this.props;
    const { modalVisible } = this.state;
    return (
      <Modal
        visible={modalVisible}
        footer={null}
        onCancel={() => {
          updateApplicant();
          this.handleModal();
        }}
        title="Данные заявителя"
        className="common-modal"
        forceRender={true}
        style={{ top: 20 + Math.random() }}
      >
        <div className={sf("modal-row")}>
          <div>
            <CommonForm data={formattedEntry} disabled={disabled} showNalog={false} />
            <Divider />
            <ApplicantForm data={formattedEntry} disabled={disabled} />
          </div>
        </div>
        <Divider />
        <PassportForm data={formattedEntry} allowForeign={false} disabled={disabled} showSnils={false} />
        <Divider />
        {/*<RegistrationForm*/}
        {/*  fieldsNames={applicantAddressFieldsNames}*/}
        {/*  data={formattedEntry}*/}
        {/*  disabled={disabled}*/}
        {/*  showRegistrOrgan={false}*/}
        {/*/>*/}
      </Modal>
    );
  };

  renderActivityCheckbox = () => {
    const { OKVEDChanged, changeOKVEDError, changeRegistrationError, changeFormattedEntryAttribute, disabled } =
      this.props;

    return (
      <div className={sf("checkbox-wrapper")}>
        <Checkbox
          onChange={(e) => changeFormattedEntryAttribute(attributes["изменениеОКВЭД"], e.target.checked)}
          checked={OKVEDChanged}
          className={sf("visibility-checkbox")}
          disabled={disabled}
        >
          Изменение видов деятельности
        </Checkbox>
        <Tooltip title={errorsTypes.requiredChange} visible={Boolean(changeOKVEDError && changeRegistrationError)} />
      </div>
    );
  };

  renderActivityForm = () => {
    const { OKVEDChanged, disabled, scene } = this.props;
    // просто disabled={disabled} у ActivityForm почему-то не работает
    const activityDisabledKostil = {
      disabled,
    };
    return (
      <div style={{ display: OKVEDChanged ? "block" : "none" }}>
        <ActivityForm
          {...activityDisabledKostil}
          disabled={disabled}
          scene={scene}
          showTitle={false}
          mode="holdOriginal"
        />
      </div>
    );
  };

  renderAddressCheckbox = () => {
    const { addressChanged, changeOKVEDError, changeRegistrationError, changeFormattedEntryAttribute, disabled } =
      this.props;

    return (
      <div className={sf("checkbox-wrapper")}>
        <Checkbox
          onChange={(e) => changeFormattedEntryAttribute(attributes["изменениеМестонахождения"], e.target.checked)}
          checked={addressChanged}
          className={sf("visibility-checkbox")}
          style={{ marginBottom: addressChanged ? "20px" : "0px" }}
          disabled={disabled}
        >
          Изменение адреса местонахождения
        </Checkbox>
        <Tooltip title={errorsTypes.requiredChange} visible={Boolean(changeOKVEDError && changeRegistrationError)} />
      </div>
    );
  };

  renderAddressForm = () => {
    const { formattedEntry, addressChanged, disabled } = this.props;
    return addressChanged ? (
      <RegistrationForm
        customValidate={customBuildingInfoValidate}
        form={formsNames["changeOOORegistration"]}
        data={formattedEntry}
        disabled={disabled}
        showTitle={false}
        showRegistrOrgan={false}
        extended
      />
    ) : (
      ""
    );
  };

  renderFilesForm = () => {
    const {
      loading,
      wasLoaded,
      scene,
      subtype,
      firstOtherFile,
      otherFiles,
      loadedOtherFilesCount,
      addOtherFile,
      userCompanyId,
    } = this.props;

    const otherFilesFields = [];
    const otherAttr = `${attributes["Другое"]}${subtype === "ustav" ? "13" : "14"}`;

    // Если загружен файл 'file_drug', то отображаем его только на этапе
    // после отправки в ФНС
    if (firstOtherFile && (isScene("Регистрация в ФНС", scene) || isScene("Зарегистрирован", scene))) {
      otherFilesFields.push({
        attribute: otherAttr,
        title: "Другое",
        label: "Другое",
        fileType: "uploadOOO_EDIT",
      });
    }

    if (otherFiles.length > 0) {
      otherFiles.forEach((file) => {
        otherFilesFields.push({
          attribute: file,
          title: "Другое",
          label: "Другое",
          fileType: "uploadOOO_EDIT",
        });
      });
    }
    if (otherFiles.length === loadedOtherFilesCount) {
      addOtherFile(otherAttr);
    }

    return (
      <div>
        <FilesForm
          fileFields={mainFields.concat(otherFilesFields)}
          disabled={!isScene("Создание ссылки", scene)}
          disabledTooltipVisible={isScene("Заполнение", scene)}
          loading={loading}
          wasLoaded={wasLoaded}
        />

        {/* на этапе после выпуска КЭП нужно дать возможность загрузить файл формы P13014 */}
        {canUserUploadForm(userCompanyId) && isScene("Создание ссылки", scene) ? (
          <>
            <Divider className={sf("files-divider")} />
            <FilesForm
              fileFields={[
                {
                  attribute: "13014",
                  title: "Форма P13014",
                  label: "Форма P13014",
                  accept: "tif",
                  fileType: "uploadOOO_EDIT",
                },
              ]}
              showTitle={false}
              loading={loading}
              wasLoaded={wasLoaded}
            />
          </>
        ) : null}

        {isScene("Выпущена КЭП", scene) && (
          <>
            <Divider className={sf("files-divider")} />
            <FilesForm
              fileFields={additionalFields}
              disabled={isScene("Зарегистрирован", scene) || isScene("Регистрация в ФНС", scene)}
              disabledTooltipVisible={isScene("Заполнение", scene)}
              loading={loading}
              wasLoaded={wasLoaded}
              showTitle={false}
            />
          </>
        )}
      </div>
    );
  };

  render() {
    const { formattedEntry, disabled } = this.props;

    if (!formattedEntry) {
      return <div />;
    }

    return (
      <div className={`${sf({ "with-files": true })} ${b()}`}>
        <div className={sf("forms")}>
          {this.renderCommonForm()}

          <Divider />

          <h2 className={sf("title")}>Данные заявителя</h2>
          {formattedEntry && (
            <div className={sf("applicant")}>
              {this.renderPreview()}
              {this.renderModal()}
            </div>
          )}
          <DocsFormatForm disabled={disabled} data={formattedEntry} className={b("docs-form")} />

          <Divider />

          {this.renderActivityCheckbox()}
          {this.renderActivityForm()}

          <Divider />

          {this.renderAddressCheckbox()}
          {this.renderAddressForm()}
        </div>

        {this.renderFilesForm()}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { loading, wasLoaded, formattedEntry, validateErrors } = state.entries;
  const user = state?.userReducer?.user;
  const userCompanyId = user?.company?.id;
  const changeOKVEDError = state?.entries?.validateErrors?.changeOKVEDError;
  const changeRegistrationError = state?.entries?.validateErrors?.changeRegistrationError;
  const OKVEDChanged =
    //@ts-ignore
    typeof formattedEntry[attributes["изменениеОКВЭД"]] === "string"
      ? //
        //@ts-ignore
        formattedEntry[attributes["изменениеОКВЭД"]] === "true"
        ? true
        : false
      : //
        //@ts-ignore
        formattedEntry[attributes["изменениеОКВЭД"]];
  const addressChanged =
    //@ts-ignore
    typeof formattedEntry[attributes["изменениеМестонахождения"]] === "string"
      ? //
        //@ts-ignore
        formattedEntry[attributes["изменениеМестонахождения"]] === "true"
        ? true
        : false
      : //
        // @ts-ignore
        formattedEntry[attributes["изменениеМестонахождения"]];
  const firstOtherFile = formattedEntry?.file_drug;
  const otherFiles = state.files.otherFiles.files;
  const loadedOtherFilesCount = Object.entries(state.files.loaded).filter(
    ([key, value]) => key.match(/drug(13|14)?_\d/g) && typeof value !== "undefined"
  ).length;

  return {
    loading,
    wasLoaded,
    formattedEntry,
    validateErrors,
    changeOKVEDError,
    changeRegistrationError,
    OKVEDChanged,
    addressChanged,
    firstOtherFile,
    otherFiles,
    loadedOtherFilesCount,
    userCompanyId,
  };
};

const mapDispatchToProps = {
  changeFormattedEntryAttribute,
  updateApplicant,
  addOtherFile,
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(Forms);

export { connectedComponent as Forms };
