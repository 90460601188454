import { attributes, entriesTypes, errorsTypes } from "src/constants";
import { updateValidateActivitiesError } from "src/redux/activities";
import { IActivity, IActivityInternal } from "src/helpers/types/Activity";
import { AppState } from "src/redux/AppState";

// возвращает title ОКВЭД по коду
export const getActivityTitleByCode = (activities: Array<IActivity>, code: string) => {
  if (activities && activities.length && code) {
    let res;
    const findActivityWithCode = (childs?: Array<IActivity>) => {
      if (childs) {
        childs.forEach((it) => {
          if (it.code === code) {
            res = it;
          } else {
            return findActivityWithCode(it.childs);
          }
        });
      }
    };

    findActivityWithCode(activities);
    //@ts-ignore
    return res ? res.title : "Неизвестный ОКВЭД";
  }
};

//возвращает все ОКВЭД, у которых значение code или title имеет вхождение строки searchQuery и которые можно выбрать, как ОКВЭД
export const getActivitiesBySearchQuery = (activities: Array<IActivity>, searchQuery: string) => {
  const res: IActivity[] = [];
  if (searchQuery.trim()) {
    const searchRegex = new RegExp(`${searchQuery}.*`, "gi");
    const findActivities = (childs?: Array<IActivity>) => {
      if (childs) {
        childs.forEach((it) => {
          if (it.code.replace(".", "").length >= 4 && (it.code.match(searchRegex) || searchRegex.test(it.title))) {
            res.push(it);
          }
          return findActivities(it.childs);
        });
      }
    };

    findActivities(activities);
  }

  return res;
};

export const concatCategoriesAndActivities = (okveds: any, cats: any) => {
  // Идем по всем категориям
  const res = cats.map((cat: any) => {
    const catInfo = { title: cat.title, code: cat.code };
    const childs = okveds.filter((okvedInfo: any) => okvedInfo.okveds[0].code.indexOf(cat.code) !== -1);
    childs.forEach((childItem: any) => {
      childItem.title = childItem.okveds[0].title;
      childItem.code = childItem.okveds[0].code;
    });
    //@ts-ignore
    catInfo["childs"] = childs;
    return catInfo;
  });

  return res;
};

//собирает и соритрует (в начале зеленые, потом красные, потом обычные) выбранные пользователем ОКВЭДы в единый массив
export const sortSelectedActivities = (selectedActivities: IActivityInternal[] = []): IActivityInternal[] => {
  const table = {
    new: 2,
    removed: 1,
    default: 0,
  };

  const selectedActivitiesCopy = selectedActivities.slice();

  return selectedActivitiesCopy.sort((act1, act2) => {
    //@ts-ignore
    return table[act2["status"]] - table[act1["status"]];
  });
};

export const validateOkveds = (state: AppState, dispatch: Function): boolean => {
  const { selectedActivities } = state.activities;
  const primaryOkvedExist = selectedActivities.some((it) => it["main"] && it["status"] !== "removed");
  // проверка для изменения ИП и изм. ООО: ЕГРЮЛ
  const entryType = state?.entries?.currentEntry?.entryType;

  if (entryType === entriesTypes["ИзмененияИП"]) {
    const changeRegistration = state?.entries?.formattedEntry?.changeRegistration;
    const okvedsWereChanged = selectedActivities.some((it) => it.status !== "default");
    if (!okvedsWereChanged && !changeRegistration) {
      dispatch(updateValidateActivitiesError(attributes["ошибкаОКВЭД"], "Нужно внести изменения в ОКВЭД"));
      return false;
    } else {
      dispatch(updateValidateActivitiesError(attributes["ошибкаОКВЭД"], ""));
    }
  }

  if (entryType !== entriesTypes["ИзмененияООО"] && !primaryOkvedExist) {
    dispatch(updateValidateActivitiesError(attributes["ошибкаОКВЭД"], errorsTypes.requiredMainOkved));
    return false;
  } else {
    dispatch(updateValidateActivitiesError(attributes["ошибкаОКВЭД"], ""));
  }

  return true;
};

export const getChangedOkveds = (selectedActivities: Array<IActivityInternal>): string[] => {
  const okvedAddition = selectedActivities
    .filter((it) => !it["main"] && it["status"] === "new")
    .map((it) => it.code)
    .join(",");
  const okvedDeletion = selectedActivities
    .filter((it) => !it["main"] && it["status"] === "removed")
    .map((it) => it.code)
    .join(",");
  const primaryOkvedAddition = selectedActivities
    .filter((it) => it["main"] && it["status"] === "new")
    .map((it) => it.code)
    .join(",");
  const primaryOkvedDeletion = selectedActivities
    .filter((it) => it["main"] && it["status"] === "removed")
    .map((it) => it.code)
    .join(",");

  return [okvedAddition, okvedDeletion, primaryOkvedAddition, primaryOkvedDeletion];
};

export const removeCurrentPrimaryOkved = (selectedActivities: IActivityInternal[]) => {
  const primaryActivityIndex = selectedActivities.findIndex((it) => it["status"] !== "removed" && it["main"]);
  if (primaryActivityIndex !== -1) {
    const newActivity = Object.assign({}, selectedActivities[primaryActivityIndex]);
    newActivity["status"] = "new";
    newActivity["main"] = false;
    let updatedActivities = selectedActivities.slice();
    if (selectedActivities[primaryActivityIndex]["status"] === "default") {
      updatedActivities = selectedActivities.map((it, index: number) => {
        if (index === primaryActivityIndex) {
          return { ...selectedActivities[primaryActivityIndex], status: "removed" };
        }
        return it;
      });
    } else {
      updatedActivities = updatedActivities.filter((it, index: number) => index !== primaryActivityIndex);
    }
    if (
      selectedActivities.findIndex(
        (it) => it["status"] === "removed" && !it["main"] && it["code"] === newActivity["code"],
      ) === -1
    ) {
      updatedActivities.push(newActivity);
    }

    return updatedActivities;
  } else {
    return selectedActivities;
  }
};
