import { ReactNode } from "react";

export interface IProps {
  label: string;
  className: string;
  disabled: boolean;
  errorVisible: boolean;
  options: ReactNode[];
  onEdit: () => void;
  onRemove?: () => void;
}

export const defaultProps = {
  label: "Неизвестно",
  className: "",
  disabled: false,
  errorVisible: false,
  options: [],
};
