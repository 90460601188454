import { WrappedFieldMetaProps } from "redux-form";

export interface IProps {
  title: string;
  options: Array<{ value: any; name: string; title: string }>;
  meta: WrappedFieldMetaProps;
  className: string;
  name: string;
  showSearch: boolean;
}
export const defaultProps = {
  className: "",
  title: "",
  showSearch: true,
};
