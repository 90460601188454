import { endpoints } from "src/constants";
import { inputConstants } from "./../input.constants";
import { message } from "antd";
import { API } from "src/helpers";

export const loadIssuedBy = (issueDate: any, kodPodrazdelenia: string): any => {
  const { ISSUEDBY_LOAD, ISSUEDBY_LOAD_SUCCESS, ISSUEDBY_LOAD_FAILED } = inputConstants;
  const request = () => ({ type: ISSUEDBY_LOAD });
  const success = (issuedBy: string) => ({ type: ISSUEDBY_LOAD_SUCCESS, issuedBy });
  const failure = () => ({ type: ISSUEDBY_LOAD_FAILED });

  // потому что date у нас приходит либо в moment-формате, либо в виде строки
  let date;
  if (typeof issueDate === "string") {
    date = issueDate;
  } else {
    date = issueDate.toDate().toLocaleDateString("ru-RU");
  }

  const day = date.split(".")[0];
  const month = date.split(".")[1];
  const year = date.split(".")[2];

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      const data: any = await API.get(
        `${endpoints.issuedBy}?issuedDate=${year}-${month}-${day}&issuerCode=${kodPodrazdelenia}`,
      );
      const issuedBy = data.payload[0];
      dispatch(success(issuedBy));
    } catch (err: any) {
      message.error(`Не удалось определить, кем выдан паспорт ${err}`);
      dispatch(failure());
    }
  };
};
