import { IForm } from "src/helpers/types/Form";
import { formsNames } from "src/constants";

export interface IProps extends IForm {
  className?: string;
  formValid: boolean;
  email: string;
  password: string;
  getToken: (email: string, password: string) => Promise<void>;
  getUserInfo: () => Promise<void>;
}

export const defaultProps = { form: formsNames["login"] };
