import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";

interface IAction extends Action {
  fieldName: string;
  value: any;
}

export const updateEntryValidateError = (state: IEntriesState, action: IAction): IEntriesState => {
  const { fieldName, value } = action;
  return {
    ...state,
    validateErrors: Object.assign({}, state.validateErrors, { [fieldName]: value }),
  };
};
