import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";

interface IAction extends Action {}

export const activateKepRequest = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    loading: true,
  };
};

export const activateKepSuccess = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    loading: false,
  };
};

export const activateKepFailure = (state: IEntriesState, action: IAction): IEntriesState => {
  return { ...state, loading: false };
};
