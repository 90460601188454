import { attributesToObj } from "src/helpers";
import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";
import { IGetEntryResponse } from "src/helpers/types/ResponsesFromBackend";

interface IAction extends Action {
  entry: IGetEntryResponse & { id: number };
}

export const getEntryRequest = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    loading: true,
    getEntryError: false,
  };
};

export const getEntrySuccess = (state: IEntriesState, action: IAction): IEntriesState => {
  const { fill } = action.entry;
  //@ts-ignore
  delete action.entry.fill;
  return {
    ...state,
    currentEntry: { ...action.entry, ...fill },
    //@ts-ignore
    formattedEntry: attributesToObj(action.entry.attributes),
    wasLoaded: true,
    loading: false,
    getEntryError: false,
  };
};

export const getEntryFailure = (state: IEntriesState, action: IAction): IEntriesState => {
  return { ...state, loading: false, getEntryError: true };
};
