import { passportsConstants } from "./../passports.constants";
import { message } from "antd";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { IPassport } from "src/helpers/types/Passport";

export const getPassport = (id: string) => {
  const { PASSPORT_GET_REQUEST, PASSPORT_GET_SUCCESS, PASSPORT_GET_FAILURE } = passportsConstants;
  const request = () => ({ type: PASSPORT_GET_REQUEST });
  const success = (passport: IPassport) => ({ type: PASSPORT_GET_SUCCESS, passport });
  const failure = () => ({ type: PASSPORT_GET_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      const entry: IPassport = await API.get(`${endpoints.passports.getById}/${id}`);
      dispatch(success(entry));
    } catch (err: any) {
      message.error(`Не удалось загрузить информацию о данном пользователе: ${err}`);
      dispatch(failure());
    }
  };
};
