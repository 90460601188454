import { IInputState } from "src/redux/input";
import { Action } from "redux";

interface IAction extends Action {}

export const resetLastChangedField = (state: IInputState, action: IAction): IInputState => {
  return {
    ...state,
    lastChangedForm: "",
    lastChangedField: "",
    lastChangedValue: "",
  };
};
