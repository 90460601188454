import { userConstants } from "./../user.constants";
import { endpoints, baseURL } from "src/constants";
import { API, getWithExpiry } from "src/helpers";
import { Dispatch } from "redux";

export const logout = () => {
  const { USER_LOGOUT } = userConstants;
  const success = { type: USER_LOGOUT };

  return async function (dispatch: Dispatch) {
    await API.post(`${baseURL}${endpoints.logout}/${getWithExpiry("token")}`);
    localStorage.removeItem("token");
    localStorage.removeItem("browser_token");
    dispatch(success);
  };
};
