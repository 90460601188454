import { attributes } from "src/constants";
import { IFileField } from "src/helpers/types/Files";
export const mainFields: IFileField[] = [];

export const additionalFields: IFileField[] = [
  {
    attribute: attributes["Сертификат"],
    title: "Сертификат",
    label: "Сертификат",
    fileType: "uploadIP",
  },
  {
    attribute: attributes["Фото клиента"],
    title: "Фото клиента",
    label: "Фото клиента",
    fileType: "uploadIP",
  },
];

export const fileFieldsModal: IFileField[] = [
  {
    attribute: attributes["Скан паспорта"],
    title: "Скан паспорта",
    label: "Паспорт",
    fileType: "uploadIP_DEL",
  },
  {
    attribute: attributes["Скан прописки"],
    title: "Скан прописки",
    label: "Прописка",
    fileType: "uploadIP_DEL",
  },
  {
    attribute: attributes["Скан СНИЛС"],
    title: "Скан СНИЛС",
    label: "СНИЛС",
    fileType: "uploadIP_DEL",
  },
];
