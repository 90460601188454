import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import classNames from "classnames/dedupe";
import "./Login.scss";
import { connect } from "react-redux";
import { history, getWithExpiry } from "src/helpers";
import { Loader } from "src/components";
import { pages } from "src/constants";
import { Helmet } from "react-helmet";
import { LoginForm } from "src/forms";
import { IProps } from "./interface";
import { AppState } from "src/redux/AppState";

const sf = cn("site-forms");
const b = cn("login-page");

class Login extends Component<IProps> {
  render() {
    const { loading } = this.props;
    // если пользователь пытался зайти на приватную страницу, будучи неавторизованным,
    // запомним адрес этой страницы
    //@ts-ignore
    let { from } = history.location.state || { from: { pathname: pages.FNSregistration } };

    if (getWithExpiry("token")) {
      history.replace(from);
    }

    return (
      <div className={b()}>
        {loading && <Loader />}
        <Helmet>
          <title>Вход</title>
        </Helmet>
        <div className={b("container")}>
          <a href="https://buro.app" className={b("logo")}>
            <img alt="logo" src="./images/logo.svg" />
          </a>
          <div className={classNames(sf(), b("form-wrapper"))}>
            <LoginForm />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppState) {
  const { loading } = state.userReducer;

  return {
    loading,
  };
}

const connectedComponent = connect(mapStateToProps, () => {})(Login);

export { connectedComponent as Login };
