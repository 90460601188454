import { getFormValues, isValid, submit } from "redux-form";
import { formsNames } from "src/constants";
import { store } from "src/redux/store";
import { cloneDeep } from "lodash";
import { AppState } from "src/redux/AppState";

export const extractMarketplaceHHFormData = (state: AppState) => {
  // соберем formData из всех форм
  const hhMarketplaceValues = getFormValues(formsNames["hhMarketplace"])(state);

  const allData = cloneDeep(Object.assign({}, hhMarketplaceValues));
  return allData;
};

export const validateMarketplaceHHFormData = (dispatch: Function): boolean => {
  // эмулируем сабмит всех форм
  dispatch(submit(formsNames["hhMarketplace"]));

  // извлекаем информацию об ошибках всех форм
  const state = store.getState();
  const hhMarketplaceValid = isValid("hhMarketplace")(state);

  // проверка корректности заполнения всех форм
  return hhMarketplaceValid;
};
