import React from "react";
import { cn } from "src/helpers/bem";
import "./Checkbox.scss";
import { Checkbox as AntdCheckbox } from "antd";
import { IProps } from "./interface";

const b = cn("site-checkbox");

export const Checkbox = ({ title, value, name, meta, ...rest }: IProps) => {
  const { touched, error, warning } = meta;
  return (
    <React.Fragment>
      <AntdCheckbox className={b()} checked={value} name={name} {...rest}>
        {title}
      </AntdCheckbox>
      {touched &&
        ((error && <span className={b("error")}>{error}</span>) ||
          (warning && <span className={b("warning")}>{warning}</span>))}
    </React.Fragment>
  );
};
