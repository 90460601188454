import React from "react";
import classNames from "classnames/dedupe";
import { cn } from "src/helpers/bem";
import { defaultProps, IProps, IState } from "./interface";
import "./CompanyNameAndINN.scss";
import { attributes } from "src/constants";
import { AInput, innCheck } from "src/helpers";
import { Field, change, reduxForm, submit } from "redux-form";
import { loadDadataInfo } from "src/redux/input";
import { Dispatch } from "redux";
import validate from "src/forms/HHMarketplace/validate";
import { connect } from "react-redux";

const sf = cn("site-form");
const b = cn("company-name-and-inn-form");

class CompanyNameAndINNForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  fillCompanyInfo = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { form, loadDadataInfo, changeFieldValue } = this.props;
    if (innCheck(evt.target.value)) {
      const companyData = await loadDadataInfo(attributes["ИНН"], evt.target.value);
      changeFieldValue(form, attributes["Компания"], companyData.suggestions[0].value);
    }
  };

  render() {
    const { className, disabled, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit} className={classNames(classNames(sf(), b(), className))}>
        <Field
          title="ИНН:"
          className={b("inn")}
          name={attributes["ИНН"]}
          component={AInput}
          disabled={disabled}
          onChange={this.fillCompanyInfo}
        />
        <Field title="Название компании" name={attributes["Компания"]} component={AInput} disabled={disabled} />
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    loadDadataInfo: (inputName: string, inputValue: string) => dispatch(loadDadataInfo(inputName, inputValue)),
    changeFieldValue: (form: string, field: string, value: string) => dispatch(change(form, field, value)),
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  validate,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(CompanyNameAndINNForm);

connectedComponent = connect(null, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as CompanyNameAndINNForm };
