import { IForm } from "src/helpers/types/Form";
import { formsNames } from "src/constants";

export interface IProps extends IForm {
  className?: string;
  loadDadataInfo(inputName: string, inputValue: string): Promise<Record<string, any>>;
  changeFieldValue(form: string, field: string, value: string): Promise<void>;
}

export interface IState {}

export const defaultProps = {
  form: formsNames["companyNameAndINN"],
};
