import { attributes, errorsTypes } from "src/constants";
import { innCheck, validateOGRN } from "src/helpers";

export default function validate(values: Record<string, any>) {
  let errors: Record<string, string> = {};

  errors = validateINN(values[attributes["ИНН"]], errors);
  errors = validateOGRN(values[attributes["ОГРН"]], errors);
  errors = validateKPP(values[attributes["КПП"]], errors);

  return errors;
}

const validateINN = (inn: string, errors: Record<string, string>) => {
  if (!inn) {
    errors[attributes["ИНН"]] = "";
  } else if (!innCheck(inn)) {
    errors[attributes["ИНН"]] = errorsTypes.forbiddenINN;
  }

  return errors;
};

const validateKPP = (kpp: string, errors: Record<string, string>) => {
  if (kpp) {
    if (kpp.indexOf("_") !== -1) {
      errors[attributes["КПП"]] = errorsTypes.forbiddenKPP;
    }
  }

  return errors;
};
