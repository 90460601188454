import { marketplaceConstants } from "../marketplace.constants";
import { API, extractMarketplaceCardData, validateMarketplaceCardData } from "src/helpers";
import { message } from "antd";
import { IMarketplaceCard } from "src/helpers/types/MarketplaceCard";
import { Dispatch } from "redux";
import { errorsTypes } from "src/constants";

export const sendMarketplaceCardForm = (cardData: IMarketplaceCard) => {
  const { SEND_MARKETPLACE_CARD_FORM_REQUEST, SEND_MARKETPLACE_CARD_FORM_SUCCESS, SEND_MARKETPLACE_CARD_FORM_FAILURE } =
    marketplaceConstants;
  const request = () => ({ type: SEND_MARKETPLACE_CARD_FORM_REQUEST });
  const success = () => ({ type: SEND_MARKETPLACE_CARD_FORM_SUCCESS });
  const failure = () => ({ type: SEND_MARKETPLACE_CARD_FORM_FAILURE });

  const { cardName, serviceUrl } = cardData;

  return async function (dispatch: Dispatch) {
    // проверяем, что все нужные поля заполнены
    const marketplaceFormCorrect = validateMarketplaceCardData(dispatch, cardName);

    if (!marketplaceFormCorrect) {
      message.error(errorsTypes.requiredFields);
    } else {
      const marketplaceFromData = extractMarketplaceCardData(cardName);
      dispatch(request());
      try {
        await API.post(`${serviceUrl}`, { ...marketplaceFromData });
        message.success("Запрос успешно отправлен");
        dispatch(success());
        return true;
      } catch (err: any) {
        message.error(`Не удалось отправить данные для карточки маркетплейса ${err}`);
        dispatch(failure());
      }
    }
  };
};
