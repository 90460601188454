import { billsConstants } from "./../bills.constants";
import { getBillsRequest, getBillsSuccess, getBillsFailure } from "./getBills";
import { changeBillStatusRequest, changeBillStatusSuccess, changeBillStatusFailure } from "./changeBillStatus";
import { companyBillRequest, companyBillSuccess, companyBillFailure } from "./companyBill";
import { changeBalanceRequest, changeBalanceSuccess, changeBalanceFailure } from "./changeBalance";
import { showBalanceRequest, showBalanceSuccess, showBalanceFailure } from "./showBalance";
import { recoverBillRequest, recoverBillSuccess, recoverBillFailure } from "./recoverBill";
import { setDateBillRequest, setDateBillSuccess, setDateBillFailure } from "./setDateBill";
import { IPayment } from "src/helpers/types/Payment";
import { IOperation } from "src/helpers/types/Operation";
import { createReducer } from "@reduxjs/toolkit";

export interface IBillsState {
  payments?: IPayment[];
  operations?: IOperation[];
  loading: boolean;
  loadingWithoutLogo: boolean;
  error: boolean;
}

const initialState: IBillsState = {
  payments: undefined,
  operations: undefined,
  loading: false,
  loadingWithoutLogo: false,
  error: false,
};

export const bills = createReducer(initialState, {
  [billsConstants.GET_BILLS_REQUEST]: getBillsRequest,
  [billsConstants.GET_BILLS_SUCCESS]: getBillsSuccess,
  [billsConstants.GET_BILLS_FAILURE]: getBillsFailure,

  [billsConstants.CHANGE_BILL_STATUS_REQUEST]: changeBillStatusRequest,
  [billsConstants.CHANGE_BILL_STATUS_SUCCESS]: changeBillStatusSuccess,
  [billsConstants.CHANGE_BILL_STATUS_FAILURE]: changeBillStatusFailure,

  [billsConstants.COMPANY_BILL_REQUEST]: companyBillRequest,
  [billsConstants.COMPANY_BILL_SUCCESS]: companyBillSuccess,
  [billsConstants.COMPANY_BILL_FAILURE]: companyBillFailure,

  [billsConstants.CHANGE_BALANCE_REQUEST]: changeBalanceRequest,
  [billsConstants.CHANGE_BALANCE_SUCCESS]: changeBalanceSuccess,
  [billsConstants.CHANGE_BALANCE_FAILURE]: changeBalanceFailure,

  [billsConstants.SHOW_BALANCE_REQUEST]: showBalanceRequest,
  [billsConstants.SHOW_BALANCE_SUCCESS]: showBalanceSuccess,
  [billsConstants.SHOW_BALANCE_FAILURE]: showBalanceFailure,

  [billsConstants.RECOVER_BILL_REQUEST]: recoverBillRequest,
  [billsConstants.RECOVER_BILL_SUCCESS]: recoverBillSuccess,
  [billsConstants.RECOVER_BILL_FAILURE]: recoverBillFailure,

  [billsConstants.SET_DATE_BILL_REQUEST]: setDateBillRequest,
  [billsConstants.SET_DATE_BILL_SUCCESS]: setDateBillSuccess,
  [billsConstants.SET_DATE_BILL_FAILURE]: setDateBillFailure,
});
