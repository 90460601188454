import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./Users.scss";
import { usersTabsTitles } from "src/constants";
import { getCompanies, showCompanies } from "src/redux/companies";
import { getUsers } from "src/redux/users";
import { Tabs } from "antd";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { CompaniesTable } from "./components/CompaniesTable/CompaniesTable";
import { UsersTable } from "./components/UsersTable/UsersTable";
import { isAdmin } from "src/helpers";
import { IProps, IState } from "./interface";
import { AppState } from "src/redux/AppState";

const { TabPane } = Tabs;

const b = cn("users-page");
const sp = cn("site-page");

class Users extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentTab: "users",
    };
  }

  componentDidMount() {
    const { companies, companiesLoading, getCompanies, getUsers } = this.props;

    // сейчас они грузятся разом, но потом нужно сделать подгрузку по факту :О
    if (typeof companies === "undefined" && !companiesLoading) {
      getCompanies();
    }

    getUsers({});
  }

  handleTabChange = (value: string) => {
    this.setState({ currentTab: value });
  };

  renderTabs = (tabs: Record<string, string>) => {
    const { currentTab } = this.state;

    return (
      <Tabs onChange={(value) => this.handleTabChange(value)} defaultActiveKey="0" type="card" activeKey={currentTab}>
        {Object.entries(tabs).map((item) => (
          <TabPane tab={<div className={sp("tab-name")}>{item[1]}</div>} key={item[0]} />
        ))}
      </Tabs>
    );
  };

  render() {
    const { currentTab } = this.state;
    const {
      userCompanyId,
      companies,
      companiesLoading,
      users,
      usersLoading,
      getUsers,
      getCompanies,
      filteredCompanies,
      showCompanies,
    } = this.props;

    return (
      <div className={`${b()} ${sp()}`}>
        <Helmet>
          <title>Пользователи</title>
        </Helmet>
        <div className={sp("tabs")}>{this.renderTabs(usersTabsTitles)}</div>
        {currentTab === "users" && (
          <UsersTable
            loading={usersLoading}
            onSearch={getUsers}
            data={{ users, companies: companies?.result }}
            isAdmin={isAdmin(userCompanyId)}
          />
        )}
        {currentTab === "companies" && (
          <CompaniesTable
            loading={companiesLoading}
            //@ts-ignore
            data={{ companies: companies, filteredCompanies: filteredCompanies?.result }}
            onUpdate={getCompanies}
            showCompanies={showCompanies}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { companies, filteredCompanies, loading: companiesLoading } = state.companies;
  const { users, loading: usersLoading } = state.users;
  const userCompanyId = state.userReducer.user.company?.id;
  return { companies, companiesLoading, users, usersLoading, userCompanyId, filteredCompanies };
};

const mapDispatchToProps = {
  getCompanies,
  getUsers,
  showCompanies,
};

// const mapDispatchToProps = dispatch => {
//   return {
//     getCompanies: (li?: number, page?: number, search?: string) => dispatch(getCompanies(li, page, search)),
//     getUsers: (search, id) => dispatch(getUsers(search, id))
//   };
// };

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(Users);

export { connectedComponent as Users };
