import { entriesConstants } from "./../entries.constants";
import { validateEntryFiles, showLoadingMessage, API } from "src/helpers";
import { endpoints, entriesTypes, attributes, errorsTypes } from "src/constants";
import { changeCurrentEntry } from "./index";
import { changeFormattedEntryAttribute, linkPooling } from "src/redux/entries";
import { message } from "antd";

export const createLink = (id: number, entryType: string) => {
  const { ENTRY_LINK_REQUEST, ENTRY_LINK_SUCCESS, ENTRY_LINK_FAILURE } = entriesConstants;
  const request = () => ({ type: ENTRY_LINK_REQUEST });
  const success = (link: ResponseType) => ({ type: ENTRY_LINK_SUCCESS, link });
  const failure = () => ({ type: ENTRY_LINK_FAILURE });

  type ResponseType = { link: string } | Array<Record<string, string>>;

  return async function (dispatch: Function) {
    // проверяем, что основные файлы загружены
    const formCorrect = validateEntryFiles(dispatch);

    if (!formCorrect) {
      message.error(errorsTypes.requiredFields);
    } else {
      showLoadingMessage("Создаётся ссылка");
      dispatch(request());
      try {
        // @ts-ignore
        const res: ResponseType = await API.get(`${endpoints.entries[`link_${entryType}`]}/${id}`, { timeout: 60000 });

        message.destroy();
        dispatch(changeCurrentEntry("scene", "fns4"));
        // в рег. ООО у каждого учредителя своя ссылка
        if (entryType === entriesTypes["РегистрацияООО"] && Array.isArray(res)) {
          res.forEach((it) => {
            const ownerAttr = `sign${Object.keys(it)[0]}`;
            const link = Object.values(it)[0];
            dispatch(changeFormattedEntryAttribute(ownerAttr, link));
          });
        } else {
          //@ts-ignore
          dispatch(changeFormattedEntryAttribute(attributes["ссылкаНаПодписание"], res.link));
        }
        dispatch(success(res));
        dispatch(linkPooling(id, entryType));
      } catch (err: any) {
        message.destroy();
        message.error(`Не удалось создать ссылку: ${err}`);
        dispatch(failure());
      }
    }
  };
};
