import { IFileField } from "src/helpers/types/Files";

export interface IProps {
  uploadFile: (fileType: string, entryId: string | number, file: any, requestAttribute: string, params: any) => any;
  loadFile: (id: string | number, fileName: string, requestAttribute: string) => Promise<Blob | undefined>;
  removeFile: (id: string | number, requestAttribute: string) => Promise<void>;
  loadFileByToken: (id: number, token: string, fileType: string) => Promise<void>;
  removeFileByToken: (id: number, token: string, fileType: string) => Promise<void>;
  currentEntryId: number;
  loading: boolean;
  wasLoaded: boolean;
  disabled: boolean;
  validateErrors: Record<string, any>;
  fileFields: IFileField[];
  fileNames: Record<string, string>;
  className?: string;
  showTitle: boolean;
  inArchive: Record<string, boolean>;
  disabledTooltipVisible?: boolean;
}

export const defaultProps = {
  className: "",
  showTitle: true,
  loading: false,
  wasLoaded: false,
};
