import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./EntryAside.scss";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { attributes, baseURL, endpoints, entriesTypes, stepsConstants } from "src/constants";
import { FormedDocs, Steps, Tooltip, UploadLink } from "src/components";
import { activateKep, changeCurrentEntry, createLink, getKep, kepPooling, sendToFNS } from "src/redux/entries";
import { downloadFile, getDocsByEntryType, getSurnameAndInitials, isAdmin, isScene } from "src/helpers";
import classNames from "classnames/dedupe";
import { defaultProps, IProps, State } from "./interface";
import { IDoc } from "src/helpers/types/Doc";
import { IFormattedEntry } from "src/helpers/types/Entry";
import { AppState } from "src/redux/AppState";
import { USER_STATUS } from "src/helpers/types/User";
// @ts-ignore
import MouseTooltip from "react-sticky-mouse-tooltip";
import { store } from "src/redux/store";

const b = cn("entry-aside");

class EntryAside extends Component<IProps, State> {
  static defaultProps = defaultProps;

  constructor(props: IProps) {
    super(props);
    this.state = {
      observerTooltipVisible: false,
      isLoading: false,
    };
  }

  setObserverTooltipVisibility = (isVisible: boolean) => this.setState({ observerTooltipVisible: isVisible });

  checkFiles = () => {
    const { loading, entryType, owners, loadedFiles } = this.props;

    // в заявке на Изменения ООО проверять нечего, ведь там у нас самоподписание
    if (entryType === entriesTypes["ИзмененияООО"]) {
      return true;
    }

    // проверка наличия сканов сертификата и фото клиентов перед отправкой заявки в ФНС
    let filesExist = true;

    // на стр. рег. ООО нужно проверить наличие файлов у всех учредителей
    if (entryType === entriesTypes["РегистрацияООО"]) {
      if (!loading) {
        const ownersIds = owners.map((it: any) => it.id);
        filesExist = ownersIds.every((it: number) => loadedFiles[`zayavkep_${it}`] && loadedFiles[`clientphoto_${it}`]);
      }
    } else {
      filesExist = Boolean(loadedFiles[attributes["Сертификат"]] && loadedFiles[attributes["Фото клиента"]]);
    }

    return filesExist;
  };

  renderActivateKepBlock() {
    const { currentEntryId } = this.props;
    return (
      <button onClick={() => store.dispatch(activateKep(currentEntryId))} type="button" className="custom-button">
        Активировать КЭП
      </button>
    );
  }

  generateDescription = (title: string) => {
    const {
      getKep,
      createLink,
      sendToFNS,
      currentEntryId,
      entryType,
      signLink,
      fnsContainer,
      raspiska,
      fnsDocs,
      userCompanyId,
      isUserObserver,
      scene,
      smevOnlyPassportLeft,
    } = this.props;

    switch (title) {
      case "Выпустить ключ": {
        // if (reqActivate === true && kepLink) {
        //   return (
        //     <button type="button" onClick={() => activateKep(currentEntryId)} className="custom-button">
        //       Активировать
        //     </button>
        //   );
        // }

        return (
          <>
            <button
              onPointerOver={() => this.setObserverTooltipVisibility(true)}
              onPointerOut={() => this.setObserverTooltipVisibility(false)}
              type="button"
              onClick={() => getKep(currentEntryId, entryType)}
              className="custom-button"
              disabled={isUserObserver}
            >
              {entryType === "oooedit" ? "Сформировать" : title}
            </button>
          </>
        );
      }
      case "Получить ссылку": {
        if (isScene("Активация КЭП", scene) || smevOnlyPassportLeft) {
          return this.renderActivateKepBlock();
        }

        if (isScene("Ошибка СМЭВ", scene)) {
          return (
            <div style={{ fontFamily: "Roboto" }}>
              Данные не прошли проверку в СМЭВ. <br /> Обратитесь в поддержку
            </div>
          );
        }

        // кидаю в onDoubleClick пустую функцию, для возможного решения DEV-729 - чтобы при двойном нажатии не отправлялось два запроса
        if (isScene("Создание ссылки", scene)) {
          return (
            <button
              onDoubleClick={() => {}}
              type="button"
              onClick={() => createLink(currentEntryId, entryType)}
              className="custom-button"
              onPointerOver={() => this.setObserverTooltipVisibility(true)}
              onPointerOut={() => this.setObserverTooltipVisibility(false)}
              disabled={isUserObserver}
            >
              {title}
            </button>
          );
        }

        return null;
      }
      case "Подписание": {
        if (entryType !== entriesTypes["РегистрацияООО"]) {
          return (
            <a href={signLink} target="_blank" rel="noopener noreferrer">
              Ссылка на подписание
            </a>
          );
        }
        return <span />;
      }
      case "Отправить в ФНС": {
        // кнопка показывается либо если все доп. файлы (сертификат и фото) загружены
        // либо если пользователь - админ
        if (isAdmin(userCompanyId) || this.checkFiles()) {
          return (
            <button
              onPointerOver={() => this.setObserverTooltipVisibility(true)}
              onPointerOut={() => this.setObserverTooltipVisibility(false)}
              type="button"
              onClick={() => sendToFNS(entryType, currentEntryId)}
              className="custom-button"
              disabled={isUserObserver}
            >
              {title}
            </button>
          );
        }
      }
      case "Регистрация в ФНС": {
        return (
          <div style={{ display: "grid" }}>
            {fnsContainer && (
              // все файлы грузятся нормально, но тут с url для загрузки приходится шаманить
              <a
                href="javascript:void(0)"
                onClick={() =>
                  downloadFile(`${baseURL}${endpoints.files.download2}/${fnsContainer.replace("/files/", "")}`)
                }
              >
                Контейнер для ФНС
              </a>
            )}
            {fnsContainer && raspiska && (
              <a
                href="javascript:void(0)"
                onClick={() =>
                  downloadFile(`${baseURL}${endpoints.files.download2}/${raspiska.replace("/files/", "")}`)
                }
              >
                Расписка
              </a>
            )}
            {fnsContainer && raspiska && fnsDocs && (
              <a
                href="javascript:void(0)"
                onClick={() => downloadFile(`${baseURL}${endpoints.files.download2}/${fnsDocs.replace("/files/", "")}`)}
              >
                Документы из ФНС
              </a>
            )}
          </div>
        );
      }
    }
  };

  renderFormedDocs = () => {
    const {
      scene,
      entryType,
      currentEntryId,
      owners,
      ustav_checkbox,
      formattedEntry,
      taxType,
      loading,
      passportKepLink,
    } = this.props;

    let docsList: IDoc[] = [];
    let additionalDocs: IDoc[] = [];

    if (scene && isScene("Выпущена КЭП", scene)) {
      docsList = getDocsByEntryType({
        entryId: currentEntryId,
        entryType,
        taxType,
        ustav_checkbox,
        formattedEntry,
        ownersCount: owners?.length,
      });

      if (entryType !== entriesTypes["РегистрацияООО"]) {
        docsList = [
          {
            name: "Заявление КЭП",
            link: `${baseURL}/api/v1/file/${currentEntryId}/${
              formattedEntry[attributes["ссылкаНаЗаявлениеКЭП"] as keyof IFormattedEntry]
            }`,
          },
          ...docsList,
        ];
      } else {
        additionalDocs = owners.map((owner) => ({
          name: (
            <>
              Сертификат
              <br />
              {getSurnameAndInitials(owner)}
            </>
          ),
          link: `${baseURL}/api/v1/file/${currentEntryId}/${
            formattedEntry[`${attributes["ссылкаНаЗаявлениеКЭП"]}_${owner.id}` as keyof IFormattedEntry]
          }`,
        }));
      }
    } else if (!scene && !loading && passportKepLink) {
      docsList = [
        {
          name: "Заявление КЭП",
          link: `${baseURL}${passportKepLink}`,
        },
      ];
    }

    // для "Изменения ООО" Заявление КЭП скрываем
    if (entryType === entriesTypes["ИзмененияООО"]) {
      docsList = docsList.filter((it) => it.name !== "Заявление КЭП");
    }

    return (
      docsList.length > 0 && (
        <FormedDocs mainDocs={docsList} additionalDocs={additionalDocs} style={{ marginTop: 24 }} />
      )
    );
  };

  render() {
    const {
      scene,
      showSteps,
      additionalContent,
      activationLink,
      isUserObserver,
      currentEntryId,
      entryType,
      identificationId,
    } = this.props;
    const { observerTooltipVisible } = this.state;

    function getTitleForKepStep() {
      if (isScene("Активация КЭП", scene)) {
        return "Активация КЭП";
      }
      if (isScene("Проверка СМЭВ", scene) || isScene("Ошибка СМЭВ", scene)) {
        return "Проверка СМЭВ";
      }

      return entryType === "oooedit" ? "Пакет документов" : "Выпуск КЭП";
    }

    const steps = showSteps
      ? [
          {
            title: "Заполнение",
            subTitle: "",
            description: isScene("Заполнение", scene) && this.generateDescription("Выпустить ключ"),
          },
          {
            title: getTitleForKepStep(),
            subTitle: "",
            description: this.generateDescription("Получить ссылку"),
          },
          {
            title: "Подписание",
            subTitle: "",
            description: isScene("Подписание", scene) && this.generateDescription("Подписание"),
          },
          {
            title: "Отправка в ФНС",
            subTitle: "",
            description: isScene("Готов к отправке", scene) && this.generateDescription("Отправить в ФНС"),
          },
          {
            title: "Регистрация в ФНС",
            subTitle: "",
            description:
              (isScene("Регистрация в ФНС", scene) || isScene("Зарегистрирован", scene)) &&
              this.generateDescription("Регистрация в ФНС"),
          },
        ]
      : [];

    return (
      <aside className={b()}>
        {isUserObserver && (
          <MouseTooltip
            style={{ display: observerTooltipVisible ? "block" : "none" }}
            offsetX={18}
            offsetY={0}
            className="sticky-tooltip-wrapper"
          >
            <Tooltip className="sticky-tooltip" visible title="Ваша роль не позволяет совершать данное действие" />
          </MouseTooltip>
        )}
        {showSteps && (
          <Steps
            //@ts-ignore
            currentStep={stepsConstants[scene]}
            steps={steps}
            currentScene={scene}
            activationLink={activationLink}
          />
        )}
        {isScene("Заполнение", scene) && showSteps && entryType !== "oooedit" ? (
          <div className={b("mobile-key-info")}>
            <div className={b("mobile-key-title")}>Выпуск электронной подписи на мобильном устройстве</div>
            <div className={b("mobile-key-text")}>
              Начиная с 1 августа все электронные подписи выпускаются только для работы через мобильное устройство.
            </div>
            <div className={b("mobile-key-text")}>
              Для использования электронной подписи у клиента должно быть мобильное устройство под управлением iOS или
              Android.
            </div>
            <a
              href="https://www.notion.so/4a0cacaeb5534f4da4737aff6ddf86ec"
              className={classNames("custom-button", b("mobile-key-button"))}
              target="_blank"
            >
              Подробнее
            </a>
          </div>
        ) : null}
        {this.renderFormedDocs()}
        {/* временное решение */}
        {entryType !== entriesTypes["РегистрацияООО"] &&
          entryType !== entriesTypes["ИзмененияООО"] &&
          isScene("Выпущена КЭП", scene) && <UploadLink id={currentEntryId} identificationId={identificationId} />}
        <div className={b("additional-content")}>{additionalContent}</div>
      </aside>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { loading: entriesLoading, formattedEntry, currentEntry, smevOnlyPassportLeft } = state.entries;
  const { loading: passportsLoading } = state.passports;
  const loading = entriesLoading || passportsLoading;
  const passportKepLink = state.passports.currentPassport?.crt_url;
  const owners = formattedEntry?.owners;
  const packageType = formattedEntry?.packageType;
  //@ts-ignore
  const activationLink = formattedEntry && formattedEntry[attributes["ссылкаНаАктивацию"]];
  //@ts-ignore
  const kepLink = formattedEntry && formattedEntry[attributes["ссылкаНаЗаявлениеКЭП"]];
  //@ts-ignore
  const signLink = formattedEntry && formattedEntry[attributes["ссылкаНаПодписание"]];
  //@ts-ignore
  const fnsContainer = formattedEntry && formattedEntry[attributes["контейнерДляФНС"]];
  //@ts-ignore
  const raspiska = formattedEntry && formattedEntry[attributes["расписка"]];
  //@ts-ignore
  const fnsDocs = formattedEntry && formattedEntry[attributes["документыИзФНС"]];
  //@ts-ignore
  const identificationId = formattedEntry && formattedEntry[attributes["токенДляСсылкиЗагрузкиФайлов"]];
  const entryType = currentEntry?.entryType;
  const scene = currentEntry?.scene;
  const currentEntryId = currentEntry?.id;
  const userCompanyId = state.userReducer.user?.company?.id;
  const loadedFiles = state.files.loaded;
  const reqActivate = state.userReducer.user?.company_settings?.reqActivate;
  const { status } = state.userReducer.user.user;
  const isUserObserver = status === USER_STATUS.OBSERVER;

  let formValues;
  let ustav_checkbox = "";
  if (entryType === entriesTypes["РегистрацияООО"]) {
    formValues = formValueSelector("commonOOO");
    ustav_checkbox = formValues(state, attributes["Общество использует типовой Устав"]);
  } else if (entryType === entriesTypes["РегистрацияИП"]) {
    formValues = formValueSelector("common");
  }
  const taxType = formValues && formValues(state, attributes["Система налогобложения"]);

  return {
    loading,
    passportKepLink,
    formattedEntry,
    owners,
    entryType,
    packageType,
    kepLink,
    activationLink,
    currentEntryId,
    signLink,
    fnsContainer,
    raspiska,
    fnsDocs,
    scene,
    userCompanyId,
    loadedFiles,
    reqActivate,
    taxType,
    ustav_checkbox,
    isUserObserver,
    smevOnlyPassportLeft,
    identificationId,
  };
};

const mapDispatchToProps = {
  changeCurrentEntry,
  getKep,
  kepPooling,
  activateKep,
  createLink,
  sendToFNS,
};

//@ts-ignore
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(EntryAside);

export { connectedComponent as EntryAside };
