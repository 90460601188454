import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./ChangeOOO.scss";
import { Breadcrumbs, Loader, EntryAside } from "src/components";
import { connect } from "react-redux";
import { Forms } from "./Forms/Forms";
import { pages, entriesTypes } from "src/constants";
import { createEntry, getEntry, clearCurrentEntry } from "src/redux/entries";
import { clearFileState } from "src/redux/files";
import { clearInputData } from "src/redux/input/actions/clearInputData";
import { loadOrCreateEntry, isScene } from "src/helpers";
import { Helmet } from "react-helmet";
import { message } from "antd";
import { AppState } from "src/redux/AppState";
import { IProps } from "./interface";

const b = cn("changeOOO-page");
const ep = cn("entry-page");

const breadcrumbsData = {
  backLabel: "Назад в панель управления",
  items: [{ text: "Регистрация в ФНС", href: pages.FNSregistration }, { text: "Внесение изменений в ООО" }],
};

class ChangeOOO extends Component<IProps> {
  componentWillMount() {
    const { createEntry, getEntry } = this.props;
    loadOrCreateEntry(entriesTypes["ИзмененияООО"], createEntry, getEntry);
  }

  componentDidUpdate(prevProps: IProps) {
    // костыль для копирования заявки со страницы самой заявки
    // при копировании у нас меняется адресная строка, туда записывается ?id=newCopiedId
    // но компонент не обновлялся, потому что единственный вызов к апишке у него в componentDidMount
    if (prevProps.location?.search !== this.props.location?.search) {
      const { createEntry, getEntry } = this.props;
      loadOrCreateEntry(entriesTypes["ИзмененияООО"], createEntry, getEntry);
    }
  }

  componentWillUnmount() {
    const { clearFileState, clearCurrentEntry, clearInputData } = this.props;

    clearInputData();
    clearFileState();
    clearCurrentEntry();
    message.destroy();
  }

  render() {
    const { loading, loadingWithoutLogo, scene, packageType } = this.props;

    return (
      <div className={`${ep()} ${b()}`}>
        <Helmet>
          <title>Внесение изменений в ООО</title>
        </Helmet>
        {loading && <Loader hideLogo={loadingWithoutLogo} />}
        <div className={ep("content-inner")}>
          <EntryAside />
          <div className={ep("wrapper")}>
            {/*<h2>ОГРН для теста - 1197847028642</h2>*/}
            <Breadcrumbs {...breadcrumbsData} className={ep("breadcrumbs")} />
            <Forms
              disabled={isScene("Выпущена КЭП", scene)}
              subtype={packageType === "P13001" ? "ustav" : "egrul"}
              scene={scene}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { loading: entriesLoading, loadingWithoutLogo, error, currentEntry } = state.entries;
  const { loading: activitiesLoading } = state.activities;
  const loading = entriesLoading || activitiesLoading;
  const scene = currentEntry?.scene;
  const packageType = state.entries?.formattedEntry?.packageType;

  return {
    error,
    loading,
    loadingWithoutLogo,
    scene,
    packageType,
  };
};

const mapDispatchToProps = {
  createEntry,
  getEntry,
  clearCurrentEntry,
  clearFileState,
  clearInputData,
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(ChangeOOO);

export { connectedComponent as ChangeOOO };
