import { EMPTY_FORMATTED_ENTRY, IEntriesState } from "src/redux/entries";
import { Action } from "redux";

interface IAction extends Action {}

export const clearEntry = (state: IEntriesState, action: IAction): IEntriesState => {
  state.linkPoolingDescriptor && clearInterval(state.linkPoolingDescriptor);
  state.activationPoolingDescriptor && clearInterval(state.activationPoolingDescriptor);
  state.smevPoolingDescriptor && clearInterval(state.smevPoolingDescriptor);
  return {
    ...state,
    smevOnlyPassportLeft: false,
    smevPoolingDescriptor: null,
    activationPoolingDescriptor: null,
    linkPoolingDescriptor: null,
    formattedEntry: EMPTY_FORMATTED_ENTRY,
    currentEntry: undefined,
    validateErrors: {},
    wasLoaded: false,
    loadingWithoutLogo: false,
    loading: false,
  };
};
