export const statusesConstants = {
  GET_FNS_STATUS_REQUEST: "GET_FNS_STATUS_REQUEST",
  GET_FNS_STATUS_SUCCESS: "GET_FNS_STATUS_SUCCESS",
  GET_FNS_STATUS_FAILURE: "GET_FNS_STATUS_FAILURE",

  GET_SIGNME_STATUS_REQUEST: "GET_SIGNME_STATUS_REQUEST",
  GET_SIGNME_STATUS_SUCCESS: "GET_SIGNME_STATUS_SUCCESS",
  GET_SIGNME_STATUS_FAILURE: "GET_SIGNME_STATUS_FAILURE",

  CLEAR_STATUSES_INTERVALS: "CLEAR_STATUSES_INTERVALS",
};
