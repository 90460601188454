export const countries = [
  { value: "004", title: "АФГАНИСТАН" },
  { value: "008", title: "АЛБАНИЯ" },
  { value: "010", title: "АНТАРКТИКА" },
  { value: "012", title: "АЛЖИР" },
  { value: "016", title: "ВОСТОЧНОЕ САМОА (США)" },
  { value: "020", title: "АНДОРРА" },
  { value: "024", title: "АНГОЛА" },
  { value: "028", title: "АНТИГУА И БАРБУДА" },
  { value: "031", title: "АЗЕРБАЙДЖАН" },
  { value: "032", title: "АРГЕНТИНА" },
  { value: "036", title: "АВСТРАЛИЯ" },
  { value: "040", title: "АВСТРИЯ" },
  { value: "044", title: "БАГАМСКИЕ ОСТРОВА" },
  { value: "048", title: "БАХРЕЙН" },
  { value: "050", title: "БАНГЛАДЕШ" },
  { value: "051", title: "АРМЕНИЯ" },
  { value: "052", title: "БАРБАДОС" },
  { value: "056", title: "БЕЛЬГИЯ" },
  { value: "060", title: "БЕРМУДСКИЕ ОСТРОВА (БРИТ.)" },
  { value: "064", title: "БУТАН" },
  { value: "068", title: "БОЛИВИЯ, МНОГОНАЦИОНАЛЬНОЕ ГОСУДАРСТВО" },
  { value: "070", title: "БОСНИЯ И ГЕРЦЕГОВИНА" },
  { value: "072", title: "БОТСВАНА" },
  { value: "074", title: "БУВЕ" },
  { value: "076", title: "БРАЗИЛИЯ" },
  { value: "084", title: "БЕЛИЗ" },
  { value: "086", title: "БРИТАНСКАЯ ТЕРРИТОРИЯ В ИНДИЙСКОМ ОКЕАНЕ" },
  { value: "090", title: "СОЛОМОНОВЫ ОСТРОВА" },
  { value: "092", title: "ВИРГИНСКИЕ ОСТРОВА (БРИТ.)" },
  { value: "096", title: "БРУНЕЙ" },
  { value: "100", title: "БОЛГАРИЯ" },
  { value: "104", title: "МЬЯНМА" },
  { value: "108", title: "БУРУНДИ" },
  { value: "112", title: "БЕЛОРУССИЯ" },
  { value: "116", title: "КАМБОДЖА" },
  { value: "120", title: "КАМЕРУН" },
  { value: "124", title: "КАНАДА" },
  { value: "132", title: "КАБО-ВЕРДЕ" },
  { value: "136", title: "КАЙМАН" },
  { value: "140", title: "ЦЕНТРАЛЬНО-АФРИКАНСКАЯ РЕСПУБЛИКА" },
  { value: "144", title: "ШРИ-ЛАНКА" },
  { value: "148", title: "ЧАД" },
  { value: "152", title: "ЧИЛИ" },
  { value: "156", title: "КИТАЙ" },
  { value: "158", title: "ТАЙВАНЬ" },
  { value: "162", title: "ОСТРОВ РОЖДЕСТВА" },
  { value: "166", title: "КОКОСОВЫЕ ОСТРОВА" },
  { value: "170", title: "КОЛУМБИЯ" },
  { value: "174", title: "КОМОРЫ" },
  { value: "175", title: "МАЙОТТА" },
  { value: "178", title: "КОНГО" },
  { value: "180", title: "ЗАИР" },
  { value: "184", title: "ОСТРОВА КУКА" },
  { value: "188", title: "КОСТА-РИКА" },
  { value: "191", title: "ХОРВАТИЯ" },
  { value: "192", title: "КУБА" },
  { value: "196", title: "КИПР" },
  { value: "203", title: "ЧЕХИЯ" },
  { value: "204", title: "БЕНИН" },
  { value: "208", title: "ДАНИЯ" },
  { value: "212", title: "ДОМИНИКА" },
  { value: "214", title: "ДОМИНИКАНСКАЯ РЕСПУБЛИКА" },
  { value: "218", title: "ЭКВАДОР" },
  { value: "222", title: "САЛЬВАДОР" },
  { value: "226", title: "ЭКВАТОРИАЛЬНАЯ ГВИНЕЯ" },
  { value: "231", title: "ЭФИОПИЯ" },
  { value: "232", title: "ЭРИТРЕЯ" },
  { value: "233", title: "ЭСТОНИЯ" },
  { value: "234", title: "ФАРЕРСКИЕ ОСТРОВА" },
  { value: "238", title: "ФОЛКЛЕНДСКИЕ ОСТРОВА" },
  { value: "239", title: "ЮЖНАЯ ДЖОРДЖИЯ И ЮЖНЫЕ САНДВИЧЕВЫ ОСТРОВА" },
  { value: "242", title: "ФИДЖИ" },
  { value: "246", title: "ФИНЛЯНДИЯ" },
  { value: "248", title: "ЭЛАНДСКИЕ ОСТРОВА" },
  { value: "250", title: "ФРАНЦИЯ" },
  { value: "254", title: "ГВИАНА" },
  { value: "258", title: "ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ" },
  { value: "260", title: "ФРАНЦУЗСКИЕ ЮЖНЫЕ ТЕРРИТОРИИ" },
  { value: "262", title: "ДЖИБУТИ" },
  { value: "266", title: "ГАБОН" },
  { value: "268", title: "ГРУЗИЯ" },
  { value: "270", title: "ГАМБИЯ" },
  { value: "274", title: "ГАЗА СЕКТОР (ПАЛЕСТИНА)" },
  { value: "275", title: "ГОСУДАРСТВО ПАЛЕСТИНА" },
  { value: "276", title: "ГЕРМАНИЯ" },
  { value: "288", title: "ГАНА" },
  { value: "292", title: "ГИБРАЛТАР" },
  { value: "296", title: "КИРИБАТИ" },
  { value: "300", title: "ГРЕЦИЯ" },
  { value: "304", title: "ГРЕНЛАНДИЯ" },
  { value: "308", title: "ГРЕНАДА" },
  { value: "312", title: "ГВАДЕЛУПА" },
  { value: "316", title: "ГУАМ" },
  { value: "320", title: "ГВАТЕМАЛА" },
  { value: "324", title: "ГВИНЕЯ" },
  { value: "328", title: "ГАЙАНА" },
  { value: "332", title: "ГАИТИ" },
  { value: "334", title: "ХЕРД И МАКДОНАЛЬД" },
  { value: "336", title: "ВАТИКАН" },
  { value: "340", title: "ГОНДУРАС" },
  { value: "344", title: "ГОНКОНГ" },
  { value: "348", title: "ВЕНГРИЯ" },
  { value: "352", title: "ИСЛАНДИЯ" },
  { value: "356", title: "ИНДИЯ" },
  { value: "360", title: "ИНДОНЕЗИЯ" },
  { value: "364", title: "ИРАН" },
  { value: "368", title: "ИРАК" },
  { value: "372", title: "ИРЛАНДИЯ" },
  { value: "376", title: "ИЗРАИЛЬ" },
  { value: "380", title: "ИТАЛИЯ" },
  { value: "384", title: "КОТ-Д`ИВУАР" },
  { value: "388", title: "ЯМАЙКА" },
  { value: "392", title: "ЯПОНИЯ" },
  { value: "396", title: "ДЖОНСТОН АТОЛЛ" },
  { value: "398", title: "КАЗАХСТАН" },
  { value: "400", title: "ИОРДАНИЯ" },
  { value: "404", title: "КЕНИЯ" },
  { value: "408", title: "КОРЕЯ (КНДР)" },
  { value: "410", title: "КОРЕЯ (РЕСПУБЛИКА)" },
  { value: "414", title: "КУВЕЙТ" },
  { value: "417", title: "КИРГИЗИЯ" },
  { value: "418", title: "ЛАОС" },
  { value: "422", title: "ЛИВАН" },
  { value: "426", title: "ЛЕСОТО" },
  { value: "428", title: "ЛАТВИЯ" },
  { value: "430", title: "ЛИБЕРИЯ" },
  { value: "434", title: "ЛИВИЯ" },
  { value: "438", title: "ЛИХТЕНШТЕЙН" },
  { value: "440", title: "ЛИТВА" },
  { value: "442", title: "ЛЮКСЕМБУРГ" },
  { value: "446", title: "МАКАО" },
  { value: "450", title: "МАДАГАСКАР" },
  { value: "454", title: "МАЛАВИ" },
  { value: "458", title: "МАЛАЙЗИЯ" },
  { value: "462", title: "МАЛЬДИВЫ" },
  { value: "466", title: "МАЛИ" },
  { value: "470", title: "МАЛЬТА" },
  { value: "474", title: "МАРТИНИКА" },
  { value: "478", title: "МАВРИТАНИЯ" },
  { value: "480", title: "МАВРИКИЙ" },
  { value: "484", title: "МЕКСИКА" },
  { value: "488", title: "ОСТРОВА  МИДУЭЙ" },
  { value: "492", title: "МОНАКО" },
  { value: "496", title: "МОНГОЛИЯ" },
  { value: "498", title: "МОЛДАВИЯ" },
  { value: "499", title: "ЧЕРНОГОРИЯ" },
  { value: "500", title: "МОНТСЕРРАТ" },
  { value: "504", title: "МАРОККО" },
  { value: "508", title: "МОЗАМБИК" },
  { value: "512", title: "ОМАН" },
  { value: "516", title: "НАМИБИЯ" },
  { value: "520", title: "НАУРУ" },
  { value: "524", title: "НЕПАЛ" },
  { value: "528", title: "НИДЕРЛАНДЫ" },
  { value: "530", title: "АНТИЛЬСКИЕ ОСТРОВА" },
  { value: "531", title: "КЮРАСАО" },
  { value: "533", title: "АРУБА" },
  { value: "534", title: "СЕН-МАРТЕН (нидерландская часть)" },
  { value: "535", title: "БОНЭЙР, СИНТ-ЭСТАТИУС И САБА" },
  { value: "540", title: "НОВАЯ КАЛЕДОНИЯ" },
  { value: "548", title: "ВАНУАТУ" },
  { value: "554", title: "НОВАЯ ЗЕЛАНДИЯ" },
  { value: "558", title: "НИКАРАГУА" },
  { value: "562", title: "НИГЕР" },
  { value: "566", title: "НИГЕРИЯ" },
  { value: "570", title: "НИУЭ" },
  { value: "574", title: "НОРФОЛК" },
  { value: "578", title: "НОРВЕГИЯ" },
  { value: "580", title: "СЕВЕРНЫЕ МАРИАНСКИЕ ОСТРОВА" },
  { value: "581", title: "МАЛЫЕ ТИХООКЕАНСКИЕ ОСТРОВА" },
  { value: "583", title: "МИКРОНЕЗИЯ" },
  { value: "584", title: "МАРШАЛЛОВЫ ОСТРОВА" },
  { value: "585", title: "ПАЛАУ" },
  { value: "586", title: "ПАКИСТАН" },
  { value: "591", title: "ПАНАМА" },
  { value: "598", title: "ПАПУА НОВАЯ ГВИНЕЯ" },
  { value: "600", title: "ПАРАГВАЙ" },
  { value: "604", title: "ПЕРУ" },
  { value: "608", title: "ФИЛИППИНЫ" },
  { value: "612", title: "ПИТКЭРН" },
  { value: "616", title: "ПОЛЬША" },
  { value: "620", title: "ПОРТУГАЛИЯ" },
  { value: "624", title: "ГВИНЕЯ-БИСАУ" },
  { value: "626", title: "ВОСТОЧНЫЙ ТИМОР" },
  { value: "630", title: "ПУЭРТО-РИКО" },
  { value: "634", title: "КАТАР" },
  { value: "638", title: "РЕЮНЬОН" },
  { value: "642", title: "РУМЫНИЯ" },
  { value: "643", title: "РОССИЯ" },
  { value: "646", title: "РУАНДА" },
  { value: "652", title: "СЕН-БАРТЕЛЕМИ" },
  { value: "654", title: "СВЯТАЯ ЕЛЕНА, ОСТРОВ ВОЗНЕСЕНИЯ, ТРИСТАН-ДА-КУНЬЯ" },
  { value: "659", title: "СЕНТ-КИТС И НЕВИС" },
  { value: "660", title: "АНГИЛЬЯ" },
  { value: "662", title: "СЕНТ-ЛЮСИЯ" },
  { value: "663", title: "СЕН-МАРТЕН" },
  { value: "666", title: "СЕН-ПЬЕР И МИКЕЛОН" },
  { value: "670", title: "СЕНТ-ВИНСЕНТ И ГРЕНАДИНЫ" },
  { value: "674", title: "САН-МАРИНО" },
  { value: "678", title: "САН-ТОМЕ И ПРИНСИПИ" },
  { value: "682", title: "САУДОВСКАЯ АРАВИЯ" },
  { value: "686", title: "СЕНЕГАЛ" },
  { value: "688", title: "СЕРБИЯ" },
  { value: "690", title: "СЕЙШЕЛЬСКИЕ ОСТРОВА" },
  { value: "694", title: "СЬЕРРА-ЛЕОНЕ" },
  { value: "702", title: "СИНГАПУР" },
  { value: "703", title: "СЛОВАКИЯ" },
  { value: "704", title: "ВЬЕТНАМ" },
  { value: "705", title: "СЛОВЕНИЯ" },
  { value: "706", title: "СОМАЛИ" },
  { value: "710", title: "ЮЖНО-АФРИКАНСКАЯ РЕСПУБЛИКА" },
  { value: "716", title: "ЗИМБАБВЕ" },
  { value: "724", title: "ИСПАНИЯ" },
  { value: "728", title: "ЮЖНЫЙ СУДАН" },
  { value: "729", title: "СУДАН" },
  { value: "732", title: "ЗАПАДНАЯ САХАРА" },
  { value: "736", title: "СУДАН" },
  { value: "740", title: "СУРИНАМ" },
  { value: "744", title: "ШПИЦБЕРГЕН ОСТРОВА" },
  { value: "748", title: "СВАЗИЛЕНД" },
  { value: "752", title: "ШВЕЦИЯ" },
  { value: "756", title: "ШВЕЙЦАРИЯ" },
  { value: "760", title: "СИРИЯ" },
  { value: "762", title: "ТАДЖИКИСТАН" },
  { value: "764", title: "ТАИЛАНД" },
  { value: "768", title: "ТОГО" },
  { value: "772", title: "ТОКЕЛАУ (ЮНИОН)" },
  { value: "776", title: "ТОНГА" },
  { value: "780", title: "ТРИНИДАД И ТОБАГО" },
  { value: "784", title: "ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ" },
  { value: "788", title: "ТУНИС" },
  { value: "792", title: "ТУРЦИЯ" },
  { value: "795", title: "ТУРКМЕНИЯ" },
  { value: "796", title: "ТЕРКС И КАЙКОС" },
  { value: "798", title: "ТУВАЛУ" },
  { value: "800", title: "УГАНДА" },
  { value: "804", title: "УКРАИНА" },
  { value: "807", title: "МАКЕДОНИЯ, БЫВШАЯ ЮГОСЛАВСКАЯ РЕСПУБЛИКА" },
  { value: "818", title: "ЕГИПЕТ" },
  { value: "826", title: "ВЕЛИКОБРИТАНИЯ" },
  { value: "830", title: "НОРМАНДСКИЕ ОСТРОВА" },
  { value: "831", title: "ГЕРНСИ" },
  { value: "832", title: "ДЖЕРСИ" },
  { value: "833", title: "ОСТРОВ МЭН" },
  { value: "834", title: "ТАНЗАНИЯ" },
  { value: "840", title: "СОЕДИНЕННЫЕ ШТАТЫ АМЕРИКИ" },
  { value: "850", title: "ВИРГИНСКИЕ ОСТРОВА (США)" },
  { value: "854", title: "БУРКИНА-ФАСО" },
  { value: "858", title: "УРУГВАЙ" },
  { value: "860", title: "УЗБЕКИСТАН" },
  { value: "862", title: "ВЕНЕСУЭЛА, БОЛИВАРИАНСКАЯ РЕСПУБЛИКА" },
  { value: "872", title: "ОСТРОВ УЭЙК" },
  { value: "876", title: "УОЛЛИС И ФУТУНА" },
  { value: "882", title: "САМОА" },
  { value: "887", title: "ЙЕМЕН" },
  { value: "891", title: "ЮГОСЛАВИЯ" },
  { value: "894", title: "ЗАМБИЯ" },
  { value: "895", title: "АБХАЗИЯ" },
  { value: "896", title: "ЮЖНАЯ ОСЕТИЯ" },
];
