import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";

interface IAction extends Action {}

export const getKepRequest = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    loading: true,
  };
};

export const getKepSuccess = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    loading: false,
    error: false,
  };
};

export const getKepFailure = (state: IEntriesState, action: IAction): IEntriesState => {
  return { ...state, error: true, loading: false };
};
