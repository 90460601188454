import { isFieldExist, validateDate } from "src/helpers";
import { attributes } from "src/constants";
import { IProps } from "./interface";

export default function validate(values: Record<string, any>, props: IProps): Record<string, string> {
  // ошибки для валидации
  let errors: Record<string, string> = {};
  errors = isFieldExist(attributes["Государство гражданства"], values[attributes["Государство гражданства"]], errors);
  errors = isFieldExist(attributes["Кем выдан"], values[attributes["Кем выдан"]], errors);
  errors = isFieldExist(attributes["Номер документа"], values[attributes["Номер документа"]], errors);
  errors = validateDate(attributes["Дата выдачи"], values[attributes["Дата выдачи"]], errors);
  errors = isFieldExist(
    attributes["Основание для проживания на территории РФ"],
    values[attributes["Основание для проживания на территории РФ"]],
    errors,
  );
  if (!values[attributes["Документ иностранца бессрочный"]]) {
    errors = validateDate(attributes["Срок действия"], values[attributes["Срок действия"]], errors);
  }

  return errors;
}
