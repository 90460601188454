import { attributes } from "src/constants";
import { replaceDoubleQuotes } from "src/helpers";

export const getInitialValues = (entryData: Record<string, any>) => {
  return {
    [attributes["Система налогобложения"]]: entryData[attributes["Система налогобложения"]],
    [attributes["Вид указания размера уставного капитала"]]:
      entryData[attributes["Вид указания размера уставного капитала"]] || "percent",
    [attributes["Сокращенное наименование"]]:
      replaceDoubleQuotes(entryData[attributes["Сокращенное наименование"]]) || "ООО «»",
    [attributes["Полное наименование"]]:
      replaceDoubleQuotes(entryData[attributes["Полное наименование"]]) ||
      "Общество с ограниченной ответственностью «»",
    [attributes["Сокращенное наименование на английском языке"]]:
      entryData[attributes["Сокращенное наименование на английском языке"]],
    [attributes["Полное наименование на английском языке"]]:
      entryData[attributes["Полное наименование на английском языке"]],
    [attributes["Есть наименование на английском языке"]]:
      entryData[attributes["Есть наименование на английском языке"]] === "true" ? true : false,
    [attributes["Дата создания"]]: entryData[attributes["Дата создания"]] || new Date().toLocaleDateString("ru-RU"),
    [attributes["Уставный капитал"]]: entryData[attributes["Уставный капитал"]] || "10 000 ₽",
    [attributes["Указывать полный адрес в документах"]]:
      entryData[attributes["Указывать полный адрес в документах"]] === "true" ? true : false,
    [attributes["Общество не использует печать"]]:
      entryData[attributes["Общество не использует печать"]] === "true" ? true : false,
    [attributes["Общество использует типовой Устав"]]:
      entryData[attributes["Общество использует типовой Устав"]] === "true" ? true : false,
    [attributes["Номер типового Устава"]]: entryData[attributes["Номер типового Устава"]]
      ? parseInt(entryData[attributes["Номер типового Устава"]], 10)
      : "",
  };
};
