import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import { Modal } from "antd";
import "./MarketplaceCard.scss";
import { baseURL, marketplaceTopics } from "src/constants";
import { marketplaceFormsMap } from "src/constants/marketplace";
import { defaultProps, IProps, IState } from "./interface";

const b = cn("marketplace-card");

export class MarketplaceCard extends Component<IProps, IState> {
  static defaultProps = defaultProps;

  constructor(props: IProps) {
    super(props);
    this.state = {
      modalVisible: false,
    };
  }

  handleModal = () => {
    this.setState({ modalVisible: !this.state.modalVisible });
  };

  renderModal = () => {
    const { modalVisible } = this.state;
    const { cardName } = this.props.data;

    return (
      <Modal visible={modalVisible} footer={null} onCancel={this.handleModal} className="common-modal">
        {marketplaceFormsMap({ cardData: this.props.data, onSuccessSend: this.handleModal })[cardName]}
      </Modal>
    );
  };

  renderCard = () => {
    const { logoUrl, title, description, topic, rewards } = this.props.data;

    return (
      <div className={b()}>
        <img src={logoUrl ? `${baseURL}${logoUrl}` : ""} alt={"logo"} className={b("logo")} />
        <h3 className={b("title")}>{title}</h3>
        <p className={b("description")}>{description}</p>
        <div className={b("bottom-wrapper")}>
          <ul className={b("tags-list")}>
            <li className={b("tags-item")}>{marketplaceTopics[topic]}</li>
            <li className={b("tags-item", { price: true })}>{rewards}</li>
          </ul>
          <button type="button" onClick={this.handleModal} className={`${b("button")} custom-button`}>
            Получить
          </button>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.renderCard()}
        {this.renderModal()}
      </>
    );
  }
}
