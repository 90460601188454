import React from "react";
import { cn } from "src/helpers/bem";
import classNames from "classnames/dedupe";
import "./CityLabel.scss";
import { IProps } from "./interface";

const b = cn("city-label");

const getCityColor = (city: string) => {
  switch (city) {
    case "Москва":
      return "blue";
    case "Санкт-Петербург":
      return "green";
    default:
      return "orange";
  }
};

export const CityLabel = (props: IProps) => {
  const { city, className } = props;

  return <span className={classNames(className, b({ [getCityColor(city)]: true }))}>{city}</span>;
};

CityLabel.defaultProps = {
  city: "Другой",
  className: "",
};
