import { filesConstants } from "./../files.constants";
import { API } from "src/helpers";
import { endpoints } from "src/constants";

export const loadFileByToken = (id: number, token: string, fileId: string) => {
  const { LOAD_FILE_BY_TOKEN_REQUEST, LOAD_FILE_BY_TOKEN_SUCCESS, LOAD_FILE_BY_TOKEN_FAILURE } = filesConstants;
  const request = () => ({ type: LOAD_FILE_BY_TOKEN_REQUEST });
  const success = () => ({ type: LOAD_FILE_BY_TOKEN_SUCCESS });
  const failure = () => ({ type: LOAD_FILE_BY_TOKEN_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());

    try {
      const file: Blob = await API.get(`${endpoints.files.loadByToken}?id=${id}&token=${token}&fileId=${fileId}`, {
        responseType: "blob",
      });

      dispatch(success());
      return file;
    } catch {
      dispatch(failure());
    }
  };
};
