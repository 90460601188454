import React from "react";
import { cn } from "src/helpers/bem";
import "./Footer.scss";
import { IProps, defaultProps } from "./interface";

const b = cn("site-footer");

export const Footer = (props: IProps) => {
  const { inner } = props;

  return (
    <footer className={b({ inner })}>
      <ul className={b("list")}>
        <li className={b("list-item")}>
          <a download href="./images/Пользовательское_соглашение.pdf" className={b("link")}>
            Пользовательское соглашение
          </a>
        </li>
        <li className={b("list-item")}>© Бюро</li>
      </ul>
    </footer>
  );
};

Footer.defaultProps = defaultProps;
