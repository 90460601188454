import { IActivitiesState } from "src/redux/activities";
import { Action } from "redux";
import { IActivityInternal } from "src/helpers/types/Activity";

interface IAction extends Action {
  selectedActivities: IActivityInternal[];
}

export const setSelectedActivities = (state: IActivitiesState, action: IAction): IActivitiesState => {
  const { selectedActivities } = action;

  return {
    ...state,
    selectedActivities,
  };
};
