import { IForm } from "src/helpers/types/Form";
import { attributes, formsNames } from "src/constants";

export interface IProps extends IForm {
  className?: string;
  autoCompleteData: Record<string, any>;
  fieldsNames: Record<string, any>;
}

export interface IState {}

export const defaultProps = {
  form: formsNames["shortPassport"],
  fieldsNames: {
    [attributes["Серия и номер паспорта"]]: attributes["Серия и номер паспорта"],
    [attributes["Дата выдачи паспорта"]]: attributes["Дата выдачи паспорта"],
    [attributes["Дата рождения"]]: attributes["Дата рождения"],
    [attributes["СНИЛС"]]: attributes["СНИЛС"],
  },
};
