import { IUsersState } from "src/redux/users";

export const deactivateUserRequest = (state: IUsersState): IUsersState => {
  return {
    ...state,
    loading: true,
  };
};

export const deactivateUserSuccess = (state: IUsersState): IUsersState => {
  return {
    ...state,
    loading: false,
  };
};

export const deactivateUserFailure = (state: IUsersState): IUsersState => {
  return {
    ...state,
    loading: false,
  };
};
