import { cloneDeep } from "lodash";
import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";
import { IFormattedEntry } from "src/helpers/types/Entry";

interface IAction extends Action {
  attributeName: keyof IFormattedEntry;
  value: any;
}

export const changeFormattedEntryAttribute = (state: IEntriesState, action: IAction): IEntriesState => {
  const { attributeName, value } = action;
  const formattedEntry = cloneDeep(state.formattedEntry);
  // @ts-ignore
  formattedEntry[attributeName] = value;
  return { ...state, formattedEntry };
};
