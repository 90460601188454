import { serverValuesToMasksFormat } from "src/helpers";
import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";
import { IFormattedEntry } from "src/helpers/types/Entry";

interface IAction extends Action {
  data: IFormattedEntry;
}

export const changeFormattedEntry = (state: IEntriesState, action: IAction): IEntriesState => {
  const { data } = action;
  //@ts-ignore
  return { ...state, formattedEntry: serverValuesToMasksFormat(data) };
};
