import React from "react";
import {
  HHForms,
  BankForms,
} from "src/pages/Marketplace/components/MarketplaceGrid/components/MarketplaceCard/components";

//константы cardNames
export const CARD_NAMES = {
  HH: "hh",
  KNOPKA: "knopka",
  LIFEPAY: "lifepay",
  TOCHKA: "tochka",
  HISE: "hise",
};

// словарь соответствий cardName карточки и формы ввода данных модалки
export const marketplaceFormsMap = (props: any) => ({
  [CARD_NAMES.HH]: <HHForms {...props} />,
  [CARD_NAMES.TOCHKA]: <BankForms {...props} />,
  [CARD_NAMES.HISE]: <BankForms {...props} />,
  [CARD_NAMES.KNOPKA]: <BankForms {...props} />,
  [CARD_NAMES.LIFEPAY]: <BankForms {...props} />,
});
