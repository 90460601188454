import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Button } from "antd";
import { cn } from "src/helpers/bem";
import "./Breadcrumbs.scss";
import { history } from "src/helpers";
import { IProps } from "./interface";

const b = cn("site-breadcrumbs");

export class Breadcrumbs extends React.Component<IProps, {}> {
  render() {
    const { backLabel, items, className } = this.props;
    return (
      <div className={`${b()} ${className}`} style={{ display: "none" }}>
        {backLabel && (
          <Button icon={<ArrowLeftOutlined />} className={b("back")} onClick={() => history.push(items[0]["href"])}>
            {backLabel}
          </Button>
        )}

        <Breadcrumb>
          {items.map((item: any, index: number) => (
            <Breadcrumb.Item key={`breadcrumb${index}`}>
              <a href={item.href}>{item.text}</a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
    );
  }
}
