import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";

interface IAction extends Action {}

export const createEntryRequest = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    loading: true,
  };
};

export const createEntrySuccess = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    loading: false,
    error: false,
  };
};

export const createEntryFailure = (state: IEntriesState, action: IAction): IEntriesState => {
  return { ...state, error: true, loading: false };
};
