import { passportsConstants } from "./../passports.constants";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { message } from "antd";
import { IPassportsResponse } from "src/helpers/types/ResponsesFromBackend";

export const getPassports = (count: number, page: number, search?: string) => {
  const { PASSPORTS_REQUEST, PASSPORTS_SUCCESS, PASSPORTS_FAILURE } = passportsConstants;
  const request = () => ({ type: PASSPORTS_REQUEST });
  const success = (passports: IPassportsResponse) => ({ type: PASSPORTS_SUCCESS, passports });
  const failure = () => ({ type: PASSPORTS_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      const passports: IPassportsResponse = await API.get(
        `${endpoints.passports.get}?li=${count}&page=${page}${search ? `&search=${search}` : ""}`,
      );
      dispatch(success(passports));
    } catch (err: any) {
      message.error(`Не удалось получить данные пользователей ${err}`);
      dispatch(failure());
    }
  };
};
