import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./Owner.scss";
import { AInput, ACheckbox } from "src/helpers";
import { connect } from "react-redux";
import validate from "./validate";
import { attributes, formsNames, equityFormatToAttr } from "src/constants";
import { Field, reduxForm, submit, formValueSelector } from "redux-form";
import { getInitialValues } from "./initialValues";
import classNames from "classnames/dedupe";
import { AppState } from "src/redux/AppState";
import { IProps, defaultProps } from "./interface";

const sf = cn("site-form");
const b = cn("owner-form");

class OwnerForm extends Component<IProps> {
  render() {
    const { handleSubmit, inputsColors, form, equityFormat, disabled } = this.props;
    const equityFormatToPlaceholder = {
      percent: "100%",
      simple: "1/100",
    };
    const equityFormatToView = {
      percent: (v: string) => v.replace(/[^0-9\.]/g, "") + "%",
      simple: (v: string) => v.replace(/[^0-9\/]/g, ""),
    };

    return (
      <form onSubmit={handleSubmit} className={classNames(sf(), b())}>
        <div className={sf("inputs-grid")}>
          <Field
            title="Доля:"
            name={equityFormatToAttr[equityFormat]}
            placeholder={equityFormatToPlaceholder[equityFormat]}
            component={AInput}
            disabled={disabled}
            parse={equityFormatToView[equityFormat]}
            outlineColor={inputsColors[`${form}-${equityFormatToAttr[equityFormat]}`]}
          />
          <Field
            title="Размер доли:"
            name={attributes["Размер доли"]}
            placeholder="10 000 ₽"
            tooltipClassName={b("equity-tooltip")}
            component={AInput}
            disabled={disabled}
            format={(v: string) => (v ? parseFloat(v.replace(/[^0-9]/g, "")).toLocaleString() + " ₽" : "")}
            normalize={(v: string) => (v ? v.replace(/[^0-9]/g, "") : "")}
            outlineColor={inputsColors[`${form}-${attributes["Размер доли"]}`]}
          />
        </div>
        <Field
          title="Назначить исполнительным органом"
          name={attributes["Назначить исполнительным органом"]}
          component={ACheckbox}
          disabled={disabled}
          className={b("isEIO")}
          // onChange={this.handleEIOCheckboxChange}
        />
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { data, form } = ownProps;
  const formValues: any = formValueSelector(form);
  const equityFormat = formValueSelector(formsNames["commonOOO"])(
    state,
    attributes["Вид указания размера уставного капитала"],
  );
  const equity_cost = formValues(state, attributes["Размер доли"]);
  const initialValues = data ? getInitialValues(data, equityFormat) : {};
  const { inputsColors } = state.input;

  return { form, equityFormat, equity_cost, inputsColors, initialValues };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    onSubmit: () => {
      dispatch(submit(form));
    },
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  validate,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(OwnerForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as OwnerForm };
