import { IInputState } from "src/redux/input";
import { Action } from "redux";

interface IAction extends Action {}

export const clearInputData = (state: IInputState, action: IAction): IInputState => {
  return {
    lastChangedForm: "",
    lastChangedField: "",
    lastChangedValue: "",
    autoCompleteData: {},
    issuedBy: "",
    inputsColors: {},
    loadAutoCompleteOnNextChange: true,
  };
};
