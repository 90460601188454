import { ICompaniesState } from "src/redux/companies";

export const updateCompanyRequest = (state: ICompaniesState): ICompaniesState => {
  return {
    ...state,
    loading: true,
  };
};

export const updateCompanySuccess = (state: ICompaniesState): ICompaniesState => {
  return {
    ...state,
    loading: false,
  };
};

export const updateCompanyFailure = (state: ICompaniesState): ICompaniesState => {
  return {
    ...state,
    loading: false,
  };
};
