import { IMarketplaceState } from "src/redux/marketplace";
import { Action } from "redux";
import { IMarketPlaceRequest } from "src/helpers/types/MarketplaceCard";

interface IAction extends Action {
  requests: IMarketPlaceRequest[];
}

export const getRequestsRequest = (state: IMarketplaceState, action: IAction): IMarketplaceState => {
  return {
    ...state,
    loading: true,
  };
};

export const getRequestsSuccess = (state: IMarketplaceState, action: IAction): IMarketplaceState => {
  return {
    ...state,
    loading: false,
    requests: action.requests,
  };
};

export const getRequestsFailure = (state: IMarketplaceState, action: IAction): IMarketplaceState => {
  return { ...state, loading: false };
};
