import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./BusinessCheck.scss";
import { businessCheckTabsTitles } from "src/constants";
import { Tabs } from "antd";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { BusinessSearch } from "./components/BusinessSearch/BusinessSearch";
import { AppState } from "src/redux/AppState";
import { IProps, IState } from "./interface";

const { TabPane } = Tabs;
const b = cn("business-check-page");
const sp = cn("site-page");

class BusinessCheck extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentTab: "search",
    };
  }

  handleTabChange = (currentTab: string) => {
    this.setState({ currentTab });
  };

  renderTabs = (tabs: { search: string }) => {
    return (
      <Tabs onChange={this.handleTabChange} defaultActiveKey="0" type="card">
        {Object.entries(tabs).map((item: Array<any>) => (
          <TabPane
            disabled={item[0] === "monitoring"} // временно :О
            tab={<div className={sp("tab-name")}>{item[1]}</div>}
            key={item[0]}
          />
        ))}
      </Tabs>
    );
  };

  render() {
    const { currentTab } = this.state;

    return (
      <div className={`${b()} ${sp()}`}>
        <Helmet>
          <title>Проверка бизнеса</title>
        </Helmet>
        {/* {loading && <Loader />} */}
        <div className={sp("tabs")}>{this.renderTabs(businessCheckTabsTitles)}</div>
        <div className={sp("content")}>{currentTab === "search" ? <BusinessSearch /> : ""}</div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Function) => {
  return {};
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(BusinessCheck);

export { connectedComponent as BusinessCheck };
