import { attributes } from "src/constants";
import { isFieldExist, noLatinLetters, validateDate, validateUstavNumber, valueMustBeBiggerThan } from "src/helpers";

export default function validate(values: Record<string, any>): Record<string, string> {
  // ошибки для валидации
  let errors: Record<string, string> = {};

  errors = noLatinLetters(
    attributes["Сокращенное наименование"],
    values[attributes["Сокращенное наименование"]],
    errors,
  );
  errors = isFieldExist(
    attributes["Сокращенное наименование"],
    values[attributes["Сокращенное наименование"]]?.replace(/ООО|«|»| /g, ""),
    errors,
  );

  errors = noLatinLetters(attributes["Полное наименование"], values[attributes["Полное наименование"]], errors);
  errors = isFieldExist(
    attributes["Полное наименование"],
    values[attributes["Полное наименование"]]?.replace(/Общество с ограниченной ответственностью|«|»| /g, ""),
    errors,
  );

  errors = isFieldExist(
    attributes["Сокращенное наименование на английском языке"],
    values[attributes["Сокращенное наименование на английском языке"]],
    errors,
  );

  errors = isFieldExist(
    attributes["Полное наименование на английском языке"],
    values[attributes["Полное наименование на английском языке"]],
    errors,
  );

  errors = validateDate(attributes["Дата создания"], values[attributes["Дата создания"]], errors);
  errors = isFieldExist(attributes["Система налогобложения"], values[attributes["Система налогобложения"]], errors);
  errors = isFieldExist(attributes["Уставный капитал"], values[attributes["Уставный капитал"]], errors, " ₽");

  errors = valueMustBeBiggerThan(
    attributes["Уставный капитал"],
    values[attributes["Уставный капитал"]]?.replace(/ /g, ""),
    10000,
    errors,
  );

  errors = errors = validateUstavNumber(values[attributes["Номер типового Устава"]], errors);

  return errors;
}
