import React from "react";
import { entriesTitles, pages, entriesTypes, additionalEntriesTypes, attributes } from "src/constants";
import { Status, CompanyName, CityLabel } from "src/components";
import { Link } from "react-router-dom";
import { attributesToObj, checkIsDefaultAvatar, getInitials } from "src/helpers";
import { Avatar, Skeleton } from "antd";
//@ts-ignore
import StringMask from "string-mask";
import { ReactComponent as CheckCircleIcon } from "src/assets/images/check-circle-icon.svg";
import { ReactComponent as EmptyCircleIcon } from "src/assets/images/empty-circle-icon.svg";
import { ReactComponent as EditIcon } from "src/pages/PersonalAccount/images/edit.svg";
import { ReactComponent as ReloadIcon } from "src/pages/PersonalAccount/images/reload.svg";
import { IPayment } from "src/helpers/types/Payment";
import moment from "moment";
import { ICurrentEntry } from "src/helpers/types/Entry";
import { ICompany, ICompanyExtended } from "src/helpers/types/Company";
import { IMarketplaceCard, IMarketPlaceRequest } from "src/helpers/types/MarketplaceCard";
import { IOperation } from "src/helpers/types/Operation";
import { IUser } from "src/helpers/types/User";
import { IDadataAddressData, IDadataOktmo } from "src/helpers/types/Dadata";

export const entriesFromServerFormat = (entries: Array<ICurrentEntry>, companies?: Array<ICompanyExtended>) => {
  const getClient = (entry: ICurrentEntry, attributes: Record<string, any>): string => {
    const { entryType } = entry;
    const { lastName, firstname, middleName, shortOOO } = attributes;
    if (
      (entryType === entriesTypes["РегистрацияИП"] ||
        entryType === entriesTypes["ИзмененияИП"] ||
        entryType == entriesTypes["ЗакрытиеИП"]) &&
      (lastName || firstname || middleName)
    ) {
      return `ИП ${lastName ? lastName : ""} ${firstname ? firstname : ""} ${middleName ? middleName : ""} `;
    } else {
      return shortOOO;
    }
  };

  if (typeof entries !== "undefined" && typeof companies !== "undefined") {
    return entries.map((entry, index) => {
      const companyInfo = companies.filter((company) => company.id === entry.companyId)[0];
      const currentAttributes = attributesToObj(entry.attributes);
      const clientName = getClient(entry, currentAttributes);
      const entryLink = `${pages.newEntry}?type=${entry.entryType}&id=${entry.id}`;
      const phoneMask = new StringMask("+0 000 000-00-00");

      return {
        key: index,
        // задаём каждой ячейке таблицы LinkTo,
        // чтобы срабатывал переход в заявку при клике на любую часть строки
        company: <Link to={entryLink}>{<CompanyName name={companyInfo?.name} />}</Link>,
        client: <Link to={entryLink}>{clientName ? clientName : "-"}</Link>,
        requestType: <Link to={entryLink}>{entry.entryType || "-"}</Link>,
        requestTypeTitle: (
          <Link to={entryLink}>
            <span className="entry-type">{entriesTitles[entry.entryType] || "-"}</span>
          </Link>
        ),
        inn: (
          <Link to={entryLink}>
            {entry.entryType === entriesTypes["ИзмененияООО"]
              ? //
                //@ts-ignore
                currentAttributes["company_inn"] || "-"
              : //
                //@ts-ignore
                currentAttributes["inn"] || "-"}
          </Link>
        ),
        dateCreated: <Link to={entryLink}>{new Date(entry["createdAt"]).toLocaleDateString("ru-RU")}</Link>,
        phone: <Link to={entryLink}>{phoneMask.apply(entry["phone"]?.replace(/[^\d]/g, "")) || "-"}</Link>,
        //@ts-ignore
        status: <Link to={entryLink}>{<Status status={entry["scene"]} />}</Link>,
      };
    });
  }

  return new Array(40).fill(undefined).map((it, index) => ({
    key: index,
    company: <Skeleton active paragraph={{ rows: 0 }} />,
    client: <Skeleton active paragraph={{ rows: 0 }} />,
    requestType: <Skeleton active paragraph={{ rows: 0 }} />,
    requestTypeTitle: <Skeleton active paragraph={{ rows: 0 }} />,
    inn: <Skeleton active paragraph={{ rows: 0 }} />,
    dateCreated: <Skeleton active paragraph={{ rows: 0 }} />,
    phone: <Skeleton active paragraph={{ rows: 0 }} />,
    status: <Skeleton active paragraph={{ rows: 0 }} />,
    administration: <Skeleton active paragraph={{ rows: 0 }} />,
  }));
};

export const passportsFromServerFormat = (passports: Array<any> = [], companies: Array<any> = []) => {
  if (typeof passports !== "undefined" && typeof companies !== "undefined") {
    return passports.map((passport, index) => {
      const { lastName, firstname, middleName, inn, snils, created_at, phone, companyId, cert_buro, cert_signme } =
        passport;
      const clientLink = `${pages.changeEntry}?type=${additionalEntriesTypes["ИзмененияПаспорта"]}&id=${passport.id}`;
      const companyInfo = companies.filter((company) => company.id === companyId)[0];

      return {
        key: index,
        company: <Link to={clientLink}>{<CompanyName name={companyInfo?.name} /> || "-"}</Link>,
        client: (
          <Link to={clientLink}>{`${lastName ? `${lastName} ` : ""}${firstname ? `${firstname} ` : ""}${
            middleName ? middleName : ""
          }`}</Link>
        ),
        inn: <Link to={clientLink}>{inn || "-"}</Link>,
        snils: <Link to={clientLink}>{snils || "-"}</Link>,
        created_at: <Link to={clientLink}>{created_at?.slice(0, 10).replace(/\//g, ".") || "-"}</Link>,
        phone: <Link to={clientLink}>{phone || "-"}</Link>,
        cert_buro: <Link to={clientLink}>{cert_buro ? <CheckCircleIcon /> : <EmptyCircleIcon />}</Link>,
        cert_signme: <Link to={clientLink}>{cert_signme ? <CheckCircleIcon /> : <EmptyCircleIcon />}</Link>,
      };
    });
  }

  return new Array(40).fill(undefined).map((it, index) => ({
    key: index,
    company: <Skeleton active paragraph={{ rows: 0 }} />,
    client: <Skeleton active paragraph={{ rows: 0 }} />,
    inn: <Skeleton active paragraph={{ rows: 0 }} />,
    snils: <Skeleton active paragraph={{ rows: 0 }} />,
    created_at: <Skeleton active paragraph={{ rows: 0 }} />,
    phone: <Skeleton active paragraph={{ rows: 0 }} />,
  }));
};

export const requestsFromServerFormat = (
  requests: IMarketPlaceRequest[],
  marketplaceCards: IMarketplaceCard[],
  companies: ICompany[],
) => {
  if (typeof requests !== "undefined" && typeof marketplaceCards !== "undefined" && typeof companies !== "undefined") {
    return requests.map((request, index) => {
      const { company_id, createdAt, serviceId, status } = request;
      const service = marketplaceCards.filter((card) => card.id === serviceId)[0].title;
      const companyInfo = companies.filter((company) => company.id === company_id)[0];

      return {
        key: index,
        company_name: <CompanyName name={companyInfo?.name || "-"} />,
        createdAt: new Date(createdAt).toLocaleDateString("ru-RU"),
        service,
        //@ts-ignore
        status: <Status status={status} />,
      };
    });
  }

  return new Array(40).fill(undefined).map((it, index) => ({
    key: index,
    company_name: <Skeleton active paragraph={{ rows: 0 }} />,
    createdAt: <Skeleton active paragraph={{ rows: 0 }} />,
    service: <Skeleton active paragraph={{ rows: 0 }} />,
    status: <Skeleton active paragraph={{ rows: 0 }} />,
  }));
};

export const operationsFromServerFormat = (operations?: IOperation[], companies?: ICompanyExtended[] | undefined) => {
  const actionLabels = {
    charge: "Списание",
    fill: "Пополнение",
  };

  if (typeof operations !== "undefined" && typeof companies !== "undefined") {
    return operations.map((operation, index) => {
      const { companyId, entityId, action, date, sum, comment } = operation;
      const companyInfo = companies.filter((company) => company.id === companyId)[0];
      const actionLabel = actionLabels[action] || "-";

      return {
        key: `operation-${index}`,
        company: <CompanyName name={companyInfo?.name} />,
        request: entityId || "-",
        actionType: actionLabel,
        date: new Date(date).toLocaleDateString("ru-RU") || "-",
        sum: (
          <span style={{ fontWeight: 500, color: action === "fill" ? "var(--color-green)" : "var(--color-red)" }}>
            {sum.toLocaleString()}
          </span>
        ),
        administration: comment,
      };
    });
  }

  return new Array(40).fill(undefined).map((it, index) => ({
    key: `operation-${index}`,
    company: <Skeleton active paragraph={{ rows: 0 }} />,
    request: <Skeleton active paragraph={{ rows: 0 }} />,
    actionType: <Skeleton active paragraph={{ rows: 0 }} />,
    date: <Skeleton active paragraph={{ rows: 0 }} />,
    sum: <Skeleton active paragraph={{ rows: 0 }} />,
    administration: <Skeleton active paragraph={{ rows: 0 }} />,
  }));
};

export const paymentsFromServerFormat = (
  payments: IPayment[] | undefined,
  companies: ICompanyExtended[] | undefined,
  onSubmitActClick: (id: number, status: "payed" | "acted") => Promise<void>,
  bemBunction: Function,
  onRecoverClick: (id: number) => Promise<void>,
  toggleSetBillModal: () => void,
  setCurrentBill: (currentBill: IPayment) => void,
) => {
  if (typeof payments !== "undefined" && typeof companies !== "undefined") {
    return payments.map((payment, index) => {
      const { id, companyId, sum, createdAt, updatedAt, payed, acted } = payment;
      const companyInfo = companies.filter((company) => company.id === companyId)[0];

      return {
        key: `payment-${index}`,
        company: <CompanyName name={companyInfo?.name} />,
        account: id,
        date: updatedAt
          ? moment(updatedAt, "YYYY-MM-DD").format("DD.MM.YYYY")
          : moment(createdAt, "YYYY-MM-DD").format("DD.MM.YYYY"),
        sum: sum?.toLocaleString() || "-",
        payed: payed ? <CheckCircleIcon /> : <EmptyCircleIcon />,
        /* Если был выставлен акт, выводим на него ссылку. 
        Если не был, то 
          - если была оплата, выводим кнопку "Выставить акт"
          - если оплаты не было, ячейка остаётся пустой */
        acted: acted ? (
          <a href={`/api/v1/company_bill_print_full/${id}`} target="blank">
            {`Акт № ${id}`}
          </a>
        ) : payed ? (
          <button className="custom-button" onClick={() => onSubmitActClick(id, "acted")}>
            Выставить акт
          </button>
        ) : (
          ""
        ),
        /* Если не было оплаты, выводим кнопку "Оплачен", иначе ячейка пустая */
        settings: !payed ? (
          <button className="custom-button" onClick={() => onSubmitActClick(id, "payed")}>
            Оплачен
          </button>
        ) : (
          <div className={bemBunction("settings-buttons")}>
            <EditIcon
              className={bemBunction("icon", { edit: true })}
              onClick={() => {
                toggleSetBillModal();
                setCurrentBill(payment);
              }}
            />
            <ReloadIcon className={bemBunction("icon")} onClick={() => onRecoverClick(id)} />
          </div>
        ),
      };
    });
  }

  return new Array(40).fill(undefined).map((it, index) => ({
    key: `payment-${index}`,
    company: <Skeleton active paragraph={{ rows: 0 }} />,
    account: <Skeleton active paragraph={{ rows: 0 }} />,
    sum: <Skeleton active paragraph={{ rows: 0 }} />,
    payed: <Skeleton active paragraph={{ rows: 0 }} />,
    acted: <Skeleton active paragraph={{ rows: 0 }} />,
    settings: <Skeleton active paragraph={{ rows: 0 }} />,
  }));
};

export const companiesFromServerFormat = (companies?: ICompanyExtended[]) => {
  if (typeof companies !== "undefined") {
    return companies.map((company) => {
      const {
        id,
        name,
        city,
        segment,
        createdAt,
        activity,
        activated,
        rate,
        balance,
        tochkaAccess,
        spheraAccess,
        creator,
      } = company;

      return {
        key: id,
        name: <CompanyName name={name} />,
        city: city ? <CityLabel city="Санкт-Петербург" /> : "-",
        activated: activated ? <CheckCircleIcon /> : <EmptyCircleIcon />,
        //@ts-ignore
        segment: <Status status={segment} />,
        createdAt: createdAt,
        activity: activity,
        rate: rate,
        balance: (
          <span style={{ fontWeight: 500, color: balance < 3000 ? "var(--color-red)" : "var(--color-green)" }}>
            {balance.toLocaleString()}
          </span>
        ),
        tochkaAccess: tochkaAccess ? <CheckCircleIcon /> : <EmptyCircleIcon />,
        spheraAccess: spheraAccess ? <CheckCircleIcon /> : <EmptyCircleIcon />,
        creator: creator,
      };
    });
  }

  return new Array(40).fill(undefined).map((it, index) => ({
    key: index,
    name: <Skeleton active paragraph={{ rows: 0 }} />,
    city: <Skeleton active paragraph={{ rows: 0 }} />,
    activated: <Skeleton active paragraph={{ rows: 0 }} />,
    segment: <Skeleton active paragraph={{ rows: 0 }} />,
    createdAt: <Skeleton active paragraph={{ rows: 0 }} />,
    activity: <Skeleton active paragraph={{ rows: 0 }} />,
    rate: <Skeleton active paragraph={{ rows: 0 }} />,
    balance: <Skeleton active paragraph={{ rows: 0 }} />,
    tochkaAccess: <Skeleton active paragraph={{ rows: 0 }} />,
    spheraAccess: <Skeleton active paragraph={{ rows: 0 }} />,
    creator: <Skeleton active paragraph={{ rows: 0 }} />,
  }));
};

export const usersFromServerFormat = (users?: IUser[], companies?: ICompanyExtended[]) => {
  if (typeof users !== "undefined" && typeof companies !== "undefined") {
    return users.map((user) => {
      const { userID, firstName, middleName, lastName, email, status, companyId, activated } = user;
      const companyInfo = companies.filter((company) => company.id === companyId)[0];
      const isDefaultAvatar = checkIsDefaultAvatar(user.avatarURL);
      return {
        key: userID,
        avatar: (
          <Avatar size={25} src={!isDefaultAvatar ? user.avatarURL : ""}>
            {isDefaultAvatar && getInitials(user)}
          </Avatar>
        ),
        company: <CompanyName name={companyInfo?.name} />,
        name: `${lastName} ${firstName}${middleName ? ` ${middleName}` : ""}`, // у старых пользователей может быть неактуальный фуллнейм
        email,
        //@ts-ignore
        status: <Status status={activated ? status : "deactivated"} />,
        date: "-", // тож временно
      };
    });
  }

  return new Array(40).fill(undefined).map((it, index) => ({
    key: index,
    company: <Skeleton active paragraph={{ rows: 0 }} />,
    name: <Skeleton active paragraph={{ rows: 0 }} />,
    email: <Skeleton active paragraph={{ rows: 0 }} />,
    status: <Skeleton active paragraph={{ rows: 0 }} />,
    date: <Skeleton active paragraph={{ rows: 0 }} />,
  }));
};

export const dadataAddressFormat = (
  data: IDadataAddressData,
  loadSonoArea?: (tax_office: string) => Promise<string>,
  getOktmoInfo?: (code: string) => Promise<IDadataOktmo>,
) => {
  return new Promise(async (resolve, reject) => {
    if (!data) {
      resolve(null);
    }

    let sono;
    let oktmoInfo;

    if (loadSonoArea && getOktmoInfo) {
      sono = await loadSonoArea(data.tax_office);
      oktmoInfo = await getOktmoInfo(data.oktmo?.slice(0, 8));
    }

    const cityView =
      data.city && data.settlement
        ? "settlement"
        : data.city
        ? "city"
        : data.settlement
        ? "settlement"
        : data.area
        ? "area"
        : undefined;

    resolve({
      [attributes["Субъект"]]: data.region_kladr_id.slice(0, 2),
      [attributes["Код терр. ФНС"]]: data.tax_office,
      [attributes["Код рег. органа"]]: sono,
      //@ts-ignore
      [attributes["Населенный пункт"]]: data[`${cityView}_type`] || "",
      //@ts-ignore
      [attributes["Наименование населенного пункта"]]: data[cityView] || "",
      [attributes["Муниципальное образование (верхнее)"]]: oktmoInfo?.area_type,
      [attributes["Наименование муниципального образования (верхнее)"]]: oktmoInfo?.area,
      [attributes["Муниципальное образование (нижнее)"]]: oktmoInfo?.subarea_type,
      [attributes["Наименование муниципального образования (нижнее)"]]: oktmoInfo?.subarea,
      // temp :o
      [attributes["Элемент планировочной структуры"]]: data.planning_structure_type,
      [attributes["Наименование элемента планировочной структуры"]]: data.planning_structure,
      [attributes["Элемент улично-дорожной сети"]]: data.street_type,
      [attributes["Наименование элемента улично-дорожной сети"]]: data.street,
      [attributes["Здание/сооружение (первый столбец)"]]: data.house_type,
      [attributes["Номер здания/сооружения (первый столбец)"]]: data.house,
      // later :o
      // [attributes["Здание/сооружение (второй столбец)"]]: data.block_type,
      // [attributes["Номер здания/сооружения (второй столбец)"]]: data.block,

      // temp :o
      [attributes["Здание/сооружение (второй столбец)"]]: data.block ? "к" : data.building_type,
      [attributes["Номер здания/сооружения (второй столбец)"]]: data.block ? data.block : data.building,
      [attributes["Здание/сооружение (третий столбец)"]]: data.block ? data.building_type : "",
      [attributes["Номер здания/сооружения (третий столбец)"]]: data.block ? data.building : "",

      // temp :o Ручной ввод пока
      // [attributes["Квартира/офис"]]: data.flat_type,
      // [attributes["Номер квартиры/офиса"]]: data.flat,

      house_fias_id: data.house_fias_id,

      [attributes["Наименование региона"]]: data.region,
      [attributes["Регион (сокр.)"]]: data.region_type,
      [attributes["Регион (полн.)"]]: data.region_type_full,

      [attributes["Код адреса"]]: data.kladr_id,
    });
  });
};
