import { companiesConstants } from "./../companies.constants";
import { message } from "antd";
import { merge } from "lodash";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { ICompanyExtended } from "src/helpers/types/Company";

export const updateCompany = (
  initialData: ICompanyExtended,
  newData: Pick<
    ICompanyExtended,
    "balance" | "id" | "inn" | "kpp" | "name" | "ogrn" | "owner" | "shortooo" | "tochkaKey"
  >,
) => {
  const { UPDATE_COMPANY_REQUEST, UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_FAILURE } = companiesConstants;
  const request = () => ({ type: UPDATE_COMPANY_REQUEST });
  const success = () => ({ type: UPDATE_COMPANY_SUCCESS });
  const failure = () => ({ type: UPDATE_COMPANY_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      const allData = merge(initialData, newData);
      await API.put(`${endpoints.companies.updateInfo}${allData.id}`, allData);
      message.success("Данные компании успешно изменены");
      dispatch(success());
    } catch (err: any) {
      message.error(`Не удалось обновить данные компании ${err}`);
      dispatch(failure());
    }
  };
};
