import { serverValuesToMasksFormat } from "src/helpers";
import { attributes } from "src/constants";
import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";
interface IAction extends Action {
  applicantData: Record<string, any>;
}

export const updateApplicant = (state: IEntriesState, action: IAction): IEntriesState => {
  const { formattedEntry } = state;
  const { applicantData } = action;

  // чтобы не перетиралось после подстановки данных по ОГРН :о
  delete applicantData[attributes["Код рег. органа"]];
  delete applicantData[attributes["Код терр. ФНС"]];

  //@ts-ignore
  return { ...state, formattedEntry: { ...formattedEntry, ...serverValuesToMasksFormat(applicantData) } };
};
