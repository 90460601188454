import { SizeType } from "antd/lib/config-provider/SizeContext";
import { WrappedFieldMetaProps } from "redux-form";
import React, { ReactNode } from "react";

export interface IProps {
  type: string;
  title: string;
  className: string;
  meta: WrappedFieldMetaProps;
  size: SizeType;
  outlineColor: string;
  autocomplete: string;
  tooltipClassName: string;
  suffix: ReactNode;
  mask: string;
  ignoreTouched: boolean;
  value: any;
  disabled: boolean;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const defaultProps = {
  type: "",
  title: "",
  className: "",
  meta: {},
  size: "large",
  outlineColor: "",
};
