import { CryptoPro } from "ruscryptojs";
import { useEffect, useRef } from "react";
import { message } from "antd";

export const useInitCryptoPro = () => {
  const cryptoproRef = useRef(new CryptoPro());

  useEffect(() => {
    cryptoproRef.current
      .init()
      .then((initRes) => {
        console.log("cryptoPro initRes = ", initRes);
      })
      .catch((err) => {
        message.error(
          `Ошибка при инициализации CryptoPro. Проверьте, установлен ли плагин CryptoPro: ${JSON.stringify(err)}`,
        );
      });
  }, []);

  return { cryptoproRef };
};
