import { IBillsState } from "src/redux/bills";
import { Action } from "redux";

interface IAction extends Action {}

export const recoverBillRequest = (state: IBillsState, action: IAction): IBillsState => {
  return { ...state, loading: true };
};

export const recoverBillSuccess = (state: IBillsState, action: IAction): IBillsState => {
  return { ...state, loading: false };
};

export const recoverBillFailure = (state: IBillsState, action: IAction): IBillsState => {
  return { ...state, loading: false };
};
