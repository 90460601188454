import { entriesConstants } from "./../entries.constants";
import { message } from "antd";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { Dispatch } from "redux";

//////////////////////// УДАЛЕНИЕ ///////////////////////////
export const deleteEntries = (ids: Array<number>) => {
  const { ENTRIES_DELETE_REQUEST, ENTRIES_DELETE_SUCCESS, ENTRIES_DELETE_FAILURE } = entriesConstants;
  const request = () => ({ type: ENTRIES_DELETE_REQUEST });
  const success = () => ({ type: ENTRIES_DELETE_SUCCESS });
  const failure = () => ({ type: ENTRIES_DELETE_FAILURE });

  return async function (dispatch: Dispatch) {
    dispatch(request());
    try {
      await API.delete(`${endpoints.entries.delete}?ids=${ids.join("&ids=")}`);
      dispatch(success());
    } catch (err: any) {
      message.error(`Не удалось удалить заявку (или заявки) ${err}`);
      dispatch(failure());
    }
  };
};
