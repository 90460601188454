import { IForm } from "src/helpers/types/Form";
import { formsNames } from "src/constants";

export interface IProps extends IForm {
  className?: string;
  data: Record<string, any>;
  showNalog: boolean;
  showPhone: boolean;
  showFnsPhone: boolean;
  passportChange: boolean;
  onFioPartChange: () => {};
  autoCompleteData: Record<string, any>;
  inputsColors: Record<string, any>;
  shortEmail: string;
}

export const defaultProps = {
  form: formsNames["common"],
  showNalog: true,
  showPhone: true,
  showFnsPhone: true,
  passportChange: false,
  onFioPartChange: () => {},
};
