import React from "react";
import { cn } from "src/helpers/bem";
import "./Input.scss";
import { Input as AntdInput, InputNumber } from "antd";
import { Tooltip } from "src/components";
import { errorsTypes } from "src/constants";
import InputMask from "react-input-mask";
import { defaultProps, IProps } from "./interface";

const b = cn("site-input");

export class Input extends React.Component<IProps> {
  static defaultProps = defaultProps;

  componentDidMount() {
    const root: any = this.refs.root;
    if (!this.props.autocomplete) {
      root.querySelector("input").setAttribute("autocomplete", "off");
    } else {
      root.querySelector("input").setAttribute("autocomplete", this.props.autocomplete);
    }
  }

  render() {
    const { title, meta, type, className, tooltipClassName, size, suffix, outlineColor, mask, ignoreTouched, ...rest } =
      this.props;
    const { touched, error, submitFailed } = meta;
    const tooltipVisible = ignoreTouched ? Boolean(error) : Boolean(touched && error);

    return (
      <div
        className={`${b({
          red: touched && (error === errorsTypes.forbiddenSymbols || error === errorsTypes.invalidPassport),
          [outlineColor]: Boolean(outlineColor),
        })} ${className}`}
        ref="root"
      >
        <label className={b("title")}>{title}</label>

        {type === "password" && (
          <AntdInput.Password size={size} {...rest} suffix={suffix} style={outlineColor ? { outlineColor } : {}} />
        )}

        {
          //
          //@ts-ignore
          type === "number" && <InputNumber size={size} {...rest} style={{ width: "100%" }} />
        }

        {type === "" && (
          <InputMask mask={mask} {...rest}>
            <AntdInput size={size} suffix={suffix} />
          </InputMask>
        )}

        {submitFailed && error === errorsTypes.requiredField && (
          <Tooltip title={error} visible={tooltipVisible} className={tooltipClassName} />
        )}

        {error !== errorsTypes.requiredField && (
          <Tooltip title={error} className={tooltipClassName} visible={tooltipVisible} />
        )}
      </div>
    );
  }
}
