import { entriesConstants } from "./../entries.constants";
import { API, extractOKVEDfromEntry, isScene, sortSelectedActivities } from "src/helpers";
import { getActivities, setSelectedActivities } from "src/redux/activities";
import { message } from "antd";
import { attributes, endpoints, entriesTypes } from "src/constants";
import { activationPooling, linkPooling } from "./index";
import { store } from "src/redux/store";
import { fillOtherFiles } from "src/redux/files";
import { IGetEntryResponse } from "src/helpers/types/ResponsesFromBackend";
import { smevPooling } from "src/redux/entries/actions/smevPooling";

export const getEntry = (id: number) => {
  const { ENTRY_GET_REQUEST, ENTRY_GET_SUCCESS, ENTRY_GET_FAILURE } = entriesConstants;
  const request = () => ({ type: ENTRY_GET_REQUEST });
  const success = (entry: IGetEntryResponse & { id: number }) => ({ type: ENTRY_GET_SUCCESS, entry });
  const failure = () => ({ type: ENTRY_GET_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      const entry: IGetEntryResponse = await API.get(`${endpoints.entries.getById}/${id}`);
      dispatch(success({ ...entry, id: +id }));

      const state = store.getState();
      //извлекаем из store formattedEntry и currentEntry
      const { formattedEntry, smevPoolingDescriptor } = state.entries;
      const { currentEntry } = state.entries;
      dispatch(fillOtherFiles(formattedEntry!));

      let activationRequired = false;

      const activities = state.activities.activities;

      if (activities.length === 0) {
        dispatch(getActivities()).then(() => {
          const selectedActivities = sortSelectedActivities(extractOKVEDfromEntry(formattedEntry!));
          dispatch(setSelectedActivities(selectedActivities));
        });
      } else {
        const selectedActivities = sortSelectedActivities(extractOKVEDfromEntry(formattedEntry!));
        dispatch(setSelectedActivities(selectedActivities));
      }

      if (currentEntry) {
        if (
          currentEntry["scene"] === "vipusk4" &&
          (currentEntry["entryType"] === entriesTypes["ЗакрытиеИП"] ||
            currentEntry["entryType"] === entriesTypes["ИзмененияИП"] ||
            currentEntry["entryType"] === entriesTypes["ИзмененияООО"])
        ) {
          // проверим, есть ли ссылка на активацию
          //@ts-ignore
          if (formattedEntry[attributes["ссылкаНаАктивацию"]]) {
            // запустим pooling активации
            dispatch(activationPooling(id));
            activationRequired = true;
          }
        }

        //если заявка имеет сцену "fns4", нужно запустить long pooling проверки подписания
        if (isScene("Подписание", currentEntry.scene) && !activationRequired) {
          dispatch(linkPooling(id, currentEntry.entryType));
        }

        if (isScene("Проверка СМЭВ", entry.scene) && !smevPoolingDescriptor) {
          dispatch(smevPooling(id, entry.fill.entryType));
        }
      }
    } catch (err: any) {
      message.error(`Не удалось загрузить заявку ${err}`);
      dispatch(failure());
    }
  };
};
