import React from "react";
import { cn } from "src/helpers/bem";
import "./TextArea.scss";
import { Input } from "antd";
import { Tooltip } from "src/components";
import { errorsTypes } from "src/constants";
import { IProps } from "./interface";

const b = cn("site-textarea");

export const TextArea = (props: IProps) => {
  const { title, meta } = props;
  const { touched, error, submitFailed } = meta;
  const { outlineColor, ...restProps } = props;

  return (
    <div
      className={`${b({
        [outlineColor]: Boolean(outlineColor),
        red: touched && error === errorsTypes.forbiddenSymbols,
      })}`}
    >
      <label className={b("title")}>{title}</label>
      <Input.TextArea {...restProps} />
      {submitFailed && error === errorsTypes.requiredField && (
        <Tooltip title={error} visible={Boolean(touched && error)} />
      )}
      {error !== errorsTypes.requiredField && <Tooltip title={error} visible={Boolean(touched && error)} />}
    </div>
  );
};

TextArea.defaultProps = {
  className: "",
};
