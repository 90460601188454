export const pages = {
  landing: "/",
  login: "/login",
  companyRegistration: "/company-registration",
  FNSregistration: "/fns-registration",
  personalAccount: "/personal-account",
  newEntry: "/new-entry",
  changeEntry: "/change-entry",
  clients: "/clients",
  marketplace: "/marketplace",
  users: "/users",
  errors: "/errors",
  businessCheck: "/business-check",
  crtCheck: "/crt-check",
  uploadDocs: "/upload-docs",
  selfSigning: "/self-signing/:token",
};
