import { ICompany } from "src/helpers/types/Company";

export type UserStatusesType = "super" | "admin" | "user" | "observer" | "deactivated";
export type UserStatusesRusType = "Суперадмин" | "Администратор" | "Пользователь" | "Наблюдатель" | "Удалён";

export type UserBusinessStatusesType = "ur" | "ip";

export interface IUser {
  activated: boolean;
  avatarURL: string;
  companyId: number;
  createdAt: string;
  email: string;
  firstName: string;
  fullName: string;
  lastName: string;
  middleName: string;
  status: string;
  userID: string;
}

export interface IUserInfo {
  balance: number;
  block_session: boolean;
  company: ICompany;
  company_settings: {
    activated: boolean;
    companyId: number;
    dbrain: boolean;
    public_balance: boolean;
    reqActivate: boolean;
    scoped: boolean;
    session_limit: boolean;
    spheraAccess: boolean;
  };
  public_balance: boolean;
  registrationsLeft: number;
  session_limit: boolean;
  user: {
    activated: boolean;
    userID?: string;
    firstName?: string;
    lastName?: string;
    avatarURL?: string;
    email?: string;
    status: USER_STATUS;
  };
  avatarURL: string;
  email: string;
  firstName: string;
  fullName: string;
  lastName: string;
  loginInfo: {
    providerID: string;
  };
  providerKey: string;

  userID: string;
  user_hash: string;
}

export type ChangeUserFormType = "new" | "edit";

export enum USER_STATUS {
  ADMIN = "admin",
  USER = "user",
  OBSERVER = "observer",
}
