import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./Login.scss";
import { AInput } from "src/helpers";
import { connect } from "react-redux";
import { Field, reduxForm, submit, isValid, formValueSelector } from "redux-form";
import { loginAttributes, formsNames, errorsTypes } from "src/constants";
import validate from "./validate";
import { getToken, getUserInfo } from "src/redux/user";
import { message } from "antd";
import { AppState } from "src/redux/AppState";
import { IProps, defaultProps } from "./interface";

const b = cn("login-form");

class LoginForm extends Component<IProps> {
  handleButtonClick = async () => {
    const { formValid, email, password, getToken, getUserInfo } = this.props;

    if (formValid) {
      // временный костыль для бана пользователей на фронте
      const bannedEmails = ["219af@bkmsk.ru", "104ef@bkmsk.ru", "102mm@bkmsk.ru", "202lb@bkmsk.ru", "geg@urvista.ru"];

      if (bannedEmails.indexOf(email) !== -1) {
        message.error("Пользователь не найден");
        return;
      }

      try {
        await getToken(email, password);
        getUserInfo();
      } catch {
        message.error("Пользователь не найден");
      }
    } else {
      message.error(errorsTypes.requiredFields);
    }
  };

  render() {
    const { handleSubmit, disabled } = this.props;

    return (
      <form className={b()} onSubmit={handleSubmit}>
        <h1 className={b("title")}>Авторизация</h1>
        <Field
          className={b("input")}
          name={loginAttributes["Электронная почта"]}
          title="Электронная почта"
          component={AInput}
          disabled={disabled}
        />
        <Field
          type="password"
          className={b("input")}
          name={loginAttributes["Пароль"]}
          title="Пароль"
          component={AInput}
          disabled={disabled}
          autocomplete="current-password"
          id="current-password"
        />
        <button type="submit" onClick={this.handleButtonClick} className={`custom-button ${b("button")}`}>
          Вход в систему
        </button>
      </form>
    );
  }
}

function mapStateToProps(state: AppState) {
  const { loading } = state.userReducer;
  const formValues = formValueSelector(formsNames["login"]);
  const email = formValues(state, loginAttributes["Электронная почта"]);
  const password = formValues(state, loginAttributes["Пароль"]);
  const formValid = isValid(formsNames["login"])(state);

  return {
    loading,
    email,
    password,
    formValid,
  };
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    onSubmit: () => dispatch(submit(formsNames["login"])),
    getToken: (email: any, password: any) => dispatch(getToken(email, password)),
    getUserInfo: () => dispatch(getUserInfo()),
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  validate,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(LoginForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as LoginForm };
