import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./ShortCommon.scss";
import { AInput, AAutoComplete, ARadio, ARadioGroup } from "src/helpers";
import { connect } from "react-redux";
import { Field, reduxForm, submit } from "redux-form";
import { attributes } from "src/constants";
import validate from "./validate";
import classNames from "classnames/dedupe";
import { IProps, IState, defaultProps } from "./interface";
import { AppState } from "src/redux/AppState";

const sf = cn("site-form");
const b = cn("short-common-form");

class ShortCommonForm extends Component<IProps, IState> {
  render() {
    const { handleSubmit, autoCompleteData, disabled, fieldsNames } = this.props;

    return (
      <form onSubmit={handleSubmit} className={classNames(sf(), b())}>
        <div className={sf("section-wrapper")}>
          <section className={sf("section")}>
            <h2 className={sf("title")}>Контакты и общая информация</h2>
            <div className={classNames(sf("inputs-grid"), b("inputs-grid"))}>
              <Field
                title="Фамилия:"
                name={fieldsNames[attributes["Фамилия"]]}
                options={autoCompleteData[fieldsNames[attributes["Фамилия"]]] || []}
                component={AAutoComplete}
                disabled={disabled}
                normalize={(v: string) => (v ? v.split("_")[0] : "")}
              />
              <Field
                title="Имя:"
                name={fieldsNames[attributes["Имя"]]}
                className={b("firstname")}
                options={autoCompleteData[fieldsNames[attributes["Имя"]]] || []}
                component={AAutoComplete}
                disabled={disabled}
              />
              <Field
                title="Отчество:"
                name={fieldsNames[attributes["Отчество"]]}
                className={b("middlename")}
                options={autoCompleteData[fieldsNames[attributes["Отчество"]]] || []}
                component={AAutoComplete}
                disabled={disabled}
              />

              <Field
                title="Номер телефона:"
                mask="+7 999 999-99-99"
                name={fieldsNames[attributes["Телефон"]]}
                className={b("phone")}
                component={AInput}
                disabled={disabled}
              />
              <Field
                title="Электронная почта:"
                name={fieldsNames[attributes["Электронная почта"]]}
                className={b("email")}
                component={AAutoComplete}
                options={autoCompleteData[fieldsNames[attributes["Электронная почта"]]] || []}
                disabled={disabled}
              />

              <div className={classNames(sf("radio", { gender: true }), b("gender"))}>
                <Field name={fieldsNames[attributes["Пол"]]} component={ARadioGroup}>
                  <label className={sf("radio-title")}>Пол:</label>
                  <Field
                    type="radio"
                    name={fieldsNames[attributes["Пол"]]}
                    value="M"
                    title="Мужчина"
                    component={ARadio}
                    disabled={disabled}
                  />
                  <Field
                    type="radio"
                    name={fieldsNames[attributes["Пол"]]}
                    value="F"
                    title="Женщина"
                    component={ARadio}
                    disabled={disabled}
                  />
                </Field>
              </div>
            </div>
          </section>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { form } = ownProps;
  const autoCompleteData = state.input.autoCompleteData[form] || {};

  return { autoCompleteData };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  validate,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(ShortCommonForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as ShortCommonForm };
