import { IFilesState } from "src/redux/files";
import { Action } from "redux";

interface IAction extends Action {
  fieldName: string;
}

export const addOtherFile = (state: IFilesState, action: IAction): IFilesState => {
  const newMaxFileId = state.otherFiles.maxFileId + 1;
  const newFilesArray =
    // ограничение в 5 файлов типа "Другой"
    // update: временно заменили на 1
    state.otherFiles.files.length < 1
      ? [...state.otherFiles.files, `${action.fieldName}_${newMaxFileId}`]
      : state.otherFiles.files;

  return {
    ...state,
    otherFiles: {
      maxFileId: newMaxFileId,
      files: newFilesArray,
    },
  };
};
