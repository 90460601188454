import { IUsersState } from "src/redux/users";
import { IUser } from "src/helpers/types/User";
import { Action } from "redux";

interface IAction extends Action {
  users: IUser[];
}

export const getUsersRequest = (state: IUsersState, action: IAction): IUsersState => {
  return {
    ...state,
    loading: true,
  };
};

export const getUsersSuccess = (state: IUsersState, action: IAction): IUsersState => {
  return {
    ...state,
    loading: false,
    users: action.users,
  };
};

export const getUsersFailure = (state: IUsersState, action: IAction): IUsersState => {
  return { ...state, loading: false };
};
