import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";

interface IAction extends Action {}

export const getEntryLinkRequest = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    loading: true,
    loadingWithoutLogo: true,
  };
};

export const getEntryLinkSuccess = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    loading: false,
    loadingWithoutLogo: false,
    error: false,
  };
};

export const getEntryLinkFailure = (state: IEntriesState, action: IAction): IEntriesState => {
  return { ...state, error: true, loading: false, loadingWithoutLogo: false };
};
