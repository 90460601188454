import React from "react";
import { cn } from "src/helpers/bem";
import "./SettingsContainer.scss";
import { Input } from "src/components";
import { Button, Checkbox, Divider } from "antd";
import { AInput } from "src/helpers";
import { connect } from "react-redux";
import { Field, reduxForm, submit } from "redux-form";
import { attributes, baseURL } from "src/constants";
import { AvatarUpload } from "src/components";
import validate from "./validate";
import { changePassword, changeCompanySettings } from "src/redux/user";
import { updateUser } from "src/redux/users";
import { IProps, IState, defaultProps } from "./interface";
import { AppState } from "src/redux/AppState";
import { USER_STATUS } from "src/helpers/types/User";

const b = cn("settings-container");

class SettingsContainer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      passwordInputsVisible: false,
      public_balance: false,
      reqActivate: false,
      avatarURL: "",
    };
  }

  static defaultProps = defaultProps;

  componentDidMount() {
    const { public_balance, reqActivate, userInfo } = this.props;
    this.setState({
      public_balance,
      reqActivate,
      avatarURL: userInfo.avatarURL
        ? userInfo.avatarURL.includes("base64")
          ? userInfo.avatarURL
          : `${baseURL}${userInfo.avatarURL.replace("https://buro.app", "")}`
        : "",
    });
  }

  handleSaveButtonClick = () => {
    const { changeCompanySettings, companyId, isAdminOrOwner, updateUser, userInfo } = this.props;
    const { public_balance, reqActivate, avatarURL } = this.state;

    if (isAdminOrOwner) {
      changeCompanySettings(companyId, { public_balance, reqActivate });
    }

    // @ts-ignore
    updateUser(userInfo.userID, { ...userInfo, avatarURL, companyId });
  };

  togglePasswordInputsVisible = () => {
    this.setState({ passwordInputsVisible: !this.state.passwordInputsVisible });
  };

  renderCheckboxes = () => {
    // const { public_balance, reqActivate } = this.state;
    const { public_balance } = this.state;

    return (
      <div className={b("checkboxes-wrapper")}>
        <Checkbox
          className={b("checkbox")}
          checked={!public_balance}
          onChange={() => this.setState({ public_balance: !public_balance })}
        >
          Скрывать баланс для пользователей
        </Checkbox>
        {/* <Checkbox
          className={b("checkbox")}
          checked={reqActivate}
          onChange={() => this.setState({ reqActivate: !reqActivate })}
        >
          Активировать ключ отдельно
        </Checkbox> */}
      </div>
    );
  };

  renderChangePassportForm = () => {
    const { changePassword } = this.props;
    const { passwordInputsVisible } = this.state;

    return (
      <form style={{ display: passwordInputsVisible ? "flex" : "none" }} className={b("password-form")}>
        <Field
          className={b("input")}
          name={attributes["Текущий пароль"]}
          component={AInput}
          type="password"
          title="Введите текущий пароль"
        />
        <Field
          className={b("input")}
          name={attributes["Новый пароль"]}
          component={AInput}
          type="password"
          title="Введите новый пароль"
        />
        <Field
          className={b("input")}
          name={attributes["Подтверждение нового пароля"]}
          component={AInput}
          type="password"
          title="Повторите новый пароль"
        />
        <button type="button" className="custom-button custom-button_large custom-button_long" onClick={changePassword}>
          Сохранить новый пароль
        </button>
      </form>
    );
  };

  render() {
    const { avatarURL } = this.state;
    const { email, isAdminOrOwner, lastName, firstName } = this.props;

    return (
      <div className={b()}>
        <Button type="link" size="large" className="modal-save" onClick={this.handleSaveButtonClick}>
          Сохранить
        </Button>
        <AvatarUpload
          url={avatarURL}
          onChange={(avatarURL) => this.setState({ avatarURL })}
          username={{ lastName, firstName }}
        />
        <Divider />
        {isAdminOrOwner && (
          <>
            {this.renderCheckboxes()}
            <Divider />
          </>
        )}

        {
          //@ts-ignore
          <Input className={b("input")} title="Электронная почта" value={email} disabled />
        }
        <button
          type="button"
          className="custom-button custom-button_large custom-button_long"
          onClick={this.togglePasswordInputsVisible}
        >
          Сменить пароль
        </button>
        {this.renderChangePassportForm()}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { email = "Неизвестный email" } = state.userReducer.user.user;
  const companyId = state.userReducer.user?.company?.id;
  const public_balance = state.userReducer.user?.public_balance;
  const reqActivate = state.userReducer.user?.company_settings?.reqActivate;
  const userInfo = Object.assign({}, state.userReducer.user?.user);
  //@ts-ignore
  delete userInfo.loginInfo; // не будем отправлять на бек
  const lastName = userInfo?.lastName;
  const firstName = userInfo?.firstName;
  const companyOwner = state.userReducer.user?.company?.owner;
  const userStatus = userInfo?.status;

  const isAdminOrOwner = companyOwner === email || userStatus === USER_STATUS.ADMIN;
  return { userInfo, email, isAdminOrOwner, companyId, public_balance, reqActivate, lastName, firstName };
};
// @ts-ignore
const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: () => dispatch(changePassword()),
    // @ts-ignore
    changeCompanySettings: (companyId, flags) => dispatch(changeCompanySettings(companyId, flags)),
    // @ts-ignore
    updateUser: (id, userData) => dispatch(updateUser(id, userData)),
    onSubmit: () => dispatch(submit("settings")),
  };
};

let connectedComponent: any = reduxForm({
  form: "settings",
  validate,
  initialValues: {
    [attributes["Текущий пароль"]]: "",
    [attributes["Новый пароль"]]: "",
    [attributes["Подтверждение нового пароля"]]: "",
  },
  //@ts-ignore
})(SettingsContainer);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

export { connectedComponent as SettingsContainer };
