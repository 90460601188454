export const errorsTypes = {
  requiredField: "Обязательное поле",
  requiredFields: "Проверьте правильность заполненных полей",
  requiredMainOkved: "Должен быть выбран основной вид деятельности",
  forbiddenSymbols: "Здесь есть запрещённые символы",
  emptyFields: "Здесь есть незаполненные поля",
  emptyForm: "Заполните хотя бы одно поле",
  forbiddenINN: "Неверный ИНН",
  forbiddenGRN: "Неверный ГРН",
  forbiddenOGRN: "Неверный ОГРН",
  forbiddenKPP: "Неверный КПП",
  forbiddenPhone: "Неверный номер",
  forbiddenMobilePhone: "Необходимо указать мобильный номер телефона",
  forbiddenEmail: "Укажите правильный адрес электронной почты",
  forbiddenDate: "Неверная дата",
  forbiddenEquityFormat: "Неверный формат доли",
  forbiddenEquitySum: "Должен быть равен размеру уставного капитала",
  forbiddenEquityPart: "Сумма размеров долей должна быть равна уставному капиталу",
  forbiddenEquityPercents: "Сумма долей учредителей не равна 100%",
  forbiddenPassport: "Неверный паспорт",
  invalidPassport: "Паспорт недействителен",
  forbiddenKP: "Неверный код подразделения",
  forbiddenSnils: "Неверный СНИЛС",
  forbiddenPasswordLength: "Длина пароля должна быть не меньше 4 символов",
  forbiddenPasswordSymbols: "Пароль должен содержать хотя бы одну цифру",
  forbiddenPasswordRegister: "Пароль должен содержать символы разных регистров",
  requiredOwners: "Необходимо добавить хотя бы одного учредителя",
  requiredChange: "Вы должны выбрать хотя бы один вид изменений",
  requiredChanges: "Вы должны внести изменения",
  failedSearchByOGRNorINN: "По данному ОГРН(ИП) / ИНН ничего не найдено",
  forbiddenSum: "Минимальная сумма пополнения — 10 000 ₽",
  invalidAddress: "Адреса нет в ФИАС",
  forbiddenSono: "Неверный код рег. органа",
  forbiddenDeliveryAddress: "Нужно указать адрес выездной идентификации",
};
