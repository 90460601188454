import React from "react";
import classNames from "classnames/dedupe";
import { cn } from "src/helpers/bem";
import { defaultProps, IProps, IState } from "./interface";
import "./ShortRegistration.scss";
import { Field, reduxForm, submit } from "redux-form";
import { attributes } from "src/constants";
import { AAutoComplete } from "src/helpers";
import validate from "src/forms/Registration/validate";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "src/redux/AppState";

const b = cn("short-registration-form");
const sf = cn("site-form");

class ShortRegistrationForm extends React.Component<IProps, IState> {
  static defaultProps = defaultProps;

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { className, handleSubmit, autoCompleteData, disabled, fieldsNames } = this.props;

    return (
      <form onSubmit={handleSubmit} className={classNames(b(), sf(), className)}>
        <div className={sf("section-wrapper")}>
          <section className={sf("section")}>
            <h2 className={sf("title")}>Адрес регистрации</h2>
            <Field
              placeholder="Введите адрес в свободной форме"
              name={fieldsNames[attributes["Полный адрес"]]}
              options={autoCompleteData[fieldsNames[attributes["Полный адрес"]]] || []}
              component={AAutoComplete}
              customBackfill={true}
              disabled={disabled}
            />
          </section>
        </div>
      </form>
    );
  }
}
const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const formName = ownProps["form"];
  const autoCompleteData = state.input.autoCompleteData[formName] || {};

  return { autoCompleteData };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(ShortRegistrationForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as ShortRegistrationForm };
