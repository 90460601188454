import { attributes, equityFormatToAttr } from "src/constants";
import { validatePercents, validateSimpleRatio, isFieldExist } from "src/helpers";
import { IProps } from "./interface";

export default function validate(values: Record<string, any>, props: IProps): Record<string, string> {
  const { equityFormat } = props;
  const equityAttr = equityFormatToAttr[equityFormat];
  let errors: Record<string, string> = {};

  errors = isFieldExist(equityAttr, values[equityAttr], errors);
  errors = isFieldExist(attributes["Размер доли"], values[attributes["Размер доли"]], errors, " ₽");
  switch (equityFormat) {
    case "percent":
      errors = validatePercents(values[equityAttr], errors);
      break;
    case "simple":
      errors = validateSimpleRatio(values[equityAttr], errors);
      break;
  }

  return errors;
}
