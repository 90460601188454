import React from "react";
import { cn } from "src/helpers/bem";
import "./ServiceBlock.scss";

const b = cn("service-block");

export const ServiceBlock = (props: any) => {
  return (
    <p
      className={`${props.className} ${b()} ${props.checked ? `${b({ checked: true })}` : ``} ${
        props.empty ? `${b({ empty: true })}` : ``
      }`}
    >
      {props.children}
    </p>
  );
};

ServiceBlock.defaultProps = {
  className: "",
  checked: "",
  empty: "",
};
