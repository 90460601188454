/* В этом файле описываются action и сервис для получения данных
 со справочника DADATA https://dadata.ru/api/find-party/#response */

import { inputConstants } from "./../input.constants";
import { attributes } from "src/constants";
import { message } from "antd";
import { dadataAPI } from "src/helpers";
import { IDadataPartyResponse } from "src/helpers/types/Dadata";

const endPointsTypes: any = {
  [attributes["ИНН"]]: "/findById/party",
};

export const loadDadataInfo = (inputName: string, inputValue: string): any => {
  const { DADATA_INFO_LOAD, DADATA_INFO_LOAD_SUCCESS, DADATA_INFO_LOAD_FAILED } = inputConstants;
  const request = () => ({ type: DADATA_INFO_LOAD });
  const success = (data: IDadataPartyResponse) => ({ type: DADATA_INFO_LOAD_SUCCESS, data, inputName });
  const failure = () => ({ type: DADATA_INFO_LOAD_FAILED });

  return async function (dispatch: any) {
    dispatch(request());
    try {
      const data: IDadataPartyResponse = await dadataAPI.post(`${endPointsTypes[inputName]}`, { query: inputValue });
      dispatch(success(data));
      return data;
    } catch (err: any) {
      message.error(`Не удалось загрузить данные от dadata ${err}`);
      dispatch(failure());
    }
  };
};
