import React, { Component } from "react";
import { Input } from "antd";
import { cn } from "src/helpers/bem";
import classNames from "classnames/dedupe";
import { Divider } from "antd";
import { Loader } from "src/components";
import { dadataAPI } from "src/helpers";
import "./BusinessSearch.scss";
import { ReactComponent as PinIcon } from "./images/pin-icon.svg";
import { ReactComponent as ClearIcon } from "./images/clear-icon.svg";
import fnsImage from "./images/image-1.jpg";
import fsgsImage from "./images/image-2.jpg";
import { IProps, IState } from "./interface";
import { CompanyStatusType, IDadataPartyResponse, IFounderShare, SmbCategoryType } from "src/helpers/types/Dadata";

const b = cn("business-search");
const sp = cn("site-page");

const colorToStatus = {
  ACTIVE: "green",
  LIQUIDATING: "orange",
  LIQUIDATED: "red",
  REORGANIZING: "orange",
};

const companyStatuses: Record<CompanyStatusType, string> = {
  ACTIVE: "Действующая организация",
  LIQUIDATING: "Организация в стадии ликвидации",
  LIQUIDATED: "Организация ликвидирована",
  REORGANIZING: "Организация в процессе реорганизации",
};

const companyCategories: Record<SmbCategoryType, string> = {
  MICRO: "Микропредприятие",
  SMALL: "Малое предприятие",
  MEDIUM: "Cреднее предприятие",
};

const getEquityValue = (info: IFounderShare) => {
  switch (info.type) {
    case "PERCENT":
      return `${info.value}%`;
    case "DECIMAL":
      return info.value;
    case "FRACTION":
      return `${info.numerator}/${info.denominator}`;
  }
};

const getSum = (sum: string) => {
  if (sum) {
    return `${sum.toLocaleString()} ₽`;
  }
  return "Неизвестная сумма";
};

export class BusinessSearch extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      searchQuery: "",
      currentCompany: undefined,
      emptyPage: true,
      loading: false,
    };
  }

  handleSearch = (value: string) => {
    this.setState({ searchQuery: value }, () => {
      if (value.length === 0) {
        this.setState({ emptyPage: true });
      } else if (value.length === 10 || value.length === 12 || value.length === 13 || value.length === 15) {
        this.getCompanyInfo();
      }
    });
  };

  handleClearIconClick = () => {
    this.setState({ searchQuery: "", emptyPage: true });
  };

  getCompanyInfo = async () => {
    const { searchQuery, emptyPage } = this.state;
    try {
      const data: IDadataPartyResponse = await dadataAPI.post("findById/party", { query: searchQuery });
      const newCompany = data["suggestions"][0]?.data;
      if (newCompany) {
        this.setState({ loading: true });
        setTimeout(
          () =>
            this.setState({ loading: false }, () => {
              if (emptyPage) {
                this.setState({ currentCompany: newCompany, emptyPage: !newCompany });
              } else {
                this.setState({ currentCompany: newCompany });
              }
            }),
          1000,
        );
      }
    } catch {}
  };

  renderSearch = () => {
    const { searchQuery, emptyPage } = this.state;

    return (
      <div className={b("search-wrapper")}>
        <Input
          placeholder="Можно ввести ИНН или ОГРН"
          className={classNames(sp("search"), b("search"))}
          onChange={(e) => this.handleSearch(e.target.value)}
          value={searchQuery}
          suffix={<ClearIcon onClick={this.handleClearIconClick} className={b("clear-icon")} />}
        />
        {!emptyPage && <div className={b("search-info")}>Актуальность данных: 1-3 дня</div>}
      </div>
    );
  };

  renderControls = () => {
    return <div className={sp("controls")}>{this.renderSearch()}</div>;
  };

  render() {
    const { currentCompany, emptyPage, loading } = this.state;
    const fnsCode = currentCompany?.authorities?.fts_report?.code;

    return emptyPage ? (
      <div className={b("empty-content")}>
        {this.renderSearch()}
        {loading && <Loader />}
        <p className={b("empty-description")}>
          Поиск данных о компании или индивидуальном предпринимателе по&nbsp;реестрам ФНС, Росстата и другим источникам
        </p>
        <div className={b("images-wrapper")}>
          <img src={fnsImage} alt="Федеральная налоговая служба." width="39" height="40" />
          <img src={fsgsImage} alt="Федеральная служба государственной статистики." width="34" height="40" />
        </div>
      </div>
    ) : (
      <div className={b()}>
        {this.renderControls()}
        {loading && <Loader />}
        <div className={b("content")}>
          <div className={b("main-info")}>
            <div className={b("column")}>
              <div className={b("company-shortname")}>{currentCompany!.name?.short_with_opf || "Неизвестно"}</div>
              <div className={b("company-fullname")}>
                {currentCompany!.name?.full_with_opf || "Неизвестное полное название"}
              </div>
            </div>
            <div className={b("column")}>
              {currentCompany!.state?.status && (
                <div className={b("company-status", { [colorToStatus[currentCompany!.state.status]]: true })}>
                  {companyStatuses[currentCompany!.state.status]}
                </div>
              )}
              <div className={b("company-size")}>
                {currentCompany!.documents?.smb &&
                  `${
                    companyCategories[currentCompany!.documents.smb.category] || "Неизвестный тип предприятия"
                  } с ${new Date(currentCompany!.documents.smb.issue_date)?.toLocaleDateString("ru-RU")}`}
              </div>
            </div>
          </div>
          <Divider className={b("divider")} />
          <div className={b("full-info")}>
            <div className={b("column")}>
              {currentCompany!.type !== "INDIVIDUAL" && currentCompany!.management && (
                <div className={b("section")}>
                  <div className={b("subtitle")}>Руководство</div>
                  <div className={b("text")}>
                    {`${
                      (currentCompany!.management.post &&
                        `${currentCompany!.management.post[0]}${currentCompany!.management.post
                          .toLowerCase()
                          .slice(1)}`) ||
                      "Неизвестная должность"
                    } — `}
                    <span className={b("name")}>{`${
                      currentCompany!.management.name?.toLowerCase() || "Неизвестно"
                    }`}</span>
                  </div>
                </div>
              )}
              {currentCompany!.capital?.value && (
                <div className={b("section")}>
                  <div className={b("subtitle")}>Уставный капитал</div>
                  <div className={b("text")}>{getSum(currentCompany!.capital.value)}</div>
                </div>
              )}
              {currentCompany!.type !== "INDIVIDUAL" && currentCompany!.founders && (
                <div className={b("section")}>
                  <div className={b("subtitle")}>Участники</div>
                  <table className={b("table-wrapper")}>
                    <tbody>
                      {currentCompany!.founders.map((it) => (
                        <tr className={b("table-row")}>
                          <td className={b("table-cell", { name: true })}>
                            {it.type === "PHYSICAL" && it.fio?.source ? (
                              <span className={b("name")}>{it.fio.source.toLowerCase()}</span>
                            ) : it.name ? (
                              it.name
                            ) : (
                              "Неизвестное имя участника"
                            )}
                          </td>
                          <td className={b("table-cell")}>{getEquityValue(it.share)}</td>
                          <td className={b("table-cell")}>{it.value || ""}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {currentCompany!.type !== "INDIVIDUAL" && currentCompany!.address?.data?.source && (
                <div className={b("section")}>
                  <div className={b("subtitle")}>Адрес местонахождения</div>
                  <div className={b("text")}>
                    {currentCompany!.address.data.source}{" "}
                    {currentCompany!.address.data.geo_lat && currentCompany!.address.data.geo_lon && (
                      <a
                        className={b("address-link")}
                        href={`https://yandex.ru/maps/?whatshere[point]=${currentCompany!.address.data.geo_lon},${
                          currentCompany!.address.data.geo_lat
                        }&whatshere[zoom]=17`}
                        target="blank"
                      >
                        <PinIcon />
                      </a>
                    )}
                  </div>
                </div>
              )}

              {currentCompany!.okveds && (
                <div className={b("section")}>
                  <div className={b("subtitle")}>Виды деятельности</div>
                  <div className={b("okveds-grid")}>
                    {currentCompany!.okveds.map((it, index) => (
                      <>
                        <div className={b("okved", { main: it.main })}>{it.code}</div>
                        <div className={b("okved", { main: it.main })}>
                          {`${it.name}${it.main ? " — основной" : ""}`}
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className={b("column")}>
              <div className={b("section")}>
                <div className={b("subtitle")}>Регистрационные данные</div>
                {currentCompany!.ogrn && (
                  <>
                    <div className={b("text")}>{`ОГРН ${currentCompany!.ogrn}`}</div>
                    {currentCompany!.ogrn_date && (
                      <div className={b("description")}>{`присвоен: ${new Date(
                        currentCompany!.ogrn_date,
                      ).toLocaleDateString("ru-RU")}`}</div>
                    )}
                  </>
                )}
                {currentCompany!.inn && (
                  <div className={b("text", { "with-margin": true })}>{`ИНН ${currentCompany!.inn}`}</div>
                )}
                {currentCompany!.type !== "INDIVIDUAL" && currentCompany!.kpp && (
                  <>
                    <div className={b("text", { "with-margin": true })}>{`КПП ${currentCompany!.kpp}`}</div>
                    {currentCompany!.authorities?.fts_report?.name && (
                      <div className={b("description")}>
                        {fnsCode ? (
                          <a
                            href={`https://www.nalog.ru/rn${fnsCode[0]}${fnsCode[1]}/ifns/imns${fnsCode[0]}${fnsCode[1]}_${fnsCode[2]}${fnsCode[3]}/`}
                            target="blank"
                          >{`${currentCompany!.authorities.fts_report.name} (код ${fnsCode})`}</a>
                        ) : (
                          currentCompany!.authorities.fts_report.name
                        )}
                      </div>
                    )}
                  </>
                )}
                {currentCompany!.documents?.pf_registration?.number && currentCompany!.authorities?.pf?.name && (
                  <div className={b("info-block")}>
                    <div className={b("text")}>{`ПФР: ${currentCompany!.documents.pf_registration.number}`}</div>
                    <div className={b("description")}>{currentCompany!.authorities.pf.name}</div>
                  </div>
                )}
                {currentCompany!.documents?.sif_registration?.number && currentCompany!.authorities?.sif?.name && (
                  <div className={b("info-block")}>
                    <div className={b("text")}>{`ФСС: ${currentCompany!.documents?.sif_registration?.number}`}</div>
                    <div className={b("description")}>{currentCompany!.authorities.sif.name}</div>
                  </div>
                )}
              </div>
              {(currentCompany!.okpo ||
                currentCompany!.opf?.code ||
                currentCompany!.oktmo ||
                currentCompany!.okato) && (
                <div className={b("section")}>
                  <div className={b("subtitle")}>Коды Росстата</div>
                  {currentCompany!.okpo && <div className={b("text")}>{`ОКПО ${currentCompany!.okpo}`}</div>}
                  {currentCompany!.opf?.code && (
                    <div className={b("text", { "with-margin": true })}>{`ОКОПФ ${currentCompany!.opf.code}`}</div>
                  )}
                  {currentCompany!.address?.data?.oktmo && (
                    <div className={b("text", { "with-margin": true })}>{`ОКТМО ${
                      currentCompany!.address.data.oktmo
                    }`}</div>
                  )}
                  {currentCompany!.address?.data?.okato && (
                    <div className={b("text", { "with-margin": true })}>{`ОКАТО ${
                      currentCompany!.address.data.okato
                    }`}</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
