import { billsConstants } from "./../bills.constants";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { getUserInfo } from "src/redux/user";
import { message } from "antd";

export const changeBalance = (companyId: number, action: string, sum: number, comment: string) => {
  const { CHANGE_BALANCE_REQUEST, CHANGE_BALANCE_SUCCESS, CHANGE_BALANCE_FAILURE } = billsConstants;
  const request = () => ({ type: CHANGE_BALANCE_REQUEST });
  const success = () => ({ type: CHANGE_BALANCE_SUCCESS });
  const failure = () => ({ type: CHANGE_BALANCE_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      await API.post(`${endpoints.bills.changeBalance}/${companyId}`, { companyId, action, sum, comment });
      dispatch(success());
      dispatch(getUserInfo()); // заодно нужно обновить userReducer в сторе и куки
      message.success("Баланс изменён");
    } catch (err: any) {
      message.error(`Ошибка при изменении баланса: ${err}`);
      dispatch(failure());
    }
  };
};
