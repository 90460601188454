import { change, formValueSelector } from "redux-form";
import { store } from "src/redux/store";
import { attributes, entriesTypes, formsNames } from "src/constants";
import { cloneDeep } from "lodash";
import { serverValuesToMasksFormat } from "./";

export const detectGender = (value: string, fioPart: string, formName: string, dispatch: Function) => {
  const state = store.getState();
  const changeFieldValue = (form: string, field: string, value: string) => dispatch(change(form, field, value));

  const autoCompleteData = state.input.autoCompleteData[formName];
  const allOptions: Array<Record<string, any>> = autoCompleteData && autoCompleteData[fioPart];

  const gender = allOptions?.filter((it) => it.value === value)[0]?.data?.gender;
  if (gender) {
    changeFieldValue(formName.replace("common", "passport"), attributes["Пол"], gender[0]);
  }
};

//  на странице изм. ООО адрес регистрации клиента (заявителя) должнен подставляться
//  в форму, названия полей которой отличается от тех, что на остальных страницах,
//  поэтому необходим словарь соответствия названий полей
const executiveRegistrationFieldsNames = {
  [attributes["Регион (сокр.)"]]: "executive_region",
  [attributes["Полный адрес"]]: "executive_address",
  [attributes["Субъект"]]: "executive_subjectCode",
  [attributes["Код рег. органа"]]: "executive_sono_area",
  [attributes["Код терр. ФНС"]]: "executive_sono",
  [attributes["Муниципальное образование (верхнее)"]]: "executive_area_abbr",
  [attributes["Наименование муниципального образования (верхнее)"]]: "executive_areaCode",
  [attributes["Муниципальное образование (нижнее)"]]: "executive_city_abbr",
  [attributes["Наименование муниципального образования (нижнее)"]]: "executive_city",
  [attributes["Населенный пункт"]]: "executive_nasel_abbr",
  [attributes["Наименование населенного пункта"]]: "executive_naselPunkt",
  [attributes["Элемент улично-дорожной сети"]]: "executive_street_abbr",
  [attributes["Наименование элемента улично-дорожной сети"]]: "executive_street",
  [attributes["Здание/сооружение (первый столбец)"]]: "executive_house_abbr",
  [attributes["Номер здания/сооружения (первый столбец)"]]: "executive_home",
  [attributes["Квартира/офис"]]: "executive_appartment_abbr",
  [attributes["Номер квартиры/офиса"]]: "executive_flat",
  [attributes["Регион (полн.)"]]: "executive_region_abbr_full",
  [attributes["Регион (сокр.)"]]: "executive_region",
  [attributes["Наименование региона"]]: "executive_region_abbr",
};

// меняет formattedEntry в зависимости от выбранной фамилии в форме Common
// (берёт данные из autoCompleteData)
export const detectDataByLastName = (value: string, fioPart: string, formName: string, dispatch: Function) => {
  const state = store.getState();
  const oldFormattedEntry = cloneDeep(state.entries.formattedEntry);
  // Если в базе присутствуют данные о клиенте, value будет состоять из
  // фамилии и id; их нужно отделить друг от друга
  const valueParts = value.split("_");

  const autoCompleteData: Array<Record<string, any>> = state.input?.autoCompleteData[formName][fioPart];
  if (valueParts.length > 1) {
    const lastName = valueParts[0];
    const id = +valueParts[1];

    const newData = Object.assign({}, autoCompleteData.filter((it) => it.lastName === lastName && it.id === id)[0]);

    if (newData) {
      const { currentEntry } = state.entries;

      //@ts-ignore
      if (currentEntry["entryType"] === entriesTypes["ИзмененияООО"]) {
        // при помощи словаря зписываем данные о регистрации клиента
        // в поля с нужными именами (которые начинаются с executive_)
        Object.values(executiveRegistrationFieldsNames).forEach((key) => {
          const withoutExecutiveKey = key.replace("executive_", "");
          newData[key] = newData[withoutExecutiveKey];
          // чтобы они не перезатёрли поля в форме юр. адреса, удаляем их
          delete newData[withoutExecutiveKey];
        });
      }

      const newFormattedEntry = serverValuesToMasksFormat(Object.assign({}, oldFormattedEntry, newData));
      const formNameParts = formName.split("-");

      const fillForms = (suffix = "") => {
        const changeFieldValue = (form: string, field: string, value: string) => dispatch(change(form, field, value));

        // common-form
        const common = `${formsNames["common"]}${suffix}`;
        changeFieldValue(common, attributes["Фамилия"], newFormattedEntry[attributes["Фамилия"]]);
        changeFieldValue(common, attributes["Имя"], newFormattedEntry[attributes["Имя"]]);
        changeFieldValue(common, attributes["Отчество"], newFormattedEntry[attributes["Отчество"]]);
        changeFieldValue(common, attributes["Электронная почта"], newFormattedEntry[attributes["Электронная почта"]]);
        changeFieldValue(
          common,
          attributes["Номер телефона для ФНС"],
          newFormattedEntry[attributes["Номер телефона для ФНС"]],
        );
        if (suffix.indexOf("eio") === -1) {
          changeFieldValue(common, attributes["Телефон"], newFormattedEntry[attributes["Телефон"]]);
        }

        // passport-form
        const passport = `${formsNames["passport"]}${suffix}`;
        changeFieldValue(
          passport,
          attributes["Серия и номер паспорта"],
          newFormattedEntry[attributes["Серия и номер паспорта"]],
        );
        changeFieldValue(passport, attributes["Пол"], newFormattedEntry[attributes["Пол"]]);
        changeFieldValue(
          passport,
          attributes["Дата выдачи паспорта"],
          newFormattedEntry[attributes["Дата выдачи паспорта"]],
        );
        changeFieldValue(passport, attributes["Код подразделения"], newFormattedEntry[attributes["Код подразделения"]]);
        changeFieldValue(passport, attributes["Кем выдан паспорт"], newFormattedEntry[attributes["Кем выдан паспорт"]]);
        changeFieldValue(passport, attributes["Дата рождения"], newFormattedEntry[attributes["Дата рождения"]]);
        changeFieldValue(passport, attributes["Место рождения"], newFormattedEntry[attributes["Место рождения"]]);
        changeFieldValue(passport, attributes["ИНН"], newFormattedEntry[attributes["ИНН"]]);
        if (suffix.indexOf("eio") === -1) {
          changeFieldValue(passport, attributes["СНИЛС"], newFormattedEntry[attributes["СНИЛС"]]);
        }
        if (!suffix) {
          // нужно сохранить состояние радио-кнопки, потому что в дальнейшем данные обнулятся,
          // а это значение с сервера не приходит
          const passportFormValues = formValueSelector(formsNames["passport"]);
          const inostr = passportFormValues(state, attributes["иностранец"]);
          changeFieldValue(passport, attributes["иностранец"], inostr);
        }
      };

      if (formNameParts.some((it: string) => it.startsWith("owner"))) {
        const ownerId = +formNameParts[formNameParts.length - 1].replace(/[^\d]/g, "");
        const suffix = `-${formsNames["owner"]}${ownerId}`;
        fillForms(suffix);
      } else if (formNameParts.some((it: string) => it.startsWith("eio"))) {
        const suffix = `-${formsNames["eio"]}`;
        fillForms(suffix);
      } else {
        fillForms();
      }
    }
  }
};
