import React from "react";
import classNames from "classnames/dedupe";
import { cn } from "src/helpers/bem";
import "./User.scss";
import { AInput, ASelect, AAutoComplete, ARadio, ARadioGroup } from "src/helpers";
import { connect } from "react-redux";
import { Field, reduxForm, submit, formValueSelector, isValid, change } from "redux-form";
import { attributes, baseURL, formsNames } from "src/constants";
import { getUsers, addUser, updateUser, changeUserPassword, deactivateUser } from "src/redux/users";
import { getUserInfo } from "src/redux/user";
import { AvatarUpload } from "src/components";
import { getInitialValues } from "./initialValues";
import validate from "./validate";
import { IProps, IState } from "./interface";
import { AppState } from "src/redux/AppState";

const sf = cn("site-form");
const b = cn("user-form");

class UserForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      avatarURL: "",
    };
  }

  componentDidMount() {
    this.updateValues();
  }

  componentDidUpdate(prevProps: IProps) {
    if (!prevProps.opened && this.props.opened) {
      this.updateValues();
    }
  }

  updateValues = () => {
    const { changeFieldValue, userInfo } = this.props;

    if (userInfo) {
      changeFieldValue(attributes["Фамилия"], userInfo.lastName);
      changeFieldValue(attributes["Имя"], userInfo.firstName);
      changeFieldValue(attributes["Отчество"], userInfo.middleName);
      changeFieldValue(attributes["Электронная почта"], userInfo.email);
      changeFieldValue(attributes["Роль"], userInfo.status);
      changeFieldValue(attributes["Пароль"], "");
      this.setState({
        avatarURL: userInfo.avatarURL
          ? userInfo.avatarURL.includes("base64")
            ? userInfo.avatarURL
            : `${baseURL}${userInfo.avatarURL.replace("https://buro.app", "")}`
          : "",
      });
    }
  };

  handleSubmit = async () => {
    const {
      onFinal,
      formType,
      formValid,
      addUser,
      updateUser,
      changeUserPassword,
      getUsers,
      firstName,
      middleName,
      lastName,
      email,
      companyId,
      status,
      password,
      userInfo,
      currentUserId,
      getUserInfo,
    } = this.props;
    const { avatarURL } = this.state;

    if (formValid) {
      // добавление нового пользователя
      if (formType === "new") {
        await addUser({
          userID: email,
          firstName,
          lastName,
          middleName,
          fullName: `${lastName} ${firstName}${middleName ? ` ${middleName}` : ""}`,
          email,
          avatarURL,
          companyId,
          status,
          password,
          activated: true,
        });
      } else {
        // редактирование существующего пользователя
        if (password) {
          await changeUserPassword(userInfo.email, password);
        }
        await updateUser(userInfo.userID, {
          userID: userInfo.userID,
          firstName,
          lastName,
          middleName,
          fullName: `${lastName} ${firstName} ${middleName}`.trim(),
          email,
          avatarURL,
          status,
          companyId: userInfo.companyId,
          activated: userInfo.activated,
        });
      }
      await getUsers({});
      //@ts-ignore
      if (userInfo.userID === currentUserId) {
        getUserInfo();
      }
      onFinal();
    }
  };

  handleDelete = async () => {
    const { deactivateUser, getUsers, onFinal, userInfo } = this.props;
    await deactivateUser(userInfo.userID);
    await getUsers({});
    onFinal();
  };

  renderRadio = () => {
    const { disabled } = this.props;

    return (
      <>
        <Field name={attributes["Роль"]} component={ARadioGroup} buttonStyle="solid">
          <Field
            type="radio"
            name={attributes["Роль"]}
            value="admin"
            title="Администратор"
            component={ARadio}
            disabled={disabled}
          />
          <Field
            type="radio"
            name={attributes["Роль"]}
            value="user"
            title="Пользователь"
            component={ARadio}
            disabled={disabled}
          />
          <Field
            type="radio"
            name={attributes["Роль"]}
            value="observer"
            title="Наблюдатель"
            component={ARadio}
            disabled={disabled}
          />
        </Field>
      </>
    );
  };

  render() {
    const { avatarURL } = this.state;
    const { formType, isAdmin, handleSubmit, autoCompleteData, companies, disabled, lastName, firstName } = this.props;
    return (
      <form onSubmit={handleSubmit} className={`${sf()} ${b()}`}>
        <div className={sf("section-wrapper")}>
          <section className={sf("section")}>
            <div className={sf("inputs-grid")}>
              <Field
                title="Фамилия:"
                name={attributes["Фамилия"]}
                options={autoCompleteData[attributes["Фамилия"]] || []}
                component={AAutoComplete}
                disabled={disabled}
              />
              <Field
                title="Имя:"
                name={attributes["Имя"]}
                className={b("firstname")}
                options={autoCompleteData[attributes["Имя"]] || []}
                component={AAutoComplete}
                disabled={disabled}
              />
              <Field
                title="Отчество:"
                name={attributes["Отчество"]}
                className={b("middlename")}
                options={autoCompleteData[attributes["Отчество"]] || []}
                component={AAutoComplete}
                disabled={disabled}
              />
              <Field
                title="Электронная почта:"
                name={attributes["Электронная почта"]}
                className={b("email")}
                component={AAutoComplete}
                options={autoCompleteData[attributes["Электронная почта"]] || []}
                disabled={disabled}
              />
              <div className={b("upload-field")}>
                <AvatarUpload
                  disabled={disabled}
                  url={avatarURL}
                  onChange={(avatarURL) => this.setState({ avatarURL })}
                  username={{ lastName, firstName }}
                />
              </div>
              <div className={b("status-radio")}>
                <label className={classNames(sf("radio-title"), b("radio-title"))}>Роль:</label>
                {this.renderRadio()}
              </div>
              {/* {formType === "edit" && ( */}
              <Field
                type="password"
                className={b("input")}
                name={attributes["Пароль"]}
                title={formType === "edit" ? "Новый пароль" : "Пароль"}
                component={AInput}
                disabled={disabled}
                autocomplete="new-password"
              />
              {isAdmin && formType === "new" && (
                <Field
                  name={attributes["ID компании"]}
                  className={b("companies-select")}
                  component={ASelect}
                  title="Компания:"
                  options={companies?.map((company) => ({
                    value: company.id,
                    title: company.name,
                  }))}
                  filterOption={(input: string, option: any) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={disabled}
                />
              )}
            </div>
          </section>
        </div>
        <div className={b("buttons-wrapper")}>
          <button
            type="submit"
            className={`custom-button ${b("submit-button")}`}
            onClick={this.handleSubmit}
            disabled={disabled}
          >
            Сохранить
          </button>
          {formType === "edit" && (
            <button className={`custom-button ${b("delete-button")}`} onClick={this.handleDelete} disabled={disabled}>
              Удалить
            </button>
          )}
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { form, data } = ownProps;
  const autoCompleteData = state.input.autoCompleteData[form] || {};
  const initialValues = data ? getInitialValues(data) : {};
  const formValid = isValid(form)(state);
  const formValues = formValueSelector(form);
  const lastName = formValues(state, attributes["Фамилия"]);
  const firstName = formValues(state, attributes["Имя"]);
  const middleName = formValues(state, attributes["Отчество"]);
  const email = formValues(state, attributes["Электронная почта"]);
  const status = formValues(state, attributes["Роль"]);
  const companyId = formValues(state, attributes["ID компании"]) || state.userReducer.user?.company?.id;
  const password = formValues(state, attributes["Пароль"]);

  const currentUserId = state.userReducer.user?.user?.userID;

  return {
    autoCompleteData,
    formValid,
    initialValues,
    lastName,
    firstName,
    middleName,
    email,
    companyId,
    status,
    password,
    currentUserId,
  };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    onSubmit: () => dispatch(submit(form)),
    changeFieldValue: (field: any, value: any) => dispatch(change(form, field, value)),
    addUser: (userData: any) => dispatch(addUser(userData)),
    updateUser: (id: any, userData: any) => dispatch(updateUser(id, userData)),
    getUsers: () => dispatch(getUsers({})),
    changeUserPassword: (id: any, newPassword: any) => dispatch(changeUserPassword(id, newPassword)),
    deactivateUser: (id: any) => dispatch(deactivateUser(id)),
    getUserInfo: () => dispatch(getUserInfo()),
  };
};

let connectedComponent: any = reduxForm({
  validate,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(UserForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = {
  form: formsNames["user"],
};

export { connectedComponent as UserForm };
