import { IForm } from "src/helpers/types/Form";
import { formsNames } from "src/constants";
import { attributes } from "src/constants";
import { EntryType } from "src/helpers/types/Entry";

export interface IProps extends IForm {
  className?: string;
  data: Record<string, any>;
  changeFieldValue: (form: string, field: string, value: string) => Promise<void>;
  type: EntryType;
  showTitle: boolean;
  fieldsNames: Record<string, string>;
}

export const defaultProps = {
  form: formsNames["INNandOGRN"],
  type: "ip",
  showTitle: false,
  fieldsNames: {
    [attributes["ОГРНИП"]]: attributes["ОГРНИП"],
    [attributes["ИНН"]]: attributes["ИНН"],
    [attributes["Код рег. органа"]]: attributes["Код рег. органа"],
  },
};
