import { IUserState } from "src/redux/user";

export const signUpRequest = (state: IUserState): IUserState => {
  return {
    ...state,
    loading: true,
  };
};

export const signUpSuccess = (state: IUserState): IUserState => {
  return {
    ...state,
    loading: false,
  };
};

export const signUpFailure = (state: IUserState): IUserState => {
  return { ...state, loading: false };
};
