import React, { Component } from "react";
import classNames from "classnames/dedupe";
import { endpoints } from "src/constants";
import { cn } from "src/helpers/bem";
import { API } from "src/helpers";
import "./UploadDocs.scss";
import { Loader } from "src/components";
import { FilesForm } from "src/forms";
import { connect } from "react-redux";
import Countdown from "react-countdown";
import { getSimplifiedEntry } from "src/redux/entries";
import { loadSimplifiedEntry } from "src/helpers";
import { Helmet } from "react-helmet";
import { message } from "antd";
import { AppState } from "src/redux/AppState";
import { getFileFields } from "./helpers";
import { IProps } from "./interface";

const b = cn("upload-page");
const ep = cn("entry-page");

class UploadDocs extends Component<IProps> {
  state = {
    isActivated: false,
  };

  componentWillMount() {
    const { getSimplifiedEntry } = this.props;

    loadSimplifiedEntry(getSimplifiedEntry);
  }

  componentWillUnmount() {
    message.destroy();
  }

  handleComplete = () => {
    message.error("Ссылка больше не действительна. Пожалуйста, запросите новую");
  };

  handleSubmit = async () => {
    const { currentId, currentToken } = this.props;

    try {
      message.loading("Происходит активация КЭП", 1000);
      await API.post(`${endpoints.entries.activateByToken}?id=${currentId}&token=${currentToken}`);
      message.destroy();
      message.success("Активация прошла успешно");
    } catch {
      message.destroy();
      message.error("Не удалось активировать. Пожалуйста, убедитесь, что все файлы загружены");
    }
  };

  render() {
    const { currentToken, loading, loadingWithoutLogo, wasLoaded, fullName, expired } = this.props;

    return (
      <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </Helmet>
        <div className={classNames(b(), ep())}>
          <Helmet>
            <title>Загрузка документов</title>
          </Helmet>
          {loading && <Loader hideLogo={loadingWithoutLogo} />}
          <div className={b("content-wrapper")}>
            <div className={b("name")}>{fullName}</div>
            {expired && (
              <div>
                Ссылка действительна в течение{" "}
                <Countdown date={Date.now() + Number(expired)} onComplete={this.handleComplete} />
              </div>
            )}
            <FilesForm
              fileFields={getFileFields(currentToken)}
              loading={loading}
              wasLoaded={wasLoaded}
              showTitle={false}
              disabled={false}
              disabledTooltipVisible={false}
            />
            <button className="custom-button" onClick={this.handleSubmit}>
              Активировать
            </button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { loading, loadingWithoutLogo, wasLoaded, error, simplifiedEntry } = state.entries;
  const currentId = simplifiedEntry?.id;
  const currentToken = simplifiedEntry?.token;
  const expired = simplifiedEntry?.expired;
  let fullName = "";

  if (simplifiedEntry) {
    const { firstName, middleName, lastName } = simplifiedEntry;
    fullName = `${lastName ? `${lastName} ` : ""}${firstName ? `${firstName} ` : ""}${middleName ? middleName : ""}`;
  }

  return {
    error,
    loading,
    loadingWithoutLogo,
    wasLoaded,
    currentId,
    currentToken,
    fullName,
    expired,
  };
};

const mapDispatchToProps = {
  getSimplifiedEntry,
};

// @ts-ignore
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(UploadDocs);

export { connectedComponent as UploadDocs };
