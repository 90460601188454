import { cloneDeep } from "lodash";
import { IFilesState } from "src/redux/files";
import { Action } from "redux";

interface IAction extends Action {
  requestAttribute: string;
}

export const uploadFileRequest = (state: IFilesState, action: IAction): IFilesState => {
  return {
    ...state,
    loading: true,
  };
};

export const uploadFileSuccess = (state: IFilesState, action: IAction): IFilesState => {
  //ПРИ ЗАГРУЗКЕ ФАЙЛА НА СЕРВЕР, НУЖНО ОБНОВИТЬ СООТВЕТСТВУЮЩИЙ АТРИБУТ ДЛЯ ПОСЛЕДУЮЩЕЙ ВАЛИДАЦИИ, ЧТО ФАЙЛ БЫЛ ЗАГРУЖЕН
  const { requestAttribute } = action;
  const loaded = cloneDeep(state.loaded);
  loaded[requestAttribute] = true;

  const validateErrors = cloneDeep(state.validateErrors);
  validateErrors[requestAttribute] = "";

  return {
    ...state,
    loading: false,
    error: false,
    loaded,
    validateErrors,
  };
};

export const uploadFileFailure = (state: IFilesState, action: IAction): IFilesState => {
  return {
    ...state,
    loading: false,
    error: true,
  };
};
