import { filesConstants } from "./../files.constants";
import { message } from "antd";
import { parseParams, API } from "src/helpers";
import { endpoints } from "src/constants";
import { changeFormattedEntryAttribute } from "src/redux/entries";
import { addOtherFile } from "./";

//////////////////////// ЗАГРУЗКА ФАЙЛА НА СЕРВЕР ///////////////////////////
export const uploadFile = (
  fileType: string,
  entryId: string | number,
  file: any,
  requestAttribute: string,
  params: any,
) => {
  // Файл может находиться в группе, тогда будет иметь формат drug_{id}
  const fileInGroup = requestAttribute.match(/drug(13|14)?_\d/g);
  const initialRequestAttr = requestAttribute;
  const currentRequestAttr = fileInGroup ? initialRequestAttr.split("_")[0] : initialRequestAttr;

  const { FILE_UPLOAD_REQUEST, FILE_UPLOAD_SUCCESS, FILE_UPLOAD_FAILURE } = filesConstants;
  const request = () => ({ type: FILE_UPLOAD_REQUEST });
  const success = (requestAttribute: string) => ({ type: FILE_UPLOAD_SUCCESS, requestAttribute });
  const failure = () => ({ type: FILE_UPLOAD_FAILURE });

  const data = new FormData();
  data.append(currentRequestAttr, file.originFileObj);
  const parsedParams = parseParams(params);
  const suffix = params ? `_${params["n"]}` : "";

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      const url =
        fileType === "fastUpload"
          ? `${endpoints.files["fastUpload"]}${parsedParams}&id=${entryId}`
          : // @ts-ignore
            `${endpoints.files[fileType as string]}/${entryId}${parsedParams}${
              fileInGroup ? `?grouped=${currentRequestAttr}` : ""
            }`;

      const res: { filename: string } = await API.post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      dispatch(success(`${currentRequestAttr}${suffix}`));
      dispatch(changeFormattedEntryAttribute(`file_${initialRequestAttr}${suffix}`, res["filename"]));

      if (fileInGroup) {
        // мы только что заполнили последнее существующее поле drug_{id} в state.files.otherFiles
        // и сейчас добавляем ещё одно, пустое
        dispatch(addOtherFile(currentRequestAttr));
      }

      // if (currentRequestAttr === "passport") {
      //   if (res[`file_${currentRequestAttr}`]?.items?.length) {
      //     fillFormByRecognition(
      //       currentRequestAttr,
      //       dispatch,
      //       res[`file_${currentRequestAttr}`].items[0]["fields"],
      //       params
      //     );
      //   } else {
      //     dispatch(alertError("Не удалось распознать данные"));
      //   }
      // }

      return res;
    } catch (err) {
      // @ts-ignore
      if (err.message === "ResizeObserver loop limit exceeded") {
        // @ts-ignore
        err["status"] = "uploaded";
        return err;
      }

      message.error(`Не удалось загрузить файл ${err}`);
      dispatch(failure());
    }
  };
};
