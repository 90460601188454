import React, { FC } from "react";
import classNames from "classnames/dedupe";
import { cn } from "src/helpers/bem";
import { Props } from "./RetryBlockProps";
import { Button, Typography } from "antd";
import "./RetryBlock.scss";

const b = cn("retry-block");
const { Text } = Typography;

export const RetryBlock: FC<Props> = (props) => {
  const { className, text, retryCallback, centered } = props;

  return (
    <div className={classNames(b({ centered }), className)}>
      {text && <Text className={b("text")}>{text}</Text>}
      {retryCallback && (
        <Button className={b("btn")} onClick={retryCallback}>
          Попробовать снова
        </Button>
      )}
    </div>
  );
};
