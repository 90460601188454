import React from "react";
import "./Loader.scss";
import { cn } from "src/helpers/bem";
import { IProps, defaultProps } from "./interface";

const b = cn("site-loader");

export const Loader = (props: IProps) => {
  const { fixed, hideLogo } = props;
  return <div className={b()}>{!hideLogo && <div className={b("item", { fixed })} />}</div>;
};

Loader.defaultProps = defaultProps;
