import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import classNames from "classnames/dedupe";
import "./CommonOOO.scss";
import {
  AInput,
  ARadio,
  ARadioGroup,
  AAntdDatepickerWithMask,
  ACheckbox,
  parseShortOOO,
  parseOOO,
  isScene,
  doSetCaretPosition,
} from "src/helpers";
import { Divider } from "antd";
import { connect } from "react-redux";
import { Field, reduxForm, submit, change, formValueSelector } from "redux-form";
import { attributes } from "src/constants";
import validate from "./validate";
import { getInitialValues } from "./initialValues";
import { TaxTypes } from "src/constants/formValues";
import { AppState } from "src/redux/AppState";
import { IProps, defaultProps } from "./interface";

const sf = cn("site-form");
const b = cn("common-form-ooo");

class CommonOOOForm extends Component<IProps> {
  shortName: React.RefObject<any> | null = null;
  constructor(props: IProps) {
    super(props);
    this.shortName = React.createRef();
  }

  handleUstavCheckboxChange = () => {
    const { changeFieldValue, form } = this.props;

    changeFieldValue(form, attributes["Номер типового Устава"], "");
  };

  handleEnglishFieldsToggle = () => {
    const { changeFieldValue, form, engName } = this.props;
    if (engName) {
      changeFieldValue(form, attributes["Сокращенное наименование на английском языке"], "");
      changeFieldValue(form, attributes["Полное наименование на английском языке"], "");
    }
  };

  renderNalogRadio = (disabled: boolean) => {
    return (
      <React.Fragment>
        <Field
          name={attributes["Система налогобложения"]}
          component={ARadioGroup}
          buttonStyle="solid"
          disabled={disabled}
        >
          <Field
            type="radio"
            name={attributes["Система налогобложения"]}
            value={TaxTypes["УСН (доходы)"]}
            title="УСН (доходы)"
            component={ARadio}
          />
          <Field
            type="radio"
            name={attributes["Система налогобложения"]}
            value={TaxTypes["УСН (доходы минус расходы)"]}
            title="УСН (доходы минус расходы)"
            component={ARadio}
          />
          <Field
            type="radio"
            name={attributes["Система налогобложения"]}
            value={TaxTypes["ОСНО (с НДС)"]}
            title="ОСНО (с НДС)"
            component={ARadio}
          />
        </Field>
      </React.Fragment>
    );
  };

  renderEquityRadio = (disabled: boolean) => {
    return (
      <React.Fragment>
        <Field
          name={attributes["Вид указания размера уставного капитала"]}
          component={ARadioGroup}
          buttonStyle="solid"
          disabled={disabled}
        >
          <Field
            type="radio"
            name={attributes["Вид указания размера уставного капитала"]}
            value="percent"
            title="Проценты"
            component={ARadio}
          />
          <Field
            type="radio"
            name={attributes["Вид указания размера уставного капитала"]}
            value="simple"
            title="Простая дробь"
            component={ARadio}
          />
        </Field>
      </React.Fragment>
    );
  };

  handleShortNameFocus = () => {
    const shortNameInput: any = document.querySelector(`.common-form-ooo__ooo-input_short-ooo input`);
    const closingQuotePos = shortNameInput.value.indexOf("»");
    if (shortNameInput.value.indexOf("«»") !== -1) {
      doSetCaretPosition(shortNameInput, closingQuotePos);
    }
  };

  handleFullNameFocus = () => {
    const fullNameInput: HTMLInputElement | null = document.querySelector(`.common-form-ooo__ooo-input_full-ooo input`);
    const closingQuotePos = fullNameInput?.value.indexOf("»");
    if (fullNameInput?.value.indexOf("«»") !== -1) {
      doSetCaretPosition(fullNameInput, closingQuotePos);
    }
  };

  render() {
    const { handleSubmit, disabled, scene, creationDateEnabled, engName, ustav_checkbox } = this.props;

    return (
      <form onSubmit={handleSubmit} className={`${sf()} ${b()}`}>
        <div className={sf("section-wrapper")}>
          <section className={sf("section")}>
            <h2 className={sf("title")}>Общая информация</h2>
            <div className={`${sf("inputs-grid")} ${b("inputs-grid")}`}>
              <Field
                title="Сокращенное наименование:"
                name={attributes["Сокращенное наименование"]}
                component={AInput}
                disabled={disabled}
                className={b("ooo-input", { "short-ooo": true })}
                parse={parseShortOOO}
                onFocus={this.handleShortNameFocus}
                onClick={this.handleShortNameFocus}
              />
              <Field
                title="Полное наименование:"
                className={b("ooo-input", { "full-ooo": true })}
                name={attributes["Полное наименование"]}
                component={AInput}
                disabled={disabled}
                parse={parseOOO}
                onFocus={this.handleFullNameFocus}
                onClick={this.handleFullNameFocus}
              />
              {engName && (
                <>
                  <Field
                    title="Сокращенное наименование на английском языке:"
                    name={attributes["Сокращенное наименование на английском языке"]}
                    component={AInput}
                    disabled={disabled}
                  />
                  <Field
                    title="Полное наименование на английском языке:"
                    name={attributes["Полное наименование на английском языке"]}
                    component={AInput}
                    disabled={disabled}
                  />
                </>
              )}
            </div>

            {!isScene("Выпущена КЭП", scene) && (
              <label
                htmlFor="eng-bool"
                className={`${b(
                  "add-english",
                )} custom-button custom-button_long custom-button_large custom-button_faded`}
                onClick={this.handleEnglishFieldsToggle}
              >
                {engName ? "Удалить наименование на английском" : "Добавить наименование на английском"}
              </label>
            )}

            <Field
              component={ACheckbox}
              className={b("eng-checkbox")}
              name={attributes["Есть наименование на английском языке"]}
              id="eng-bool"
            />

            <div className={b("row")}>
              <Field
                title="Дата создания:"
                name={attributes["Дата создания"]}
                component={AAntdDatepickerWithMask}
                disabled={disabled && !creationDateEnabled}
                className={b("field", { createDate: true })}
              />
              <Field
                title="Уставный капитал:"
                name={attributes["Уставный капитал"]}
                component={AInput}
                disabled={disabled}
                className={b("field", { capital: true })}
                format={(v: string) => (v ? parseFloat(v.replace(/[^0-9]/g, "")).toLocaleString() + " ₽" : "")}
                normalize={(v: string) => (v ? v.replace(/[^0-9]/g, "") : "")}
              />
            </div>

            <div className={b("checkbox")}>
              <Field
                title="Указывать полный адрес в документах"
                name={attributes["Указывать полный адрес в документах"]}
                component={ACheckbox}
                disabled={disabled}
              />
            </div>
            <div className={b("checkbox")}>
              <Field
                title="Общество не использует печать"
                name={attributes["Общество не использует печать"]}
                component={ACheckbox}
                disabled={disabled}
              />
            </div>
            <div className={b("checkbox")}>
              <Field
                title="Общество использует типовой Устав"
                name={attributes["Общество использует типовой Устав"]}
                component={ACheckbox}
                disabled={disabled}
                onChange={this.handleUstavCheckboxChange}
              />
            </div>

            {ustav_checkbox && (
              <Field
                title="Номер типового Устава:"
                name={attributes["Номер типового Устава"]}
                component={AInput}
                disabled={disabled}
                className={b("field", { ustav: true })}
              />
            )}

            <div className={classNames(sf("radio"), b("radio"))}>
              <label className={sf("radio-title")}>Система налогобложения:</label>
              {this.renderNalogRadio(disabled)}
            </div>

            {/* <div className={classNames(sf("radio"), b("radio"))}>
              <label className={sf("radio-title")}>Вид указания размера уставного капитала:</label>
              {this.renderEquityRadio(disabled)}
            </div> */}

            <Divider />
          </section>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { data, form } = ownProps;
  const initialValues = data ? getInitialValues(data) : {};
  const commonOOOValues = formValueSelector(form);
  const shortOOOEng = commonOOOValues(state, attributes["Сокращенное наименование на английском языке"]);
  const engName = commonOOOValues(state, attributes["Есть наименование на английском языке"]);
  const ustav_checkbox = commonOOOValues(state, attributes["Общество использует типовой Устав"]);

  return { initialValues, shortOOOEng, engName, ustav_checkbox };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    changeFieldValue: (form: string, field: string, value: string) => dispatch(change(form, field, value)),
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  validate,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(CommonOOOForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as CommonOOOForm };
