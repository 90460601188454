import { RouteComponentProps } from "react-router-dom";
import { EntryType } from "src/helpers/types/Entry";
import { SceneType } from "src/helpers/types/Scene";
import { IUser } from "src/helpers/types/User";
import { ICompany } from "src/helpers/types/Company";

export interface IProps extends RouteComponentProps {
  currentEntryId?: number;
  currentPassportId?: number;
  logout: () => Promise<void>;
  clearFileState: () => void;
  getEntry: (id: number) => Promise<void>;
  deleteEntries: (ids: Array<number>) => Promise<void>;
  updateEntry: (ca?: string, showMessages?: boolean, loadingWithoutLogo?: boolean) => Promise<void>;
  copyEntries: (ids: Array<number>, option?: string) => Promise<number | undefined>;
  updatePassport: () => Promise<void>;
  removePassports: (ids: number[]) => Promise<void>;
  entryType?: EntryType;
  scene: SceneType;
  userCompanyId: number;
  getEntryError: boolean;
  entriesLoading: boolean;
  passportLoading: boolean;
  isEntryPage: boolean;
  userBalance: number;
  userAvatar: string;
  inner: boolean;
  firstName: string;
  lastName: string;
  isCurrentCompanyAdmin: boolean;
  public_balance: boolean;
  fixed: boolean;
  outer: boolean;
  users: IUser[];
  companies: ICompany[];
}
export interface IState {
  dropdownOpened: boolean;
  pinEntryModalVisible: boolean;
  pinEntryValue: string;
}

export const defaultProps = {
  fixed: false,
  outer: false,
  inner: false,
};
