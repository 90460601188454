export const passportsConstants = {
  // ПОЛУЧЕНИЕ ЗАЯВОК
  PASSPORTS_REQUEST: "PASSPORTS_REQUEST",
  PASSPORTS_SUCCESS: "PASSPORTS_SUCCESS",
  PASSPORTS_FAILURE: "PASSPORTS_FAILURE",

  //ПОЛУЧЕНИЕ ЗАЯВКИ ПО ID
  PASSPORT_GET_REQUEST: "PASSPORT_GET_REQUEST",
  PASSPORT_GET_SUCCESS: "PASSPORT_GET_SUCCESS",
  PASSPORT_GET_FAILURE: "PASSPORT_GET_FAILURE",

  // Обновление паспорта
  UPDATE_PASSPORT_REQUEST: "UPDATE_PASSPORT_REQUEST",
  UPDATE_PASSPORT_SUCCESS: "UPDATE_PASSPORT_SUCCESS",
  UPDATE_PASSPORT_FAILURE: "UPDATE_PASSPORT_FAILURE",

  // Удаление группы паспортов
  REMOVE_PASSPORTS_REQUEST: "REMOVE_PASSPORTS_REQUEST",
  REMOVE_PASSPORTS_SUCCESS: "REMOVE_PASSPORTS_SUCCESS",
  REMOVE_PASSPORTS_FAILURE: "REMOVE_PASSPORTS_FAILURE",
};
