import { IFilesState } from "src/redux/files";
import { Action } from "redux";

interface IAction extends Action {
  fieldName: string;
}

export const removeOtherFile = (state: IFilesState, action: IAction): IFilesState => {
  return {
    ...state,
    otherFiles: {
      ...state.otherFiles,
      files: state.otherFiles.files.filter((file) => file !== action.fieldName),
    },
  };
};
