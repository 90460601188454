import { cloneDeep } from "lodash";
import { getFormValues, submit, isValid } from "redux-form";
import { formsNames, attributes } from "src/constants";
import { store } from "src/redux/store";
import { Dispatch } from "redux";

export const extractSignupData = () => {
  const state = store.getState();

  const commonValues = getFormValues(formsNames["commonCompany"])(state);
  const requisitesValues = getFormValues(formsNames["companyRequisites"])(state);
  const adminValues = getFormValues(formsNames["companyAdmin"])(state);

  const allData: Record<string, any> = cloneDeep(Object.assign({}, commonValues, requisitesValues, adminValues));
  // удаляем поля, которые не нужно будет отправлять на сервер
  delete allData[attributes["статусПользователя"]];
  delete allData[attributes["Подтверждение пароля"]];
  // преобразуем строку в число конкретно в этом поле
  allData[attributes["Род деятельности"]] = +allData[attributes["Род деятельности"]];

  return allData;
};

export const validateSignupForm = (dispatch: Dispatch) => {
  // эмулируем сабмит всех форм
  dispatch(submit(formsNames["commonCompany"]));
  dispatch(submit(formsNames["companyRequisites"]));
  dispatch(submit(formsNames["companyAdmin"]));

  // извлекаем информацию об ошибках всех форм
  const state = store.getState();
  const commonValid = isValid("commonCompany")(state);
  const requisitesValid = isValid("companyRequisites")(state);
  const adminValid = isValid("companyAdmin")(state);

  return commonValid && requisitesValid && adminValid;
};
