import React, { useState } from "react";
import "./NewRequest.scss";
import { cn } from "src/helpers/bem";
import { Link } from "react-router-dom";
import { pages, entriesTypes } from "src/constants";
import { IProps } from "./interface";
import { useSelector } from "react-redux";
import { AppState } from "src/redux/AppState";
import { isAdmin } from "src/helpers";
import { Tooltip } from "src/components";
// @ts-ignore
import MouseTooltip from "react-sticky-mouse-tooltip";

const b = cn("new-request");

export const NewRequest = (props: IProps) => {
  const { onButtonClick } = props;
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const userCompanyId = useSelector((state: AppState) => state.userReducer.user?.company?.id);

  // const disableOooEdit = !isAdmin(userCompanyId);
  const disableOooEdit = false;

  return (
    <div className={b()}>
      <div className={b("title")}>Тип заявки</div>
      <div className={b("content-wrapper")}>
        <ul className={b("list")}>
          <li className={b("item")}>
            <Link
              to={`${pages.newEntry}?type=${entriesTypes["РегистрацияИП"]}`}
              onClick={onButtonClick}
              className={b("link-wrapper", { "ip-reg": true })}
            >
              <span className={b("link-text")}>
                Регистрация <span className={b("type")}>ИП</span>
              </span>
            </Link>
          </li>

          <li className={b("item")}>
            <Link
              to={`${pages.changeEntry}?type=${entriesTypes["ИзмененияИП"]}`}
              onClick={onButtonClick}
              className={b("link-wrapper", { "ip-change": true })}
            >
              <span className={b("link-text")}>
                Внесение изменений <span className={b("type")}>ИП</span>
              </span>
            </Link>
          </li>
          <li className={b("item")}>
            <Link
              to={`${pages.changeEntry}?type=${entriesTypes["ЗакрытиеИП"]}`}
              onClick={onButtonClick}
              className={b("link-wrapper", { "ip-close": true })}
            >
              <span className={b("link-text")}>
                Закрытие <span className={b("type")}>ИП</span>
              </span>
            </Link>
          </li>

          <li className={b("item")}>
            <Link
              to={`${pages.newEntry}?type=${entriesTypes["РегистрацияООО"]}`}
              onClick={onButtonClick}
              className={b("link-wrapper", { "ooo-reg": true })}
            >
              <span className={b("link-text")}>
                Регистрация <span className={b("type")}>ООО</span>
              </span>
            </Link>
          </li>

          <li
            className={b("item", { disabled: disableOooEdit })} //TODO: удалить вместе с тултипом "Временно недоступно"
            onMouseEnter={disableOooEdit ? () => setTooltipVisible(true) : () => {}}
            onMouseLeave={disableOooEdit ? () => setTooltipVisible(false) : () => {}}
          >
            <MouseTooltip visible={tooltipVisible} offsetX={0} offsetY={0} className={b("tooltip-wrapper")}>
              <Tooltip className={b("tooltip")} visible title="Временно недоступно" />
            </MouseTooltip>
            <Link
              to={`${pages.changeEntry}?type=${entriesTypes["ИзмененияООО"]}&subtype=egrul`}
              //TODO: удалить вместе с тултипом "Временно недоступно"
              onClick={(e) => (disableOooEdit ? e.preventDefault() : onButtonClick)}
              className={b("link-wrapper", { "ooo-change": true })}
            >
              <span className={b("link-text")}>
                Внесение изменений <span className={b("type")}>ООО</span>
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
