export enum SigningDataViewMode {
  APPLICANT = "applicant",
  CERTIFICATE = "certificate",
}

export type Props = {
  className?: string;
  fio: string;
  inn: string;
  oooName: string;
  onClick?: () => void;
  mode: SigningDataViewMode;
  serialNumber?: string;
  issuer?: string;
  showFIO?: boolean;
};
