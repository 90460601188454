import React, { FC } from "react";
import classNames from "classnames/dedupe";
import { cn } from "src/helpers/bem";
import { Props, SigningDataViewMode } from "./props";
import "src/pages/Signing/components/SigningData/SigningData.scss";
import { Divider } from "antd";

const b = cn("signing-data");

export const SigningData: FC<Props> = (props) => {
  const { className, fio, inn, oooName, onClick, mode, issuer, serialNumber, showFIO } = props;

  if (mode === SigningDataViewMode.APPLICANT) {
    return (
      <div className={classNames(b(), className)} onClick={onClick}>
        <div className={b("title")}>{oooName ? oooName : "Название сертификата неизвестно"}</div>
        <Divider className={b("divider")} />

        <div className={b("text-wrapper")}>
          <div className={b("text")}>ИНН {inn ? inn : "Неизвестно"}</div>
          <div className={b("text")}>{fio ? fio : "ФИО неизвестно"}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={classNames(b(), className)} onClick={onClick}>
        <div className={b("title")}>{oooName ? oooName : "Название сертификата неизвестно"}</div>
        <Divider className={b("divider")} />

        <div className={b("title", { secondary: true })}>ИНН {inn ? inn : "Неизвестно"}</div>
        {showFIO ? <div className={b("title", { secondary: true })}>{fio ? fio : "ФИО неизвестно"}</div> : null}

        <div className={b("text-wrapper")}>
          <div className={b("text", { small: true })}>Выдан: {issuer ? issuer : "Неизвестно"}</div>
          <div className={b("text", { small: true })}>SN: {serialNumber ? serialNumber : "Неизвестно"}</div>
        </div>
      </div>
    );
  }
};
