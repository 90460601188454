import React from "react";
import { cn } from "src/helpers/bem";
import "./ActivityOption.scss";
import { ReactComponent as RemoveIcon } from "./images/remove-icon.svg";
import { IProps } from "./interface";

const b = cn("activity-option");

export const ActivityOption = (props: IProps) => {
  const {
    removeLabel,
    activityCode,
    activityTitle,
    onRemove,
    onRestore,
    onStarClicked,
    isStarFilled,
    disabled,
    status,
    main,
  } = props;

  return (
    <div className={b("")}>
      <div className={b("wrapper", { disabled, [status]: true })}>
        <div onClick={() => onStarClicked(activityCode)} className={b("star", { filled: isStarFilled })} />

        {removeLabel === "text" && (status === "default" || status === "new") && (
          <div className={b("change")} onClick={() => onRemove(activityCode)}>
            Удалить
          </div>
        )}

        {status === "removed" && (
          <div className={b("change")} onClick={() => onRestore(activityCode, main)}>
            Восстановить
          </div>
        )}

        <div className={b("activity-code")}>{activityCode}</div>
        <div className={b("activity-title")}>{activityTitle}</div>
      </div>
      {/* В изменениях ИП и ООО показываем надпись "Удалить", а в остальных заявках - значок */}
      {removeLabel === "button" && onRemove && !disabled && (status === "default" || status === "new") && (
        <button className={b("remove-btn")} onClick={() => onRemove(activityCode)} type="button">
          <RemoveIcon />
        </button>
      )}
    </div>
  );
};

ActivityOption.defaultProps = {
  removeLabel: "button",
  activityCode: "код деятельности неизвестен",
  activityTitle: "название деятельности неизвестно",
  isStarFilled: false,
  onRemove: () => {},
  onRestore: () => {},
  disabled: false,
  status: "default",
};
