import { IInputState } from "src/redux/input";
import { Action } from "redux";

interface IAction extends Action {
  form: string;
  inputName: string;
  color: string;
}

export const updateInputColor = (state: IInputState, action: IAction): IInputState => {
  const { form, inputName, color } = action;

  return {
    ...state,
    inputsColors: Object.assign({}, state.inputsColors, { [`${form}-${inputName}`]: color }),
  };
};
