import { billsConstants } from "./../bills.constants";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { getBills } from "./getBills";
import { message } from "antd";

export const setDateBill = (id: number, date: string) => {
  const { SET_DATE_BILL_REQUEST, SET_DATE_BILL_SUCCESS, SET_DATE_BILL_FAILURE } = billsConstants;
  const request = () => ({ type: SET_DATE_BILL_REQUEST });
  const success = () => ({ type: SET_DATE_BILL_SUCCESS });
  const failure = () => ({ type: SET_DATE_BILL_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      await API.get(`${endpoints.bills.setDate}/${id}/${date}`);
      message.success("Дата изменена");
      dispatch(success());
      dispatch(getBills());
    } catch (err: any) {
      message.error(`Ошибка при изменении даты счёта: ${err}`);
      dispatch(failure());
    }
  };
};
