import { IPassportsState } from "src/redux/passports";
import { Action } from "redux";
import { IPassportsResponse } from "src/helpers/types/ResponsesFromBackend";

interface IAction extends Action {
  passports: IPassportsResponse;
}

export const getPassportsRequest = (state: IPassportsState, action: IAction): IPassportsState => {
  return {
    ...state,
    loading: true,
  };
};

export const getPassportsSuccess = (state: IPassportsState, action: IAction): IPassportsState => {
  return {
    ...state,
    loading: false,
    entries: action.passports,
    error: false,
  };
};

export const getPassportsFailure = (state: IPassportsState, action: IAction): IPassportsState => {
  return { ...state, error: true, loading: false };
};
