import { attributes } from "src/constants";
import { validateCyrillicField, isFieldExist, valueMustBeBiggerThan } from "src/helpers";
import { change } from "redux-form";
import { IProps } from "./interface";

export default function validate(values: Record<string, any>, props: IProps): Record<string, string> {
  let errors: Record<string, string> = {};
  const { dispatch, form } = props;
  const changeFieldValue = (form: string, field: string, value: string) => dispatch(change(form, field, value));

  errors = isFieldExist(attributes["Наименование должности"], values[attributes["Наименование должности"]], errors);
  errors = validateCyrillicField(
    attributes["Наименование должности"],
    values[attributes["Наименование должности"]],
    errors,
  );
  if (!values[attributes["Бессрочно"]]) {
    errors = isFieldExist(attributes["Срок полномочий"], values[attributes["Срок полномочий"]], errors, " ");
    errors = valueMustBeBiggerThan(attributes["Срок полномочий"], values[attributes["Срок полномочий"]], 1, errors);
  } else {
    changeFieldValue(form, attributes["Срок полномочий"], "");
    errors[attributes["Срок полномочий"]] = "";
  }

  return errors;
}
