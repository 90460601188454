import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";
import { ISimplyfiedEntry } from "src/helpers/types/Entry";

interface IAction extends Action {
  simplifiedEntry: ISimplyfiedEntry;
}

export const getSimplifiedEntryRequest = (state: IEntriesState): IEntriesState => {
  return {
    ...state,
    loading: true,
    getEntryError: false,
  };
};

export const getSimplifiedEntrySuccess = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    simplifiedEntry: { ...action.simplifiedEntry },
    wasLoaded: true,
    loading: false,
    getEntryError: false,
  };
};

export const getSimplifiedEntryFailure = (state: IEntriesState): IEntriesState => {
  return { ...state, loading: false, getEntryError: true };
};
