import { RouteProps } from "react-router";

export interface IProps extends RouteProps {
  forSomeCompanies?: number[];
  userCompanyId?: number;
}

export interface IState {}

export const defaultProps = {};
