import { IBillsState } from "src/redux/bills";
import { Action } from "redux";

interface IAction extends Action {}

export const setDateBillRequest = (state: IBillsState, action: IAction) => {
  return { ...state, loading: true };
};

export const setDateBillSuccess = (state: IBillsState, action: IAction) => {
  return { ...state, loading: false };
};

export const setDateBillFailure = (state: IBillsState, action: IAction) => {
  return { ...state, loading: false };
};
