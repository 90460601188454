import { IUserState } from "src/redux/user";

export const changePasswordRequest = (state: IUserState): IUserState => {
  return {
    ...state,
    loading: true,
  };
};

export const changePasswordSuccess = (state: IUserState): IUserState => {
  return {
    ...state,
    loading: false,
  };
};

export const changePasswordFailure = (state: IUserState): IUserState => {
  return { ...state, loading: false };
};
