import React from "react";
import { cn } from "src/helpers/bem";
import { Header, Footer } from "src/components";
import "./EntryLayout.scss";
import {} from "src/constants";

const b = cn("entry-layout");

export class EntryLayout extends React.Component<any, any> {
  render() {
    const { children } = this.props;

    return (
      <div className={b()}>
        {
          //@ts-ignore
          <Header inner />
        }
        {children}
        <Footer inner />
      </div>
    );
  }
}
