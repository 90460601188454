import { IUserState } from "src/redux/user";

export const getTokenRequest = (state: IUserState): IUserState => {
  return {
    ...state,
    loading: true,
  };
};

export const getTokenSuccess = (state: IUserState): IUserState => {
  return {
    ...state,
    loading: false,
  };
};

export const getTokenFailure = (state: IUserState): IUserState => {
  return {
    ...state,
    loading: false,
  };
};
