import { billsConstants } from "./../bills.constants";
import { API, showLoadingMessage } from "src/helpers";
import { baseURL, endpoints } from "src/constants";
import { message } from "antd";

export const companyBill = (companyId: number, sum: number) => {
  const { COMPANY_BILL_REQUEST, COMPANY_BILL_SUCCESS, COMPANY_BILL_FAILURE } = billsConstants;
  const request = () => ({ type: COMPANY_BILL_REQUEST });
  const success = () => ({ type: COMPANY_BILL_SUCCESS });
  const failure = () => ({ type: COMPANY_BILL_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    showLoadingMessage("Идёт формирование счёта");
    try {
      const res: any = await API.post(`${endpoints.bills.companyBill}/${companyId}/${sum}`);
      message.destroy();
      dispatch(success());
      return `${baseURL}/bills/${companyId}/${res.id}`;
    } catch (err: any) {
      message.destroy();
      message.error(`Не удалось сформировать счет: ${err}`);
      dispatch(failure());
    }
  };
};
