import { entriesConstants } from "./../entries.constants";
import { message } from "antd";
import { API } from "src/helpers";
import { endpoints } from "src/constants";

export const copyEntries = (
  ids: Array<number>,
  option?: string,
): ((dispatch: Function) => Promise<number | undefined>) => {
  const { ENTRIES_COPY_REQUEST, ENTRIES_COPY_SUCCESS, ENTRIES_COPY_FAILURE } = entriesConstants;
  const request = () => ({ type: ENTRIES_COPY_REQUEST });
  const success = () => ({ type: ENTRIES_COPY_SUCCESS });
  const failure = () => ({ type: ENTRIES_COPY_FAILURE });

  const params = option ? `&change=${option}` : "";

  return async function (dispatch: Function): Promise<number | undefined> {
    dispatch(request());
    try {
      const newCopiedId: number = await API.post(`${endpoints.entries.copy}?ids=${ids.join("&ids=")}${params}`);
      dispatch(success());
      return newCopiedId;
    } catch (err: any) {
      message.error(`Ошибка при копировании заявки: ${err}`);
      dispatch(failure());
    }
  };
};
