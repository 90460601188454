import React from "react";
import "./HHForms.scss";
import { cn } from "src/helpers/bem";
import { HHMarketplaceForm } from "src/forms/HHMarketplace/HHMarketplace";
import classNames from "classnames/dedupe";
import { IProps, IState, defaultProps } from "./interface";
import { sendMarketplaceCardForm } from "src/redux/marketplace";
import { connect } from "react-redux";
import { Checkbox } from "antd";
import { AppState } from "src/redux/AppState";

const b = cn("hh-forms");
const mf = cn("marketplace-forms");

class HHForms extends React.Component<IProps, IState> {
  static defaultProps = defaultProps;

  constructor(props: IProps) {
    super(props);
    this.state = {
      agreementChecked: false,
    };
  }

  handleAgreementCheck = () => this.setState({ agreementChecked: !this.state.agreementChecked });

  render() {
    const { className, sendMarketplaceCardForm, cardData, onSuccessSend } = this.props;
    const { agreementChecked } = this.state;
    return (
      <div className={classNames(b(), className)}>
        <h2 className={b("title")}>Бесплатная вакансия «Стандарт плюс» на hh.ru для новых клиентов</h2>
        <p className={b("description")}>
          hh.ru — один из самых крупных сервисов по поиску работы и сотрудников в мире (по данным рейтинга SimilarWeb).
          Мы создаем передовые технологии на всех доступных платформах, для того чтобы работодатели могли быстро найти
          подходящего сотрудника, а соискатели — хорошую работу. Наши мобильные приложения стабильно занимают первые
          места в категории «Бизнес» на всех платформах.
        </p>
        <h3 className={b("subtitle")}>Условия получения вакансии</h3>
        <p className={b("text-content")}>
          Данное спецпредложение действует в соответствии с условиями пользования сайтом hh.ru. Ознакомьтесь с правилами
          активации спецпредложения, а также условиями получения вакансии «Стандарт плюс»:
          <ol className={b("list")}>
            <li>Предложение действует для компаний, не имеющих ранее регистрации на сайте hh.ru (0+).</li>
            <li>
              Вакансии предоставляется только юридическим лицам, прямым работодателям (не кадровым и рекрутинговым
              агентствам), успешно прошедшим регистрацию на сайте hh.ru. После предоставления вакансий компания может
              использовать их в течение 12 месяцев.
            </li>
            <li>HeadHunter имеет право отказать в регистрации компании без объяснения причин.</li>
            <li>Компания обязана соблюдать правила, установленные HeadHunter.</li>
            <li>У одного юридического лица может быть только одна регистрация на hh.ru.</li>
            <li>Выплата денежного эквивалента услуги не предусмотрена и не осуществляется.</li>
            <li>Компания-работодатель может активировать только одну вакансию «Стандарт плюс».</li>
            <li>
              Передавать ссылку на данное спецпредложение третьим лицам, которые не являются участниками данного
              мероприятия, запрещается.
            </li>
            <li>
              Компания HeadHunter вправе отказать в регистрации клиента, в случае, если не были предоставлены документы,
              подтверждающие информацию о компании (ИНН, свидетельство о регистрации юридического лица, корпоративный
              сайт и т.д.)
            </li>
            <li>
              Данное специальное предложение действует в соответствии с Условиями использования сайта http://hh.ru (0+)
              и Условиями оказания услуг.
            </li>
          </ol>
        </p>
        <HHMarketplaceForm />
        <Checkbox checked={agreementChecked} onChange={this.handleAgreementCheck} className={b("agreement-checkbox")}>
          Получено согласие клиента на передачу персональных данных сервису hh.ru
        </Checkbox>
        <div className={b("agreement-text")}>
          Нажимая кнопку «Отправить», я подтверждаю, что мною получено согласие заявителя на осуществление ООО
          «Хэдхантер» (ИНН 7718620740, адрес: 129085, г. Москва, ул. Годовикова, д.9, стр.10) обработки (сбора,
          систематизации, накопления, хранения, уточнения (обновления, изменения), использования, распространения любым
          способом, обезличивания, блокирования и уничтожения, в том числе автоматизированной, а также передачи в адрес
          третьих лиц – Партнеров ООО «Хэдхантер») персональных данных заявителя, а именно: фамилия, имя, отчество,
          контактный телефон, адрес электронной почты, место работы, должность, в соответствии с требованиями
          Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» в целях регистрации заявителя на сайте
          http://hh.ru и коммуникации ним по вопросам регистрации на сайте и получения вакансий на специальных условиях.
          Срок действия настоящего согласия не ограничен. Заявитель уведомлен: (а), что он может отозвать свое согласие
          на обработку персональных данных и/или на направление ему информации, представив в ООО «Хэдхантер» заявление в
          письменной форме.
        </div>
        <button
          type="button"
          className={classNames("custom-button", mf("send-button"))}
          onClick={async () => {
            const res = await sendMarketplaceCardForm(cardData);
            if (res) {
              onSuccessSend();
            }
          }}
          disabled={!agreementChecked}
        >
          Отправить
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {};
};

const mapDispatchToProps = {
  sendMarketplaceCardForm,
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(HHForms);
export { connectedComponent as HHForms };
