import { userConstants } from "../user.constants";
import { message } from "antd";
import { endpoints, errorsTypes } from "src/constants";
import { extractSignupData, validateSignupForm, history, API } from "src/helpers";
import { Dispatch } from "redux";

export const signUp = () => {
  const { SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE } = userConstants;
  const request = () => ({ type: SIGNUP_REQUEST });
  const success = () => ({ type: SIGNUP_SUCCESS });
  const failure = () => ({ type: SIGNUP_FAILURE });

  return async function (dispatch: Dispatch) {
    const formCorrect = validateSignupForm(dispatch);

    if (!formCorrect) {
      message.error(errorsTypes.requiredFields);
    } else {
      dispatch(request());
      try {
        const data = extractSignupData();
        await API.post(`${endpoints.signUp}`, data);
        dispatch(success());
        message.success("Заявка на регистрацию отправлена");
        setTimeout(() => history.push("/"), 500); // так красивее :О
      } catch (err: any) {
        message.error(`Не удалось зарегистрироваться: ${err}`);
        dispatch(failure());
      }
    }
  };
};
