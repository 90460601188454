import { SceneType } from "src/helpers/types/Scene";
export type StepsConstantsKeysType = Exclude<SceneType, "vipusk3" | "fns7">;

export const stepsConstants: Record<Exclude<StepsConstantsKeysType, "fns_error">, number> = {
  vipusk1: 0,
  vipusk2: 0,
  smevFailed: 1,
  smevWaiting: 1,
  smevActivation: 1,
  vipusk4: 1,
  fns4: 2,
  fns5: 3,
  fns_pending: 4,
  fns_registration: 4,
  fns_success: 4,
  fns_reject: 4,
};
