import React from "react";
import { cn } from "src/helpers/bem";
import "./Select.scss";
import { Select as AntdSelect } from "antd";
import { Tooltip } from "src/components";
import { errorsTypes } from "src/constants";
import { IProps, defaultProps } from "./interface";

const { Option } = AntdSelect;
const b = cn("site-select");

export const Select = (props: IProps) => {
  const { showSearch, title, options, meta, className, ...rest } = props;
  const { touched, error, submitFailed } = meta;

  return (
    <div className={`${b()} ${className}`}>
      <label className={b("title")}>{title}</label>
      <AntdSelect showSearch={showSearch} size="large" {...rest} onBlur={() => {}}>
        {options.map((opt) => (
          <Option value={opt.value} key={`option_${props.name}_${opt.value}`}>
            {opt.title}
          </Option>
        ))}
      </AntdSelect>
      {submitFailed && error === errorsTypes.requiredField && (
        <Tooltip title={error} visible={Boolean(touched && error)} />
      )}
      {error !== errorsTypes.requiredField && <Tooltip title={error} visible={Boolean(touched && error)} />}
    </div>
  );
};

Select.defaultProps = defaultProps;
