import React, { Component } from "react";
import { Link } from "react-router-dom";
import { cn } from "src/helpers/bem";
import classNames from "classnames/dedupe";
import "./CompaniesTable.scss";
import { companiesColumns } from "src/constants";
import { Loader } from "src/components";
import { CompanySettingsForm } from "src/forms";
import { pages } from "src/constants";
import { PlusOutlined } from "@ant-design/icons";
import { Input, Select, Table, Modal } from "antd";
import { companiesFromServerFormat, getCookie, setCookie } from "src/helpers";
import { ReactComponent as StarFilled } from "./images/star-filled.svg";
import { ReactComponent as Star } from "src/assets/images/check-icon.svg";
import { IState, IProps, defaultProps } from "./interface";

const { Option } = Select;

const b = cn("companies-table");
const sp = cn("site-page");
const topCompaniesIds = [210, 100, 4, 261, 72, 201, 118, 10, 38, 140];

export class CompaniesTable extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      pageSize: 20,
      searchQuery: "",
      searchOnFocus: false,
      currentPage: 1,
      modalVisible: false,
      openedCompanyData: undefined,
      starFilterChecked: false,
    };
  }

  static defaultProps = defaultProps;

  componentDidMount() {
    //@ts-ignore
    this.setState({ pageSize: +getCookie("users-page-size") || 20 }, () => this.updateCompanies());
  }

  componentDidUpdate() {
    const {
      data: { filteredCompanies },
    } = this.props;
    const currentCompany = filteredCompanies?.filter((company) => company.id === this.state.openedCompanyData?.id)[0];

    if (currentCompany && this.state.openedCompanyData?.activated !== currentCompany.activated) {
      this.setState({
        openedCompanyData: currentCompany,
      });
    }
  }

  handleModalVisible = () => {
    this.setState({ modalVisible: !this.state.modalVisible });
  };

  handleFilterChange = (filterName: string, value: string | number, clearPaginationPage = false) => {
    if (clearPaginationPage) {
      this.setState({ currentPage: 1, starFilterChecked: false });
    }

    //@ts-ignore
    this.setState({ [filterName]: value, starFilterChecked: false }, () => this.updateCompanies());

    if (filterName === "pageSize") {
      setCookie("users-page-size", value);
    }
  };

  handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: evt.target.value, starFilterChecked: false }, () => this.updateCompanies(true));
  };

  handleSearchFocus = () => {
    this.setState({ searchOnFocus: true });
  };

  handleSearchBlur = () => {
    this.setState({ searchOnFocus: false });
  };

  updateCompanies = (clearPaginationPage = false) => {
    const { onUpdate } = this.props;
    const { currentPage, pageSize, searchQuery } = this.state;

    if (searchQuery.length >= 3) {
      const page = clearPaginationPage ? 1 : currentPage;
      this.setState({ currentPage: page }, () => onUpdate(pageSize, page, searchQuery));
    } else if (searchQuery.length === 0) {
      onUpdate(pageSize, currentPage);
    }
  };

  handleStarFilterClick = (starFilterChecked: boolean) => {
    const { pageSize, currentPage } = this.state;
    const { showCompanies, onUpdate } = this.props;
    this.setState({ starFilterChecked });
    // если зведочка отмечена
    if (starFilterChecked) {
      // показываем топ-компании
      showCompanies(topCompaniesIds);
    } else {
      // иначе возвращаем показ компаний по фильтрам
      onUpdate(pageSize, currentPage);
    }
  };

  renderModal = () => {
    const { modalVisible, openedCompanyData } = this.state;

    return (
      <Modal visible={modalVisible} onCancel={this.handleModalVisible} footer={null} className="common-modal">
        <h2 className={b("modal-title")}>Данные компании</h2>
        <CompanySettingsForm companyInfo={openedCompanyData} opened={modalVisible} onUpdate={this.updateCompanies} />
      </Modal>
    );
  };

  renderControls = () => {
    const { pageSize, searchQuery, searchOnFocus, starFilterChecked } = this.state;
    const { loading } = this.props;
    return (
      <div className={classNames(sp("controls"))}>
        <div className={b("search-wrapper")}>
          <Input
            placeholder="Поиск..."
            className={sp("search")}
            value={searchQuery}
            onChange={this.handleSearchChange}
            onFocus={this.handleSearchFocus}
            onBlur={this.handleSearchBlur}
            disabled={loading && !searchOnFocus}
          />
          <Link to={pages.companyRegistration} className="custom-button">
            <PlusOutlined />
            Создать компанию
          </Link>
        </div>
        <div className={classNames(sp("control-items"), b("control-items"))}>
          {starFilterChecked ? (
            <StarFilled
              width={20}
              height={20}
              className={b("star-filter")}
              onClick={() => this.handleStarFilterClick(false)}
            />
          ) : (
            <Star
              width={20}
              height={20}
              className={b("star-filter")}
              onClick={() => this.handleStarFilterClick(true)}
            />
          )}
          <Select
            defaultValue={20}
            value={pageSize}
            style={{ width: 113 }}
            onChange={(value) => this.handleFilterChange("pageSize", value, true)}
            disabled={loading}
            className={sp("control", { last: true })}
          >
            <Option value={20}>20</Option>
            <Option value={40}>40</Option>
            <Option value={80}>80</Option>
          </Select>
        </div>
      </div>
    );
  };

  render() {
    const { pageSize, currentPage, starFilterChecked } = this.state;
    const {
      data: { companies, filteredCompanies },
      loading,
    } = this.props;

    const tableData = companiesFromServerFormat(filteredCompanies);
    return (
      <div className={classNames(sp("content"), b())}>
        {this.renderModal()}
        {this.renderControls()}
        <Table
          className={sp("table")}
          onRow={(record) => {
            return {
              onClick: () => {
                this.setState({
                  openedCompanyData: filteredCompanies?.filter((company) => company.id === record.key)[0],
                });
                this.handleModalVisible();
              },
            };
          }}
          columns={companiesColumns}
          dataSource={tableData}
          scroll={{ y: "100%" }}
          pagination={{
            showSizeChanger: false,
            pageSize,
            // если включен режим "показывать топ компании", берем count у filteredCompanies
            total: starFilterChecked ? filteredCompanies?.length : companies?.count,
            onChange: (value) => this.handleFilterChange("currentPage", value),
            current: currentPage,
            hideOnSinglePage: true,
          }}
          loading={{
            spinning: Boolean(loading && filteredCompanies?.length),
            indicator: <Loader fixed={false} />,
          }}
        />
      </div>
    );
  }
}
