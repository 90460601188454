import { entriesConstants } from "./../entries.constants";
import { change } from "redux-form";
import { store } from "src/redux/store";
import { attributes, formsNames } from "src/constants";
import { IOwner } from "src/helpers/types/Owner";

// убирает галочку "сделать ЕИО" у всех учредителей, кроме учредителя с id = ignoreId
export const clearMakeEIO = (ignoreId: number) => {
  return (dispatch: Function) => {
    const changeFieldValue = (form: string, field: string, value: any) => dispatch(change(form, field, value));
    // обратитмся к учредителям, чтобы узнать их id, а значит и названия redux-форм
    const state = store.getState();
    //@ts-ignore
    const owners: IOwner[] = state.entries.formattedEntry[attributes["учредители"]];
    const ids = owners.map((it) => it.id);
    ids.forEach((id: number) => {
      id !== ignoreId &&
        changeFieldValue(`${formsNames["owner"]}${id}`, attributes["Назначить исполнительным органом"], false);
    });
    dispatch({ type: entriesConstants.ENTRY_CLEAR_MAKE_EIO });
  };
};
