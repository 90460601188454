import { createReducer } from "@reduxjs/toolkit";
import { marketplaceConstants } from "./../marketplace.constants";
import { getCardsRequest, getCardsSuccess, getCardsFailure } from "./getCards";
import {
  sendMarketplaceCardFormRequest,
  sendMarketplaceCardFormSuccess,
  sendMarketplaceCardFormFailure,
} from "./sendMarketplaceCardForm";
import { getRequestsRequest, getRequestsSuccess, getRequestsFailure } from "./getRequests";
import { IMarketplaceCard, IMarketPlaceRequest } from "src/helpers/types/MarketplaceCard";

export interface IMarketplaceState {
  cards: IMarketplaceCard[];
  loading: boolean;
  error: boolean;
  requests?: IMarketPlaceRequest[];
}

const initialState: IMarketplaceState = { cards: [], loading: true, error: false };

export const marketplace = createReducer(initialState, {
  [marketplaceConstants.GET_CARDS_REQUEST]: getCardsRequest,
  [marketplaceConstants.GET_CARDS_SUCCESS]: getCardsSuccess,
  [marketplaceConstants.GET_CARDS_FAILURE]: getCardsFailure,

  [marketplaceConstants.SEND_MARKETPLACE_CARD_FORM_REQUEST]: sendMarketplaceCardFormRequest,
  [marketplaceConstants.SEND_MARKETPLACE_CARD_FORM_SUCCESS]: sendMarketplaceCardFormSuccess,
  [marketplaceConstants.SEND_MARKETPLACE_CARD_FORM_FAILURE]: sendMarketplaceCardFormFailure,

  [marketplaceConstants.GET_REQUESTS_REQUEST]: getRequestsRequest,
  [marketplaceConstants.GET_REQUESTS_SUCCESS]: getRequestsSuccess,
  [marketplaceConstants.GET_REQUESTS_FAILURE]: getRequestsFailure,
});
