import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import { Divider, Modal } from "antd";
import { UserPreview } from "src/components";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { attributes } from "src/constants";
import { mainFields, additionalFields, fileFieldsModal } from "./constants";
import { addOtherFile } from "src/redux/files";
import { entriesTypes } from "src/constants";
import { COMPANIES_IDS_THAT_CAN_CALL_COURIER } from "src/constants/permissions";
import "./Forms.scss";
import { getSurnameAndInitials, isScene } from "src/helpers";
import {
  CommonForm,
  FilesForm,
  INNandOGRNForm,
  PassportForm,
  RegistrationForm,
  ForeignForm,
  DocsFormatForm,
  DeliveryForm,
} from "src/forms";
import { updateApplicant } from "src/redux/entries";
import { AppState } from "src/redux/AppState";
import { IProps, IState } from "./interface";

const sf = cn("site-forms");
const b = cn("closeIP-forms");

class Forms extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { modalVisible: false };
  }

  handleModal = () => {
    this.setState({ modalVisible: !this.state.modalVisible });
  };

  renderPreview = () => {
    const { formattedEntry, disabled, validateErrors } = this.props;
    const applicantError = validateErrors?.applicantError;

    return (
      <UserPreview
        onEdit={this.handleModal}
        label={getSurnameAndInitials(formattedEntry) || "Новый заявитель"}
        disabled={disabled}
        errorVisible={Boolean(applicantError)}
      />
    );
  };

  renderModal = () => {
    const { loading, wasLoaded, inostr, formattedEntry, disabled, scene, updateApplicant, userCompanyId } = this.props;
    const { modalVisible } = this.state;

    return (
      <Modal
        visible={modalVisible}
        footer={null}
        onCancel={() => {
          updateApplicant();
          this.handleModal();
        }}
        title="Данные заявителя"
        className="common-modal"
        forceRender={true}
      >
        <CommonForm disabled={disabled} data={formattedEntry} showNalog={false} />

        {typeof userCompanyId === "number" && COMPANIES_IDS_THAT_CAN_CALL_COURIER.includes(userCompanyId) && (
          <>
            <Divider />
            <DeliveryForm disabled={disabled} data={formattedEntry} />
          </>
        )}

        <Divider />
        <FilesForm
          showTitle={false}
          fileFields={fileFieldsModal}
          disabled={
            !(isScene("Создание ссылки", scene) || isScene("Проверка СМЭВ", scene) || isScene("Активация КЭП", scene))
          }
          disabledTooltipVisible={isScene("Заполнение", scene)}
          className={sf("files-row")}
          loading={loading}
          wasLoaded={wasLoaded}
        />
        <Divider />
        <PassportForm disabled={disabled} data={formattedEntry} />
        <Divider />
        {inostr === "true" && [<ForeignForm disabled={disabled} data={formattedEntry} />, <Divider />]}
        <RegistrationForm disabled={disabled} data={formattedEntry} showRegistrOrgan={false} />
      </Modal>
    );
  };

  renderFilesForm = () => {
    const { loading, wasLoaded, scene, firstOtherFile, otherFiles, loadedOtherFilesCount, addOtherFile } = this.props;

    const otherFilesFields = [];
    // Если загружен файл 'file_drug', то отображаем его только на этапе
    // после отправки в ФНС
    if (firstOtherFile && (isScene("Регистрация в ФНС", scene) || isScene("Зарегистрирован", scene))) {
      otherFilesFields.push({
        attribute: attributes["Другое"],
        title: "Другое",
        label: "Другое",
        fileType: "uploadIP_EDIT",
      });
    }

    if (otherFiles.length > 0) {
      otherFiles.forEach((file) => {
        otherFilesFields.push({
          attribute: file,
          title: "Другое",
          label: "Другое",
          fileType: "uploadIP_EDIT",
        });
      });
    }
    if (otherFiles.length === loadedOtherFilesCount) {
      addOtherFile(attributes["Другое"]);
    }

    return (
      <div>
        <FilesForm
          fileFields={mainFields.concat(otherFilesFields)}
          disabled={!isScene("Создание ссылки", scene)}
          disabledTooltipVisible={isScene("Заполнение", scene)}
          loading={loading}
          wasLoaded={wasLoaded}
        />
        {scene && isScene("Выпущена КЭП", scene) && (
          <>
            <Divider className={sf("files-divider")} />
            <FilesForm
              fileFields={additionalFields}
              disabled={isScene("Зарегистрирован", scene) || isScene("Регистрация в ФНС", scene)}
              disabledTooltipVisible={isScene("Заполнение", scene)}
              showTitle={false}
              loading={loading}
              wasLoaded={wasLoaded}
            />
          </>
        )}
      </div>
    );
  };

  render() {
    const { formattedEntry, disabled } = this.props;
    // disabled = disabled || !ogrn || (ogrn && ogrn.indexOf("_") !== -1);

    return (
      <div className={`${sf({ "with-files": true })} ${b()}`}>
        <div className={sf("forms")}>
          <INNandOGRNForm
            disabled={disabled}
            type={entriesTypes["ИзмененияИП"]}
            data={formattedEntry}
            showTitle={true}
          />

          <Divider />

          <h2 className={sf("title")}>Данные заявителя</h2>
          {this.renderPreview()}
          {this.renderModal()}
          <DocsFormatForm disabled={disabled} data={formattedEntry} className={b("docs-form")} />
        </div>

        {this.renderFilesForm()}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { loading, wasLoaded, formattedEntry, validateErrors } = state.entries;
  const userCompanyId = state?.userReducer?.user?.company?.id;
  const passportValues = formValueSelector("passport");
  const INNandOGRNValues = formValueSelector("INNandOGRN");
  const inostr = passportValues(state, attributes["иностранец"]);
  const ogrn = INNandOGRNValues(state, attributes["ОГРНИП"]);
  const firstOtherFile = formattedEntry?.file_drug;
  const otherFiles = state.files.otherFiles.files;
  const loadedOtherFilesCount = Object.entries(state.files.loaded).filter(
    ([key, value]) => key.match(/drug(13|14)?_\d/g) && typeof value !== "undefined",
  ).length;

  return {
    loading,
    wasLoaded,
    inostr,
    formattedEntry,
    ogrn,
    validateErrors,
    firstOtherFile,
    otherFiles,
    loadedOtherFilesCount,
    userCompanyId,
  };
};

const mapDispatchToProps = {
  updateApplicant,
  addOtherFile,
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(Forms);

export { connectedComponent as Forms };
