import { IBillsState } from "src/redux/bills";
import { Action } from "redux";
import { IOperation } from "src/helpers/types/Operation";

interface IAction extends Action {
  operations: IOperation[];
}

export const showBalanceRequest = (state: IBillsState, action: IAction): IBillsState => {
  return {
    ...state,
    loading: true,
  };
};

export const showBalanceSuccess = (state: IBillsState, action: IAction): IBillsState => {
  return {
    ...state,
    operations: action.operations,
    loading: false,
  };
};

export const showBalanceFailure = (state: IBillsState, action: IAction): IBillsState => {
  return {
    ...state,
    loading: false,
  };
};
