import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./Common.scss";
import { AInput, AAutoComplete, ARadio, ARadioGroup, detectDataByLastName } from "src/helpers";
import { connect } from "react-redux";
import { Field, reduxForm, submit } from "redux-form";
import { attributes } from "src/constants";
import validate from "./validate";
import { getInitialValues } from "./initialValues";
import { TaxTypes } from "src/constants/formValues";
import { AppState } from "src/redux/AppState";
import { defaultProps, IProps } from "./interface";
import classNames from "classnames/dedupe";

const sf = cn("site-form");
const b = cn("common-form");

const renderLastNameOptions = (
  options: Array<{
    id: string | number;
    value: any;
    firstname: string;
    lastName: string;
    middleName: string;
    inn: string;
    snp: string;
  }>,
  searchQuery: string,
) => {
  const reg = new RegExp(searchQuery.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), "gi");

  return options.map((it, index: number) => {
    return {
      key: it.id ? it.id : `${it.value}-${index}`,
      value: it.id ? `${it.lastName}_${it.id}` : it.value,
      className: it.id ? b("select-item") : "",
      label: it.id ? (
        <div className={b("name-wrapper")}>
          <span
            dangerouslySetInnerHTML={{
              __html: it.lastName.replace(reg, (res: string) => `<span class="${b("highlight")}">${res}</span>`),
            }}
          />
          <span>{` ${it.firstname} ${it.middleName}`}</span>
          <div className={b("client-info")}>{`ИНН ${it.inn}`}</div>
          <div className={b("client-info")}>{`Паспорт ${it.snp}`}</div>
        </div>
      ) : (
        <span
          dangerouslySetInnerHTML={{
            __html: it.value.replace(reg, (res: string) => `<span class="${b("highlight")}">${res}</span>`),
          }}
        />
      ),
    };
  });
};

class CommonForm extends Component<IProps> {
  onLastNameSelect = (value: string) => {
    const { form, dispatch } = this.props;
    detectDataByLastName(value, attributes["Фамилия"], form, dispatch);
  };

  renderNalogRadio = (disabled: boolean) => {
    return (
      <div className={b("nalog-radio")}>
        <Field name={attributes["Система налогобложения"]} component={ARadioGroup} buttonStyle="solid">
          <Field
            type="radio"
            name={attributes["Система налогобложения"]}
            value={TaxTypes["УСН (доходы)"]}
            title="УСН (доходы)"
            component={ARadio}
            disabled={disabled}
          />
          <Field
            type="radio"
            name={attributes["Система налогобложения"]}
            value={TaxTypes["УСН (доходы минус расходы)"]}
            title="УСН (доходы минус расходы)"
            component={ARadio}
            disabled={disabled}
          />
          <Field
            type="radio"
            name={attributes["Система налогобложения"]}
            value={TaxTypes["ОСНО (с НДС)"]}
            title="ОСНО (с НДС)"
            component={ARadio}
            disabled={disabled}
          />
        </Field>
      </div>
    );
  };

  render() {
    const {
      handleSubmit,
      autoCompleteData,
      disabled,
      showNalog,
      showPhone,
      showFnsPhone,
      form,
      inputsColors,
      shortEmail,
      passportChange,
      className,
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className={classNames(sf(), b(), className)}>
        <div className={sf("section-wrapper")}>
          <section className={sf("section")}>
            <h2 className={sf("title")}>Контакты и общая информация</h2>
            <div className={sf("inputs-grid")}>
              <Field
                title="Фамилия:"
                name={attributes["Фамилия"]}
                options={autoCompleteData[attributes["Фамилия"]] || []}
                component={AAutoComplete}
                disabled={disabled || passportChange}
                outlineColor={inputsColors[`${form}-${attributes["Фамилия"]}`]}
                renderOptions={renderLastNameOptions}
                //@ts-ignore
                onSelect={this.onLastNameSelect}
                normalize={(v: string) => (v ? v.split("_")[0] : "")}
              />
              <Field
                title="Имя:"
                name={attributes["Имя"]}
                className={b("firstname")}
                options={autoCompleteData[attributes["Имя"]] || []}
                component={AAutoComplete}
                disabled={disabled || passportChange}
                outlineColor={inputsColors[`${form}-${attributes["Имя"]}`]}
              />
              <Field
                title="Отчество:"
                name={attributes["Отчество"]}
                className={b("middlename")}
                options={autoCompleteData[attributes["Отчество"]] || []}
                component={AAutoComplete}
                disabled={disabled || passportChange}
                outlineColor={inputsColors[`${form}-${attributes["Отчество"]}`]}
              />
              <Field
                title="Электронная почта:"
                name={attributes["Электронная почта"]}
                className={b("email", { short: shortEmail })}
                component={AAutoComplete}
                options={autoCompleteData[attributes["Электронная почта"]] || []}
                disabled={disabled}
              />
              {showPhone && (
                <Field
                  title="Номер телефона:"
                  mask="+7 999 999-99-99"
                  name={attributes["Телефон"]}
                  className={b("phone")}
                  component={AInput}
                  disabled={disabled || passportChange}
                />
              )}
              {showFnsPhone && (
                <Field
                  title="Номер телефона для ФНС:"
                  mask="+7 999 999-99-99"
                  name={attributes["Номер телефона для ФНС"]}
                  className={b("phone")}
                  component={AInput}
                  disabled={disabled}
                />
              )}
            </div>

            {showNalog && (
              <div className={sf("radio")}>
                <label className={sf("radio-title")}>Система налогообложения:</label>
                {this.renderNalogRadio(disabled)}
              </div>
            )}
          </section>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { form, data } = ownProps;
  const { inputsColors } = state.input;
  const autoCompleteData = state.input.autoCompleteData[form] || {};
  const initialValues = data ? getInitialValues(data) : {};

  return { autoCompleteData, initialValues, inputsColors };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  validate,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(CommonForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as CommonForm };
