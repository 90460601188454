import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./PassportChange.scss";
import { Breadcrumbs, Loader, EntryAside } from "src/components";
import { connect } from "react-redux";
import { Forms } from "./Forms/Forms";
import { CertificatesStatusForm } from "src/forms";
import { pages } from "src/constants";
import { getPassport } from "src/redux/passports";
import { history, isAdmin } from "src/helpers";
import qs from "query-string";
import { Helmet } from "react-helmet";
import { AppState } from "src/redux/AppState";
import { IProps } from "./interface";

const b = cn("passport-change-page");
const ep = cn("entry-page");

const breadcrumbsData = {
  backLabel: "Назад в панель управления",
  items: [{ text: "Клиенты", href: pages.clients }, { text: "Татаринцев Юрий Сергеевич" }],
};

class PassportChange extends Component<IProps> {
  componentDidMount() {
    const { getPassport } = this.props;
    const queryParams = qs.parse(history.location.search);
    const { id } = queryParams;
    //@ts-ignore
    getPassport(id);
  }

  render() {
    const { loading, currentPassport, clientName, userCompanyId } = this.props;

    return (
      <div className={`${b()} ${ep()}`}>
        <Helmet>
          <title>{clientName}</title>
        </Helmet>
        {loading && <Loader />}
        <div className={ep("content-inner")}>
          <EntryAside
            showSteps={false}
            additionalContent={
              <CertificatesStatusForm data={currentPassport} disabled={loading || !isAdmin(userCompanyId)} />
            }
          />
          <div className={ep("wrapper")}>
            <Breadcrumbs {...breadcrumbsData} className={ep("breadcrumbs")} />
            <Forms currentPassport={currentPassport} disabled={loading} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const userCompanyId = state.userReducer.user?.company?.id;
  const { loading, error, currentPassport } = state.passports;
  const lastName = currentPassport?.lastName;
  const firstName = currentPassport?.firstname;
  const middleName = currentPassport?.middleName;
  const clientName =
    (lastName && firstName && `${lastName} ${firstName}${middleName ? ` ${middleName}` : ""}`) || "Buro";

  return {
    error,
    loading,
    currentPassport,
    clientName,
    userCompanyId,
  };
};

const mapDispatchToProps = {
  getPassport,
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(PassportChange);

export { connectedComponent as PassportChange };
