import React from "react";
import classNames from "classnames/dedupe";
import { cn } from "src/helpers/bem";
import { defaultProps, IProps, IState } from "./interface";
import "./BankForms.scss";
import { ShortCommonForm, ShortPassportForm, ShortRegistrationForm, CompanyNameAndINNForm } from "src/forms";
import { Divider } from "antd";
import { sendMarketplaceCardForm } from "src/redux/marketplace";
import { connect } from "react-redux";
import { attributes } from "src/constants";
import { AppState } from "src/redux/AppState";

const b = cn("bank-forms");
const mf = cn("marketplace-forms");

class BankForms extends React.Component<IProps, IState> {
  static defaultProps = defaultProps;

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { className, sendMarketplaceCardForm, cardData, onSuccessSend } = this.props;

    return (
      <div className={classNames(b(), className, mf())}>
        <ShortCommonForm
          fieldsNames={{
            [attributes["Фамилия"]]: attributes["Фамилия маркетплейс"],
            [attributes["Имя"]]: attributes["Имя маркетплейс"],
            [attributes["Отчество"]]: attributes["Отчество маркетплейс"],
            [attributes["Телефон"]]: attributes["Телефон"],
            [attributes["Электронная почта"]]: attributes["Электронная почта маркетплейс"],
            [attributes["Пол"]]: attributes["Пол маркетплейс"],
          }}
        />
        <Divider />
        <ShortPassportForm
          fieldsNames={{
            [attributes["Серия и номер паспорта"]]: attributes["Серия и номер паспорта маркетплейс"],
            [attributes["Дата выдачи паспорта"]]: attributes["Дата выдачи паспорта маркетплейс"],
            [attributes["Дата рождения"]]: attributes["Дата рождения маркетплейс"],
            [attributes["СНИЛС маркетплейс"]]: attributes["СНИЛС маркетплейс"],
          }}
        />
        <Divider />
        <ShortRegistrationForm
          fieldsNames={{
            [attributes["Полный адрес"]]: attributes["Полный адрес маркетплейс"],
          }}
        />
        <Divider />
        <CompanyNameAndINNForm />
        <button
          type="button"
          className={classNames("custom-button", mf("send-button"))}
          onClick={async () => {
            const res = await sendMarketplaceCardForm(cardData);
            if (res) {
              onSuccessSend();
            }
          }}
        >
          Отправить
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {};
};

const mapDispatchToProps = {
  sendMarketplaceCardForm,
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(BankForms);
export { connectedComponent as BankForms };
