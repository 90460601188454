import { attributes } from "src/constants";
export const getInitialValues = (entryData: Record<string, any>) => {
  return {
    [attributes["Фамилия"]]: entryData[attributes["Фамилия"]],
    [attributes["Имя"]]: entryData[attributes["Имя"]],
    [attributes["Отчество"]]: entryData[attributes["Отчество"]],
    [attributes["Телефон"]]: entryData[attributes["Телефон"]],
    [attributes["Номер телефона для ФНС"]]: entryData[attributes["Номер телефона для ФНС"]],
    [attributes["Электронная почта"]]: entryData[attributes["Электронная почта"]],
    [attributes["Система налогобложения"]]: entryData[attributes["Система налогобложения"]],
  };
};
