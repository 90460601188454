import { IForm } from "src/helpers/types/Form";
import { formsNames } from "src/constants";
import { UserBusinessStatusesType } from "src/helpers/types/User";

export interface IProps extends IForm {
  className?: string;
  data: Record<string, any>;
  userStatus: UserBusinessStatusesType;
}

export interface IState {}

export const defaultProps = {
  form: formsNames["companyRequisites"],
};
