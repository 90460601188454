import { entriesConstants } from "src/redux/entries";
import { message } from "antd";
import { API, validateEntryFiles } from "src/helpers";
import { endpoints, errorsTypes } from "src/constants";
import { getEntry } from "./index";

export const activateKep = (id: number): any => {
  const { ACTIVATE_KEP_REQUEST, ACTIVATE_KEP_SUCCESS, ACTIVATE_KEP_FAILURE } = entriesConstants;
  const request = () => ({ type: ACTIVATE_KEP_REQUEST });
  const success = () => ({ type: ACTIVATE_KEP_SUCCESS });
  const failure = () => ({ type: ACTIVATE_KEP_FAILURE });

  return async function (dispatch: Function) {
    // проверяем, что основные файлы загружены
    const formCorrect = validateEntryFiles(dispatch);

    if (!formCorrect) {
      message.error(errorsTypes.requiredFields);
      return;
    }

    dispatch(request());

    try {
      await API.post(`${endpoints.entries.activateKep}/${id}`);
      dispatch(getEntry(id));
      dispatch(success());

      // dispatch(changeCurrentEntry("scene", "vipusk4"));
    } catch (err) {
      console.log("err =", err);
      message.destroy();
      message.error(`Не удалось активировать КЭП: ${err}`);
      dispatch(failure());
    }
  };
};
