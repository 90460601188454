import { IInputState } from "src/redux/input";
import { Action } from "redux";

interface IAction extends Action {
  form: string;
  inputName: string;
  data: Record<string, any>;
}

export const autocompleteLoadSuccess = (state: IInputState, action: IAction) => {
  const { form, inputName } = action;
  return {
    ...state,
    autoCompleteData: { ...state.autoCompleteData, [form]: { [inputName]: action.data } },
  };
};
