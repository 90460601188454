export const inputConstants = {
  AUTOCOMPLETE_LOAD: "AUTOCOMPLETE_LOAD",
  AUTOCOMPLETE_LOAD_SUCCESS: "AUTOCOMPLETE_LOAD_SUCCESS",
  AUTOCOMPLETE_LOAD_FAILED: "AUTOCOMPLETE_LOAD_FAILED",

  LOAD_INN_REQUEST: "LOAD_INN_REQUEST",
  LOAD_INN_SUCCESS: "LOAD_INN_SUCCESS",
  LOAD_INN_FAILURE: "LOAD_INN_FAILURE",

  SONO_AREA_LOAD_REQUEST: "SONO_AREA_LOAD_REQUEST",
  SONO_AREA_LOAD_SUCCESS: "SONO_AREA_LOAD_SUCCESS",
  SONO_AREA_LOAD_FAILED: "SONO_AREA_LOAD_FAILED",

  ISSUEDBY_LOAD: "ISSUEDBY_LOAD",
  ISSUEDBY_LOAD_SUCCESS: "ISSUEDBY_LOAD_SUCCESS",
  ISSUEDBY_LOAD_FAILED: "ISSUEDBY_LOAD_FAILED",

  CHECK_PASSPORT_DATA_REQUEST: "CHECK_PASSPORT_DATA_REQUEST",
  CHECK_PASSPORT_DATA_SUCCESS: "CHECK_PASSPORT_DATA_SUCCESS",
  CHECK_PASSPORT_DATA_FAILURE: "CHECK_PASSPORT_DATA_FAILURE",

  DADATA_INFO_LOAD: "DADATA_INFO_LOAD",
  DADATA_INFO_LOAD_SUCCESS: "DADATA_INFO_LOAD_SUCCESS",
  DADATA_INFO_LOAD_FAILED: "DADATA_INFO_LOAD_FAILED",

  GET_OKTMO_INFO_REQUEST: "GET_OKTMO_INFO_REQUEST",
  GET_OKTMO_INFO_SUCCESS: "GET_OKTMO_INFO_SUCCESS",
  GET_OKTMO_INFO_FAILURE: "GET_OKTMO_INFO_FAILURE",

  RESET_LAST_CHANGED_FIELD: "RESET_LAST_CHANGED_FIELD",

  UPDATE_INPUT_COLOR: "UPDATE_INPUT_COLOR",

  CLEAR_INPUT_DATA: "CLEAR_INPUT_DATA",

  TOGGLE_NEXT_AUTOCOMPLETE_LOAD: "TOGGLE_NEXT_AUTOCOMPLETE_LOAD",
};
