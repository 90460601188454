import { IFilesState } from "src/redux/files";

export const loadFileByTokenRequest = (state: IFilesState): IFilesState => {
  return {
    ...state,
    loading: true,
  };
};

export const loadFileByTokenSuccess = (state: IFilesState): IFilesState => {
  return {
    ...state,
    loading: false,
    error: false,
  };
};

export const loadFileByTokenFailure = (state: IFilesState): IFilesState => {
  return {
    ...state,
    loading: false,
    error: true,
  };
};
