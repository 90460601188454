import { filesConstants } from "./../files.constants";
import { createReducer } from "@reduxjs/toolkit";
import { loadFileRequest, loadFileSuccess, loadFileFailure } from "./loadFile";
import { loadFileByTokenRequest, loadFileByTokenSuccess, loadFileByTokenFailure } from "./loadFileByToken";
import { removeFileRequest, removeFileSuccess, removeFileFailure } from "./removeFile";
import { uploadFileRequest, uploadFileSuccess, uploadFileFailure } from "./uploadFile";
import { fileValidateError } from "./fileValidate";
import { clearFileState } from "./clearFileState";
import { addFileToArchive } from "./addFileToArchive";
import { fillOtherFiles } from "./fillOtherFiles";
import { addOtherFile } from "./addOtherFile";
import { removeOtherFile } from "./removeOtherFile";
import { removeFileByTokenRequest, removeFileByTokenSuccess, removeFileByTokenFailure } from "./removeFileByToken";

export interface IFilesState {
  loading: boolean;
  error: boolean;
  loaded: Record<string, boolean | undefined>;
  inArchive: Record<string, boolean>;
  validateErrors: Record<string, any>;
  otherFiles: { maxFileId: number; files: string[] };
}

export const initialState: IFilesState = {
  loading: false,
  error: false,
  loaded: {},
  inArchive: {},
  validateErrors: {},
  otherFiles: { maxFileId: 0, files: [] },
};

export const files = createReducer(initialState, {
  [filesConstants.FILE_LOAD_REQUEST]: loadFileRequest,
  [filesConstants.FILE_LOAD_SUCCESS]: loadFileSuccess,
  [filesConstants.FILE_LOAD_FAILURE]: loadFileFailure,

  [filesConstants.LOAD_FILE_BY_TOKEN_REQUEST]: loadFileByTokenRequest,
  [filesConstants.LOAD_FILE_BY_TOKEN_SUCCESS]: loadFileByTokenSuccess,
  [filesConstants.LOAD_FILE_BY_TOKEN_FAILURE]: loadFileByTokenFailure,

  [filesConstants.FILE_REMOVE_REQUEST]: removeFileRequest,
  [filesConstants.FILE_REMOVE_SUCCESS]: removeFileSuccess,
  [filesConstants.FILE_REMOVE_FAILURE]: removeFileFailure,

  [filesConstants.REMOVE_FILE_BY_TOKEN_REQUEST]: removeFileByTokenRequest,
  [filesConstants.REMOVE_FILE_BY_TOKEN_SUCCESS]: removeFileByTokenSuccess,
  [filesConstants.REMOVE_FILE_BY_TOKEN_FAILURE]: removeFileByTokenFailure,

  [filesConstants.FILE_UPLOAD_REQUEST]: uploadFileRequest,
  [filesConstants.FILE_UPLOAD_SUCCESS]: uploadFileSuccess,
  [filesConstants.FILE_UPLOAD_FAILURE]: uploadFileFailure,

  [filesConstants.FILE_VALIDATE_ERROR]: fileValidateError,

  [filesConstants.FILE_ADD_TO_ARCHIVE]: addFileToArchive,

  [filesConstants.FILL_OTHER_FILES]: fillOtherFiles,
  [filesConstants.ADD_OTHER_FILE]: addOtherFile,
  [filesConstants.REMOVE_OTHER_FILE]: removeOtherFile,

  [filesConstants.FILE_CLEAR_STATE]: clearFileState,
});
