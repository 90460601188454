import React from "react";
import { cn } from "src/helpers/bem";
import "./RadioGroup.scss";
import { Radio as AntdRadio } from "antd";
import { Tooltip } from "src/components";
import { errorsTypes } from "src/constants";
import { IProps } from "src/components/RadioGroup/interface";

const b = cn("site-radio");

export const RadioGroup = ({ name, title, meta, children, ...rest }: IProps) => {
  const { error, submitFailed } = meta;

  return (
    <div className={b()}>
      <AntdRadio.Group name={name} {...rest}>
        {title && <div>{title}</div>}
        {children}
      </AntdRadio.Group>
      {submitFailed && error === errorsTypes.requiredField && (
        <Tooltip className={b("tooltip")} title={errorsTypes.requiredField} visible={true} />
      )}
    </div>
  );
};
