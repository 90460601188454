import { entriesConstants } from "./../entries.constants";
import { LINK_POOLING_INTERVAL } from "src/constants";
import { message } from "antd";
import { API, parseParams } from "src/helpers";
import { endpoints } from "src/constants";
import { changeCurrentEntry } from "./index";

interface ResponseType {
  sign: "false" | "true";
}

export const linkPooling = (id: number, entryType: string, params = {}) => {
  const { ENTRY_LINK_POOLING_REQUEST, ENTRY_LINK_POOLING_SUCCESS, ENTRY_LINK_POOLING_FAILURE } = entriesConstants;
  const request = (descriptor: ReturnType<typeof setTimeout>) => ({ type: ENTRY_LINK_POOLING_REQUEST, descriptor });
  const success = (linkData: ResponseType) => ({ type: ENTRY_LINK_POOLING_SUCCESS, linkData });
  const failure = () => ({ type: ENTRY_LINK_POOLING_FAILURE });

  // преобразовываем параметры запроса из объекта в строку вида '?param1=value1&param2=value2'
  const paramsString = parseParams(params);

  return async function (dispatch: Function) {
    const descriptor = setInterval(async () => {
      try {
        const res: { sign: "false" | "true" } = await API.get(
          //@ts-ignore
          `${endpoints.entries[`linkPooling_${entryType}`]}/${id}${paramsString}`,
        );
        if (res && res.sign === "true") {
          message.destroy();
          dispatch(success(res));
          dispatch(changeCurrentEntry("scene", "fns5"));
          clearInterval(descriptor);
        }
      } catch (err: any) {
        message.destroy();
        message.error(`Не удалось определить статус создания ссылки: ${err}`);
        dispatch(failure());
        clearInterval(descriptor);
      }
    }, LINK_POOLING_INTERVAL);

    dispatch(request(descriptor));
  };
};
