import { IUser } from "src/helpers/types/User";
import { UploadFile } from "antd/es/upload/interface";

export interface IProps {
  className?: string;
  username: Partial<IUser>;
  url: string;
  disabled: boolean;
  onChange(url: string): void;
}

export interface IState {
  modalVisible: boolean;
  uploadedFile: UploadFile | null;
}

export const defaultProps = {
  disabled: false,
};
