import axios, { AxiosInstance } from "axios";
import { AXIOS_TIMEOUT, baseURL, dadataBaseUrl, dadataToken, signmeBaseUrl } from "src/constants";
import { getWithExpiry } from "src/helpers";

const setInterceptors = (api: AxiosInstance) => {
  api.interceptors.response.use(
    function (response) {
      return response.data;
    },
    function (error) {
      if (typeof error.response?.data === "string" && error.response?.data?.includes("<html")) {
        return Promise.reject("Загрузка данных не удалась");
      }

      if (error.message.includes("timeout")) {
        return Promise.reject("Превышено время ожидания запроса");
      }
      return Promise.reject(JSON.stringify(error?.response?.data) || "Неизвестная ошибка");
    }
  );
};

export const API_REQUEST_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: getWithExpiry("token"),
};

let API = axios.create({
  baseURL,
  timeout: AXIOS_TIMEOUT,
  headers: API_REQUEST_HEADERS,
});

setInterceptors(API);

export const reinitializeAPI = () => {
  API = axios.create({
    baseURL,
    timeout: AXIOS_TIMEOUT,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getWithExpiry("token"),
    },
  });

  setInterceptors(API);
};

const dadataAPI = axios.create({
  baseURL: dadataBaseUrl,
  timeout: AXIOS_TIMEOUT,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Token " + dadataToken,
  },
});

setInterceptors(dadataAPI);

const signmeAPI = axios.create({
  baseURL: signmeBaseUrl,
  timeout: AXIOS_TIMEOUT,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

setInterceptors(signmeAPI);

export { API, dadataAPI, signmeAPI };
