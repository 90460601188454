import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./IPregistration.scss";
import { Breadcrumbs, Loader, EntryAside } from "src/components";
import { connect } from "react-redux";
import { Forms } from "./Forms/Forms";
import { pages, entriesTypes } from "src/constants";
import { createEntry, getEntry, clearCurrentEntry } from "src/redux/entries";
import { history, loadOrCreateEntry, isScene } from "src/helpers";
import { clearFileState } from "src/redux/files";
import qs from "query-string";
import { Helmet } from "react-helmet";
import { clearInputData } from "src/redux/input/actions/clearInputData";
import { message } from "antd";
import { AppState } from "src/redux/AppState";
import { IProps } from "./interface";

// const b = cn("registration-page");
const ep = cn("entry-page");

const breadcrumbsData = {
  backLabel: "Назад в панель управления",
  items: [{ text: "Регистрация в ФНС", href: pages.FNSregistration }, { text: "Регистрация ИП" }],
};

class IPregistration extends Component<IProps> {
  componentWillMount() {
    const { createEntry, getEntry } = this.props;
    loadOrCreateEntry(entriesTypes["РегистрацияИП"], createEntry, getEntry);
  }

  componentDidUpdate(prevProps: IProps) {
    // костыль для копирования заявки со страницы самой заявки
    // при копировании у нас меняется адресная строка, туда записывается ?id=newCopiedId
    // но компонент не обновлялся, потому что единственный вызов к апишке у него в componentDidMount
    if (prevProps.location?.search !== this.props.location?.search) {
      const { createEntry, getEntry } = this.props;
      loadOrCreateEntry(entriesTypes["РегистрацияИП"], createEntry, getEntry);
    }
  }

  componentWillUnmount() {
    const { clearInputData, clearFileState, clearCurrentEntry } = this.props;
    clearInputData();
    clearFileState();
    clearCurrentEntry();
    message.destroy();
  }

  resetEntry = () => {
    const { getEntry } = this.props;
    const queryParams = qs.parse(history.location.search);
    //@ts-ignore
    fetch(`https://buro.app/api/v1/reset_entry_admin/${queryParams["id"]}`).then(() => getEntry(queryParams["id"]));
  };

  render() {
    const { loading, loadingWithoutLogo, scene } = this.props;

    return (
      <div className={ep()}>
        <Helmet>
          <title>Регистрация ИП</title>
        </Helmet>
        {loading && <Loader hideLogo={loadingWithoutLogo} />}
        <div className={ep("content-inner")}>
          <EntryAside />
          <div className={ep("wrapper")}>
            <Breadcrumbs {...breadcrumbsData} className={ep("breadcrumbs")} />
            {/*<button onClick={this.resetEntry}>Сбросить заявку</button>*/}
            <Forms disabled={isScene("Выпущена КЭП", scene)} scene={scene} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { loading: entriesLoading, loadingWithoutLogo, currentEntry } = state.entries;
  const { loading: activitiesLoading } = state.activities;
  const loading = entriesLoading || activitiesLoading;
  const scene = currentEntry?.scene;

  return {
    loading,
    loadingWithoutLogo,
    scene,
  };
};

const mapDispatchToProps = {
  createEntry,
  getEntry,
  clearCurrentEntry,
  clearFileState,
  clearInputData,
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(IPregistration);

export { connectedComponent as IPregistration };
