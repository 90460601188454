import { entriesConstants } from "./../entries.constants";
import { message } from "antd";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { changeCurrentEntry } from "./index";

export const sendToFNS = (entryType: string, id: string) => {
  const { ENTRY_SEND_FNS_REQUEST, ENTRY_SEND_FNS_SUCCESS, ENTRY_SEND_FNS_FAILURE } = entriesConstants;
  const request = () => ({ type: ENTRY_SEND_FNS_REQUEST });
  const success = () => ({ type: ENTRY_SEND_FNS_SUCCESS });
  const failure = () => ({ type: ENTRY_SEND_FNS_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      //@ts-ignore
      await API.get(`${endpoints.entries[`sendFNS_${entryType}`]}/${id}`);
      dispatch(success());
      dispatch(changeCurrentEntry("scene", "fns_pending"));
    } catch (err: any) {
      message.error(`Ошибка при отправке данных в ФНС: ${err}`);
      dispatch(failure());
    }
  };
};
