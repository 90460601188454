import React, { FC, useState } from "react";
import { cn } from "src/helpers/bem";
import classNames from "classnames/dedupe";
import "./CrtCheck.scss";
import { connect } from "react-redux";
import {
  Field,
  FormErrors,
  formValueSelector,
  getFormSyncErrors,
  isDirty,
  isValid,
  reduxForm,
  submit,
} from "redux-form";
import { Loader } from "src/components";
import { AInput } from "src/helpers";
import { Helmet } from "react-helmet";
import { attributes, errorsTypes, formsNames } from "src/constants";
import validate from "./validate";
import { message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { ReactComponent as CheckCircleIcon } from "src/assets/images/check-circle-icon.svg";
import { IProps } from "./interface";
import { BaseComponentProps } from "../../helpers/types/Component";
import {
  CrtDetailedInfoSource,
  CrtInfo,
  useLazyGetCertificateDataByEmailQuery,
  useLazyGetCertificateDataByInnQuery,
  useLazyGetCertificateDataByOGRNQuery,
  useLazyGetCertificateDataByPhoneQuery,
  useLazyGetCertificateDataBySnilsQuery,
  useLazyGetDetailedCertificateInfoQuery,
} from "../../redux/certificates";
import { useAppSelector } from "../../helpers/redux";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { DetailedCertificateInfo } from "./components";
import { CrtCheckButtons } from "./helpers";

const b = cn("crt-check-page");
const ep = cn("entry-page");
const sf = cn("site-form");
const sfs = cn("site-forms");

const CrtCheck: FC<BaseComponentProps & IProps> = ({ className, handleSubmit }) => {
  const [fetchByPhone, byPhoneData] = useLazyGetCertificateDataByPhoneQuery();
  const [fetchByOgrn, byOgrnData] = useLazyGetCertificateDataByOGRNQuery();
  const [fetchByEmail, byEmailData] = useLazyGetCertificateDataByEmailQuery();
  const [fetchByInn, byInnData] = useLazyGetCertificateDataByInnQuery();
  const [fetchBySnils, bySnilsData] = useLazyGetCertificateDataBySnilsQuery();
  const [fetchDetailedCertificateInfo, detailedCertData] = useLazyGetDetailedCertificateInfoQuery();
  const [lastClickedButton, setLastClickedButton] = useState<CrtCheckButtons | null>(null);
  const state = useAppSelector((state) => state);
  const formValid = isValid(formsNames["crtCheck"])(state);
  const formDirty = isDirty(formsNames["crtCheck"])(state);
  const formValues = formValueSelector<any>(formsNames["crtCheck"]);
  const formErrors: FormErrors<any> = getFormSyncErrors(formsNames["crtCheck"])(state);
  const phone = formValues(state, attributes["Телефон"])?.replace(/[^\d]/g, "");
  const email = formValues(state, attributes["Электронная почта"]);
  const inn = formValues(state, attributes["ИНН"]);
  const snils = formValues(state, attributes["СНИЛС"]);
  const ogrn = formValues(state, attributes["ОГРН"]);

  const phoneCorrect = Boolean(phone) && !Boolean(formErrors[attributes["Телефон"]]);
  const snilsCorrect = Boolean(snils) && !Boolean(formErrors[attributes["СНИЛС"]]);

  async function handleDetailedInfoClicked({ source }: { source: CrtDetailedInfoSource }) {
    const res = await fetchDetailedCertificateInfo({
      source,
      value: source === CrtDetailedInfoSource.PHONE ? phone : snils,
    });
    if (res.status === QueryStatus.fulfilled) {
      setLastClickedButton(CrtCheckButtons.DETAILED_INFO);
    }
  }

  function handleCheckButtonClick() {
    if (formDirty) {
      if (formValid) {
        email && fetchByEmail({ value: email });
        phone && fetchByPhone({ value: phone });
        inn && fetchByInn({ value: inn });
        snils && fetchBySnils({ value: snils });
        ogrn && fetchByOgrn({ value: ogrn });
        setLastClickedButton(CrtCheckButtons.INFO);
      } else {
        message.error(errorsTypes.requiredFields);
      }
    } else {
      message.error(errorsTypes.emptyForm);
    }
  }

  function renderAdditionalCrtData({ data }: { data: CrtInfo }) {
    return (
      <div className={b("additional-crt-data")}>
        <div className={b("status", { success: data.approved })}>{data.approved ? "Одобрен" : "Не одобрен"}</div>
        <div className={b("status", { success: data.created })}>{data.created ? "Ключ создан" : "Ключ не создан"}</div>
        <div className={b("status", { success: data.mine })}>{data.mine ? "Мой клиент" : "Чужой клиент"}</div>
      </div>
    );
  }

  function renderCertificateData({
    data,
    queryStatus,
    withDetailedInfo,
  }: {
    data?: CrtInfo;
    queryStatus: QueryStatus;
    withDetailedInfo?: boolean;
  }) {
    if (lastClickedButton === CrtCheckButtons.INFO) {
      if (data) {
        if (data.pdf) {
          return (
            <>
              <a href={`data:application/pdf;base64,${data.pdf}`} download className={b("pdf-link")}>
                <DownloadOutlined className={b("download-icon")} />
                <span
                  className={b("info-text", {
                    "with-dot": true,
                  })}
                >{`ID ${data.id}`}</span>
                {renderAdditionalCrtData({ data })}
              </a>
            </>
          );
        } else {
          return (
            <div className={b("info")}>
              <CheckCircleIcon className={b("success-icon")} />
              <span>{`ID ${data.id}`}</span>
            </div>
          );
        }
      } else {
        if (queryStatus === QueryStatus.fulfilled) {
          return <span className={b("info-text")}>Сертификат не найден</span>;
        }
      }
    } else if (lastClickedButton === CrtCheckButtons.DETAILED_INFO && withDetailedInfo) {
      if (detailedCertData.data) {
        return <DetailedCertificateInfo data={detailedCertData.data} />;
      }
    }
  }

  function renderForm() {
    return (
      <form className={classNames(sf(), b("form-wrapper"))} onSubmit={handleSubmit}>
        <div className={b("inputs-wrapper", { "two-columns": lastClickedButton === CrtCheckButtons.DETAILED_INFO })}>
          <div className={b("input-wrapper")}>
            <Field
              title="Номер телефона:"
              mask="+7 999 999-99-99"
              name={attributes["Телефон"]}
              component={AInput}
              className={b("input")}
            />
            {phoneCorrect ? (
              <button
                type="submit"
                className={classNames("custom-button", b("detailed-info-btn"))}
                onClick={() => handleDetailedInfoClicked({ source: CrtDetailedInfoSource.PHONE })}
              >
                Информация
              </button>
            ) : null}
          </div>

          <div className={b("info-wrapper")}>
            {renderCertificateData({
              data: byPhoneData.data?.status?.phone,
              queryStatus: byPhoneData.status,
            })}
          </div>
          <Field
            title="Электронная почта:"
            name={attributes["Электронная почта"]}
            component={AInput}
            className={b("input")}
          />
          <div className={b("info-wrapper")}>
            {renderCertificateData({ data: byEmailData.data?.status?.email, queryStatus: byEmailData.status })}
          </div>
          <div className={b("input-wrapper")}>
            <Field
              title="СНИЛС:"
              name={attributes["СНИЛС"]}
              mask="999-999-999 99"
              component={AInput}
              className={b("input")}
            />
            {snilsCorrect ? (
              <button
                type="submit"
                className={classNames("custom-button", b("detailed-info-btn"))}
                onClick={() => handleDetailedInfoClicked({ source: CrtDetailedInfoSource.SNILS })}
              >
                Информация
              </button>
            ) : null}
          </div>

          <div className={b("info-wrapper")}>
            {renderCertificateData({ data: bySnilsData.data?.status?.snils, queryStatus: bySnilsData.status })}
          </div>
          <Field title="ИНН:" name={attributes["ИНН"]} component={AInput} mask="999999999999" className={b("input")} />
          <div className={b("info-wrapper")}>
            {renderCertificateData({ data: byInnData.data?.status?.inn, queryStatus: byInnData.status })}
          </div>

          <Field
            title="ОГРН"
            name={attributes["ОГРН"]}
            component={AInput}
            mask="9999999999999"
            className={b("input")}
          />
          <div className={classNames(b("info-wrapper"), b("detailed-info"))}>
            {renderCertificateData({
              data: byOgrnData.data?.status?.cogrn,
              queryStatus: byOgrnData.status,
              withDetailedInfo: true,
            })}
          </div>
        </div>

        <button
          type="submit"
          className={classNames("custom-button", b("submit-button"))}
          onClick={handleCheckButtonClick}
        >
          Получить сертификат
        </button>
      </form>
    );
  }

  const loading =
    byOgrnData.isFetching ||
    byPhoneData.isFetching ||
    byInnData.isFetching ||
    bySnilsData.isFetching ||
    byEmailData.isFetching ||
    detailedCertData.isFetching;

  return (
    <div className={classNames(className, b(), ep())}>
      <Helmet>
        <title>Проверка сертификата</title>
      </Helmet>
      {loading && <Loader />}
      <div className={classNames(ep("content-inner"), b("content"))}>
        <div className={ep("wrapper")}>
          <div className={classNames(sfs(), b("container"))}>{renderForm()}</div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;

  return {
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  validate,
  //@ts-ignore
})(CrtCheck);

connectedComponent = connect(() => {}, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = {
  form: formsNames["crtCheck"],
};

export { connectedComponent as CrtCheck };
