import { createStore, applyMiddleware, Middleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./rootReducer";
import { selfSigningApi } from "src/redux/selfSigning";
import { certificatesApi } from "./certificates";
import { rtkQueryErrorLogger } from "./rtkQueryErrorMiddleware";

let middleware: Middleware[] = [rtkQueryErrorLogger, selfSigningApi.middleware, certificatesApi.middleware];
if (process.env.NODE_ENV !== "production") {
  const loggerMiddleware = createLogger({ collapsed: true });
  middleware = [...middleware, thunkMiddleware, loggerMiddleware];
} else {
  middleware = [...middleware, thunkMiddleware];
}

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));
