import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./CompanyAdmin.scss";
import { AInput, AAutoComplete } from "src/helpers";
import { connect } from "react-redux";
import { Field, reduxForm, submit } from "redux-form";
import { attributes, formsNames } from "src/constants";
import validate from "./validate";
import { AppState } from "src/redux/AppState";
import { IProps } from "./interface";

const sf = cn("site-form");
const b = cn("company-admin-form");

class CompanyAdminForm extends Component<IProps> {
  render() {
    const { handleSubmit, autoCompleteData, disabled } = this.props;

    return (
      <form onSubmit={handleSubmit} className={`${sf()} ${b()}`}>
        <div className={sf("section-wrapper")}>
          <section className={sf("section")}>
            <h2 className={sf("title")}>Администратор компании</h2>
            <div className={sf("inputs-grid")}>
              <Field
                title="Фамилия:"
                name={attributes["Фамилия"]}
                options={autoCompleteData[attributes["Фамилия"]] || []}
                component={AAutoComplete}
                disabled={disabled}
              />
              <Field
                title="Имя:"
                name={attributes["имя2"]}
                options={autoCompleteData[attributes["имя2"]] || []}
                component={AAutoComplete}
                disabled={disabled}
              />
              <Field
                title="Отчество:"
                name={attributes["Отчество"]}
                options={autoCompleteData[attributes["Отчество"]] || []}
                component={AAutoComplete}
                disabled={disabled}
              />
              <Field
                title="Телефон администратора:"
                mask="+7 999 999-99-99"
                name={attributes["Телефон"]}
                component={AInput}
                disabled={disabled}
              />
              <Field
                title="Почта администратора:"
                name={attributes["емейл2"]}
                options={autoCompleteData[attributes["емейл2"]] || []}
                component={AAutoComplete}
                disabled={disabled}
                className={b("email-input")}
                placeholder="email@example.com"
              />
              <Field
                title="Пароль для входа:"
                name={attributes["Пароль"]}
                component={AInput}
                type="password"
                disabled={disabled}
              />
              <Field
                title="Повторите пароль:"
                name={attributes["Подтверждение пароля"]}
                component={AInput}
                type="password"
                disabled={disabled}
              />
            </div>
          </section>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { form } = ownProps;
  const autoCompleteData = state.input.autoCompleteData[form] || {};
  return { autoCompleteData };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  validate,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(CompanyAdminForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = {
  form: formsNames["companyAdmin"],
};

export { connectedComponent as CompanyAdminForm };
