import { attributes } from "src/constants";
export const fileFields = [
  {
    attribute: attributes["Скан паспорта"],
    title: "Скан паспорта",
    label: "Паспорт",
    accept: "image",
    fileType: "uploadIP",
  },
  {
    attribute: attributes["Скан прописки"],
    title: "Скан прописки",
    label: "Прописка",
    accept: "image",
    fileType: "uploadIP",
  },
  {
    attribute: attributes["Скан СНИЛС"],
    title: "Скан СНИЛС",
    label: "СНИЛС",
    accept: "image",
    fileType: "uploadIP",
  },
];
