export const userConstants = {
  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",

  GET_TOKEN_REQUEST: "GET_TOKEN_REQUEST",
  GET_TOKEN_SUCCESS: "GET_TOKEN_SUCCESS",
  GET_TOKEN_FAILURE: "GET_TOKEN_FAILURE",

  GET_USER_INFO_REQUEST: "GET_USER_INFO_REQUEST",
  GET_USER_INFO_SUCCESS: "GET_USER_INFO_SUCCESS",
  GET_USER_INFO_FAILURE: "GET_USER_INFO_FAILURE",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

  CHANGE_BALANCE_VISIBILITY_REQUEST: "CHANGE_BALANCE_VISIBILITY_REQUEST",
  CHANGE_BALANCE_VISIBILITY_SUCCESS: "CHANGE_BALANCE_VISIBILITY_SUCCESS",
  CHANGE_BALANCE_VISIBILITY_FAILURE: "CHANGE_BALANCE_VISIBILITY_FAILURE",

  CHANGE_COMPANY_SETTINGS_REQUEST: "CHANGE_COMPANY_SETTINGS_REQUEST",
  CHANGE_COMPANY_SETTINGS_SUCCESS: "CHANGE_COMPANY_SETTINGS_SUCCESS",
  CHANGE_COMPANY_SETTINGS_FAILURE: "CHANGE_COMPANY_SETTINGS_FAILURE",

  USER_LOGOUT: "USERS_LOGOUT",
};
