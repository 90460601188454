//@ts-nocheck
import { statusesConstants } from "../statuses.constants";
import { message } from "antd";
import { endpoints, STATUS_POOLING_INTERVAL } from "src/constants";
import { API } from "src/helpers";

export const getSignmeStatus = () => {
  const { GET_SIGNME_STATUS_REQUEST, GET_SIGNME_STATUS_SUCCESS, GET_SIGNME_STATUS_FAILURE } = statusesConstants;
  const request = () => ({ type: GET_SIGNME_STATUS_REQUEST });
  const success = (status, descriptor) => ({ type: GET_SIGNME_STATUS_SUCCESS, status, descriptor });
  const failure = () => ({ type: GET_SIGNME_STATUS_FAILURE });

  return async function (dispatch) {
    let descriptor = null;

    const doRequest = async () => {
      try {
        const data = await API.get(`${endpoints.statuses.get.signme}`);
        const status = data.status;
        dispatch(success(status, descriptor));
      } catch (err: any) {
        message.error(`Не удалось определить статус сервиса signMe ${err}`);
        dispatch(failure());
        clearInterval(descriptor);
      }
    };

    // первый раз отправляем запрос сразу же, второй и последующие - через заданный интервал
    doRequest();
    descriptor = setInterval(doRequest, STATUS_POOLING_INTERVAL);
    dispatch(request());
  };
};
