import { cloneDeep } from "lodash";
import { IActivitiesState } from "src/redux/activities";
import { Action } from "redux";

interface IAction extends Action {
  requestAttribute: string;
  textError: string;
}

export const activitiesValidateError = (state: IActivitiesState, action: IAction): IActivitiesState => {
  const validateErrors = cloneDeep(state.validateErrors);
  const { requestAttribute, textError } = action;
  validateErrors[requestAttribute] = textError;
  return {
    ...state,
    validateErrors,
  };
};
