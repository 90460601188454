import { isFieldExist, isPasswordCorrect } from "src/helpers";
import { attributes } from "src/constants";
import { IProps } from "./interface";

export default function validate(values: Record<string, any>, props: IProps): Record<string, string> {
  // ошибки для валидации
  let errors: Record<string, string> = {};
  errors = isFieldExist(attributes["Текущий пароль"], values[attributes["Текущий пароль"]], errors);
  errors = isPasswordCorrect(attributes["Новый пароль"], values[attributes["Новый пароль"]], errors);
  errors = isPasswordCorrect(
    attributes["Подтверждение нового пароля"],
    values[attributes["Подтверждение нового пароля"]],
    errors,
  );

  if (
    values[attributes["Новый пароль"]] &&
    values[attributes["Подтверждение нового пароля"]] &&
    values[attributes["Новый пароль"]] !== values[attributes["Подтверждение нового пароля"]]
  ) {
    errors[attributes["Новый пароль"]] = "Пароли должны совпадать";
    errors[attributes["Подтверждение нового пароля"]] = "Пароли должны совпадать";
  }

  return errors;
}
