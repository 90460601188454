import { endpoints } from "src/constants";
import { inputConstants } from "./../input.constants";
import { message } from "antd";
import { API } from "src/helpers";
import { IDadataOktmoResponse } from "src/helpers/types/Dadata";

export const getOktmoInfo = (code: string) => {
  const { GET_OKTMO_INFO_REQUEST, GET_OKTMO_INFO_SUCCESS, GET_OKTMO_INFO_FAILURE } = inputConstants;
  const request = () => ({ type: GET_OKTMO_INFO_REQUEST });
  const success = () => ({ type: GET_OKTMO_INFO_SUCCESS });
  const failure = () => ({ type: GET_OKTMO_INFO_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      const data: IDadataOktmoResponse = await API.get(`${endpoints.oktmo}${code}`);
      dispatch(success());
      return data?.suggestions[0]?.data;
    } catch (err: any) {
      message.error(`Не получиль загрузить данные ОКТМО ${err}`);
      dispatch(failure());
    }
  };
};
