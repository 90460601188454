import { attributes } from "src/constants";

export const getInitialValues = (entryData: Record<string, any>) => {
  return {
    [attributes["Идентификация курьерской службой"]]: entryData[attributes["Идентификация курьерской службой"]] || "0",
    [attributes["Адрес для встречи с курьером"]]: entryData[attributes["Адрес для встречи с курьером"]] || "",
    [attributes["Адрес для встречи с курьером (полн.)"]]:
      entryData[attributes["Адрес для встречи с курьером (полн.)"]] || "",
    [attributes["Регион доставки"]]: entryData[attributes["Регион доставки"]] || "",
    [attributes["Город доставки"]]: entryData[attributes["Город доставки"]] || "",
    [attributes["Комментарий для курьера"]]: entryData[attributes["Комментарий для курьера"]] || "",
  };
};
