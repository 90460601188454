import { cloneDeep } from "lodash";
import { IFilesState } from "src/redux/files";
import { Action } from "redux";

interface IAction extends Action {
  requestAttribute: string;
}

export const removeFileRequest = (state: IFilesState, action: IAction): IFilesState => {
  return {
    ...state,
    loading: true,
  };
};

export const removeFileSuccess = (state: IFilesState, action: IAction): IFilesState => {
  const loaded = cloneDeep(state.loaded);
  loaded[action.requestAttribute] = undefined;

  return {
    ...state,
    loading: false,
    error: false,
    loaded,
  };
};

export const removeFileFailure = (state: IFilesState, action: IAction): IFilesState => {
  return {
    ...state,
    loading: false,
    error: true,
  };
};
