import { loadAutoComplete } from "src/redux/input";
import { store } from "src/redux/store";
import { attributes } from "src/constants";
import { isFieldExist, validateSono } from "src/helpers";
import { IProps } from "./interface";

// список полей, которым нужен запрос на сервер при изменении
const serverRequestNeededFields = [attributes["Полный адрес"], `executive_${attributes["Полный адрес"]}`];

export default function validate(values: Record<string, any>, props: IProps): Record<string, string> {
  // ошибки для валидации
  let errors: Record<string, string> = {};
  const { form, dispatch, customValidate, fieldsNames } = props;

  errors = validateSono(values[attributes["Код рег. органа"]], errors);

  /* заберем из store название последнего  измененого inputа и узнаем, нужно ли грузить ему autocomlete варианты */
  const { lastChangedField } = store.getState().input;

  if (lastChangedField === attributes["Полный адрес"]) {
    errors = isFieldExist(lastChangedField, values[lastChangedField], errors);
  }

  // если была передана функция дополнительной валидации
  if (customValidate) {
    errors = customValidate(values, errors, fieldsNames);
  }

  // если пользователь что-то ввел в поле и это поле есть в списке полей, которым нужно обратиться к серверу
  if (lastChangedField && serverRequestNeededFields.indexOf(lastChangedField) !== -1) {
    dispatch(loadAutoComplete(form, lastChangedField, values[lastChangedField]));
  }

  return errors;
}
