import React from "react";
import { cn } from "src/helpers/bem";
import "./AutoComplete.scss";
import { AutoComplete as AntdAutoComplete } from "antd";
import { Tooltip } from "src/components";
import { errorsTypes } from "src/constants";
import { toggleNextAutoCompleteLoad } from "src/redux/input";
import { IProps, IState } from "./interface";

const b = cn("site-autocomplete");

const highlightSearchQueryInOptions = (options: Array<any>, searchQuery: string, cursor: number) => {
  const reg = new RegExp(searchQuery.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), "gi");
  return options.map((it: any, index: number) => {
    return {
      key: index,
      value: it.value,
      label: (
        <span
          dangerouslySetInnerHTML={{
            __html: it.value?.replace(reg, (res: string) => `<span class='${b("highlight")}'>${res}</span>`),
          }}
        />
      ),
      className: cursor % options.length === index ? b("selected-item") : "",
    };
  });
};

export class AutoComplete extends React.Component<IProps, IState> {
  static defaultProps = {
    className: "",
    customBackfill: false,
    renderOptions: highlightSearchQueryInOptions,
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      cursor: -1,
    };
  }

  componentDidMount() {
    const root: any = this.refs.root;
    root.querySelector("input").setAttribute("autocomplete", "new-password");
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.options[0]?.value !== this.props.options[0]?.value) {
      this.setState({ cursor: -1 });
    }
  }

  handleKeyDown = (e: any) => {
    const { options, onChange, onSelect, value } = this.props;
    const { dispatch } = this.props.meta;
    const { cursor } = this.state;

    // вверх
    if (e.keyCode === 38) {
      dispatch(toggleNextAutoCompleteLoad(false));
      const newCursor = cursor >= 1 ? cursor - 1 : options.length - 1;
      this.setState({ cursor: newCursor });
      onChange(options[newCursor % options.length].value);
      // вниз
    } else if (e.keyCode === 40) {
      dispatch(toggleNextAutoCompleteLoad(false));
      const newCursor = (cursor + 1) % options.length;
      this.setState({ cursor: newCursor });
      onChange(options[newCursor].value);
      // enter
    } else if (e.keyCode === 13 && cursor !== -1) {
      if (options.some((opt) => opt.value === value)) {
        onSelect(value);
      }
    }
  };

  render() {
    const {
      title,
      meta,
      suffix,
      className,
      outlineColor,
      placeholder,
      customBackfill,
      options,
      renderOptions,
      value,
      prefix,
      ignoreTouched,
      ...rest
    } = this.props;
    const { touched, error, submitFailed } = meta;
    const { cursor } = this.state;
    const tooltipVisible = ignoreTouched ? Boolean(error) : Boolean(touched && error);

    return (
      <div
        className={`${b({
          [outlineColor]: Boolean(outlineColor),
          red: touched && error === errorsTypes.forbiddenSymbols,
        })} ${className}`}
        ref="root"
      >
        <label className={b("title")}>{title}</label>
        <AntdAutoComplete
          options={renderOptions(options, value, cursor)}
          value={value}
          {...rest}
          onKeyDown={customBackfill ? this.handleKeyDown : () => {}}
          virtual={false}
          size="large"
          placeholder={placeholder}
        >
          {/* <Input size="large" placeholder={placeholder} suffix={suffix} prefix={prefix} /> */}
        </AntdAutoComplete>
        {submitFailed && error === errorsTypes.requiredField && <Tooltip title={error} visible={tooltipVisible} />}
        {error !== errorsTypes.requiredField && <Tooltip title={error} visible={tooltipVisible} />}
      </div>
    );
  }
}
