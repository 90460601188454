import { IForm } from "src/helpers/types/Form";
import { IFormattedEntry } from "src/helpers/types/Entry";
import { formsNames } from "src/constants";

export interface IProps extends IForm {
  data: IFormattedEntry;
}

export const defaultProps = {
  form: formsNames["commonCompany"],
};
