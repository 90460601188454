import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./Files.scss";
import { connect } from "react-redux";
import { baseURL, endpoints } from "src/constants";
import { downloadFile, downloadFileWithAxiosAndContentDisposition, getWithExpiry } from "src/helpers";
import { FileUpload } from "src/components";
import { removeFile, uploadFile, loadFile, loadFileByToken, removeFileByToken } from "src/redux/files";
import { UploadFile } from "antd/lib/upload/interface";
import { AppState } from "src/redux/AppState";
import { IProps, defaultProps } from "./inteface";
import axios from "axios";

const sf = cn("site-form");
const b = cn("file-form");

/* 
по требованию заказчика необходимо показывать лоадер, когда:
1) страница только загружается и ещё не известно, есть ли в файл-аплоадах данные или нет
2) файл есть и загружается с сервера
для реализации такого поведения создана вспомогательная функция isFileExist, которая возвращает 
три состояния:
1) undefined - неизвестно, существует ли файл
2) false - файла нет или он в архиве
3) true - файл есть
далее эти состояния используются в компоненте FileUpload для отображения лоадера
*/
const isFileExist = (wasLoaded: boolean, fileName: string | undefined, inArchive: boolean) => {
  if (!wasLoaded) {
    return undefined;
  }

  if (wasLoaded && (!fileName || inArchive)) {
    return false;
  }

  return true;
};

class FilesForm extends Component<IProps> {
  static defaultProps = defaultProps;

  render() {
    const {
      uploadFile,
      loadFile,
      removeFile,
      loadFileByToken,
      removeFileByToken,
      currentEntryId,
      loading,
      wasLoaded,
      disabled,
      validateErrors,
      fileFields,
      fileNames,
      className,
      showTitle,
      inArchive,
      disabledTooltipVisible,
    } = this.props;

    return (
      <form className={`${sf()} ${b()} ${className}`}>
        <div className={sf("files")}>
          {showTitle && <h3 className={sf("title")}>Сканы документов</h3>}

          <div className={b("items")}>
            {fileFields.map((it, index: number) => {
              // суффикс для файла, если передан параметр n
              const suffix = it.params ? `_${it.params["n"]}` : "";

              return (
                <div className={sf("dragger")} key={`${it.attribute}${it.title}${index}`}>
                  <FileUpload
                    accept={it.accept}
                    inArchive={inArchive[it.attribute + suffix]}
                    title={it.title}
                    label={it.label}
                    onChange={(file: UploadFile) =>
                      uploadFile(it.fileType, currentEntryId, file, it.attribute, it.params)
                    }
                    // @ts-ignore
                    loadFile={
                      !loading || wasLoaded
                        ? it.fileType === "fastUpload"
                          ? () => loadFileByToken(currentEntryId, it.params?.token, it.attribute)
                          : fileNames[it.attribute + suffix]
                          ? () => loadFile(currentEntryId, fileNames[it.attribute + suffix], it.attribute + suffix)
                          : undefined
                        : undefined
                    }
                    // loadFile={
                    //   (!loading || wasLoaded) && fileNames[it.attribute + suffix]
                    //     ? () => loadFile(currentEntryId, fileNames[it.attribute + suffix], it.attribute + suffix)
                    //     : undefined
                    // }
                    downloadFile={
                      it.fileType !== "fastUpload"
                        ? () =>
                            downloadFile(
                              `${baseURL}${endpoints.files.download}/${getWithExpiry("token")}/${currentEntryId}/${
                                fileNames[it.attribute + suffix]
                              }`
                            )
                        : () => {
                            axios({
                              url: `${baseURL}/api/v1/file_preview_by_token?id=${currentEntryId}&token=${it.params?.token}&fileId=${it.attribute}`, //your url
                              method: "GET",
                              headers: { Authorization: getWithExpiry("token") },
                              responseType: "blob", // important
                            }).then((response) => downloadFileWithAxiosAndContentDisposition({ response }));
                          }
                    }
                    removeFile={
                      it.fileType === "fastUpload"
                        ? () => removeFileByToken(currentEntryId, it.params?.token, it.attribute)
                        : () => removeFile(currentEntryId, it.attribute + suffix)
                    }
                    disabled={disabled}
                    error={validateErrors[it.attribute + suffix]}
                    type={it.type}
                    isFileExist={isFileExist(
                      wasLoaded,
                      fileNames[it.attribute + suffix],
                      inArchive[it.attribute + suffix]
                    )}
                    disabledTooltipVisible={disabledTooltipVisible}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { validateErrors, inArchive } = state.files;
  const currentEntryId = state.entries.currentEntry?.id || state.entries.simplifiedEntry?.id;
  const formattedEntry = state.entries.formattedEntry || {};
  let fileNames = {};
  const { fileFields } = ownProps;

  fileNames = fileFields?.reduce((acc: any, it: any) => {
    const suffix = typeof it.params?.n !== "undefined" ? `_${it.params.n}` : "";
    //@ts-ignore
    acc[it.attribute + suffix] = formattedEntry["file_" + it.attribute + suffix];
    return acc;
  }, {});

  return {
    currentEntryId,
    fileNames,
    validateErrors,
    inArchive,
  };
};

const mapDispatchToProps = {
  uploadFile,
  loadFile,
  removeFile,
  loadFileByToken,
  removeFileByToken,
};

// @ts-ignore
const connectedComponent: any = connect(mapStateToProps, mapDispatchToProps)(FilesForm);

export { connectedComponent as FilesForm };
