import { billsConstants } from "./../bills.constants";
import { message } from "antd";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { IOperation } from "src/helpers/types/Operation";

export const showBalance = (action = "", companyId: string | number | undefined = "") => {
  const { SHOW_BALANCE_REQUEST, SHOW_BALANCE_SUCCESS, SHOW_BALANCE_FAILURE } = billsConstants;
  const request = () => ({ type: SHOW_BALANCE_REQUEST });
  const success = (operations: IOperation[]) => ({ type: SHOW_BALANCE_SUCCESS, operations });
  const failure = () => ({ type: SHOW_BALANCE_FAILURE });

  const currentCompanyId = companyId === "all" ? "" : companyId;
  const currentAction = action === "all" ? "" : action;

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      const operations: IOperation[] = await API.get(
        `${endpoints.bills.showBalance}?${currentCompanyId ? `companyId=${currentCompanyId}&` : ""}${
          currentAction ? `action=${currentAction}&` : ""
        }`,
      );
      dispatch(success(operations));
    } catch (err: any) {
      message.error(`Не удалось загрузить операции ${err}`);
      dispatch(failure());
    }
  };
};
