import { attributes, errorsTypes, additionalEntriesTypes } from "src/constants";
import { isFieldExist, innCheck, validateOGRN } from "src/helpers";
import { store } from "src/redux/store";
import { fetchByOGRNorINN } from "src/redux/entries";
import { resetLastChangedField, loadAutoComplete } from "src/redux/input";
import { IProps } from "./interface";

// список полей, которым нужен запрос на сервер при изменении
const serverRequestNeededFields = [attributes["Полный адрес"], `executive_${attributes["Полный адрес"]}`];

export default function validate(values: Record<string, any>, props: IProps): Record<string, string> {
  // ошибки для валидации
  let errors: Record<string, string> = {};
  const { dispatch, form } = props;

  errors = validateINN(values[attributes["ИНН"]], errors);
  errors = validateOGRN(values[attributes["ОГРН"]], errors);

  if (values[attributes["статусПользователя"]] === "ur") {
    errors = validateKPP(values[attributes["КПП"]], errors);
  }

  errors = isFieldExist(
    attributes["Полное наименование компании"],
    values[attributes["Полное наименование компании"]],
    errors,
  );

  const { lastChangedField, lastChangedValue } = store.getState().input;

  // если пользователь ввел корректный ОГРН
  if (!errors[attributes["ОГРН"]] && lastChangedField === attributes["ОГРН"]) {
    dispatch(fetchByOGRNorINN(lastChangedValue, additionalEntriesTypes["РегистрацияКомпании"]));
    dispatch(resetLastChangedField());
  }

  // если пользователь что-то ввел в поле и это поле есть в списке полей, которым нужно обратиться к серверу
  if (lastChangedField && serverRequestNeededFields.indexOf(lastChangedField) !== -1) {
    dispatch(loadAutoComplete(form, lastChangedField, values[lastChangedField]));
  }

  return errors;
}

const validateINN = (inn: string, errors: Record<string, string>) => {
  if (!innCheck(inn)) {
    errors[attributes["ИНН"]] = errorsTypes.forbiddenINN;
  }

  return errors;
};

const validateKPP = (kpp: string, errors: Record<string, string>) => {
  if (typeof kpp === "undefined" || kpp.indexOf("_") !== -1) {
    errors[attributes["КПП"]] = errorsTypes.forbiddenKPP;
  }

  return errors;
};
