import { ICompanyExtended } from "src/helpers/types/Company";

export interface IProps {
  className?: string;
  loading: boolean;
  data: {
    companies?: { count: number; result: ICompanyExtended[] };
    filteredCompanies: ICompanyExtended[];
  };
  onUpdate: (li?: number, page?: number, search?: string) => Promise<void>;
  showCompanies: (companiesIds: number[]) => Promise<void>;
}

export interface IState {
  pageSize: number;
  searchQuery: string;
  searchOnFocus: boolean;
  currentPage: number;
  modalVisible: boolean;
  openedCompanyData?: ICompanyExtended;
  starFilterChecked: boolean;
}

export const defaultProps = {};
