import { billsConstants } from "./../bills.constants";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { getBills } from "./getBills";
import { message } from "antd";

export const recoverBill = (id: number) => {
  const { RECOVER_BILL_REQUEST, RECOVER_BILL_SUCCESS, RECOVER_BILL_FAILURE } = billsConstants;
  const request = () => ({ type: RECOVER_BILL_REQUEST });
  const success = () => ({ type: RECOVER_BILL_SUCCESS });
  const failure = () => ({ type: RECOVER_BILL_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      await API.get(`${endpoints.bills.recover}/${id}`);
      message.success("Дата обновлена");
      dispatch(success());
      dispatch(getBills());
    } catch (err: any) {
      message.error(`Ошибка при обновлении счёта: ${err}`);
      dispatch(failure());
    }
  };
};
