import { store } from "src/redux/store";
import { getFormValues, submit, isValid, setSubmitFailed } from "redux-form";
import { formsNames, attributes } from "src/constants";
import { cloneDeep } from "lodash";
import { wasFileLoaded, validateOkveds, trimSpaces, isScene } from "src/helpers";
import { history } from "src/helpers";
import qs from "query-string";
import { AppState } from "src/redux/AppState";

export const extractIPdata = (state: AppState) => {
  // извлекаем ОКВЭДы
  const { selectedActivities } = state.activities;

  // соберем formData из всех форм
  const passportValues = getFormValues(formsNames["passport"])(state);
  const commonValues = getFormValues(formsNames["common"])(state);
  const deliveryValues = getFormValues(formsNames["delivery"])(state);
  const docsValues: Record<string, any> = getFormValues(formsNames["docsFormat"])(state);
  const registrationValues = getFormValues(formsNames["registration"])(state);
  const foreignValues = getFormValues(formsNames["foreign"])(state);
  const id = qs.parse(history.location.search)["id"];

  const allData = cloneDeep(
    Object.assign({}, passportValues, commonValues, deliveryValues, docsValues, registrationValues, foreignValues),
  );
  allData["id"] = id;
  allData[attributes["Выдать документы на бумажном носителе"]] = docsValues[
    attributes["Выдать документы на бумажном носителе"]
  ]
    ? "1"
    : "";

  const primaryOkved = selectedActivities.filter((it) => it["main"]);
  const okveds = selectedActivities.filter((it) => !it["main"]);

  allData[attributes["основнойОКВЭД"]] = primaryOkved.map((it) => it.code).join(",");
  allData[attributes["допОКВЭД"]] = okveds.map((it) => it.code).join(",");

  if (allData[attributes["иностранец"]] === "true") {
    trimSpaces(allData, ["Фамилия", "Имя", "Отчество", "Электронная почта", "Кем выдан паспорт"]);
  } else {
    trimSpaces(allData);
  }

  return allData;
};

// проверяет, что у заявки на выпуск ИП заполнены все обязательные поля на этапе до выпуска КЭП
export const validateIPregistation = (dispatch: Function): boolean => {
  // эмулируем сабмит всех форм
  dispatch(submit(formsNames["common"]));
  dispatch(setSubmitFailed(formsNames["common"]));
  dispatch(submit(formsNames["delivery"]));
  dispatch(setSubmitFailed(formsNames["delivery"]));
  dispatch(submit(formsNames["passport"]));
  dispatch(setSubmitFailed(formsNames["passport"]));
  dispatch(submit(formsNames["registration"]));
  dispatch(setSubmitFailed(formsNames["registration"]));
  dispatch(submit(formsNames["foreign"]));
  dispatch(setSubmitFailed(formsNames["foreign"]));

  // извлекаем информацию об ошибках всех форм
  const state = store.getState();
  const commonValid = isValid("common")(state);
  const deliveryValid = isValid("delivery")(state);
  const passportValid = isValid("passport")(state);
  const registrationValid = isValid("registration")(state);
  const foreignValid = isValid("foreign")(state);

  //проверка, что пользователь выбрал основной оквэд
  const okvedsOK = validateOkveds(state, dispatch);

  // проверка корректности заполнения всех форм
  return commonValid && deliveryValid && passportValid && registrationValid && foreignValid && okvedsOK;
};

// проверяет, что все основные файлы загружены
export const validateIPregistationFiles = (dispatch: Function): boolean => {
  const state = store.getState();
  let filesOK = true;
  filesOK = wasFileLoaded(state, dispatch, attributes["Скан паспорта"]) && filesOK;
  filesOK = wasFileLoaded(state, dispatch, attributes["Скан прописки"]) && filesOK;
  filesOK = wasFileLoaded(state, dispatch, attributes["Скан СНИЛС"]) && filesOK;

  const {
    entries: { currentEntry },
  } = state;
  // если требуется активация после шага СМЭВ, то нужно убедиться, что пользователь загрузил Сертификат и Фото клиента
  if (isScene("Активация КЭП", currentEntry!.scene)) {
    filesOK = wasFileLoaded(state, dispatch, attributes["Сертификат"]) && filesOK;
    filesOK = wasFileLoaded(state, dispatch, attributes["Фото клиента"]) && filesOK;
  }

  return filesOK;
};
