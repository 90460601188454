import { passportsConstants } from "./../passports.constants";
import { message } from "antd";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { Dispatch } from "redux";

export const removePassports = (ids: number[]) => {
  const { REMOVE_PASSPORTS_REQUEST, REMOVE_PASSPORTS_SUCCESS, REMOVE_PASSPORTS_FAILURE } = passportsConstants;
  const request = () => ({ type: REMOVE_PASSPORTS_REQUEST });
  const success = () => ({ type: REMOVE_PASSPORTS_SUCCESS });
  const failure = () => ({ type: REMOVE_PASSPORTS_FAILURE });

  return async function (dispatch: Dispatch) {
    dispatch(request());
    try {
      await API.delete(`${endpoints.passports.remove}/?ids=${ids.join("&ids=")}`);
      dispatch(success());
    } catch (err: any) {
      message.error(`Не удалось удалить данные ${err}`);
      dispatch(failure());
    }
  };
};
