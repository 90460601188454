import { IForm } from "src/helpers/types/Form";
import { formsNames } from "src/constants";

export interface IProps extends IForm {
  className?: string;
  changeFieldValue: (form: string, field: string, value: string) => Promise<void>;
  loadDadataInfo: (inputName: string, inputValue: string) => any;
  autoCompleteData: Record<string, any>;
}

export const defaultProps = {
  form: formsNames["hhMarketplace"],
};
