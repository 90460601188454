import { endpoints } from "src/constants";
import { inputConstants } from "./../input.constants";
import { message } from "antd";
import { API } from "src/helpers";
import { Dispatch } from "redux";

export const checkPassportData = (pspn: string): any => {
  const { CHECK_PASSPORT_DATA_REQUEST, CHECK_PASSPORT_DATA_SUCCESS, CHECK_PASSPORT_DATA_FAILURE } = inputConstants;
  const request = () => ({ type: CHECK_PASSPORT_DATA_REQUEST });
  const success = () => ({ type: CHECK_PASSPORT_DATA_SUCCESS });
  const failure = () => ({ type: CHECK_PASSPORT_DATA_FAILURE });

  return async function (dispatch: Dispatch) {
    dispatch(request());
    try {
      const data: { valid: boolean } = await API.get(`${endpoints.checkPassport}${pspn}`);
      dispatch(success());
      return data["valid"];
    } catch (err: any) {
      message.error(err);
      dispatch(failure());
      // если ошибка сервера, то возвращаем true, чтобы валидация не мешала работе с заявкой 👀
      return true;
    }
  };
};
