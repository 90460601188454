import { companiesConstants } from "./../companies.constants";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { message } from "antd";
import { ICompanyExtended } from "src/helpers/types/Company";

export const getCompanies = (li?: number, page?: number, search?: string) => {
  const { GET_COMPANIES_REQUEST, GET_COMPANIES_SUCCESS, GET_COMPANIES_FAILURE } = companiesConstants;
  const request = () => ({ type: GET_COMPANIES_REQUEST });
  const success = (companies: { count: number; result: ICompanyExtended[] }, filtered: boolean) => ({
    type: GET_COMPANIES_SUCCESS,
    companies,
    filtered,
  });
  const failure = () => ({ type: GET_COMPANIES_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      let params = li ? `?li=${li}&page=${page}` : "";
      params = search ? `${params}&search=${search}` : params;
      const filtered = Boolean(params);
      const companies: { count: number; result: ICompanyExtended[] } = await API.get(
        `${endpoints.companies.getAll}${params}`,
      );
      dispatch(success(companies, filtered));
    } catch (err: any) {
      message.error(`Не удалось получить список компаний ${err}`);
      dispatch(failure());
    }
  };
};
