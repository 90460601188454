import { EntrySubType, EntryType } from "src/helpers/types/Entry";

export const entriesStatuses = {
  inwork: "В работе",
  registration: "Регистрация",
  ended: "Завершено",
};

export const entriesTitles: Record<EntryType, string> = {
  ip: "Регистрация ИП",
  multiooo: "Регистрация ООО",
  oooedit: "Изменения ООО",
  ipedit: "Изменения ИП",
  ipdel: "Закрытие ИП",
};

export enum entriesTypes {
  РегистрацияИП = "ip",
  РегистрацияООО = "multiooo",
  ИзмененияИП = "ipedit",
  ЗакрытиеИП = "ipdel",
  ИзмененияООО = "oooedit",
}

export const entrySubTypes: Record<string, EntrySubType> = {
  ЕГРЮЛ: "egrul",
  УСТАВ: "ustav",
};

export const additionalEntriesTypes = {
  РегистрацияКомпании: "company",
  ИзмененияПаспорта: "passportChange",
};
