import React, { Component } from "react";
import { Input, Select } from "antd";
import { cn } from "src/helpers/bem";
import classNames from "classnames/dedupe";
import { marketplaceTopics } from "src/constants";
import { MarketplaceCard } from "./components/MarketplaceCard/MarketplaceCard";
import "./MarketplaceGrid.scss";
import { IProps, IState } from "./interface";
import { IMarketplaceCard } from "src/helpers/types/MarketplaceCard";

const { Option } = Select;

const b = cn("marketplace-grid");
const sp = cn("site-page");

// мне не нравится, что родитель sp на самой странице, а тут используются только элементы(

export class MarketplaceGrid extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      searchQuery: "",
      selectValue: "all",
    };
  }

  handleSearchChange = (searchQuery: string) => {
    this.setState({ searchQuery });
  };

  handleSelectChange = (selectValue: string) => {
    this.setState({ selectValue });
  };

  filterCardsBySearch = (cards?: IMarketplaceCard[]) => {
    const { searchQuery } = this.state;

    const reg = new RegExp(searchQuery, "i");
    return cards?.filter((it) => {
      return reg.test(it.title);
    });
  };

  filterCardsBySelect = (cards?: IMarketplaceCard[]) => {
    const { selectValue } = this.state;

    if (selectValue !== "all") {
      return cards?.filter((it) => it.topic === selectValue);
    }

    return cards;
  };

  renderControls = () => {
    const { selectValue } = this.state;

    return (
      <div className={sp("controls")}>
        <Input
          placeholder="Поиск..."
          className={sp("search")}
          onChange={(e) => this.handleSearchChange(e.target.value)}
        />

        <div className={sp("control-items")}>
          <Select
            value={selectValue}
            style={{ width: 145 }}
            onChange={this.handleSelectChange}
            className={sp("control")}
          >
            <Option value={"all"}>Все</Option>
            {Object.keys(marketplaceTopics).map((item, index: number) => {
              return (
                <Option value={item} key={index}>
                  {marketplaceTopics[item]}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>
    );
  };

  render() {
    const { data } = this.props;

    return (
      <div className={b()}>
        {this.renderControls()}
        <ul className={classNames(b("cards-list"), sp("list"))}>
          {this.filterCardsBySearch(this.filterCardsBySelect(data))?.map((it, index: number) => {
            return (
              <li className={b("cards-item")} key={`marketplace_card${index}`}>
                <MarketplaceCard data={it} />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
