import { attributes, errorsTypes } from "src/constants";
import { innCheck } from "src/helpers";
import { store } from "src/redux/store";
import { fetchByOGRNorINN } from "src/redux/entries";
import { resetLastChangedField } from "src/redux/input";
import { IProps } from "./interface";

export default function validate(values: Record<string, any>, props: IProps): Record<string, string> {
  const { dispatch, type, fieldsNames } = props;
  // ошибки для валидации
  let errors: Record<string, string> = {};

  errors = validateINN(fieldsNames[attributes["ИНН"]], values[fieldsNames[attributes["ИНН"]]], errors);
  errors = validateOGRN(fieldsNames[attributes["ОГРНИП"]], values[fieldsNames[attributes["ОГРНИП"]]], errors);

  /* заберем из store название последнего измененого inputа */
  const { lastChangedField, lastChangedValue } = store.getState().input;
  const loading = store.getState().entries.loading;

  // если пользователь ввел корректный ОГРН или ИНН
  if (
    ((!errors[fieldsNames[attributes["ОГРНИП"]]] && lastChangedField === fieldsNames[attributes["ОГРНИП"]]) ||
      (!errors[fieldsNames[attributes["ИНН"]]] && lastChangedField === fieldsNames[attributes["ИНН"]])) &&
    !loading
  ) {
    dispatch(fetchByOGRNorINN(lastChangedValue, type));
    dispatch(resetLastChangedField());
  }

  return errors;
}

const validateINN = (fieldName: string, inn: string, errors: any) => {
  if (!innCheck(inn)) {
    errors[fieldName] = errorsTypes.forbiddenINN;
  }

  return errors;
};

const validateOGRN = (fieldName: string, ogrn: string, errors: any) => {
  if (typeof ogrn === "undefined" || ogrn.indexOf("_") !== -1 || ogrn.length === 0) {
    errors[fieldName] = errorsTypes.forbiddenOGRN;
  }

  return errors;
};
