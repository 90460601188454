import React from "react";
import { cn } from "src/helpers/bem";
import "./SiteFooter.scss";

const b = cn("landing-footer");

export const SiteFooter = () => {
  return (
    <footer className={b()}>
      <div className="main-container">
        <div className={b("wrapper")}>
          <p className={b("text")}>&copy;&nbsp;Бюро, 2019 (ООО &laquo;Бюро&raquo;, Санкт-Петербург)</p>
          <p className={b("text")}>
            Мы&nbsp;используем{" "}
            <a className={b("link")} href="https://ru.wikipedia.org/wiki/Cookie" target="blank">
              cookie-файлы
            </a>{" "}
            для сбора статистики и&nbsp;улучшения сервиса. <br />
            Оставаясь на&nbsp;сайте вы&nbsp;соглашаетесь с&nbsp;
            <a className={b("link")} href="#">
              условиями использования сервиса
            </a>
            .
          </p>
        </div>
      </div>
    </footer>
  );
};
