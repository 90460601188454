import { attributes } from "src/constants";
import moment from "moment";
moment.locale("ru");
export const getInitialValues = (entryData: Record<string, any>) => {
  return {
    [attributes["иностранец"]]: entryData[attributes["иностранец"]] || "false",
    [attributes["Фамилия (латиница)"]]: entryData[attributes["Фамилия (латиница)"]],
    [attributes["Имя (латиница)"]]: entryData[attributes["Имя (латиница)"]],
    [attributes["Отчество (латиница)"]]: entryData[attributes["Отчество (латиница)"]],
    [attributes["Государство гражданства"]]: entryData[attributes["Государство гражданства"]],
    [attributes["Серия и номер паспорта"]]: entryData[attributes["Серия и номер паспорта"]],
    [attributes["Дата выдачи паспорта"]]: entryData[attributes["Дата выдачи паспорта"]] || "",
    [attributes["Код подразделения"]]: entryData[attributes["Код подразделения"]],
    [attributes["Кем выдан паспорт"]]: entryData[attributes["Кем выдан паспорт"]],
    [attributes["Дата рождения"]]: entryData[attributes["Дата рождения"]] || "",
    [attributes["Место рождения"]]: entryData[attributes["Место рождения"]],
    [attributes["СНИЛС"]]: entryData[attributes["СНИЛС"]],
    [attributes["ИНН"]]: entryData[attributes["ИНН"]],
    [attributes["Пол"]]: entryData[attributes["Пол"]],
  };
};
