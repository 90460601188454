import { ICompaniesState } from "src/redux/companies";

interface IAction {
  type: string;
  companiesIds: number[];
}

export const showCompanies = (state: ICompaniesState, action: IAction): ICompaniesState => {
  const companiesToShow = state.companies
    ? state.companies.result.filter((company) => action.companiesIds.findIndex((id) => company.id === id) !== -1)
    : [];
  return {
    ...state,
    filteredCompanies: {
      count: companiesToShow.length,
      result: companiesToShow,
    },
  };
};
