import { cloneDeep } from "lodash";
import { IFilesState } from "src/redux/files";
import { Action } from "redux";

interface IAction extends Action {
  requestAttribute: string;
  textError: string;
}

export const fileValidateError = (state: IFilesState, action: IAction): IFilesState => {
  const validateErrors: any = cloneDeep(state.validateErrors);
  const { requestAttribute, textError } = action;
  validateErrors[requestAttribute] = textError;
  return {
    ...state,
    validateErrors,
  };
};
