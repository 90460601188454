import { userConstants } from "./../user.constants";
import { API } from "src/helpers";
import { endpoints, formsNames } from "src/constants";
import { message } from "antd";
import { store } from "src/redux/store";
import { getFormValues, isValid, submit } from "redux-form";

export const changePassword = () => {
  const { CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE } = userConstants;
  const request = () => ({ type: CHANGE_PASSWORD_REQUEST });
  const success = () => ({ type: CHANGE_PASSWORD_SUCCESS });
  const failure = () => ({ type: CHANGE_PASSWORD_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    const state = store.getState();
    dispatch(submit(formsNames["settings"]));
    const settingsValid = isValid(formsNames["settings"])(state);

    if (!settingsValid) {
      dispatch(failure());
      message.error("Проверьте введённые данные");
      return;
    }

    //@ts-ignore
    const settingsValues: { currentPassword: string; newPassword: string } = getFormValues(formsNames["settings"])(
      state,
    );
    const { currentPassword, newPassword } = settingsValues;

    try {
      await API.post(`${endpoints.changePassword}`, {
        "current-password": currentPassword,
        "new-password": newPassword,
      });
      dispatch(success());
      message.success("Пароль изменён");
    } catch (err: any) {
      message.error(`Не удалось изменить пароль ${err}`);
      dispatch(failure());
    }
  };
};
