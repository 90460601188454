import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import "./Foreign.scss";
import { AInput, ARadio, ARadioGroup, ACheckbox, AAntdDatepickerWithMask } from "src/helpers";
import { connect } from "react-redux";
import { Field, reduxForm, submit, change, formValueSelector } from "redux-form";
import validate from "./validate";
import { attributes } from "src/constants";
import { getInitialValues } from "./initialValues";
import { AppState } from "src/redux/AppState";
import { defaultProps, IProps } from "./interface";

const sf = cn("site-form");
const b = cn("foreign-form");

class ForeignForm extends Component<IProps> {
  render() {
    const { foreignDocInfinite, handleSubmit, changeFieldValue, disabled } = this.props;

    return (
      <form onSubmit={handleSubmit} className={`${sf()} ${b()}`}>
        <div className={sf("section-wrapper")}>
          <section className={sf("section")}>
            <h2 className={sf("title")}>Основание для проживания на территории РФ</h2>
            <div className={sf("radio")}>
              <Field
                name={attributes["Основание для проживания на территории РФ"]}
                component={ARadioGroup}
                buttonStyle="solid"
                className={b("radio-column")}
              >
                <Field
                  type="radio"
                  name={attributes["Основание для проживания на территории РФ"]}
                  value="2"
                  title="Разрешение на временное пребывание"
                  component={ARadio}
                  disabled={disabled}
                />
                <Field
                  type="radio"
                  name={attributes["Основание для проживания на территории РФ"]}
                  value="1"
                  title="Вид на жительство"
                  component={ARadio}
                  disabled={disabled}
                />
              </Field>
            </div>
            <div className={sf("inputs-grid")}>
              <Field
                title="Номер документа:"
                name={attributes["Номер документа"]}
                component={AInput}
                disabled={disabled}
              />
              <div className={b("getSource")}>
                <Field title="Кем выдан:" name={attributes["Кем выдан"]} component={AInput} disabled={disabled} />
              </div>
              <Field
                title="Дата выдачи:"
                name={attributes["Дата выдачи"]}
                component={AAntdDatepickerWithMask}
                disabled={disabled}
              />
              <div className={b("validity-wrapper")}>
                <Field
                  className={b("validity-field")}
                  title="Срок действия:"
                  name={attributes["Срок действия"]}
                  component={AAntdDatepickerWithMask}
                  disabled={disabled || foreignDocInfinite}
                />
                <Field
                  onChange={() => changeFieldValue(attributes["Срок действия"], "")}
                  className={b("infinite-checkbox")}
                  title="Бессрочно"
                  name={attributes["Документ иностранца бессрочный"]}
                  component={ACheckbox}
                  disabled={disabled}
                  normalize={(value: boolean) => (value ? "1" : "")}
                  format={(value: string) => (value ? true : false)}
                />
              </div>
            </div>
          </section>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { data, form } = ownProps;
  const initialValues = data ? getInitialValues(data) : {};
  const formValues = formValueSelector(form);
  const foreignDocInfinite = formValues(state, attributes["Документ иностранца бессрочный"]);

  return { initialValues, foreignDocInfinite };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    changeFieldValue: (field: string, value: string) => dispatch(change(form, field, value)),
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(ForeignForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as ForeignForm };
