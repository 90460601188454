import { Action } from "redux";
import { IInputState } from "src/redux/input";

interface IAction extends Action {
  sono: string;
}

export const sonoLoadSuccess = (state: IInputState, action: IAction): IInputState => {
  const { sono } = action;
  return {
    ...state,
    sono,
  };
};
