import { IForm } from "src/helpers/types/Form";
import { formsNames } from "src/constants";
import { EquityFormatType } from "src/helpers/types/Owner";

export interface IProps extends IForm {
  className?: string;
  data: Record<string, any>;
  inputsColors: Record<string, any>;
  equityFormat: EquityFormatType;
}

export const defaultProps = { form: formsNames["owner"] };
