import { IForm } from "src/helpers/types/Form";
import { attributes, formsNames } from "src/constants";

export interface IProps extends IForm {
  className?: string;
  autoCompleteData: Record<string, any>;
  fieldsNames: Record<string, any>;
}

export interface IState {}

export const defaultProps = {
  form: formsNames["shortCommon"],
  fieldsNames: {
    [attributes["Фамилия"]]: attributes["Фамилия"],
    [attributes["Имя"]]: attributes["Имя"],
    [attributes["Отчество"]]: attributes["Отчество"],
    [attributes["Телефон"]]: attributes["Телефон"],
    [attributes["Электронная почта"]]: attributes["Электронная почта"],
    [attributes["Пол"]]: attributes["Пол"],
  },
};
