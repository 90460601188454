import { IForm } from "src/helpers/types/Form";
import { attributes, formsNames } from "src/constants";

export interface IProps extends IForm {
  className?: string;
  autoCompleteData: Record<string, any>;
  fieldsNames: Record<string, any>;
}

export interface IState {}

export const defaultProps = {
  form: formsNames["shortRegistration"],
  fieldsNames: {
    [attributes["Полный адрес"]]: attributes["Полный адрес"],
  },
};
