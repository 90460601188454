import { endpoints } from "src/constants";
import { inputConstants } from "./../input.constants";
import { message } from "antd";
import { API } from "src/helpers";

export const loadINN = (lastName: string, firstName: string, middleName: string, dob: string, pspn: string) => {
  const { LOAD_INN_REQUEST, LOAD_INN_SUCCESS, LOAD_INN_FAILURE } = inputConstants;
  const request = () => ({ type: LOAD_INN_REQUEST });
  const success = () => ({ type: LOAD_INN_SUCCESS });
  const failure = () => ({ type: LOAD_INN_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      const data: any = await API.get(
        `${
          endpoints.inn
        }?lastName=${lastName}&firstname=${firstName}&middleName=${middleName}&dob=${dob}&pspn=${pspn.replace(
          / /g,
          "+",
        )}`,
      );
      if (data["result"] === "error") {
        message.error("Не удалось определить ИНН. Проверьте корректность введённых данных");
        dispatch(failure());
      } else {
        dispatch(success());
        return data["inn"];
      }
    } catch (err: any) {
      message.error(`Не удалось определить ИНН: ${err}`);
      dispatch(failure());
    }
  };
};
