import { attributes } from "src/constants";
import { IFileField } from "src/helpers/types/Files";

export const mainFields: IFileField[] = [
  {
    attribute: attributes["Устав"],
    title: "Устав",
    label: "Устав",
    fileType: "uploadOOO_EDIT",
  },
  {
    attribute: attributes["Решение"],
    title: "Решение",
    label: "Решение",
    fileType: "uploadOOO_EDIT",
  },
  {
    attribute: attributes["Юр. адрес"],
    title: "Юр. адрес",
    label: "Юр. адрес",
    fileType: "uploadOOO_EDIT",
  },
];

// export const fileFieldsModal: IFileField[] = [
//   {
//     attribute: attributes["Скан паспорта"],
//     title: "Скан паспорта",
//     label: "Паспорт",
//     fileType: "uploadOOO_EDIT"
//   },
//   {
//     attribute: attributes["Скан прописки"],
//     title: "Прописка",
//     label: "Прописка",
//     fileType: "uploadOOO_EDIT"
//   },
//   {
//     attribute: attributes["Скан СНИЛС"],
//     title: "Скан СНИЛС",
//     label: "СНИЛС",
//     fileType: "uploadOOO_EDIT"
//   }
// ];

export const additionalFields: IFileField[] = [
  // {
  //   attribute: attributes["Сертификат"],
  //   title: "Скан сертификата",
  //   label: "Сертификат",
  //   fileType: "uploadOOO_EDIT",
  // },
  // {
  //   attribute: attributes["Фото клиента"],
  //   title: "Фото клиента",
  //   label: "Фото клиента",
  //   fileType: "uploadOOO_EDIT",
  // },
];

export const applicantAddressFieldsNames = {
  [attributes["Полный адрес"]]: `executive_${attributes["Полный адрес"]}`,
  [attributes["Субъект"]]: `executive_${attributes["Субъект"]}`,
  [attributes["Код терр. ФНС"]]: `executive_${attributes["Код терр. ФНС"]}`,
  [attributes["Код рег. органа"]]: `executive_${attributes["Код рег. органа"]}`,
  [attributes["Муниципальное образование (верхнее)"]]: `executive_${attributes["Муниципальное образование (верхнее)"]}`,
  [attributes[
    "Наименование муниципального образования (верхнее)"
  ]]: `executive_${attributes["Наименование муниципального образования (верхнее)"]}`,
  [attributes["Муниципальное образование (нижнее)"]]: `executive_${attributes["Муниципальное образование (нижнее)"]}`,
  [attributes[
    "Наименование муниципального образования (нижнее)"
  ]]: `executive_${attributes["Наименование муниципального образования (нижнее)"]}`,
  [attributes["Населенный пункт"]]: `executive_${attributes["Населенный пункт"]}`,
  [attributes["Наименование населенного пункта"]]: `executive_${attributes["Наименование населенного пункта"]}`,
  [attributes["Элемент планировочной структуры"]]: `executive_${attributes["Элемент планировочной структуры"]}`,
  [attributes[
    "Наименование элемента планировочной структуры"
  ]]: `executive_${attributes["Наименование элемента планировочной структуры"]}`,
  [attributes["Элемент улично-дорожной сети"]]: `executive_${attributes["Элемент улично-дорожной сети"]}`,
  [attributes[
    "Наименование элемента улично-дорожной сети"
  ]]: `executive_${attributes["Наименование элемента улично-дорожной сети"]}`,
  [attributes["Здание/сооружение (первый столбец)"]]: `executive_${attributes["Здание/сооружение (первый столбец)"]}`,
  [attributes[
    "Номер здания/сооружения (первый столбец)"
  ]]: `executive_${attributes["Номер здания/сооружения (первый столбец)"]}`,
  [attributes["Здание/сооружение (второй столбец)"]]: `executive_${attributes["Здание/сооружение (второй столбец)"]}`,
  [attributes[
    "Номер здания/сооружения (второй столбец)"
  ]]: `executive_${attributes["Номер здания/сооружения (второй столбец)"]}`,
  [attributes["Здание/сооружение (третий столбец)"]]: `executive_${attributes["Здание/сооружение (третий столбец)"]}`,
  [attributes[
    "Номер здания/сооружения (третий столбец)"
  ]]: `executive_${attributes["Номер здания/сооружения (третий столбец)"]}`,
  [attributes["Квартира/офис"]]: `executive_${attributes["Квартира/офис"]}`,
  [attributes["Номер квартиры/офиса"]]: `executive_${attributes["Номер квартиры/офиса"]}`,
  [attributes["Помещение"]]: `executive_${attributes["Помещение"]}`,
  [attributes["Номер помещения"]]: `executive_${attributes["Номер помещения"]}`,

  // вспомогательные поля
  [attributes["Регион (сокр.)"]]: `executive_${attributes["Регион (сокр.)"]}`,
  [attributes["Наименование региона"]]: `executive_${attributes["Наименование региона"]}`,
  [attributes["Регион (полн.)"]]: `executive_${attributes["Регион (полн.)"]}`,
};

export const INNandOGRNFieldsNames = {
  [attributes["ОГРНИП"]]: attributes["ОГРН"],
  [attributes["ИНН"]]: attributes["ИННкомпании"],
  [attributes["Код рег. органа"]]: attributes["Код рег. органа"],
};
