import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import classNames from "classnames/dedupe";
import "./Passport.scss";
import { AInput, AAntdDatepickerWithMask, ARadio, ARadioGroup, ATextArea, ASelect, isAdmin } from "src/helpers";
import { connect } from "react-redux";
import { loadINN, checkPassportData } from "src/redux/input";
import { Field, reduxForm, formValueSelector, getFormSyncErrors, getFormMeta, submit, change } from "redux-form";
import validate from "./validate";
import asyncValidate from "./asyncValidate";
import { countries, attributes, formsNames } from "src/constants";
import { getInitialValues } from "./initialValues";
import { AppState } from "src/redux/AppState";
import { defaultProps, IProps } from "./interface";
import { COMPANIES_IDS_THAT_CAN_USE_FOREIGN_IN_IP_REGISTRATION } from "src/constants/permissions";

const sf = cn("site-form");
const b = cn("passport-form");

const countriesFilterOption = (val: string, option: any) =>
  option.props.children.toLowerCase().indexOf(val.toLowerCase()) !== -1;

class PassportForm extends Component<IProps> {
  state = {
    innSuffix: "",
  };

  static defaultProps = {
    allowForeign: true,
    showSnils: true,
  };

  componentDidUpdate(prevProps: IProps) {
    const { innError, innButtonVisible, innTouched } = this.props;

    if (prevProps.innError && prevProps.innButtonVisible && !innError && !innButtonVisible) {
      // если ИНН определится автоматически при нажатии на кнопку
      this.setState({ innSuffix: "check" });
    }

    if (innButtonVisible && !prevProps.innError && !innTouched && innError) {
      // если после автоопределения изменили ИНН на неправильный,
      // но событие touched не произошло
      this.setState({ innSuffix: "cross" });
    }
  }

  getFieldSuffix = (error: string, touched: boolean) => {
    if (touched) {
      return error ? "cross" : "check";
    }
    return;
  };

  handleINNButtonClick = async () => {
    const { form, loadINN, changeFieldValue, lastName, firstName, middleName, dob, snp } = this.props;

    const innValue = await loadINN(lastName, firstName, middleName, dob, snp);
    changeFieldValue(form, attributes["ИНН"], innValue);
  };

  render() {
    const {
      inostr,
      showSnils,
      snilsError,
      snilsTouched,
      innTouched,
      innError,
      disabled,
      allowForeign,
      form,
      onSubmit,
      inputsColors,
      innButtonVisible,
      passportChange,
      // временно используем userCompanyId прямо в форме, поскольку некоторые поля блокируются для всех,
      // а некоторые - только для не_админов
      userCompanyId,
    } = this.props;
    const { innSuffix } = this.state;

    return (
      <form onSubmit={onSubmit} className={`${sf()} ${b({ inostr: inostr === "true" })}`}>
        <div className={sf("section-wrapper")}>
          <section className={sf("section")}>
            <div className={sf("row")}>
              <h2 className={sf("title", { "no-margin": true })}>Паспорт</h2>

              {allowForeign && COMPANIES_IDS_THAT_CAN_USE_FOREIGN_IN_IP_REGISTRATION.includes(userCompanyId) && (
                <Field name={attributes["иностранец"]} component={ARadioGroup} buttonStyle="solid">
                  <Field
                    type="radio"
                    name={attributes["иностранец"]}
                    isButton={true}
                    value={"false"}
                    title="Гражданин РФ"
                    component={ARadio}
                    disabled={disabled || passportChange}
                  />
                  <Field
                    type="radio"
                    name={attributes["иностранец"]}
                    isButton={true}
                    value={"true"}
                    checked={inostr}
                    title="Иностранный гражданин"
                    component={ARadio}
                    disabled={disabled || passportChange}
                  />
                </Field>
              )}
            </div>

            <div className={classNames(sf("inputs-grid"), b("inputs-grid"))}>
              <div className={classNames(sf("radio", { gender: true }), b("gender"))}>
                <Field name={attributes["Пол"]} component={ARadioGroup}>
                  <label className={sf("radio-title")}>Пол:</label>
                  <Field
                    type="radio"
                    name={attributes["Пол"]}
                    value="M"
                    title="Мужчина"
                    component={ARadio}
                    disabled={disabled}
                  />
                  <Field
                    type="radio"
                    name={attributes["Пол"]}
                    value="F"
                    title="Женщина"
                    component={ARadio}
                    disabled={disabled}
                  />
                </Field>
              </div>

              {inostr === "true" && [
                <Field
                  type="select"
                  key="inostr_field4"
                  name={attributes["Государство гражданства"]}
                  className={b("citizenship")}
                  component={ASelect}
                  title="Государство гражданства:"
                  options={countries}
                  filterOption={countriesFilterOption}
                  disabled={disabled}
                />,
                <Field
                  title="Фамилия (латиница):"
                  key="inostr_field1"
                  name={attributes["Фамилия (латиница)"]}
                  component={AInput}
                  disabled={disabled}
                  className={b("inostr-lastname")}
                />,
                <Field
                  title="Имя (латиница):"
                  key="inostr_field3"
                  className={b("inostr-firstname")}
                  name={attributes["Имя (латиница)"]}
                  component={AInput}
                  disabled={disabled}
                />,
                <Field
                  title="Отчество (латиница):"
                  key="inostr_field2"
                  className={b("inostr-middlename")}
                  name={attributes["Отчество (латиница)"]}
                  component={AInput}
                  disabled={disabled}
                />,
              ]}

              <Field
                title="Серия и номер паспорта:"
                className={b("passport-number")}
                name={attributes["Серия и номер паспорта"]}
                mask={inostr === "true" ? "" : "99 99 999999"}
                component={AInput}
                disabled={disabled}
                outlineColor={inputsColors[`${form}-${attributes["Серия и номер паспорта"]}`]}
              />

              <Field
                title="Дата выдачи паспорта:"
                name={attributes["Дата выдачи паспорта"]}
                component={AAntdDatepickerWithMask}
                placeholder=""
                className={b("passport-get-date")}
                disabled={disabled}
                outlineColor={inputsColors[`${form}-${attributes["Дата выдачи паспорта"]}`]}
              />

              {(inostr === "false" || !inostr) && (
                <Field
                  title="Код подразделения:"
                  name={attributes["Код подразделения"]}
                  mask="999-999"
                  component={AInput}
                  className={b("kp")}
                  disabled={disabled}
                  outlineColor={inputsColors[`${form}-${attributes["Код подразделения"]}`]}
                />
              )}

              <div className={b("get-source")}>
                <Field
                  title="Кем выдан паспорт:"
                  name={attributes["Кем выдан паспорт"]}
                  component={ATextArea}
                  style={{ height: 108 }}
                  disabled={disabled}
                  outlineColor={inputsColors[`${form}-${attributes["Кем выдан паспорт"]}`]}
                />
              </div>

              <Field
                title="Дата рождения:"
                name={attributes["Дата рождения"]}
                component={AAntdDatepickerWithMask}
                placeholder=""
                disabled={disabled}
                outlineColor={inputsColors[`${form}-${attributes["Дата рождения"]}`]}
              />

              <Field
                title="Место рождения:"
                name={attributes["Место рождения"]}
                component={AInput}
                disabled={disabled}
                className={b("pob")}
                outlineColor={inputsColors[`${form}-${attributes["Место рождения"]}`]}
              />

              {showSnils && (
                <div className={sf("input-with-suffix")}>
                  <Field
                    title="СНИЛС:"
                    name={attributes["СНИЛС"]}
                    mask="999-999-999 99"
                    component={AInput}
                    disabled={disabled || (passportChange && !isAdmin(userCompanyId))}
                  />
                  <div className={sf("input-suffix", { icon: this.getFieldSuffix(snilsError, snilsTouched) })}></div>
                </div>
              )}
              <div className={b("inn-wrapper", { inostr: inostr === "true" })}>
                <div className={sf("input-with-suffix")}>
                  <Field
                    title="ИНН:"
                    name={attributes["ИНН"]}
                    component={AInput}
                    mask="999999999999"
                    disabled={disabled || (passportChange && !isAdmin(userCompanyId))}
                    className={b("inn-field")}
                  />
                  <div
                    className={sf("input-suffix", { icon: this.getFieldSuffix(innError, innTouched) || innSuffix })}
                  ></div>
                </div>
                {!disabled && innButtonVisible && inostr !== "true" && (
                  <button
                    type="button"
                    className={classNames("custom-button", b("inn-button"))}
                    onClick={this.handleINNButtonClick}
                  >
                    Определить ИНН
                  </button>
                )}
              </div>
            </div>
          </section>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { form, data } = ownProps;
  const userCompanyId = state.userReducer.user?.company?.id;
  const suffix = form.split("-")[1];
  const commonForm = `${formsNames["common"]}${suffix ? `-${suffix}` : ""}`;
  const commonValues = formValueSelector(commonForm);
  const passportValues = formValueSelector(form);
  const commonFormErrors: any = getFormSyncErrors(commonForm)(state);
  const passportFormErrors: any = getFormSyncErrors(form)(state);
  const formMeta: any = getFormMeta(form)(state);
  const { inputsColors } = state.input;
  const lastNameError = commonFormErrors[attributes["Фамилия"]],
    firstNameError = commonFormErrors[attributes["Имя"]],
    middleNameError = commonFormErrors[attributes["Отчество"]];
  const inostr = passportValues(state, attributes["иностранец"]),
    snilsTouched = formMeta[attributes["СНИЛС"]] && formMeta[attributes["СНИЛС"]].touched,
    snilsError = passportFormErrors[attributes["СНИЛС"]],
    innTouched = formMeta[attributes["ИНН"]] && formMeta[attributes["ИНН"]].touched,
    innError = passportFormErrors[attributes["ИНН"]],
    dobError = passportFormErrors[attributes["Дата рождения"]],
    snpError = passportFormErrors[attributes["Серия и номер паспорта"]];
  const lastName = commonValues(state, attributes["Фамилия"]);
  const firstName = commonValues(state, attributes["Имя"]);
  const middleName = commonValues(state, attributes["Отчество"]);
  const dob = passportValues(state, attributes["Дата рождения"]);
  const snp = passportValues(state, attributes["Серия и номер паспорта"]);
  const innButtonVisible = innError && !lastNameError && !firstNameError && !middleNameError && !dobError && !snpError;
  const initialValues = data ? getInitialValues(data) : {};

  return {
    inostr,
    snilsError,
    snilsTouched,
    innTouched,
    innError,
    initialValues,
    inputsColors,
    lastName,
    firstName,
    middleName,
    dob,
    snp,
    innButtonVisible,
    userCompanyId,
  };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    changeFieldValue: (form: string, field: string, value: string) => dispatch(change(form, field, value)),
    onSubmit: () => dispatch(submit(form)),
    loadINN: (lastName: any, firstName: any, middleName: any, dob: any, pspn: any) =>
      dispatch(loadINN(lastName, firstName, middleName, dob, pspn)),
    checkPassportData: (pspn: any) => dispatch(checkPassportData(pspn)),
  };
};

let connectedComponent: any = reduxForm({
  validate,
  asyncBlurFields: [attributes["Серия и номер паспорта"]],
  asyncValidate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(PassportForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as PassportForm };
