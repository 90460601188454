import { UploadFile } from "antd/lib/upload/interface";
import { IFileUploadResponse } from "src/helpers/types/Files";

export type FileAcceptType = "pdfOrImage" | "image" | "tif" | "tiff";

export interface IProps {
  title: string;
  tip: string;
  label: string;
  onChange: (file: UploadFile) => Promise<IFileUploadResponse>;
  loadFile?: () => Promise<Blob | undefined>;
  removeFile?: () => Promise<void>;
  downloadFile?: () => void;
  disabled: boolean;
  error: string;
  type: string;
  inArchive: boolean;
  isFileExist?: boolean;
  disabledTooltipVisible: boolean;
  accept: FileAcceptType;
}

export interface IState {
  loading: boolean;
  fileList: Array<Partial<UploadFile>>;
  labelVisible: boolean;
  previewVisible: boolean;
  fileBlob: Blob;
  tooltipVisible: boolean;
}

export const defaultProps = {
  title: "Поле для файла",
  tip: "Перетяните файлы в эту область",
  label: "Загруженный файл",
  error: "",
  type: "withPreview",
  inArchive: false,
  disabledTooltipVisible: false,
  accept: "pdfOrImage",
};
