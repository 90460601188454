import { Action } from "redux";
import { IUsersState } from "src/redux/users";

interface IAction extends Action {}

export const updateUserRequest = (state: IUsersState, action: IAction): IUsersState => {
  return {
    ...state,
    loading: true,
  };
};

export const updateUserSuccess = (state: IUsersState, action: IAction): IUsersState => {
  return {
    ...state,
    loading: false,
  };
};

export const updateUserFailure = (state: IUsersState, action: IAction): IUsersState => {
  return {
    ...state,
    loading: false,
  };
};
