import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import classNames from "classnames/dedupe";
import "./ShortPassport.scss";
import { AInput, AAntdDatepickerWithMask } from "src/helpers";
import { connect } from "react-redux";
import { Field, reduxForm, submit } from "redux-form";
import validate from "./validate";
import { attributes, formsNames } from "src/constants";
import { defaultProps, IState, IProps } from "./interface";
import { AppState } from "src/redux/AppState";

const sf = cn("site-form");
const b = cn("short-passport-form");

class ShortPassportForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { disabled, handleSubmit, fieldsNames } = this.props;

    return (
      <form onSubmit={handleSubmit} className={classNames(b(), sf())}>
        <div className={sf("section-wrapper")}>
          <section className={sf("section")}>
            <div className={sf("row")}>
              <h2 className={sf("title", { "no-margin": true })}>Паспорт</h2>
            </div>

            <div className={classNames(sf("inputs-grid"), b("inputs-grid"))}>
              <Field
                title="Серия и номер паспорта:"
                name={fieldsNames[attributes["Серия и номер паспорта"]]}
                mask={"99 99 999999"}
                component={AInput}
                disabled={disabled}
              />

              <Field
                title="Дата выдачи паспорта:"
                name={fieldsNames[attributes["Дата выдачи паспорта"]]}
                component={AAntdDatepickerWithMask}
                disabled={disabled}
              />

              <Field
                title="Дата рождения:"
                name={fieldsNames[attributes["Дата рождения"]]}
                component={AAntdDatepickerWithMask}
                disabled={disabled}
              />

              <Field
                title="СНИЛС:"
                name={fieldsNames[attributes["СНИЛС"]]}
                mask="999-999-999 99"
                component={AInput}
                disabled={disabled}
              />
            </div>
          </section>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  return {};
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(ShortPassportForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = {
  form: formsNames["shortPassport"],
  allowForeign: true,
};

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as ShortPassportForm };
