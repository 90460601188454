import { IForm } from "src/helpers/types/Form";
import { formsNames } from "src/constants";

export interface IProps extends IForm {
  className?: string;
  data: Record<string, any>;
  foreignDocInfinite: boolean;
  changeFieldValue: (field: string, value: string) => Promise<void>;
}

export const defaultProps = {
  form: formsNames["foreign"],
};
