const stagingHostname = "play.buro.app";

export const baseURL = `https://${
  process.env.NODE_ENV === "production" ? document.location.hostname : stagingHostname
}`;

export const AXIOS_TIMEOUT = 30000;
export const dadataBaseUrl = "https://suggestions.dadata.ru/suggestions/api/4_1/rs";
export const signmeBaseUrl = "https://sign.me";
export const dadataToken = "7f239df77134fb3513f74f8aaf1898912d805f4d";

export const endpoints = {
  signUp: "/signUp_json",
  getToken: "/api/jwt/authenticate",
  changePassword: "/password/change",
  whoAmI: "/whoami",
  resetSession: "/reset_session",
  logout: "/api/v1/logout",
  updateCompanySettings: "/api/v1/company_settings",
  entries: {
    get: "/api/v1/entries",
    copy: "/api/v1/copy_entries_admin",
    delete: "/api/v1/entries",
    search: "/api/v1/search_entries",
    create: "/api/v1/entries_new",
    update: "/api/v1/entry_new",
    getById: "/api/v1/entry_new",
    getSimplifiedById: "/api/v1/get_for_identification",
    kep_ip: "/api/v1/reg_api/new_s1",
    kep_multiooo: "/api/v1/reg_api/new_s1",
    kep_ipedit: "/api/v1/reg_api/new_s1",
    kep_oooedit: "/api/v1/reg_api/new_s1",
    kep_ipdel: "/api/v1/reg_api/new_s1",
    kepPooling_ip: "/is_form_generated_entry",
    kepPooling_multiooo: "/is_form_generated_entry",
    kepPooling_ipedit: "/api/v1/edit_api/is_form_generated_entry",
    kepPooling_oooedit: "/api/v1/edit_ooo_api/is_form_generated_entry",
    kepPooling_ipdel: "/api/v1/del_api/is_form_generated_entry",
    activateKep: "/api/v1/activate",
    link_ip: "/api/v1/reg_api/e1",
    link_multiooo: "/api/v1/reg_api_ooo/e1",
    link_ipedit: "/api/v1/edit_api/e1",
    link_oooedit: "/api/v1/create_link/e1_create",
    link_ipdel: "/api/v1/del_api/e1",
    linkPooling_ip: "/api/v1/reg_api/e2",
    linkPooling_multiooo: "/api/v1/reg_api_ooo/e2",
    linkPooling_oooedit: "/api/v1/create_link/e2_check",
    linkPooling_ipedit: "/api/v1/edit_api/e2",
    linkPooling_ipdel: "/api/v1/del_api/e2",
    activationPooling: "/api/v1/link_precheck",
    sendFNS_ip: "/api/v1/reg_api/s2",
    sendFNS_multiooo: "/api/v1/reg_api_ooo/s2",
    sendFNS_ipdel: "/api/v1/del_api/s2",
    sendFNS_ipedit: "/api/v1/edit_api/s2",
    sendFNS_oooedit: "/api/v1/edit_ooo_api/s2",
    fetchByOGRNorINN: "/fetch_ogrn",
    pin: "api/v1/change_entry_owner",
    getUploadLink: "/api/v1/generate_for_identification",
    activateByToken: "/api/v1/activate_by_token",
    checkActivation: "/api/v1/check_activation",
  },
  passports: {
    get: "/api/v1/passports",
    getById: "/api/v1/passport",
    update: "/api/v1/passport",
    remove: "/api/v1/passport",
  },
  bills: {
    getBills: "/api/v1/bills",
    changeBillStatus: "/api/v1/bill",
    companyBill: "/api/v1/company_bill",
    changeBalance: "/api/v1/change_balance",
    showBalance: "/api/v1/show_balance",
    recover: "/bill_recover",
    setDate: "/bill_set_date",
  },
  files: {
    uploadIP: "/api/v1/entry_files_f",
    uploadOOO: "/api/v1/entry_files_f_ooo_multi",
    uploadIP_EDIT: "/api/v1/entry_files_f_edit",
    uploadOOO_EDIT: "/api/v1/entry_files_f_edit_ooo",
    uploadIP_DEL: "/api/v1/entry_files_f_del",
    load: "/api/v1/file_preview",
    remove: "/api/v1/remove_entry_files",
    download: "/api/v1/file_token",
    download2: "/api/v1/file",
    fastUpload: "/api/v1/entry_files_save_by_token",
    loadByToken: "/api/v1/file_preview_by_token",
    downloadByToken: "/api/v1/get_file_by_token",
    removeByToken: "/api/v1/remove_entry_files_by_token",
  },
  inn: "/fetch_fns_param/",
  sono2: "/fns2/",
  oktmo: "/detect/oktmo/",
  issuedBy: "/fetch_passport_param/",
  checkPassport: "/api/v1/passport_check/",
  fetchName: "/fetch_name",
  checkDelivery: "/signapi/checkdelivery",
  activities: {
    get_okved: "/okveds",
    get_okved_cats: "/okved_cats",
  },
  companies: {
    getAll: "/api/v1/companies",
    updateInfo: "/api/v1/company/",
  },
  users: {
    users: "/api/v1/users",
    changePassword: "/api/v1/user_password",
    deactivate: "/api/v1/user_deactivate",
  },
  marketplace: {
    getCards: "/api/v1/marketplace/cards",
    getRequests: "/api/v1/mailer/requests",
  },
  mails: "/api/v1/mailer",
  errors: {
    getAll: "/api/v1/errors/get_all",
    addError: "/api/v1/errors/handle",
    clearAll: "/api/v1/errors/clear",
  },
  certificates: {
    check: {
      byPhone: "/api/v1/signme/precheck/phone",
      bySnils: "/api/v1/signme/precheck/snils",
      byInn: "/api/v1/signme/precheck/inn",
      byEmail: "/api/v1/signme/precheck/email",
      byOgrn: "/api/v1/signme/precheck/ogrn",
    },
  },
  statuses: {
    get: {
      fns: "/api/v1/status/fns",
      signme: "/api/v1/status/signme",
    },
  },
};

export const KEP_POOLING_INTERVAL = 5 * 1000;
export const LINK_POOLING_INTERVAL = 5 * 1000;
export const ACTIVATE_POOLING_INTERVAL = 5 * 1000;
export const STATUS_POOLING_INTERVAL = 60 * 1000;
export const SMEV_POOLING_INTERVAL = 10 * 1000;
