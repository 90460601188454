import { cloneDeep } from "lodash";
import { attributes } from "src/constants";
import { serverValuesToMasksFormat } from "src/helpers";
import { IEntriesState } from "./entries.reducer";
import { Action } from "redux";

interface IAction extends Action {
  EIOData: Record<string, any>;
}

export const updateEIO = (state: IEntriesState, action: IAction) => {
  const formattedEntry: any = cloneDeep(state.formattedEntry);
  const { EIOData } = action;
  formattedEntry[attributes["ЕИО"]] = Object.assign(
    {},
    formattedEntry[attributes["ЕИО"]],
    serverValuesToMasksFormat(EIOData),
  );

  return {
    ...state,
    formattedEntry,
  };
};
