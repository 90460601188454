import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, submit } from "redux-form";
import { cn } from "src/helpers/bem";
import classNames from "classnames/dedupe";
import { attributes } from "src/constants";
import { ACheckbox } from "src/helpers";
import { getInitialValues } from "./initialValues";
import { AppState } from "src/redux/AppState";
import { IProps, defaultProps } from "./interface";
import "./DocsFormat.scss";

const b = cn("docs-format-form");

class DocsFormatForm extends Component<IProps> {
  // Это форма состоит из одного чекбокса и без проблем
  // может быть воткнута в любое место странички

  render() {
    const { className, handleSubmit, disabled } = this.props;

    return (
      <form onSubmit={handleSubmit} className={classNames(b(), className)}>
        <Field
          className={b("checkbox")}
          title="Выдать документы на бумажном носителе"
          name={attributes["Выдать документы на бумажном носителе"]}
          component={ACheckbox}
          disabled={disabled}
        />
      </form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { data } = ownProps;
  const initialValues = data ? getInitialValues(data) : {};

  return { initialValues };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  return {
    onSubmit: () => dispatch(submit(ownProps.form)),
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(DocsFormatForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as DocsFormatForm };
