import { IActivitiesState } from "./../reducer/activities.reducer";
import { Action } from "redux";
import { IActivity } from "src/helpers/types/Activity";

interface IAction extends Action {
  activities: IActivity[];
  error: boolean;
}

export const getActivitiesRequest = (state: IActivitiesState, action: IAction): IActivitiesState => {
  return {
    ...state,
    loading: true,
  };
};

export const getActivitiesSuccess = (state: IActivitiesState, action: IAction): IActivitiesState => {
  return {
    ...state,
    loading: false,
    activities: action.activities,
  };
};

export const getActivitiesFailure = (state: IActivitiesState, action: IAction): IActivitiesState => {
  return { ...state, error: action.error, loading: false };
};
