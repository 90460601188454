import { IMarketplaceCard } from "src/helpers/types/MarketplaceCard";

export interface IProps {
  className?: string;
  data: IMarketplaceCard;
}

export interface IState {
  modalVisible: boolean;
}

export const defaultProps = {};
