import { attributes, errorsTypes } from "src/constants";
import moment from "moment";

export const DATE_REG = /\d{2}.\d{2}.\d{4}/;

export const isDateValid = (date: string) => {
  const dateFormat = "DD.MM.YYYY";
  const valueAsDate = moment(date, dateFormat);
  //@ts-ignore
  return !isNaN(valueAsDate?.toDate()) && valueAsDate?.toDate() instanceof Date;
};

export const snilsCheck = (value: string) => {
  const pattern = /^(\d{3})\-(\d{3})\-(\d{3})\s(\d{2})$/;
  const isTrueLength = (value ? value : "").replace(/\D/gm, "").length == 11;

  if (!isTrueLength || !pattern.test(value)) {
    return false;
  }

  const valueClean = (value ? value : "").replace(/-/g, "");
  let valueFinal: any = valueClean.replace(" ", "");

  const checkSum = parseInt(valueFinal.slice(9), 10);

  // представить строку как массив (для старых браузеров)
  valueFinal = "" + valueFinal;
  valueFinal = valueFinal.split("");

  const sum =
    valueFinal[0] * 9 +
    valueFinal[1] * 8 +
    valueFinal[2] * 7 +
    valueFinal[3] * 6 +
    valueFinal[4] * 5 +
    valueFinal[5] * 4 +
    valueFinal[6] * 3 +
    valueFinal[7] * 2 +
    valueFinal[8] * 1;

  if (sum < 100) {
    return sum == checkSum;
  }

  if (sum == 100 || sum == 101) {
    return checkSum == 0;
  }

  if (sum > 101) {
    return sum % 101 == checkSum || (sum % 101 == 100 && checkSum == 0);
  }
};

export const innCheck = (value: string) => {
  const pattern = /^(\d{10,12})$/;
  if (!pattern.test(value)) {
    return false;
  }

  // преобразуем в строку
  let valueFinal: any = "" + value;

  // преобразуем в массив
  valueFinal = valueFinal.split("");

  //для ИНН в 10 знаков
  if (
    valueFinal.length == 10 &&
    valueFinal[9] ==
      ((2 * valueFinal[0] +
        4 * valueFinal[1] +
        10 * valueFinal[2] +
        3 * valueFinal[3] +
        5 * valueFinal[4] +
        9 * valueFinal[5] +
        4 * valueFinal[6] +
        6 * valueFinal[7] +
        8 * valueFinal[8]) %
        11) %
        10
  ) {
    return true;
    //для ИНН в 12 знаков
  }

  if (
    valueFinal.length == 12 &&
    valueFinal[10] ==
      ((7 * valueFinal[0] +
        2 * valueFinal[1] +
        4 * valueFinal[2] +
        10 * valueFinal[3] +
        3 * valueFinal[4] +
        5 * valueFinal[5] +
        9 * valueFinal[6] +
        4 * valueFinal[7] +
        6 * valueFinal[8] +
        8 * valueFinal[9]) %
        11) %
        10 &&
    valueFinal[11] ==
      ((3 * valueFinal[0] +
        7 * valueFinal[1] +
        2 * valueFinal[2] +
        4 * valueFinal[3] +
        10 * valueFinal[4] +
        3 * valueFinal[5] +
        5 * valueFinal[6] +
        9 * valueFinal[7] +
        4 * valueFinal[8] +
        6 * valueFinal[9] +
        8 * valueFinal[10]) %
        11) %
        10
  ) {
    return true;
  }
  return false;
};

export const isFieldExist = (
  attributeName: string,
  value: string,
  errors: Record<string, string>,
  ignoreSymbols = "",
  errorText?: string,
): Record<string, string> => {
  if (ignoreSymbols && typeof value === "string") {
    const reg = new RegExp(ignoreSymbols, "g");
    value = value && value.replace(reg, "");
  }

  if (!value) {
    errors[attributeName] = errorText || errorsTypes.requiredField;
  }

  return errors;
};

export const noLatinLetters = (
  attributeName: string,
  value: string,
  errors: Record<string, string>,
): Record<string, string> => {
  const reg = /^[^a-z]+$/gi;
  if (!reg.test(value)) {
    errors[attributeName] = "Поле не может содержать латиницу";
  }
  return errors;
};

export const validateUstavNumber = (number: number, errors: Record<string, string>): Record<string, string> => {
  if (!number) {
    errors[attributes["Номер типового Устава"]] = errorsTypes.requiredField;
    //@ts-ignore
  } else if (!parseInt(number) || number < 1 || number > 36) {
    errors[attributes["Номер типового Устава"]] = "Можно указать только числа от 1 до 36";
  }

  return errors;
};

export const validateCyrillicField = (
  attributeName: string,
  value: string,
  errors: Record<string, string>,
): Record<string, string> => {
  const textReg = /^([а-яА-ЯёЁ0-9\-\+\.,\\\/'\"#«»№IVXLCDM\(\) ]+)$/;

  if (value && !textReg.test(value)) {
    errors[attributeName] = errorsTypes.forbiddenSymbols;
  }

  return errors;
};

export const validateIssuedByField = (
  attributeName: string,
  value: string,
  errors: Record<string, string>,
): Record<string, string> => {
  const engReg = /^([a-zA-Zа-яА-ЯёЁ0-9\-\+\.,\\\/'\"#<>:«»|№\(\) ]+)$/;

  if (!value) {
    errors[attributeName] = errorsTypes.requiredField;
  } else if (!engReg.test(value)) {
    errors[attributeName] = errorsTypes.forbiddenSymbols;
  }

  return errors;
};

export const validateLatin = (
  attributeName: string,
  value: string,
  errors: Record<string, string>,
  required = true,
): Record<string, string> => {
  const latinReg = /^[a-z\d\-\,\.\s]*$/gi;
  if (!value && required) {
    errors[attributeName] = errorsTypes.requiredField;
  }

  if (value && !latinReg.test(value)) {
    errors[attributeName] = errorsTypes.forbiddenSymbols;
  }
  return errors;
};

export const validateEmail = (
  attributeName: string,
  email: string,
  errors: Record<string, string>,
  required = true,
): Record<string, string> => {
  const emailReg = /(.+)@(.+){2,}\.(.+){2,}/;
  const cyrillicReg = /[а-яА-ЯЁё]/;

  if (!email) {
    if (required) {
      errors[attributeName] = errorsTypes.requiredField;
    }
  }

  if (email && !emailReg.test(email)) {
    errors[attributeName] = errorsTypes.forbiddenEmail;
  }

  if (cyrillicReg.test(email)) {
    errors[attributeName] = errorsTypes.forbiddenSymbols;
  }

  return errors;
};

export const validatePhone = (
  attributeName: string,
  phone: string,
  errors: Record<string, string>,
  required = true,
): Record<string, string> => {
  const phoneNumbers = phone?.replace(/[^\d]/g, "");
  if (!phone) {
    if (required) {
      errors[attributeName] = errorsTypes.requiredField;
    }
  } else if (phoneNumbers[1] !== "9" && required) {
    // обязательный телефон - только мобильный
    errors[attributeName] = errorsTypes.forbiddenMobilePhone;
  } else if (phoneNumbers.length !== 11) {
    errors[attributeName] = errorsTypes.forbiddenPhone;
  }
  return errors;
};

export const validateSnils = (
  attributeName: string,
  snils: string,
  errors: Record<string, string>,
  required = true,
): Record<string, string> => {
  if (!snils) {
    if (required) {
      errors[attributeName] = errorsTypes.requiredField;
    }
  } else if (snils === "___-___-___ __" || !snilsCheck(snils)) {
    errors[attributeName] = errorsTypes.forbiddenSnils;
  }

  return errors;
};

export const validateINN = (inn: string, errors: Record<string, string>, required = true): Record<string, string> => {
  if (!inn) {
    if (required) {
      errors[attributes["ИНН"]] = errorsTypes.requiredField;
    }
  } else if (inn === "____________" || !innCheck(inn)) {
    errors[attributes["ИНН"]] = errorsTypes.forbiddenINN;
  }

  return errors;
};

export const validateOGRN = (ogrn: string, errors: Record<string, string>): Record<string, string> => {
  if (ogrn) {
    if (ogrn.indexOf("_") !== -1) {
      errors[attributes["ОГРН"]] = errorsTypes.forbiddenOGRN;
    }
  }

  return errors;
};

export const validateSono = (sono: string, errors: Record<string, string>): Record<string, string> => {
  const sonoReg = /^\d{4}$/;
  if (!sono) {
    errors[attributes["Код рег. органа"]] = errorsTypes.requiredField;
  } else if (!sono.match(sonoReg)) {
    errors[attributes["Код рег. органа"]] = errorsTypes.forbiddenSono;
  }

  return errors;
};

export const validateDate = (
  attributeName: string,
  date: string,
  errors: Record<string, string>,
): Record<string, string> => {
  if (!date) {
    errors[attributeName] = errorsTypes.requiredField;
  } else if (!date.match(DATE_REG) || !isDateValid(date)) {
    errors[attributeName] = errorsTypes.forbiddenDate;
  }
  return errors;
};

export const validatePercents = (percents: string, errors: Record<string, string>): Record<string, string> => {
  const parsedPercents = parseFloat(percents);
  const percentsReg = /^[0-9]{1,3}%$/gi;
  if (!percentsReg.test(percents) || isNaN(parsedPercents) || parsedPercents > 100) {
    errors[attributes["Доля (в процентах)"]] = errorsTypes.forbiddenEquityFormat;
  }
  return errors;
};

export const validateDecimalRatio = (value: string, errors: Record<string, string>): Record<string, string> => {
  if (!/^(0.\d+)|1$/.test(value)) {
    errors[attributes["Доля (десятичная дробь)"]] = errorsTypes.forbiddenEquityFormat;
  }
  return errors;
};

export const validateSimpleRatio = (value: string, errors: Record<string, string>): Record<string, string> => {
  if (!/^(\d+\/\d+)|1$/g.test(value)) {
    errors[attributes["Доля (простая дробь)"]] = errorsTypes.forbiddenEquityFormat;
  }
  return errors;
};

export const valueMustBeBiggerThan = (
  attributeName: string,
  value: string,
  minValue: number,
  errors: Record<string, string>,
): Record<string, string> => {
  const parsedValue = parseFloat(value);

  if (parsedValue < minValue) {
    errors[attributeName] = `Значение должно быть больше ${minValue}`;
  }
  return errors;
};

export const validateRusPassport = (value: string, errors: Record<string, string>): Record<string, string> => {
  const passportReg = /^\d{2} \d{2} \d{6}$/gi;
  if (!passportReg.test(value)) {
    errors[attributes["Серия и номер паспорта"]] = errorsTypes.forbiddenPassport;
  }
  return errors;
};

export const validateKodPodrazdelenia = (value: string, errors: Record<string, string>): Record<string, string> => {
  const KodPodrazdeleniaReg = /^\d{3}-\d{3}$/gi;
  if (!KodPodrazdeleniaReg.test(value)) {
    errors[attributes["Код подразделения"]] = errorsTypes.forbiddenKP;
  }
  return errors;
};

export const isPasswordCorrect = (
  attributeName: string,
  value: string,
  errors: Record<string, string>,
): Record<string, string> => {
  if (value) {
    if (value.length < 4) {
      errors[attributeName] = errorsTypes.forbiddenPasswordLength;
      return errors;
    }

    const numberRegex = /\d/gi;
    if (!numberRegex.test(value)) {
      errors[attributeName] = errorsTypes.forbiddenPasswordSymbols;
      return errors;
    }

    if (value.toLowerCase() === value || value.toUpperCase() === value) {
      errors[attributeName] = errorsTypes.forbiddenPasswordRegister;
      return errors;
    }

    return errors;
  }
  errors[attributeName] = errorsTypes.requiredField;
  return errors;
};

export const textMustBeLessThan = (
  attributeName: string,
  value: string,
  maxLength: number,
  errors: Record<string, string>,
): Record<string, string> => {
  if (value?.length > maxLength) {
    errors[attributeName] = `Здесь должно быть не более ${maxLength} символов`;
  }
  return errors;
};
