import { attributes, errorsTypes } from "src/constants";
import { checkPassportData } from "src/redux/input";
import { Dispatch } from "redux";
import { IProps } from "./interface";

export default async function asyncValidate(
  values: Record<string, any>,
  dispatch: Dispatch,
  props: IProps,
  blurredField: string,
) {
  if (values[attributes["иностранец"]] !== "true" && blurredField === attributes["Серия и номер паспорта"]) {
    const passportValid = await dispatch(
      checkPassportData(values[attributes["Серия и номер паспорта"]].replace(/ /g, "")),
    );
    if (!passportValid) {
      throw { [attributes["Серия и номер паспорта"]]: errorsTypes.invalidPassport };
    }
  }
}
