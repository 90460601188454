import { IUserInfo, IUser } from "src/helpers/types/User";
import { IUpdateUserSignature } from "src/redux/users";
import { IForm } from "src/helpers/types/Form";

export interface IProps extends IForm {
  className?: string;
  username: IUser;
  url: string;
  disabled: boolean;
  onChange(url: string): void;
  public_balance: boolean;
  reqActivate: boolean;
  userInfo: IUserInfo;
  changeCompanySettings: (companyId: number, flags: { public_balance: boolean; reqActivate: boolean }) => Promise<void>;
  companyId: number;
  isAdminOrOwner: boolean;
  updateUser: (id: string, data: IUpdateUserSignature) => Promise<void>;
  changePassword: () => Promise<void>;
  email: string;
  lastName: string;
  firstName: string;
}

export interface IState {
  passwordInputsVisible: boolean;
  public_balance: boolean;
  reqActivate: boolean;
  avatarURL: string;
}

export const defaultProps = {};
