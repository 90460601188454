import { cloneDeep } from "lodash";
import { sortSelectedActivities } from "src/helpers";
import { IActivitiesState } from "src/redux/activities";
import { Action } from "redux";

interface IAction extends Action {
  activityCode: string;
}

export const restoreActivity = (state: IActivitiesState, action: IAction): IActivitiesState => {
  const { activityCode } = action;
  const { selectedActivities } = state;

  let updatedActivities = cloneDeep(selectedActivities.slice());

  // находим ОКВЭД с данным кодом, имеющий статус "removed"
  const activityToBeRestored = cloneDeep(
    selectedActivities.filter((it) => it["code"] === activityCode && it["status"] === "removed")[0],
  );

  // если он основной, удалим все остальные основные ОКВЭД
  if (activityToBeRestored["main"]) {
    updatedActivities = updatedActivities.filter((it) => {
      if (it["main"] && it["status"] === "new") {
        return false;
      }
      return true;
    });
  }

  //  удаляем остальные ОКВЭД с данным кодом из текущего списка, которые не имеют статус removed
  updatedActivities = updatedActivities.filter(
    (it) =>
      it["code"] !== activityToBeRestored["code"] ||
      (it["code"] === activityToBeRestored["code"] && it["status"] === "removed"),
  );

  //заменяем данный ОКВЭД в checkedActivities
  updatedActivities = updatedActivities.map((it) => {
    if (it.code === activityCode) {
      return { ...activityToBeRestored, status: "default" };
    } else {
      return it;
    }
  });

  return {
    ...state,
    selectedActivities: sortSelectedActivities(updatedActivities),
  };
};
