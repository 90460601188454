import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";
import { IEntriesResponse } from "src/helpers/types/ResponsesFromBackend";

interface IAction extends Action {
  entries: IEntriesResponse;
}

export const getEntriesRequest = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    loading: true,
  };
};

export const getEntriesSuccess = (state: IEntriesState, action: IAction): IEntriesState => {
  return {
    ...state,
    loading: false,
    entries: action.entries,
    error: false,
  };
};

export const getEntriesFailure = (state: IEntriesState, action: IAction): IEntriesState => {
  return { ...state, error: true, loading: false };
};
