import { IMarketplaceState } from "src/redux/marketplace";
import { Action } from "redux";

interface IAction extends Action {}

export const sendMarketplaceCardFormRequest = (state: IMarketplaceState, action: IAction): IMarketplaceState => {
  return {
    ...state,
    loading: true,
  };
};

export const sendMarketplaceCardFormSuccess = (state: IMarketplaceState, action: IAction): IMarketplaceState => {
  return {
    ...state,
    loading: false,
  };
};

export const sendMarketplaceCardFormFailure = (state: IMarketplaceState, action: IAction): IMarketplaceState => {
  return {
    ...state,
    loading: false,
  };
};
