import { marketplaceConstants } from "./../marketplace.constants";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { message } from "antd";
import { IMarketplaceCard } from "src/helpers/types/MarketplaceCard";

export const getCards = () => {
  const { GET_CARDS_REQUEST, GET_CARDS_SUCCESS, GET_CARDS_FAILURE } = marketplaceConstants;
  const request = () => ({ type: GET_CARDS_REQUEST });
  const success = (cards: IMarketplaceCard[]) => ({ type: GET_CARDS_SUCCESS, cards });
  const failure = () => ({ type: GET_CARDS_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      const cards: IMarketplaceCard[] = await API.get(`${endpoints.marketplace.getCards}`);
      dispatch(success(cards));
    } catch (err: any) {
      message.error(`Не удалось загрузить список предложий маркетплейса ${err}`);
      dispatch(failure());
    }
  };
};
