import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import classNames from "classnames/dedupe";
import { isEmpty } from "lodash";
import "./UsersTable.scss";
import { usersColumns } from "src/constants";
import { Loader } from "src/components";
import { PlusOutlined } from "@ant-design/icons";
import { Input, Select, Table, Modal } from "antd";
import { usersFromServerFormat, getCookie, setCookie } from "src/helpers";
import { UserForm } from "src/forms";
import { ChangeUserFormType } from "src/helpers/types/User";
import { IProps, IState } from "./interface";

const { Option } = Select;

const b = cn("users-table");
const sp = cn("site-page");

export class UsersTable extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      pageSize: 20,
      searchQuery: "",
      currentPage: 1,
      searchOnFocus: false,
      modalVisible: false,
      companyId: "",
      openedUserData: { activated: false },
      status: "",
      activated: "",
    };
  }

  componentDidMount() {
    //@ts-ignore
    this.setState({ pageSize: +getCookie("users-page-size") || 20 });
  }

  handleModalVisible = () => {
    this.setState({ modalVisible: !this.state.modalVisible });
  };

  handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ currentPage: 1, searchQuery: evt.target.value }, this.updateUsers);
  };

  handleSearchFocus = () => {
    this.setState({ searchOnFocus: true });
  };

  handleSearchBlur = () => {
    this.setState({ searchOnFocus: false });
  };

  handleFormSubmit = () => {
    this.setState({ searchQuery: "", modalVisible: false });
  };

  handleFilterChange = (filterName: string, value: string | number, clearPaginationPage = false) => {
    const { onSearch } = this.props;

    //@ts-ignore
    this.setState({ [filterName]: value });

    if (clearPaginationPage) {
      this.setState({ currentPage: 1 });
    }

    if (filterName === "pageSize") {
      setCookie("users-page-size", value);
    }

    if (filterName === "companyId" || filterName === "status" || filterName === "activated") {
      const { companyId, searchQuery, activated, status } = this.state;
      onSearch({ companyId, search: searchQuery, activated, status, [filterName]: value });
    }
  };

  updateUsers = () => {
    const { onSearch } = this.props;
    const { companyId, searchQuery, activated, status } = this.state;

    if (searchQuery.length >= 3) {
      onSearch({ companyId, search: searchQuery, activated, status });
    } else if (searchQuery.length === 0) {
      onSearch({ companyId, search: searchQuery });
    }
  };

  renderModal = () => {
    const {
      loading,
      isAdmin,
      data: { companies },
    } = this.props;
    const { modalVisible, openedUserData } = this.state;
    const formType: ChangeUserFormType = isEmpty(openedUserData) ? "new" : "edit";

    return (
      <Modal visible={modalVisible} onCancel={this.handleModalVisible} footer={null} className="common-modal">
        <h2 className={b("modal-title")}>{formType === "edit" ? "Данные пользователя" : "Новый пользователь"}</h2>
        <UserForm
          isAdmin={isAdmin}
          companies={companies}
          onFinal={this.handleFormSubmit}
          opened={modalVisible}
          userInfo={openedUserData}
          formType={formType}
          disabled={loading || (formType === "edit" && !openedUserData.activated)}
        />
      </Modal>
    );
  };

  renderControls = () => {
    const { pageSize, searchOnFocus, searchQuery, companyId, status, activated } = this.state;
    const {
      loading,
      isAdmin,
      data: { companies },
    } = this.props;
    return (
      <div className={sp("controls")}>
        <div className={b("search-wrapper")}>
          <Input
            placeholder="Поиск..."
            className={sp("search")}
            value={searchQuery}
            onChange={this.handleSearchChange}
            onFocus={this.handleSearchFocus}
            onBlur={this.handleSearchBlur}
            disabled={loading && !searchOnFocus}
          />
          <button
            type="button"
            onClick={() => {
              this.setState({ openedUserData: {} }, () => this.handleModalVisible());
            }}
            className="custom-button"
          >
            <PlusOutlined />
            Создать пользователя
          </button>
        </div>
        <div className={sp("control-items")}>
          {isAdmin && (
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={activated}
              style={{ width: 145 }}
              onChange={(value) => this.handleFilterChange("activated", value, true)}
              className={sp("control")}
              disabled={loading}
            >
              <Option value="">Все</Option>
              <Option value="true">Активирован</Option>
              <Option value="false">Не активирован</Option>
            </Select>
          )}

          {isAdmin && (
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={status}
              style={{ width: 145 }}
              onChange={(value) => this.handleFilterChange("status", value, true)}
              className={sp("control")}
              disabled={loading}
            >
              <Option value="">Все</Option>
              <Option value="admin">Администратор</Option>
              <Option value="observer">Наблюдатель</Option>
              <Option value="user">Пользователь</Option>
              <Option value="null">Неизвестен</Option>
            </Select>
          )}

          {isAdmin && typeof companies !== "undefined" && (
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={companyId}
              style={{ width: 145 }}
              onChange={(value) => this.handleFilterChange("companyId", value, true)}
              className={sp("control")}
              disabled={loading}
            >
              <Option value={""}>Все компании</Option>
              {companies.map((opt, index: number) => (
                <Option value={opt.id} key={`companies-select-option-${index}`}>
                  {opt.name}
                </Option>
              ))}
            </Select>
          )}
          <Select
            defaultValue={20}
            value={pageSize}
            style={{ width: 113 }}
            onChange={(value) => this.handleFilterChange("pageSize", value, true)}
            disabled={loading}
            className={sp("control", { last: true })}
          >
            <Option value={20}>20</Option>
            <Option value={40}>40</Option>
            <Option value={80}>80</Option>
          </Select>
        </div>
      </div>
    );
  };

  render() {
    const {
      data: { users, companies },
      loading,
    } = this.props;
    const { pageSize, currentPage, searchOnFocus } = this.state;
    const tableData = usersFromServerFormat(users, companies);

    return (
      <div className={classNames(sp("content"), b())}>
        {this.renderModal()}
        {this.renderControls()}
        <Table
          className={sp("table")}
          onRow={(record) => {
            return {
              onClick: () => {
                this.setState({
                  openedUserData: users?.filter((user) => user.userID === record.key)[0] || {},
                });
                this.handleModalVisible();
              },
            };
          }}
          columns={usersColumns}
          dataSource={tableData}
          scroll={{ y: "100%" }}
          pagination={{
            showSizeChanger: false,
            pageSize,
            total: tableData?.length,
            onChange: (value) => this.handleFilterChange("currentPage", value),
            current: currentPage,
            hideOnSinglePage: true,
          }}
          loading={{
            spinning: Boolean(loading && users?.length && !searchOnFocus),
            indicator: <Loader fixed={false} />,
          }}
        />
      </div>
    );
  }
}
