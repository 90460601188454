import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "src/constants";
import { prepareHeadersForRtkQuery } from "../../helpers/redux";

const certificatesBaseUrl = `${baseURL}/api/v1/signme`;

export enum CrtInfoSource {
  PHONE = "phone",
  EMAIL = "email",
  INN = "inn",
  SNILS = "snils",
  OGRN = "cogrn",
}

export enum CrtDetailedInfoSource {
  PHONE = "phone",
  SNILS = "snils",
}

// Define a service using a base URL and expected endpoints
export const certificatesApi = createApi({
  reducerPath: "certificatesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: certificatesBaseUrl,
    prepareHeaders: prepareHeadersForRtkQuery,
  }),
  endpoints: (builder) => ({
    getCertificateDataByPhone: builder.query<GetCrtInfoResponse, GetCrtInfoParams>({
      query: ({ value }) => `/precheck/${CrtInfoSource.PHONE}/${value}`,
    }),
    getCertificateDataByEmail: builder.query<GetCrtInfoResponse, GetCrtInfoParams>({
      query: ({ value }) => `/precheck/${CrtInfoSource.EMAIL}/${value}`,
    }),
    getCertificateDataByInn: builder.query<GetCrtInfoResponse, GetCrtInfoParams>({
      query: ({ value }) => `/precheck/${CrtInfoSource.INN}/${value}`,
    }),
    getCertificateDataBySnils: builder.query<GetCrtInfoResponse, GetCrtInfoParams>({
      query: ({ value }) => `/precheck/${CrtInfoSource.SNILS}/${value}`,
    }),
    getCertificateDataByOGRN: builder.query<GetCrtInfoResponse, GetCrtInfoParams>({
      query: ({ value }) => `/precheck/${CrtInfoSource.OGRN}/${value}`,
    }),
    getDetailedCertificateInfo: builder.query<DetailedCrtInfoResponse, GetDetailedCrtInfoParams>({
      query: ({ source, value }) => `/userinfo?${source}=${value.replace(/[\s\-]/gi, "")}`,
    }),
  }),
});

export const {
  useLazyGetCertificateDataByPhoneQuery,
  useLazyGetCertificateDataByEmailQuery,
  useLazyGetCertificateDataByOGRNQuery,
  useLazyGetCertificateDataBySnilsQuery,
  useLazyGetCertificateDataByInnQuery,
  useLazyGetDetailedCertificateInfoQuery,
} = certificatesApi;

export type GetCrtInfoResponse = {
  status: {
    [key in CrtInfoSource]?: CrtInfo;
  };
};

type GetCrtInfoParams = {
  value: string;
};

export type CrtInfo = {
  id: number;
  approved: boolean;
  apprtype: number;
  created: boolean;
  rejected: boolean;
  reject_comment: string;
  pdf: string;
  keys: any[];
  requests: Array<{ id: number; mobile: boolean }>;
  mine: boolean;
  smev: {
    snils: { status: string; details: string };
    inn: { status: string; details: string };
    passport: { status: string; details: any };
  };
};

type GetDetailedCrtInfoParams = {
  source: CrtDetailedInfoSource;
  value: string;
};

export type DetailedCrtInfoResponse = {
  id: number;
  fio: string;
  phone: string;
  snils: string;
  pdf: string;
  haspdf: any;
  haspas: boolean;
  haspas2: boolean;
  hasnils: boolean;
  hasface: boolean;
  keys: any[];
  delivery: DetailedCrtInfoDelivery;
};

export enum DetailedCrtInfoDeliveryStatus {
  None = "None",
  Created = "created",
  Scheduled = "scheduled",
  Rescheduled = "rescheduled",
  Problem = "problem",
  Canceled = "canceled",
  Failed = "failed",
  Completed = "completed",
}

export type DetailedCrtInfoDeliveryCompany = 0 | 2 | 3 | 4;

type DetailedCrtInfoDelivery = {
  status: null | DetailedCrtInfoDeliveryStatus;
  reason: null;
  date: null;
  interval: null;
  company: null | DetailedCrtInfoDeliveryCompany;
  address: null | string;
};
