import { inputConstants } from "./../input.constants";
import { endpoints, attributes, formsNames } from "src/constants";
import { message } from "antd";
import { API, dadataAPI, detectGender } from "src/helpers";
import { store } from "src/redux/store";
import { toggleNextAutoCompleteLoad } from "src/redux/input";

const endPointsTypes = {
  [attributes["Имя маркетплейс"]]: "fio",
  [attributes["Фамилия маркетплейс"]]: "fio",
  [attributes["Отчество маркетплейс"]]: "fio",
  [attributes["Имя"]]: "fio",
  [attributes["имя2"]]: "fio",
  [attributes["Фамилия"]]: "fio",
  [attributes["Отчество"]]: "fio",
  [attributes["Полный адрес"]]: "fias",
  [attributes["Полный адрес маркетплейс"]]: "fias",
  [attributes["Адрес для встречи с курьером"]]: "fias",
  [`executive_${attributes["Полный адрес"]}`]: "fias",
  // later :o
  // [attributes["Полный адрес"]]: "address",
  // [`executive_${attributes["Полный адрес"]}`]: "address",
  [attributes["Электронная почта"]]: "email",
  [attributes["емейл2"]]: "email",
  [attributes["ИННкомпании"]]: "party",
};

const dataTypes = {
  [attributes["Имя маркетплейс"]]: "NAME",
  [attributes["Имя"]]: "NAME",
  [attributes["имя2"]]: "NAME",
  [attributes["Фамилия"]]: "SURNAME",
  [attributes["Фамилия маркетплейс"]]: "SURNAME",
  [attributes["Отчество"]]: "PATRONYMIC",
  [attributes["Отчество маркетплейс"]]: "PATRONYMIC",
};

export const loadAutoComplete = (form: string, inputName: string, inputValue: string): any => {
  const { AUTOCOMPLETE_LOAD, AUTOCOMPLETE_LOAD_SUCCESS, AUTOCOMPLETE_LOAD_FAILED } = inputConstants;
  const request = () => ({ type: AUTOCOMPLETE_LOAD });
  const success = (data: any) => ({ type: AUTOCOMPLETE_LOAD_SUCCESS, data, form, inputName });
  const failure = () => ({ type: AUTOCOMPLETE_LOAD_FAILED });

  return async function (dispatch: Function) {
    const state = store.getState();
    const { loadAutoCompleteOnNextChange } = state.input;
    if (!loadAutoCompleteOnNextChange) {
      dispatch(toggleNextAutoCompleteLoad(true));
      return;
    }
    dispatch(request());
    try {
      const data: any = await dadataAPI.post(`/suggest/${endPointsTypes[inputName]}`, {
        query: inputValue,
        hint: "",
        parts: [dataTypes[inputName]],
      });
      let cards = data.suggestions;
      const lastNameNextToPassportForm =
        form !== formsNames["companyAdmin"] && inputName === attributes["Фамилия"] && inputValue.length >= 3;
      if (lastNameNextToPassportForm) {
        // подгружаем существующие данные о клиентах внутри заявок
        const clientCards: any = await API.get(
          `${endpoints.passports.get}?li=10&page=1${inputValue ? `&search=${inputValue}` : ""}`,
        );
        cards = [...clientCards.passports, ...cards];
      }
      dispatch(success(cards));
      // заодно пытаемся определить пол
      // именно здесь, чтобы не страдать с asyncValidate...
      if (lastNameNextToPassportForm) {
        await detectGender(inputValue, attributes["Фамилия"], form, dispatch);
      }
    } catch (err: any) {
      message.error(`Не удалось загрузить данные для подсказок ${err}`);
      dispatch(failure());
    }
  };
};
