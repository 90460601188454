const МодульБанкId = 307;
const БизнесКлассId = 261;
const UrvistaId = 4;
const БланкБанкId = 308;

export const COMPANIES_IDS_THAT_CAN_VIEW_MARKETPLACE = [1, 4];
export const COMPANIES_IDS_THAT_CAN_UPLOAD_FORM = [1, 4, 261, 280];
export const ADMINS_ID = [1];
export const COMPANIES_IDS_THAT_CAN_USE_FOREIGN_IN_IP_REGISTRATION = [
  1,
  МодульБанкId,
  БизнесКлассId,
  UrvistaId,
  БланкБанкId,
];
export const COMPANIES_IDS_THAT_CAN_CALL_COURIER = [1, МодульБанкId];
