import { IBillsState } from "src/redux/bills";
import { Action } from "redux";
import { IPayment } from "src/helpers/types/Payment";

interface IAction extends Action {
  payments: IPayment[];
}

export const getBillsRequest = (state: IBillsState, action: IAction): IBillsState => {
  return {
    ...state,
    loading: true,
  };
};

export const getBillsSuccess = (state: IBillsState, action: IAction): IBillsState => {
  return {
    ...state,
    loading: false,
    payments: action.payments,
  };
};

export const getBillsFailure = (state: IBillsState, action: IAction): IBillsState => {
  return {
    ...state,
    loading: false,
  };
};
