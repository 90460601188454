import { entriesConstants } from "./../entries.constants";
import { API } from "src/helpers";
import { endpoints } from "src/constants";
import { message } from "antd";
import { IEntriesResponse } from "src/helpers/types/ResponsesFromBackend";

export const getEntries = (count: number, page: number, type: string, status: string, companyId: string | number) => {
  const { ENTRIES_REQUEST, ENTRIES_SUCCESS, ENTRIES_FAILURE } = entriesConstants;
  const request = () => ({ type: ENTRIES_REQUEST });
  const success = (entries: IEntriesResponse) => ({ type: ENTRIES_SUCCESS, entries });
  const failure = () => ({ type: ENTRIES_FAILURE });

  const currentCompanyId = companyId === "all" ? "" : companyId;

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      const entries: IEntriesResponse = await API.get(
        `${endpoints.entries.get}?li=${count}&page=${page}&entryType=${type}&statusValue=${status}&companyId=${currentCompanyId}`,
      );
      dispatch(success(entries));
    } catch (err: any) {
      message.error(`Не удалось загрузить заявки ${err}`);
      dispatch(failure());
    }
  };
};
