import { cloneDeep } from "lodash";
import { sortSelectedActivities } from "src/helpers";
import { IActivitiesState } from "src/redux/activities";
import { Action } from "redux";
import { ActivityModeType } from "src/helpers/types/Activity";

interface IAction extends Action {
  mode: ActivityModeType;
  activityCode: string;
}

export const removeActivity = (state: IActivitiesState, action: IAction): IActivitiesState => {
  const { mode, activityCode } = action;
  const { selectedActivities } = state;

  if (mode === "default") {
    const updatedActivities = cloneDeep(selectedActivities.filter((it) => it["code"] !== activityCode));
    return {
      ...state,
      selectedActivities: updatedActivities,
    };
  } else if (mode === "holdOriginal") {
    let updatedActivities = selectedActivities.slice();

    // находим ОКВЭД, который нужно удалить
    const removingOkved = cloneDeep(
      updatedActivities.filter((it) => it["code"] === activityCode && it["status"] !== "removed")[0],
    );

    // если он новый
    if (removingOkved["status"] === "new") {
      // просто удаляем его
      updatedActivities = updatedActivities.filter(
        (it) => !(it["code"] === activityCode && it["status"] !== "removed"),
      );
      // если он default
    } else if (removingOkved["status"] === "default") {
      // даем ему статус "removed"
      updatedActivities = updatedActivities.map((it) => {
        if (it["code"] === activityCode && it["status"] !== "removed") {
          return { ...removingOkved, status: "removed" };
        }
        return it;
      });
    }

    return {
      ...state,
      selectedActivities: sortSelectedActivities(updatedActivities),
    };
  }

  return {
    ...state,
  };
};
