import React from "react";
import classNames from "classnames/dedupe";
import { cn } from "src/helpers/bem";
import "./BubbleText.scss";

const b = cn("bubble-text");

export const BubbleText = (props: any) => {
  return (
    <>
      <p
        className={classNames(b({ position: props.position }), props.signed && b({ large: true }), props.className)}
        id={props.id}
      >
        {props.children}
      </p>
      {props.signed && <p className={b("author")}>{props.authorName}</p>}
    </>
  );
};

BubbleText.defaultProps = {
  className: "",
  signed: false,
  authorName: "",
  position: "bottom-left",
};
