export const usersConstants = {
  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",

  ADD_USER_REQUEST: "ADD_USER_REQUEST",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",

  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  CHANGE_USER_PASSWORD_REQUEST: "CHANGE_USER_PASSWORD_REQUEST",
  CHANGE_USER_PASSWORD_SUCCESS: "CHANGE_USER_PASSWORD_SUCCESS",
  CHANGE_USER_PASSWORD_FAILURE: "CHANGE_USER_PASSWORD_FAILURE",

  DEACTIVATE_USER_REQUEST: "DEACTIVATE_USER_REQUEST",
  DEACTIVATE_USER_SUCCESS: "DEACTIVATE_USER_SUCCESS",
  DEACTIVATE_USER_FAILURE: "DEACTIVATE_USER_FAILURE",
};
