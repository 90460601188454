import { IPassportsState } from "src/redux/passports";
import { Action } from "redux";
import { IPassport } from "src/helpers/types/Passport";

interface IAction extends Action {
  passport: IPassport;
}

export const getPassportRequest = (state: IPassportsState, action: IAction): IPassportsState => {
  return {
    ...state,
    loading: true,
  };
};

export const getPassportSuccess = (state: IPassportsState, action: IAction): IPassportsState => {
  return {
    ...state,
    currentPassport: action.passport,
    loading: false,
    wasLoaded: true,
  };
};

export const getPassportFailure = (state: IPassportsState, action: IAction): IPassportsState => {
  return { ...state, loading: false };
};
