import { getFormValues, isValid, submit } from "redux-form";
import { attributes, formsNames } from "src/constants";
import { store } from "src/redux/store";
import { cloneDeep } from "lodash";
import { AppState } from "src/redux/AppState";

export const extractMarketplaceBankFormData = (state: AppState) => {
  // соберем formData из всех форм
  const shortCommonValues: any = cloneDeep(getFormValues(formsNames["shortCommon"])(state));
  const shortPassportValues = cloneDeep(getFormValues(formsNames["shortPassport"])(state));
  const shortRegistrationValues = cloneDeep(getFormValues(formsNames["shortRegistration"])(state));
  const companyNameAndINNValues = cloneDeep(getFormValues(formsNames["companyNameAndINN"])(state));

  // извлечем поле с серией и номером паспорта и разобьем на два отдельных поля,
  // ибо так нужно беку
  //@ts-ignore
  const snp = shortPassportValues[attributes["Серия и номер паспорта маркетплейс"]];
  //@ts-ignore
  shortPassportValues[attributes["Серия паспорта маркетплейс"]] = snp.match(/[0-9]{2} [0-9]{2}/)[0];
  //@ts-ignore
  shortPassportValues[attributes["Номер паспорта маркетплейс"]] = snp.match(/[0-9]{6}/)[0];

  // удаляем поле с серией и номером, потому что его на бек отправлять не нужно
  //@ts-ignore
  delete shortPassportValues[attributes["Серия и номер паспорта маркетплейс"]];

  const allData = cloneDeep(
    Object.assign({}, shortCommonValues, shortPassportValues, shortRegistrationValues, companyNameAndINNValues),
  );
  return allData;
};

export const validateMarketplaceBankFormData = (dispatch: Function): boolean => {
  // эмулируем сабмит всех форм
  dispatch(submit(formsNames["shortCommon"]));
  dispatch(submit(formsNames["shortPassport"]));
  dispatch(submit(formsNames["shortRegistration"]));
  dispatch(submit(formsNames["companyNameAndINN"]));

  // извлекаем информацию об ошибках всех форм
  const state = store.getState();
  const shortCommonValid = isValid("shortCommon")(state);
  const shortPassportValid = isValid("shortPassport")(state);
  const shortRegistrationValid = isValid("shortRegistration")(state);
  const companyNameAndINNValid = isValid("companyNameAndINN")(state);

  // проверка корректности заполнения всех форм
  return shortCommonValid && shortPassportValid && shortRegistrationValid && companyNameAndINNValid;
};
