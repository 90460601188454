import { IForm } from "src/helpers/types/Form";
import { formsNames } from "src/constants";
import { EntrySubType } from "src/helpers/types/Entry";

export interface IProps extends IForm {
  data: Record<string, any>;
  subtype: EntrySubType;
  changeReason: string;
  changeFieldValue: (form: string, field: string, value: string) => void;
}

export const defaultProps = {
  form: formsNames["changeCommonOOO"],
  subtype: "ustav",
};
