import { companiesConstants } from "./../companies.constants";
import { getCompaniesRequest, getCompaniesSuccess, getCompaniesFailure } from "./getCompanies";
import { updateCompanyRequest, updateCompanySuccess, updateCompanyFailure } from "./updateCompany";
import { showCompanies } from "./showCompanies";
import { ICompanyExtended } from "src/helpers/types/Company";
import { createReducer } from "@reduxjs/toolkit";

export interface ICompaniesState {
  companies?: { count: number; result: ICompanyExtended[] };
  filteredCompanies?: { count: number; result: ICompanyExtended[] };
  loading: boolean;
  error: boolean;
}
const initialState: ICompaniesState = {
  companies: undefined,
  filteredCompanies: undefined,
  loading: false,
  error: false,
};

export const companies = createReducer(initialState, {
  [companiesConstants.GET_COMPANIES_REQUEST]: getCompaniesRequest,
  [companiesConstants.GET_COMPANIES_SUCCESS]: getCompaniesSuccess,
  [companiesConstants.GET_COMPANIES_FAILURE]: getCompaniesFailure,

  [companiesConstants.UPDATE_COMPANY_REQUEST]: updateCompanyRequest,
  [companiesConstants.UPDATE_COMPANY_SUCCESS]: updateCompanySuccess,
  [companiesConstants.UPDATE_COMPANY_FAILURE]: updateCompanyFailure,

  [companiesConstants.SHOW_COMPANIES]: showCompanies,
});
