import { IPassportsState } from "src/redux/passports";

export const removePassportRequest = (state: IPassportsState): IPassportsState => {
  return {
    ...state,
    loading: true,
  };
};

export const removePassportSuccess = (state: IPassportsState): IPassportsState => {
  return {
    ...state,
    loading: false,
  };
};

export const removePassportFailure = (state: IPassportsState): IPassportsState => {
  return {
    ...state,
    loading: false,
  };
};
