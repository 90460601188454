import { IMarketplaceState } from "src/redux/marketplace";
import { Action } from "redux";
import { IMarketplaceCard } from "src/helpers/types/MarketplaceCard";

interface IAction extends Action {
  cards: IMarketplaceCard[];
}

export const getCardsRequest = (state: IMarketplaceState, action: IAction): IMarketplaceState => {
  return {
    ...state,
    loading: true,
  };
};

export const getCardsSuccess = (state: IMarketplaceState, action: IAction): IMarketplaceState => {
  return {
    ...state,
    loading: false,
    cards: action.cards.filter((it) => it.active),
  };
};

export const getCardsFailure = (state: IMarketplaceState, action: IAction): IMarketplaceState => {
  return { ...state, loading: false };
};
