import { filesConstants } from "./../files.constants";
import { addFileToArchive } from "src/redux/files";
import { message } from "antd";
import { API } from "src/helpers";
import { endpoints } from "src/constants";

export const loadFile = (entryId: string | number, fileName: string, requestAttribute: string) => {
  const fileInGroup = requestAttribute.match(/drug(13|14)?_\d/g);

  const { FILE_LOAD_REQUEST, FILE_LOAD_SUCCESS, FILE_LOAD_FAILURE } = filesConstants;
  const request = () => ({ type: FILE_LOAD_REQUEST });
  const success = (fileName: string, file: any, requestAttribute: string) => ({
    type: FILE_LOAD_SUCCESS,
    file,
    fileName,
    requestAttribute,
  });
  const failure = () => ({ type: FILE_LOAD_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());

    try {
      const query = fileInGroup
        ? `file_${requestAttribute}.${fileName.split(".")[1]}?size=800`
        : `${fileName}?size=800`;
      const file: Blob = await API.get(`${endpoints.files.load}/${entryId}/${query}`, {
        responseType: "blob",
      });
      dispatch(success(fileName, file, requestAttribute));
      return file;
    } catch (err: any) {
      dispatch(failure());
      message.error(`Ошибка при загрузке файла ${fileName}`);
      dispatch(addFileToArchive(requestAttribute));
      dispatch(failure());
    }
  };
};
