import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import { Divider } from "antd";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { attributes } from "src/constants";
import { mainFields, additionalFields } from "./constants";
import { isScene, customBuildingInfoValidate } from "src/helpers";
import { COMPANIES_IDS_THAT_CAN_CALL_COURIER } from "src/constants/permissions";
import { addOtherFile } from "src/redux/files";
import "./Forms.scss";
import {
  CommonForm,
  PassportForm,
  FilesForm,
  RegistrationForm,
  ActivityForm,
  ForeignForm,
  DocsFormatForm,
  DeliveryForm,
} from "src/forms";
import { AppState } from "src/redux/AppState";
import { IProps } from "./interface";

const sf = cn("site-forms");
const b = cn("IPregistration-forms");

class Forms extends Component<IProps> {
  renderFilesForm = () => {
    const { loading, wasLoaded, scene, addOtherFile, firstOtherFile, otherFiles, loadedOtherFilesCount } = this.props;

    const otherFilesFields = [];
    // Если загружен файл 'file_drug', то отображаем его только на этапе
    // после отправки в ФНС
    if (firstOtherFile && (isScene("Регистрация в ФНС", scene) || isScene("Зарегистрирован", scene))) {
      otherFilesFields.push({
        attribute: attributes["Другое"],
        title: "Другое",
        label: "Другое",
        fileType: "uploadIP_EDIT",
      });
    }

    if (otherFiles.length > 0) {
      otherFiles.forEach((file) => {
        otherFilesFields.push({
          attribute: file,
          title: "Другое",
          label: "Другое",
          fileType: "uploadIP_EDIT",
        });
      });
    }
    if (otherFiles.length === loadedOtherFilesCount) {
      addOtherFile(attributes["Другое"]);
    }

    return (
      <div>
        <FilesForm
          fileFields={mainFields.concat(otherFilesFields)}
          disabled={
            !(isScene("Создание ссылки", scene) || isScene("Проверка СМЭВ", scene) || isScene("Активация КЭП", scene))
          }
          disabledTooltipVisible={isScene("Заполнение", scene)}
          loading={loading}
          wasLoaded={wasLoaded}
        />
        {scene && isScene("Выпущена КЭП", scene) && (
          <>
            <Divider className={sf("files-divider")} />
            <FilesForm
              fileFields={additionalFields}
              disabled={isScene("Зарегистрирован", scene) || isScene("Регистрация в ФНС", scene)}
              disabledTooltipVisible={isScene("Заполнение", scene)}
              showTitle={false}
              loading={loading}
              wasLoaded={wasLoaded}
            />
          </>
        )}
      </div>
    );
  };

  render() {
    const { inostr, disabled, scene, formattedEntry, userCompanyId } = this.props;

    // просто disabled={disabled} у ActivityForm почему-то не работает(
    const activityDisabledKostil = {
      disabled,
    };

    return (
      <div className={`${sf({ "with-files": true })} ${b()}`}>
        <div className={sf("forms")}>
          <CommonForm disabled={disabled} data={formattedEntry} />
          <DocsFormatForm disabled={disabled} data={formattedEntry} className={b("docs-form")} />

          {typeof userCompanyId === "number" && COMPANIES_IDS_THAT_CAN_CALL_COURIER.includes(userCompanyId) && (
            <>
              <Divider />
              <DeliveryForm disabled={disabled} data={formattedEntry} />
            </>
          )}

          <Divider />

          <PassportForm allowForeign disabled={disabled} data={formattedEntry} />

          <Divider />

          {inostr === "true" && (
            <div>
              <ForeignForm disabled={disabled} data={formattedEntry} />
              <Divider />
            </div>
          )}

          <RegistrationForm
            customValidate={customBuildingInfoValidate}
            disabled={disabled}
            data={formattedEntry}
            extended
          />

          <Divider />

          <ActivityForm {...activityDisabledKostil} scene={scene} showPrintButton />
        </div>

        {this.renderFilesForm()}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { loading, wasLoaded, formattedEntry } = state.entries;
  const userCompanyId = state?.userReducer?.user?.company?.id;
  const passportValues = formValueSelector("passport");
  const inostr = passportValues(state, attributes["иностранец"]);
  const firstOtherFile = formattedEntry?.file_drug;
  const otherFiles = state.files.otherFiles.files;
  const loadedOtherFilesCount = Object.entries(state.files.loaded).filter(
    ([key, value]) => key.match(/drug(13|14)?_\d/g) && typeof value !== "undefined",
  ).length;

  return {
    loading,
    wasLoaded,
    inostr,
    formattedEntry,
    firstOtherFile,
    otherFiles,
    loadedOtherFilesCount,
    userCompanyId,
  };
};

const mapDispatchToProps = {
  addOtherFile,
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(Forms);

export { connectedComponent as Forms };
