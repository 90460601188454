import { attributes } from "src/constants";
import { cloneDeep } from "lodash";
import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";
import { IOwner } from "src/helpers/types/Owner";

interface IAction extends Action {
  id: number;
}

export const removeOwner = (state: IEntriesState, action: IAction): IEntriesState => {
  const formattedEntry = cloneDeep(state.formattedEntry);
  const { id } = action;

  //@ts-ignore
  formattedEntry[attributes["учредители"]] = formattedEntry[attributes["учредители"]].filter(
    (it: IOwner) => it.id !== id,
  );

  return {
    ...state,
    formattedEntry,
  };
};
