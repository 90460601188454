import { userConstants } from "../user.constants";
import { message } from "antd";
import { endpoints } from "src/constants";
import { API } from "src/helpers";
import { getUserInfo } from "./";

export const changeCompanySettings = (
  companyId: number,
  flags: { public_balance?: boolean; reqActivate?: boolean; activated?: boolean },
) => {
  // flags - объект вида { settingName1: value, settingName2: value }
  const { CHANGE_COMPANY_SETTINGS_REQUEST, CHANGE_COMPANY_SETTINGS_SUCCESS, CHANGE_COMPANY_SETTINGS_FAILURE } =
    userConstants;
  const request = () => ({ type: CHANGE_COMPANY_SETTINGS_REQUEST });
  const success = () => ({ type: CHANGE_COMPANY_SETTINGS_SUCCESS });
  const failure = () => ({ type: CHANGE_COMPANY_SETTINGS_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    try {
      await API.put(`${endpoints.updateCompanySettings}/${companyId}`, flags);
      dispatch(getUserInfo()); // чтобы мгновенно обновить инф-ю о компании юзера
      dispatch(success());
      message.success("Настройки изменены");
    } catch (err: any) {
      message.error(`Не удалось изменить настройки компании ${err}`);
      dispatch(failure());
    }
  };
};
