import { entriesConstants } from "../entries.constants";
import { extractOKVEDfromEntry, dadataAPI, API, replaceDoubleQuotes } from "src/helpers";
import { attributes, entriesTypes, additionalEntriesTypes, errorsTypes, endpoints } from "src/constants";
import { message } from "antd";
import { setSelectedActivities } from "src/redux/activities";
import { IDadataFioParts, IDadataParty, IDadataPartyResponse } from "src/helpers/types/Dadata";
import { IFnsDataResponse } from "src/helpers/types/ResponsesFromBackend";
import { EntryType } from "src/helpers/types/Entry";
import { Dispatch } from "redux";

// запрашивает информацию по ИНН или ОГРН
// используется только для заявок, то есть должна существовать formattedEntry
export const fetchByOGRNorINN = (query: string, entryType: EntryType | string): any => {
  const { FETCH_BY_OGRN_OR_INN_REQUEST, FETCH_BY_OGRN_OR_INN_SUCCESS, FETCH_BY_OGRN_OR_INN_FAILURE } = entriesConstants;
  const request = () => ({ type: FETCH_BY_OGRN_OR_INN_REQUEST });
  const success = (info: Record<string, any>) => ({ type: FETCH_BY_OGRN_OR_INN_SUCCESS, info });
  const failure = () => ({ type: FETCH_BY_OGRN_OR_INN_FAILURE });

  return async function (dispatch: Dispatch) {
    dispatch(request());

    try {
      const responseData: IDadataPartyResponse = await dadataAPI.post("findById/party", {
        query,
      });
      if (responseData.suggestions?.length > 0) {
        const info: Record<string, any> = {};
        const { data } = responseData.suggestions[0];

        // ФИО
        if (entryType === entriesTypes["ИзмененияИП"] && data.name && data.name.full) {
          const fioParts: IDadataFioParts[] = await API.get(`${endpoints.fetchName}/${data.name.full}`);
          if (fioParts[0]) {
            info[attributes["Фамилия"]] = fioParts[0].surname || "";
            info[attributes["Имя"]] = fioParts[0].name || "";
            info[attributes["Отчество"]] = fioParts[0].patronymic || "";
          }
          info[attributes["ИНН"]] = data[attributes["ИНН"] as keyof IDadataParty];
        }

        // ФИО для заявителя в изменении ООО
        if (entryType === entriesTypes["ИзмененияООО"] && data.management && data.management.name) {
          const fioParts: IDadataFioParts[] = await API.get(`${endpoints.fetchName}/${data.management.name}`);
          if (fioParts[0]) {
            info[attributes["Фамилия"]] = fioParts[0].surname || "";
            info[attributes["Имя"]] = fioParts[0].name || "";
            info[attributes["Отчество"]] = fioParts[0].patronymic || "";
          }
          info[attributes["наименованиеДолжности"]] = `${data.management.post[0]}${data.management.post
            .slice(1, data.management.post.length)
            .toLowerCase()}`;
        }

        // название ООО в изменении ООО
        if (entryType === entriesTypes["ИзмененияООО"] && data.name) {
          const { full_with_opf, short_with_opf } = data.name;
          info[attributes["Сокращенное наименование"]] = replaceDoubleQuotes(short_with_opf);
          info[attributes["Полное наименование"]] = replaceDoubleQuotes(full_with_opf);
        }

        if (entryType === entriesTypes["ИзмененияООО"]) {
          info[attributes["ОГРН"]] = data["ogrn"];
          info[attributes["ИННкомпании"]] = data[attributes["ИНН"] as keyof IDadataParty];
          info[attributes["адресКомпании"]] = data.address?.value;
        }

        if (entryType === entriesTypes["ИзмененияИП"]) {
          info[attributes["ОГРНИП"]] = data["ogrn"];
        }

        const userStatuses = {
          LEGAL: "ur",
          INDIVIDUAL: "ip",
        };

        // Код рег. органа и код терр. ФНС в изменении ИП и ООО + закрытии ИП
        if (
          entryType === entriesTypes["ИзмененияООО"] ||
          entryType === entriesTypes["ИзмененияИП"] ||
          entryType === entriesTypes["ЗакрытиеИП"]
        ) {
          const sono = data.authorities?.fts_report?.code;
          if (sono) {
            const fnsData: IFnsDataResponse = await API.get(`${endpoints.sono2}${sono}`);
            const sono_area = fnsData.sprofDetails?.ifnsCode;
            if (!sono_area) {
              message.error("Не удалось определить регистрирущий орган, обратитесь в поддержку");
            }
            info[attributes["Код терр. ФНС"]] = sono;
            info[attributes["Код рег. органа"]] = sono_area;
          } else {
            message.error("Не удалось определить регистрирущий орган, обратитесь в поддержку");
          }
        }

        // Регистрация компании
        if (entryType === additionalEntriesTypes["РегистрацияКомпании"]) {
          info[attributes["ОГРН"]] = data[attributes["ОГРН"] as keyof IDadataParty];
          info[attributes["КПП"]] = data[attributes["КПП"] as keyof IDadataParty];
          info[attributes["ИНН"]] = data[attributes["ИНН"] as keyof IDadataParty];
          // @ts-ignore
          info[attributes["статусПользователя"]] = userStatuses[data["type"]];
          info[attributes["Полное наименование компании"]] =
            info[attributes["статусПользователя"]] === "ur" ? data?.name["full_with_opf"] : data?.name["full"];
        }

        // ОКВЭДЫ
        if (data.okveds && data.okveds.length > 0) {
          const { okveds, okved } = data;

          // определяем дополнительные ОКВЭД, выкинув из всего списка основной
          if (entryType !== additionalEntriesTypes["РегистрацияКомпании"]) {
            const additionalOkveds = okveds
              .filter((it) => it.code !== okved)
              .map((it) => it.code)
              .join(",");

            info[attributes["основнойОКВЭД"]] = okved;
            info[attributes["допОКВЭД"]] = additionalOkveds;
          }

          dispatch(success(info));

          const selectedActivities = extractOKVEDfromEntry(info);
          dispatch(setSelectedActivities(selectedActivities));
        }
      } else {
        message.error(errorsTypes.failedSearchByOGRNorINN);
        dispatch(failure());
      }
    } catch (err: any) {
      message.error(`Не удалось получить данные по ОГРН или ИНН ${err}`);
      dispatch(failure());
    }
  };
};
