import { inputConstants } from "./../input.constants";
import { createReducer } from "@reduxjs/toolkit";
import { reduxFormChange } from "./reduxFormChange";
import { reduxFormFocus } from "./reduxFormFocus";
import { autocompleteLoadSuccess } from "./loadAutoComplete";
import { issuedbyLoadSuccess, issuedbyLoadFailed } from "./loadIssuedBy";
import { resetLastChangedField } from "./resetLastChangedField";
import { loadINNRequest, loadINNSuccess, loadINNFailure } from "./loadINN";
import { checkPassportDataRequest, checkPassportDataSuccess, checkPassportDataFailure } from "./checkPassportData";
import { getOktmoInfoRequest, getOktmoInfoSuccess, getOktmoInfoFailure } from "./getOktmoInfo";
import { sonoLoadSuccess } from "./loadSonoArea";
import { updateInputColor } from "./updateInputColor";
import { toggleNextAutoCompleteLoad } from "./toggleNextAutoCompleteLoad";
import { clearInputData } from "./clearInputData";

export interface IInputState {
  lastChangedForm: string;
  lastChangedField: string;
  lastChangedValue: string;
  autoCompleteData: Record<string, any>;
  issuedBy: string;
  inputsColors: Record<string, any>;
  loadAutoCompleteOnNextChange: boolean;
  sono?: string;
  lastFocusedForm?: string;
  lastFocusedField?: string;
}

const initialState: IInputState = {
  lastChangedForm: "",
  lastChangedField: "",
  lastChangedValue: "",
  autoCompleteData: {},
  issuedBy: "",
  inputsColors: {},
  loadAutoCompleteOnNextChange: true,
};

export const input = createReducer(initialState, {
  "@@redux-form/CHANGE": reduxFormChange,
  "@@redux-form/FOCUS": reduxFormFocus,

  [inputConstants.AUTOCOMPLETE_LOAD_SUCCESS]: autocompleteLoadSuccess,

  [inputConstants.ISSUEDBY_LOAD_SUCCESS]: issuedbyLoadSuccess,
  [inputConstants.ISSUEDBY_LOAD_FAILED]: issuedbyLoadFailed,

  [inputConstants.LOAD_INN_REQUEST]: loadINNRequest,
  [inputConstants.LOAD_INN_SUCCESS]: loadINNSuccess,
  [inputConstants.LOAD_INN_FAILURE]: loadINNFailure,

  [inputConstants.CHECK_PASSPORT_DATA_REQUEST]: checkPassportDataRequest,
  [inputConstants.CHECK_PASSPORT_DATA_SUCCESS]: checkPassportDataSuccess,
  [inputConstants.CHECK_PASSPORT_DATA_FAILURE]: checkPassportDataFailure,

  [inputConstants.GET_OKTMO_INFO_REQUEST]: getOktmoInfoRequest,
  [inputConstants.GET_OKTMO_INFO_SUCCESS]: getOktmoInfoSuccess,
  [inputConstants.GET_OKTMO_INFO_FAILURE]: getOktmoInfoFailure,

  [inputConstants.SONO_AREA_LOAD_SUCCESS]: sonoLoadSuccess,

  [inputConstants.RESET_LAST_CHANGED_FIELD]: resetLastChangedField,

  [inputConstants.UPDATE_INPUT_COLOR]: updateInputColor,

  [inputConstants.TOGGLE_NEXT_AUTOCOMPLETE_LOAD]: toggleNextAutoCompleteLoad,

  [inputConstants.CLEAR_INPUT_DATA]: clearInputData,
});
