import { IUserState } from "src/redux/user";

export const changeCompanySettingsRequest = (state: IUserState): IUserState => {
  return {
    ...state,
    loading: true,
  };
};

export const changeCompanySettingsSuccess = (state: IUserState): IUserState => {
  return {
    ...state,
    loading: false,
  };
};

export const changeCompanySettingsFailure = (state: IUserState): IUserState => {
  return { ...state, loading: false };
};
