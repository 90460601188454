import { attributes } from "src/constants";
import { cloneDeep } from "lodash";
import { serverValuesToMasksFormat } from "src/helpers";
import { IEntriesState } from "src/redux/entries";
import { Action } from "redux";

interface IAction extends Action {
  id: number;
  data: any;
}

export const updateOwner = (state: IEntriesState, action: IAction): IEntriesState => {
  const formattedEntry: any = cloneDeep(state.formattedEntry);
  const { id, data } = action;
  const index = formattedEntry[attributes["учредители"]].findIndex((it: any) => it.id === id);
  formattedEntry[attributes["учредители"]][index] = Object.assign(
    {},
    formattedEntry[attributes["учредители"]][index],
    serverValuesToMasksFormat(data),
  );

  return {
    ...state,
    formattedEntry,
  };
};
