import React from "react";
import { cn } from "src/helpers/bem";
import "./UserPreview.scss";
import { Tooltip } from "src/components";
import { errorsTypes } from "src/constants";
import { isEmpty } from "lodash";
import classNames from "classnames/dedupe";
import { ReactComponent as RemoveIcon } from "./images/remove-icon.svg";
import { IProps, defaultProps } from "./interface";

const b = cn("user-preview");

export class UserPreview extends React.Component<IProps> {
  static defaultProps = defaultProps;

  render() {
    const { label, options, onEdit, onRemove, className, disabled, errorVisible } = this.props;

    return (
      <div className={classNames(b(), className)}>
        <div className={b("info-wrapper")} onClick={onEdit}>
          <span className={b("bold-label")}>{label}</span>
          {!isEmpty(options) && (
            <ul className={b("options-list")}>
              {options.map((it, index) => (
                <li key={`option-${index}`} className={b("options-item")}>
                  {it}
                </li>
              ))}
            </ul>
          )}
        </div>
        {onRemove && !disabled && (
          <button className={b("remove-btn")} onClick={onRemove} type="button">
            <RemoveIcon />
          </button>
        )}
        <Tooltip className={b("tooltip")} title={errorsTypes.emptyFields} visible={errorVisible} />
      </div>
    );
  }
}
