import { entriesConstants } from "./../entries.constants";
import { API } from "src/helpers";
import { endpoints, ACTIVATE_POOLING_INTERVAL, attributes } from "src/constants";
import { getEntry, changeFormattedEntryAttribute } from "./index";
import { message } from "antd";

export const activationPooling = (id: number) => {
  const { ENTRY_ACTIVATION_POOLING_REQUEST, ENTRY_ACTIVATION_POOLING_SUCCESS, ENTRY_ACTIVATION_POOLING_FAILURE } =
    entriesConstants;
  const request = (descriptor: ReturnType<typeof setTimeout>) => ({
    type: ENTRY_ACTIVATION_POOLING_REQUEST,
    descriptor,
  });
  const success = () => ({ type: ENTRY_ACTIVATION_POOLING_SUCCESS });
  const failure = () => ({ type: ENTRY_ACTIVATION_POOLING_FAILURE });

  return async function (dispatch: Function) {
    const descriptor = setInterval(async () => {
      try {
        const res: any = await API.get(`${endpoints.entries[`activationPooling`]}/${id}`);
        if (res && res.status === "true") {
          dispatch(success());
          dispatch(changeFormattedEntryAttribute(attributes["ссылкаНаАктивацию"], undefined));
          dispatch(getEntry(id));
          clearInterval(descriptor);
        }
      } catch (err: any) {
        dispatch(failure());
        message.error(`Не удалось узнать статус активации ${err}`);
        clearInterval(descriptor);
      }
    }, ACTIVATE_POOLING_INTERVAL);
    dispatch(request(descriptor));
  };
};
