import { IFilesState } from "src/redux/files";
import { Action } from "redux";

interface IAction extends Action {}

export const clearFileState = (state: IFilesState, action: IAction): IFilesState => {
  return {
    ...state,
    loading: false,
    error: false,
    loaded: {},
    validateErrors: {},
    otherFiles: { maxFileId: 0, files: [] },
    inArchive: {},
  };
};
