import { attributes } from "src/constants";
import moment from "moment";
moment.locale("ru");
export const getInitialValues = (entryData: Record<string, any>) => {
  return {
    [attributes["Основание для проживания на территории РФ"]]:
      entryData[attributes["Основание для проживания на территории РФ"]],
    [attributes["Номер документа"]]: entryData[attributes["Номер документа"]],
    [attributes["Кем выдан"]]: entryData[attributes["Кем выдан"]],
    [attributes["Дата выдачи"]]: entryData[attributes["Дата выдачи"]] || "",
    [attributes["Срок действия"]]: entryData[attributes["Срок действия"]] || "",
    [attributes["Документ иностранца бессрочный"]]:
      entryData[attributes["Документ иностранца бессрочный"]] === "1" ? true : false,
  };
};
