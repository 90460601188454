export const entriesConstants = {
  // ПОЛУЧЕНИЕ ЗАЯВОК
  ENTRIES_REQUEST: "ENTRIES_REQUEST",
  ENTRIES_SUCCESS: "ENTRIES_SUCCESS",
  ENTRIES_FAILURE: "ENTRIES_FAILURE",

  //КОПИРОВАНИЕ
  ENTRIES_COPY_REQUEST: "ENTRIES_COPY_REQUEST",
  ENTRIES_COPY_SUCCESS: "ENTRIES_COPY_SUCCESS",
  ENTRIES_COPY_FAILURE: "ENTRIES_COPY_FAILURE",

  //ПОИСК
  ENTRIES_SEARCH_REQUEST: "ENTRIES_SEARCH_REQUEST",
  ENTRIES_SEARCH_SUCCESS: "ENTRIES_SEARCH_SUCCESS",
  ENTRIES_SEARCH_FAILURE: "ENTRIES_SEARCH_FAILURE",

  // УДАЛЕНИЕ
  ENTRIES_DELETE_REQUEST: "ENTRIES_DELETE_REQUEST",
  ENTRIES_DELETE_SUCCESS: "ENTRIES_DELETE_SUCCESS",
  ENTRIES_DELETE_FAILURE: "ENTRIES_DELETE_FAILURE",

  //СОЗДАНИЕ
  ENTRY_CREATE_REQUEST: "ENTRY_CREATE_REQUEST",
  ENTRY_CREATE_SUCCESS: "ENTRY_CREATE_SUCCESS",
  ENTRY_CREATE_FAILURE: "ENTRY_CREATE_FAILURE",

  //РЕДАКТИРОВАНИЕ
  ENTRY_UPDATE_REQUEST: "ENTRY_UPDATE_REQUEST",
  ENTRY_UPDATE_SUCCESS: "ENTRY_UPDATE_SUCCESS",
  ENTRY_UPDATE_FAILURE: "ENTRY_UPDATE_FAILURE",

  //ПОЛУЧЕНИЕ ЗАЯВКИ ПО ID
  ENTRY_GET_REQUEST: "ENTRY_GET_REQUEST",
  ENTRY_GET_SUCCESS: "ENTRY_GET_SUCCESS",
  ENTRY_GET_FAILURE: "ENTRY_GET_FAILURE",

  GET_SIMPLIFIED_ENTRY_REQUEST: "GET_SIMPLIFIED_ENTRY_REQUEST",
  GET_SIMPLIFIED_ENTRY_SUCCESS: "GET_SIMPLIFIED_ENTRY_SUCCESS",
  GET_SIMPLIFIED_ENTRY_FAILURE: "GET_SIMPLIFIED_ENTRY_FAILURE",

  //ВЫПУСК КЭП
  ENTRY_KEP_REQUEST: "ENTRY_KEP_REQUEST",
  ENTRY_KEP_SUCCESS: "ENTRY_KEP_SUCCESS",
  ENTRY_KEP_FAILURE: "ENTRY_KEP_FAILURE",

  //POOLING КЭП
  ENTRY_KEP_POOLING_REQUEST: "ENTRY_KEP_POOLING_REQUEST",
  ENTRY_KEP_POOLING_SUCCESS: "ENTRY_KEP_POOLING_SUCCESS",
  ENTRY_KEP_POOLING_FAILURE: "ENTRY_KEP_POOLING_FAILURE",

  // Ручная активация КЭП
  ACTIVATE_KEP_REQUEST: "ACTIVATE_KEP_REQUEST",
  ACTIVATE_KEP_SUCCESS: "ACTIVATE_KEP_SUCCESS",
  ACTIVATE_KEP_FAILURE: "ACTIVATE_KEP_FAILURE",

  //СОЗДАНИЕ ПОДПИСАНИЯ
  ENTRY_LINK_REQUEST: "ENTRY_LINK_REQUEST",
  ENTRY_LINK_SUCCESS: "ENTRY_LINK_SUCCESS",
  ENTRY_LINK_FAILURE: "ENTRY_LINK_FAILURE",

  //POOLING АКТИВАЦИИ
  ENTRY_ACTIVATION_POOLING_REQUEST: "ENTRY_ACTIVATE_POOLING_REQUEST",
  ENTRY_ACTIVATION_POOLING_SUCCESS: "ENTRY_ACTIVATE_POOLING_SUCCESS",
  ENTRY_ACTIVATION_POOLING_FAILURE: "ENTRY_ACTIVATE_POOLING_FAILURE",

  //POOLING ССЫЛКИ
  ENTRY_LINK_POOLING_REQUEST: "ENTRY_LINK_POOLING_REQUEST",
  ENTRY_LINK_POOLING_SUCCESS: "ENTRY_LINK_POOLING_SUCCESS",
  ENTRY_LINK_POOLING_FAILURE: "ENTRY_LINK_POOLING_FAILURE",

  //ОТПРАВКА В ФНС
  ENTRY_SEND_FNS_REQUEST: "ENTRY_SEND_FNS_REQUEST",
  ENTRY_SEND_FNS_SUCCESS: "ENTRY_SEND_FNS_SUCCESS",
  ENTRY_SEND_FNS_FAILURE: "ENTRY_SEND_FNS_FAILURE",

  // OOO
  // Добавление учредителя
  ENTRY_ADD_OWNER: "ENTRY_ADD_OWNER",

  // Удаление учредителя
  ENTRY_REMOVE_OWNER: "ENTRY_REMOVE_OWNER",

  // Обновление информации об учредители
  ENTRY_UPDATE_OWNER: "ENTRY_UPDATE_OWNER",

  // Обновление информации о заявителе в изм. ООО
  UPDATE_APPLICANT: "UPDATE_APPLICANT",

  // Обновление информации об ЕИО
  ENTRY_UPDATE_EIO: "ENTRY_UPDATE_EIO",

  // Очистка информации об ЕИО
  ENTRY_CLEAR_EIO: "ENTRY_CLEAR_EIO",

  // Сброс галочки "Назначить ЕИО" у всех учредителей
  ENTRY_CLEAR_MAKE_EIO: "ENTRY_CLEAR_MAKE_EIO",

  // Обновление информации о неверно заполненных полях заявки (используется как минимум для учредителей и ЕИО в регистрации ООО)
  ENTRY_UPDATE_VALIDATE_ERROR: "ENTRY_UPDATE_VALIDATE_ERROR",

  //ПОЛУЧЕНИЕ ИНФОРМАЦИИ ПО ОГРН
  FETCH_BY_OGRN_OR_INN_REQUEST: "FETCH_BY_OGRN_OR_INN_REQUEST",
  FETCH_BY_OGRN_OR_INN_SUCCESS: "FETCH_BY_OGRN_OR_INN_SUCCESS",
  FETCH_BY_OGRN_OR_INN_FAILURE: "FETCH_BY_OGRN_OR_INN_FAILURE",

  //ИЗМЕНЕНИЕ СВОЙСТВА formattedEntry
  CHANGE_FORMATTED_ENTRY: "CHANGE_FORMATTED_ENTRY",

  // ОЧИСТКА ИНФОРМАЦИИ О ТЕКУЩЕЙ ЗАЯВКЕ
  CLEAR_CURRENT_ENTRY: "CLEAR_CURRENT_ENTRY",

  // ИЗМЕНЕНИЕ ИНФОРМАЦИИ В ТЕКУЩЕЙ ЗАЯВКЕ
  CHANGE_CURRENT_ENTRY: "CHANGE_CURRENT_ENTRY",

  // ПОЛНАЯ ПЕРЕЗАПИСЬ FORMATTED ENTRY
  OVERWRITE_FORMATTED_ENTRY: "OVERWRITE_FORMATTED_ENTRY",

  // SMEV-POOLING
  ENTRY_SMEV_POOLING_REQUEST: "ENTRY_SMEV_POOLING_REQUEST",
  ENTRY_SMEV_POOLING_SUCCESS: "ENTRY_SMEV_POOLING_SUCCESS",
  ENTRY_SMEV_POOLING_ONLY_PASSPORT_LEFT: "ENTRY_SMEV_POOLING_ONLY_PASSPORT_LEFT",
  ENTRY_SMEV_POOLING_FAILURE: "ENTRY_SMEV_POOLING_FAILURE",
};
