import React from "react";
import { cn } from "src/helpers/bem";
import { downloadFile } from "src/helpers";
import "./FormedDocs.scss";
import { ReactComponent as FileLogo } from "./images/file-icon.svg";
import { Divider } from "antd";
import { IProps } from "./interface";

const b = cn("formed-docs");

export const FormedDocs = (props: IProps) => {
  const { mainDocs, additionalDocs, style } = props;

  return (
    <section className={b()} style={style}>
      <h2 className={b("title")}>Файлы</h2>
      <ul className={b("list")}>
        {mainDocs.map((it: any, index: number) => (
          <li className={b("item")} key={index}>
            <a href="javascript:void(0)" className={b("link")} onClick={() => downloadFile(it.link)}>
              <FileLogo className={b("file-icon")} />
              <span className={b("file-name")}>{it.name}</span>
            </a>
          </li>
        ))}
      </ul>
      {additionalDocs.length > 0 && (
        <>
          <Divider className={b("divider")} />
          <ul className={b("list")}>
            {additionalDocs.map((it: any, index: number) => (
              <li className={b("item")} key={index}>
                <a href="javascript:void(0)" className={b("link")} onClick={() => downloadFile(it.link)}>
                  <FileLogo className={b("file-icon")} />
                  <span className={b("file-name")}>{it.name}</span>
                </a>
              </li>
            ))}
          </ul>
        </>
      )}
    </section>
  );
};

FormedDocs.defaultProps = {
  mainDocs: [],
  additionalDocs: [],
  style: {},
};
