import React from "react";
import { cn } from "src/helpers/bem";
import "./Radio.scss";
import { Radio as AntdRadio } from "antd";
import { IProps } from "./interface";

const b = cn("site-radio");

export const Radio = ({ title, value, name, meta, isButton, ...rest }: IProps) => {
  const { touched, error, warning } = meta;
  return (
    <React.Fragment>
      {isButton ? (
        <AntdRadio.Button value={value} name={name} {...rest}>
          {title}
        </AntdRadio.Button>
      ) : (
        <AntdRadio value={value} name={name} {...rest}>
          {title}
        </AntdRadio>
      )}

      {touched &&
        ((error && <span className={b("error")}>{error}</span>) ||
          (warning && <span className={b("warning")}>{warning}</span>))}
    </React.Fragment>
  );
};
