import React, { Component, ChangeEvent } from "react";
import classNames from "classnames/dedupe";
import { connect } from "react-redux";
import { Field, reduxForm, submit, change, formValueSelector } from "redux-form";

import { cn } from "src/helpers/bem";
import { AppState } from "src/redux/AppState";
import {
  signmeAPI,
  AAutoComplete,
  ATextArea,
  ACheckbox,
  AInput,
  dadataAddressFormat,
  isFederalCity,
} from "src/helpers";
import { attributes, endpoints } from "src/constants";

import "./Delivery.scss";
import validate from "./validate";
import { IState, IProps, defaultProps } from "./interface";
import { getInitialValues } from "./initialValues";

const sfs = cn("site-forms");
const sf = cn("site-form");
const b = cn("delivery-form");

class DeliveryForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      deliveryStatus: "default",
    };
  }

  clearAddress = (): void => {
    const { form, changeFieldValue } = this.props;

    changeFieldValue(form, attributes["Адрес для встречи с курьером"], "");
    changeFieldValue(form, attributes["Адрес для встречи с курьером (полн.)"], "");
    changeFieldValue(form, attributes["Регион доставки"], "");
    changeFieldValue(form, attributes["Город доставки"], "");

    this.setState({ deliveryStatus: "default" });
  };

  handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>): void => {
    const { form, changeFieldValue } = this.props;

    if (!event.target.checked) {
      this.clearAddress();

      changeFieldValue(form, attributes["Комментарий для курьера"], "");
    }
  };

  handleChangeAddress = (value: any): void => {
    // конфликт типов onChange у инпутов и автокомплита, поэтому any
    if (!value) {
      this.clearAddress();
    }
  };

  handleSelectAddress = async (selectedAddress: string): Promise<void> => {
    const { autoCompleteData, form, changeFieldValue } = this.props;

    const selectedHint = autoCompleteData[attributes["Адрес для встречи с курьером"]].filter(
      (item: any) => item.value === selectedAddress,
    )[0];

    changeFieldValue(form, attributes["Адрес для встречи с курьером (полн.)"], JSON.stringify(selectedHint));

    const addressData: any = await dadataAddressFormat(selectedHint.data);

    const formData = new FormData();
    formData.append("kladr", addressData[attributes["Код адреса"]]);

    const isDeliveryEnabled = await signmeAPI.post(endpoints.checkDelivery, formData);

    this.setState({ deliveryStatus: isDeliveryEnabled ? "enabled" : "disabled" });

    changeFieldValue(form, attributes["Регион доставки"], addressData[attributes["Наименование региона"]]);
    changeFieldValue(
      form,
      attributes["Город доставки"],
      !addressData[attributes["Наименование населенного пункта"]] &&
        isFederalCity(addressData[attributes["Наименование региона"]])
        ? addressData[attributes["Наименование региона"]]
        : addressData[attributes["Наименование населенного пункта"]],
    );
  };

  render() {
    const { checked, disabled, autoCompleteData } = this.props;

    return (
      <div className={b()}>
        <div className={sfs("checkbox-wrapper")}>
          <Field
            className={sfs("visibility-checkbox")}
            title="Идентификация курьерской службой"
            name={attributes["Идентификация курьерской службой"]}
            component={ACheckbox}
            disabled={disabled}
            format={(v: number) => Boolean(Number(v))}
            parse={(v: boolean) => Number(v).toString()}
            onChange={this.handleChangeCheckbox}
          />
        </div>

        {checked ? (
          <>
            <div className={b("address")}>
              <div className={classNames(sf("input-with-suffix"), sf("address-input"))}>
                <Field
                  title="Адрес для встречи с курьером:"
                  placeholder="Введите адрес в свободной форме"
                  name={attributes["Адрес для встречи с курьером"]}
                  options={autoCompleteData[attributes["Адрес для встречи с курьером"]] || []}
                  component={AAutoComplete}
                  onSelect={this.handleSelectAddress}
                  onChange={this.handleChangeAddress}
                  customBackfill={true}
                  disabled={disabled}
                />
                <Field name={attributes["Регион доставки"]} component={AInput} hidden />
                <Field name={attributes["Город доставки"]} component={AInput} hidden />
                <Field name={attributes["Адрес для встречи с курьером (полн.)"]} component={AInput} hidden />
              </div>
              {this.state.deliveryStatus === "disabled" && (
                <div className={b("error-text")}>
                  Не нашли возможность идентификации по данному адресу, напишите в поддержку
                </div>
              )}
              {this.state.deliveryStatus === "enabled" && (
                <div className={b("success-text")}>Выездная идентификация по этому адресу возможна</div>
              )}
            </div>
            <Field
              title="Комментарий для курьера:"
              name={attributes["Комментарий для курьера"]}
              component={ATextArea}
              disabled={disabled}
            />
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { data, form } = ownProps;
  const initialValues = data ? getInitialValues(data) : {};
  const values = formValueSelector(form);
  const checked = values(state, attributes["Идентификация курьерской службой"]) === "1";
  const autoCompleteData = state.input.autoCompleteData[form] || {};

  return { initialValues, checked, autoCompleteData };
};

const mapDispatchToProps = (dispatch: Function, ownProps: IProps) => {
  const { form } = ownProps;
  return {
    changeFieldValue: (form: string, field: string, value: string) => dispatch(change(form, field, value)),
    onSubmit: () => dispatch(submit(form)),
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  validate,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(DeliveryForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;

export { connectedComponent as DeliveryForm };
