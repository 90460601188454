import { getActivityTitleByCode, sortSelectedActivities } from "src/helpers";
import { IActivitiesState } from "src/redux/activities";
import { Action } from "redux";
import { ActivityModeType, IActivityInternal } from "src/helpers/types/Activity";

interface IAction extends Action {
  mode: ActivityModeType;
  activityCode: string;
}

export const addActivity = (state: IActivitiesState, action: IAction): IActivitiesState => {
  const { mode, activityCode } = action;
  const { activities, selectedActivities } = state;

  if (mode === "default") {
    const newActivity = { code: activityCode, title: getActivityTitleByCode(activities, activityCode), main: false };
    const updatedActivities = [...selectedActivities, newActivity];
    return {
      ...state,
      selectedActivities: updatedActivities,
    };
  } else if (mode === "holdOriginal") {
    const newActivity: IActivityInternal = {
      code: activityCode,
      title: getActivityTitleByCode(activities, activityCode),
      main: false,
      status: "new",
    };
    const updatedActivities = [...selectedActivities, newActivity];
    return {
      ...state,
      selectedActivities: sortSelectedActivities(updatedActivities),
    };
  }

  return {
    ...state,
  };
};
