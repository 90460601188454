import { usersConstants } from "../users.constants";
import { endpoints } from "src/constants";
import { API } from "src/helpers";
import { message } from "antd";
import { Dispatch } from "redux";

export const changeUserPassword = (email: string, newPassword: string) => {
  const { CHANGE_USER_PASSWORD_REQUEST, CHANGE_USER_PASSWORD_SUCCESS, CHANGE_USER_PASSWORD_FAILURE } = usersConstants;
  const request = () => ({ type: CHANGE_USER_PASSWORD_REQUEST });
  const success = () => ({ type: CHANGE_USER_PASSWORD_SUCCESS });
  const failure = () => ({ type: CHANGE_USER_PASSWORD_FAILURE });

  return async function (dispatch: Dispatch) {
    dispatch(request());
    try {
      await API.post(`${endpoints.users.changePassword}/${email}`, { password: newPassword });
      dispatch(success());
    } catch (err: any) {
      message.error(`Не удалось изменить пароль ${err}`);
      dispatch(failure());
    }
  };
};
