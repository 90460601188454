import React, { FC } from "react";
import classNames from "classnames/dedupe";
import { cn } from "src/helpers/bem";
import { Props } from "./props";
import "./DetailedCertificateInfo.scss";
import { Descriptions } from "antd";
import { ReactComponent as CheckCircleIcon } from "src/assets/images/check-circle-icon.svg";
import { CloseCircleFilled } from "@ant-design/icons";
import { DetailedCrtInfoDeliveryCompany, DetailedCrtInfoDeliveryStatus } from "../../../../redux/certificates";

const b = cn("detailed-certificate-info");

const DELIVERY_STATUS_MAP: Record<DetailedCrtInfoDeliveryStatus, string> = {
  [DetailedCrtInfoDeliveryStatus.None]: "Нет",
  [DetailedCrtInfoDeliveryStatus.Created]: "Создана",
  [DetailedCrtInfoDeliveryStatus.Scheduled]: "Назначена",
  [DetailedCrtInfoDeliveryStatus.Rescheduled]: "Перенесена",
  [DetailedCrtInfoDeliveryStatus.Problem]: "Проблема",
  [DetailedCrtInfoDeliveryStatus.Canceled]: "Встреча отменена",
  [DetailedCrtInfoDeliveryStatus.Failed]: "Заказ отменен",
  [DetailedCrtInfoDeliveryStatus.Completed]: "Выполнено",
};

const DELIVERY_COMPANY_MAP: Record<DetailedCrtInfoDeliveryCompany, string> = {
  "0": "не определено",
  "2": "Финдоставка",
  "3": "BC Express",
  "4": "Fexpress",
};

export const DetailedCertificateInfo: FC<Props> = ({ className, data }) => {
  function renderSuccessOrFailIcon({ success }: { success: boolean }) {
    return success ? <CheckCircleIcon /> : <CloseCircleFilled className="error-icon" />;
  }

  return (
    <div className={classNames(b(), className)}>
      <Descriptions title="Данные клиента:" column={1}>
        <Descriptions.Item label="ID">{data.id}</Descriptions.Item>
        <Descriptions.Item label="ФИО">{data.fio}</Descriptions.Item>
        <Descriptions.Item label="Телефон">{data.phone}</Descriptions.Item>
        <Descriptions.Item label="СНИЛС">{data.snils}</Descriptions.Item>
      </Descriptions>

      <Descriptions title="Документы в удостоверяющем центре:" column={1}>
        <Descriptions.Item label="Паспорт">{renderSuccessOrFailIcon({ success: data.haspas })}</Descriptions.Item>
        <Descriptions.Item label="Прописка">{renderSuccessOrFailIcon({ success: data.haspas2 })}</Descriptions.Item>
        <Descriptions.Item label="СНИЛС">{renderSuccessOrFailIcon({ success: data.hasnils })}</Descriptions.Item>
        <Descriptions.Item label="Фото клиента ">
          {renderSuccessOrFailIcon({ success: data.hasface })}
        </Descriptions.Item>
      </Descriptions>

      {data.delivery.status ? (
        <Descriptions title="Идентификация курьером:" column={1}>
          <Descriptions.Item label="Статус" contentStyle={{ fontWeight: "bold" }}>
            {DELIVERY_STATUS_MAP[data.delivery.status] || "—"}
          </Descriptions.Item>
          <Descriptions.Item label="Комментарий">{data.delivery.reason || "—"}</Descriptions.Item>
          <Descriptions.Item label="Дата доставки">{data.delivery.date || "—"}</Descriptions.Item>
          <Descriptions.Item label="Интервал">{data.delivery.interval || "—"}</Descriptions.Item>
          <Descriptions.Item label="Компания">{DELIVERY_COMPANY_MAP[data.delivery.company || 0]}</Descriptions.Item>
          <Descriptions.Item label="Адрес">{data.delivery.address || "—"}</Descriptions.Item>
        </Descriptions>
      ) : null}
    </div>
  );
};
