import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import { AInput, AAutoComplete, innCheck } from "src/helpers";
import { connect } from "react-redux";
import { Field, reduxForm, change, Form } from "redux-form";
import { attributes } from "src/constants";
import validate from "./validate";
import { loadDadataInfo } from "src/redux/input";
import "./HHMarketplace.scss";
import { AppState } from "src/redux/AppState";
import { IProps, defaultProps } from "./interface";
import { Dispatch } from "redux";

const sf = cn("site-form");
const b = cn("hh-marketplace-form");

class HHMarketplaceForm extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.fillCompanyInfo = this.fillCompanyInfo.bind(this);
  }

  async fillCompanyInfo(evt: any) {
    const { form, loadDadataInfo, changeFieldValue } = this.props;
    if (innCheck(evt.target.value)) {
      const companyData = await loadDadataInfo(attributes["ИНН"], evt.target.value);
      changeFieldValue(form, attributes["Компания"], companyData.suggestions[0].value);
    }
  }

  render() {
    const { handleSubmit, autoCompleteData, disabled } = this.props;

    return (
      <Form onSubmit={handleSubmit} className={`${sf()} ${b()}`}>
        <div className={sf("section-wrapper")}>
          <section className={sf("section")}>
            <h2 className={b("title")}>Заявка на подключение</h2>
            <Field title="ФИО" name={attributes["ФИО"]} component={AInput} disabled={disabled} />
            <Field
              title="Телефон"
              mask="+9 999 999-99-99"
              name={attributes["Телефон"]}
              component={AInput}
              disabled={disabled}
            />
            <Field
              title="ИНН компании"
              name={attributes["ИНН"]}
              component={AInput}
              disabled={disabled}
              mask="9999999999"
              onChange={this.fillCompanyInfo}
            />
            <Field title="Название компании" name={attributes["Компания"]} component={AInput} disabled={disabled} />
            <Field title="Город" name={attributes["Город"]} component={AInput} disabled={disabled} />
            <Field
              title="Email"
              name={attributes["емейл2"]}
              options={autoCompleteData[attributes["емейл2"]] || []}
              component={AAutoComplete}
              disabled={disabled}
              placeholder="email@example.com"
            />
          </section>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: IProps) => {
  const { form } = ownProps;
  const autoCompleteData = state.input.autoCompleteData[form] || {};
  const initialValues = { company_id: state.userReducer.user?.company.id }; // так быстрее всего 🙈
  return { autoCompleteData, initialValues };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onSubmit: () => {},
    loadDadataInfo: (inputName: string, inputValue: string) => dispatch(loadDadataInfo(inputName, inputValue)),
    changeFieldValue: (form: string, field: string, value: string) => dispatch(change(form, field, value)),
  };
};

let connectedComponent: any = reduxForm({
  enableReinitialize: true,
  validate,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  //@ts-ignore
})(HHMarketplaceForm);

connectedComponent = connect(mapStateToProps, mapDispatchToProps)(connectedComponent);

connectedComponent.defaultProps = defaultProps;
export { connectedComponent as HHMarketplaceForm };
