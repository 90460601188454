import React from "react";
import "./FileUpload.scss";
// @ts-ignore
import MouseTooltip from "react-sticky-mouse-tooltip";
import { ReactComponent as UploadIcon } from "./images/file-upload-icon.svg";
import { Upload, message, Modal, Button } from "antd";
import { LoadingOutlined, UploadOutlined, DatabaseOutlined, DownloadOutlined } from "@ant-design/icons";
import { Tooltip } from "src/components";
import { cn } from "src/helpers/bem";
import { convertBlobToBase64, isImage, isPdfOrImage, isTif, isTiff } from "src/helpers";
import { IProps, IState, FileAcceptType, defaultProps } from "./interface";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";

const b = cn("file-upload");

/*
за отображение лоадера отвечают два флага - loading и isFileExist.
loading === true, когда файл загружается/удаляется в/из файл-аплоада.
логика ifFileExist описана в контейнере Files
*/

export class FileUpload extends React.Component<IProps, IState> {
  static defaultProps = defaultProps;

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: Boolean(typeof this.props.isFileExist === "undefined"),
      fileList: [],
      labelVisible: false,
      previewVisible: false,
      fileBlob: new Blob(),
      tooltipVisible: false,
    };
  }

  componentDidMount() {
    this.loadFileToState();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { loadFile, isFileExist } = this.props;
    const { fileList } = this.state;

    if (!prevProps.loadFile && loadFile) {
      this.loadFileToState();
    }

    if ((typeof prevProps.isFileExist === "undefined" || prevProps.isFileExist === true) && isFileExist === false) {
      this.setState({ loading: false });
    }

    if (prevProps.isFileExist && !isFileExist) {
      this.setState({ fileList: [] });
    }

    if (prevState.fileList.length === 0 && fileList.length !== 0) {
      this.setState({ labelVisible: true });
    }

    if (prevState.fileList.length !== 0 && fileList.length === 0) {
      this.setState({ labelVisible: false });
    }
  }

  loadFileToState = () => {
    this.props.loadFile &&
      this.props
        .loadFile()
        .then((fileBlob: Blob | undefined) => {
          if (fileBlob) {
            this.setState({ fileBlob });
            convertBlobToBase64(fileBlob).then((url: string) => {
              this.setState({
                loading: false,
                fileList: [
                  {
                    uid: Math.floor(Math.random() * 1000).toString(),
                    name: "file",
                    status: "done",
                    url: url,
                  },
                ],
              });
            });
          }
        })
        .catch(() => this.setState({ loading: false }));
  };

  beforeUpload = (file: UploadFile) => {
    const { accept } = this.props;
    let isValidExtension = false;
    let errorMessages: Record<FileAcceptType, string> = {
      image: "jpg, png",
      pdfOrImage: "pdf, jpg, png",
      tif: "tif",
      tiff: "tiff",
    };

    if (accept === "pdfOrImage") {
      isValidExtension = isPdfOrImage(file);
    } else if (accept === "image") {
      isValidExtension = isImage(file);
    } else if (accept === "tif") {
      isValidExtension = isTif(file);
    } else if (accept === "tiff") {
      isValidExtension = isTiff(file);
    }

    if (!isValidExtension) {
      message.error(`Файл имеет недопустимый формат. Поддерживаются: ${errorMessages[accept]}`);
    }
    //@ts-ignore
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error("Файл слишком большой. Максимально допустимый размер файла 10MB");
    }

    return isValidExtension && isLt10M;
  };

  handleChange = (info: UploadChangeParam) => {
    const { onChange } = this.props;
    const file = info.fileList[0];
    if (file && this.beforeUpload(file)) {
      this.setState({
        loading: true,
      });
      onChange(info.fileList[0]).then((res) => {
        if (res?.status === "uploaded") {
          this.setState({ loading: false, fileList: [...info.fileList].map((it) => ({ ...it, status: "done" })) });
        } else {
          this.setState({
            loading: false,
            fileList: [...info.fileList].map((it) => ({ ...it, status: "error" })),
          });
        }
      });
    }
  };

  handlePreview = () => {
    this.setState({ previewVisible: !this.state.previewVisible });
  };

  handleRemove = () => {
    this.setState({ loading: true });
    const { removeFile } = this.props;
    removeFile && removeFile().then(() => this.setState({ fileList: [], loading: false }));
  };

  handleDownload = () => {
    const { downloadFile } = this.props;

    downloadFile && downloadFile();
  };

  createPreview = () => {
    const fileList = this.state.fileList;
    if (fileList.length > 0) {
      if (fileList[0].originFileObj) {
        return URL.createObjectURL(fileList[0].originFileObj);
      } else {
        return fileList[0].url;
      }
    }
  };

  renderDraggerWithPreview = () => {
    const { title, tip, label, disabled, inArchive, isFileExist, removeFile } = this.props;
    const { fileList, labelVisible, loading, tooltipVisible } = this.state;

    return (
      <Upload.Dragger
        customRequest={() => {}}
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={{ showRemoveIcon: !!removeFile && !disabled }}
        // @ts-ignore
        fileList={fileList}
        onChange={this.handleChange}
        onRemove={this.handleRemove}
        onPreview={this.handlePreview}
        onDownload={this.handleDownload}
        disabled={inArchive || disabled}
      >
        {typeof isFileExist === "undefined" || (isFileExist === true && loading) || loading ? (
          <LoadingOutlined spin />
        ) : (
          <>
            <MouseTooltip visible={tooltipVisible} offsetX={18} offsetY={0}>
              <Tooltip className={b("tooltip")} visible title="Загрузка файлов доступна после выпуска КЭП" />
            </MouseTooltip>

            {labelVisible && (
              <label
                className={b("label")}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.handleDownload();
                }}
              >
                {label} <DownloadOutlined className={b("download-icon")} />
              </label>
            )}

            <div style={{ visibility: fileList.length > 0 ? "hidden" : "initial" }}>
              <p className="ant-upload-drag-icon">
                {inArchive ? (
                  <DatabaseOutlined style={{ fontSize: "38px", color: "#cf1322" }} />
                ) : (
                  <UploadIcon className={b("icon")} />
                )}
              </p>

              <p className="ant-upload-text">{title}</p>
              <p className="ant-upload-hint">{inArchive ? "Файл в архиве, обратитесь в чат" : tip}</p>
            </div>
          </>
        )}
      </Upload.Dragger>
    );
  };

  renderSmallDragger = () => {
    const { disabled } = this.props;
    const { fileList } = this.state;

    return (
      <Upload
        customRequest={() => {}}
        // @ts-ignore
        fileList={fileList}
        onChange={this.handleChange}
        onRemove={this.handleRemove}
        onPreview={this.handlePreview}
        onDownload={this.handleDownload}
        disabled={disabled}
      >
        {fileList.length === 0 ? (
          <Button type="link">
            <UploadOutlined /> Загрузить
          </Button>
        ) : (
          ""
        )}
      </Upload>
    );
  };

  renderPreview = () => {
    const { previewVisible } = this.state;

    return (
      <Modal visible={previewVisible} footer={null} onCancel={this.handlePreview}>
        <img alt="example" style={{ width: "100%" }} src={this.createPreview()} />
      </Modal>
    );
  };

  render() {
    const { error, type, disabledTooltipVisible } = this.props;
    const { fileList } = this.state;

    return (
      <div
        className={`${b()}${fileList.length > 0 ? " uploaded" : ""}${type === "small" ? ` ${b({ small: true })}` : ""}`}
        ref="tooltipParent"
        onMouseEnter={() => this.setState({ tooltipVisible: disabledTooltipVisible })}
        onMouseLeave={() => this.setState({ tooltipVisible: false })}
      >
        {type === "withPreview" && this.renderDraggerWithPreview()}
        {type === "small" && this.renderSmallDragger()}

        <Tooltip title={error} visible={error !== ""} />

        {this.renderPreview()}
      </div>
    );
  }
}
