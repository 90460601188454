import { store } from "src/redux/store";
import { getFormValues } from "redux-form";
import { baseURL, formsNames, entriesTypes, attributes } from "src/constants";
import { cloneDeep, isEmpty } from "lodash";
import { getActivityTitleByCode, isFederalCity } from "src/helpers";
//@ts-ignore
import StringMask from "string-mask";

import { extractIPdata, validateIPregistation, validateIPregistationFiles } from "src/helpers/entries/ipRegistration";
import {
  extractOOOdata,
  validateOOOregistation,
  validateOOOregistationFiles,
} from "src/helpers/entries/oooRegistration";
import {
  extractChangeIPData,
  validateChangeIP,
  validateChangeIPModal,
  validateChangeIPFiles,
} from "src/helpers/entries/changeIP";
import {
  extractChangeOOOData,
  validateChangeOOO,
  validateChangeOOOModal,
  validateChangeOOOFiles,
} from "src/helpers/entries/changeOOO";
import {
  extractCloseIPData,
  validateCloseIP,
  validateCloseIPModal,
  validateCloseIPFiles,
} from "src/helpers/entries/closeIP";
import { SceneType, SceneTypeRus } from "src/helpers/types/Scene";
import { IDoc } from "src/helpers/types/Doc";
import { IAttribute, IFormattedEntry } from "src/helpers/types/Entry";
import { AppState } from "src/redux/AppState";
import { IOwner } from "src/helpers/types/Owner";
import { IExecutive } from "src/helpers/types/Executive";

export const extractEntryData = () => {
  const state = store.getState();
  const { currentEntry } = state.entries;
  const type = currentEntry!.entryType;

  switch (type) {
    case entriesTypes["РегистрацияИП"]:
      return extractIPdata(state);
    case entriesTypes["РегистрацияООО"]:
      return extractOOOdata(state);
    case entriesTypes["ИзмененияИП"]:
      return extractChangeIPData(state);
    case entriesTypes["ИзмененияООО"]:
      return extractChangeOOOData(state);
    case entriesTypes["ЗакрытиеИП"]:
      return extractCloseIPData(state);
  }
};

export const extractPassportData = () => {
  const state = store.getState();
  const passportValues = getFormValues(formsNames["passport"])(state);
  const foreignValues = getFormValues(formsNames["foreign"])(state);
  const commonValues = getFormValues(formsNames["common"])(state);
  const registrationValues = getFormValues(formsNames["registration"])(state);
  const certificatesValues = getFormValues(formsNames["certificatesStatus"])(state);

  const id = state.passports.currentPassport!.id;
  const allData = cloneDeep(
    Object.assign({}, passportValues, commonValues, registrationValues, foreignValues, certificatesValues)
  );

  allData["id"] = id;

  return allData;
};

export const validateApplicantModal = (dispatch: Function) => {
  const state = store.getState();
  const type = state.entries.currentEntry!.entryType;

  switch (type) {
    case entriesTypes["ИзмененияИП"]:
      return validateChangeIPModal(dispatch);
    case entriesTypes["ИзмененияООО"]:
      return validateChangeOOOModal(dispatch);
    case entriesTypes["ЗакрытиеИП"]:
      return validateCloseIPModal(dispatch);
    default:
      return false;
  }
};

export const validateEntry = (dispatch: Function): boolean => {
  const state = store.getState();
  const type = state.entries.currentEntry!.entryType;

  switch (type) {
    case entriesTypes["РегистрацияИП"]:
      return validateIPregistation(dispatch);
    case entriesTypes["РегистрацияООО"]:
      return validateOOOregistation(dispatch);
    case entriesTypes["ИзмененияИП"]:
      return validateChangeIP(dispatch);
    case entriesTypes["ИзмененияООО"]:
      return validateChangeOOO(dispatch);
    case entriesTypes["ЗакрытиеИП"]:
      return validateCloseIP(dispatch);
    default:
      return false;
  }
};

export const validateEntryFiles = (dispatch: Function): boolean => {
  const state = store.getState();
  const { currentEntry } = state.entries;
  const type = currentEntry!.entryType;

  switch (type) {
    case entriesTypes["РегистрацияИП"]:
      return validateIPregistationFiles(dispatch);
    case entriesTypes["РегистрацияООО"]:
      return validateOOOregistationFiles(dispatch);
    case entriesTypes["ИзмененияИП"]:
      return validateChangeIPFiles(dispatch);
    case entriesTypes["ИзмененияООО"]:
      return validateChangeOOOFiles(dispatch);
    case entriesTypes["ЗакрытиеИП"]:
      return validateCloseIPFiles(dispatch);
    default:
      return false;
  }
};

export const extractOwnerData = (id: number) => {
  const state = store.getState();
  const passportValues = getFormValues(`${formsNames["passport"]}-${formsNames["owner"]}${id}`)(state);
  const commonValues = getFormValues(`${formsNames["common"]}-${formsNames["owner"]}${id}`)(state);
  const deliveryValues = getFormValues(`${formsNames["delivery"]}-${formsNames["owner"]}${id}`)(state);
  const registrationValues = getFormValues(`${formsNames["registration"]}-${formsNames["owner"]}${id}`)(state);
  const ownerValues = getFormValues(`${formsNames["owner"]}${id}`)(state);
  const allData = cloneDeep(
    Object.assign({}, passportValues, commonValues, deliveryValues, registrationValues, ownerValues)
  );
  return allData;
};

export const extractEIOData = (): Record<string, any> => {
  const state = store.getState();
  const passportValues = getFormValues(`${formsNames["passport"]}-${formsNames["eio"]}`)(state);
  const commonValues = getFormValues(`${formsNames["common"]}-${formsNames["eio"]}`)(state);
  // const registrationValues = getFormValues(`${formsNames["registration"]}-${formsNames["eio"]}`)(state);
  const eioValues = getFormValues(`${formsNames["eio"]}`)(state);
  const allData = cloneDeep(Object.assign({}, passportValues, commonValues, eioValues));

  return allData;
};

export const extractApplicantData = (): Record<string, any> => {
  const state = store.getState();
  const applicantValues = getFormValues(formsNames["applicant"])(state);
  const commonValues = getFormValues(formsNames["common"])(state);
  const passportValues = getFormValues(formsNames["passport"])(state);
  const registrationValues = getFormValues(formsNames["registration"])(state);
  const allData = cloneDeep(Object.assign({}, passportValues, commonValues, registrationValues, applicantValues));

  return allData;
};

// извлекает ОКВЭД из пришедшей с сервера заявки
// (ОКВЭД приходит в виде строки, а нам нужно преобразовать их в массив объектов с полями title, code, main, original)
// original - это флаг того, что ОКВЭД изначально был в заявке (пришел с сервера)
export const extractOKVEDfromEntry = (formattedEntry: IFormattedEntry | Record<string, any>) => {
  if (formattedEntry) {
    const activities = store.getState().activities.activities;

    // пробуем извлечь список удаленных (красных) и добавленных (зеленых) ОКВЭД
    const {
      okvedAddition = "",
      okvedDeletion = "",
      primaryOkvedAddition = "",
      primaryOkvedDeletion = "",
    } = formattedEntry;

    const okvedAdditionArray = okvedAddition.split(",");
    const okvedDeletionArray = okvedDeletion.split(",");
    const primaryOkvedAdditionArray = primaryOkvedAddition.split(",");
    const primaryOkvedDeletionArray = primaryOkvedDeletion.split(",");

    const getStatus = (additionsArray: Array<string>, deletionsArray: Array<string>, code: string) => {
      if (additionsArray.indexOf(code) !== -1) {
        return "new";
      }

      if (deletionsArray.indexOf(code) !== -1) {
        return "removed";
      }

      return "default";
    };

    const primaryOkved =
      //@ts-ignore
      (formattedEntry[attributes["основнойОКВЭД"]] &&
        //@ts-ignore
        formattedEntry[attributes["основнойОКВЭД"]].split(",").map((it) => {
          const status = getStatus(primaryOkvedAdditionArray, primaryOkvedDeletionArray, it);
          return {
            code: it,
            title: getActivityTitleByCode(activities, it),
            main: true,
            status,
          };
        })) ||
      [];

    const okveds =
      //@ts-ignore
      (formattedEntry[attributes["допОКВЭД"]] &&
        //@ts-ignore
        formattedEntry[attributes["допОКВЭД"]].split(",").map((it) => {
          const status = getStatus(okvedAdditionArray, okvedDeletionArray, it);
          return {
            code: it,
            title: getActivityTitleByCode(activities, it),
            main: false,
            status,
          };
        })) ||
      [];

    return [...primaryOkved, ...okveds];
  }
  return [];
};

//преобразует массив атрибутов в объект атрибутов
export const attributesToObj = (attributes: IAttribute[]) => {
  return attributes?.reduce((acc, item) => {
    /*
    тут происходит треш
    */
    if (IsJsonString(item.value)) {
      const value = JSON.parse(item.value);
      if (typeof value === "object" || Array.isArray(value)) {
        //@ts-ignore
        acc[item.attribute] = value;
      } else {
        //@ts-ignore
        acc[item.attribute] = item.value;
      }
    } else {
      //@ts-ignore
      acc[item.attribute] = item.value;
    }

    return acc;
  }, {});
};

export const serverValuesToMasksFormat = (formattedEntry: Record<string, any>) => {
  if (typeof formattedEntry[attributes["Серия и номер паспорта"]] !== "undefined") {
    if (formattedEntry[attributes["иностранец"]] !== "true") {
      const passportMask = new StringMask("00 00 000000");
      formattedEntry[attributes["Серия и номер паспорта"]] = passportMask.apply(
        formattedEntry[attributes["Серия и номер паспорта"]].replace(/[^\d]/g, "")
      );
    }
  }

  if (typeof formattedEntry[attributes["СНИЛС"]] !== "undefined") {
    const snilsMask = new StringMask("000-000-000 00");
    formattedEntry[attributes["СНИЛС"]] = snilsMask.apply(formattedEntry[attributes["СНИЛС"]].replace(/[^\d]/g, ""));
  }

  return formattedEntry;
};

//проверяет, что строка является валидным JSON
const IsJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getSurnameAndInitials = (data?: IFormattedEntry | IOwner | IExecutive) => {
  if (!isEmpty(data) && typeof data !== "undefined") {
    //@ts-ignore
    const lastName = data[attributes["Фамилия"]] || "";
    //@ts-ignore
    const firstName = data[attributes["Имя"]] ? `${data[attributes["Имя"]][0]}.` : "";
    //@ts-ignore
    const middleName = data[attributes["Отчество"]] ? `${data[attributes["Отчество"]][0]}.` : "";
    return `${lastName}\xa0${firstName}${middleName}`.trim();
  }

  return "";
};

export const getDocsByEntryType = ({
  entryType,
  taxType,
  formattedEntry,
  entryId,
  ustav_checkbox,
  ownersCount,
}: {
  entryType: string;
  entryId: number;
  ownersCount?: number;
  ustav_checkbox: string;
  formattedEntry: IFormattedEntry;
  taxType?: string;
}): IDoc[] => {
  const rootUrl = `${baseURL}/api/v1/file/${entryId}/`;
  switch (entryType) {
    case entriesTypes["РегистрацияИП"]: {
      let docsList = [{ name: "Форма Р21001", link: `${rootUrl}r21001.tiff` }];
      if (taxType !== "0") {
        docsList = [...docsList, { name: "Уведомление УСН", link: `${rootUrl}usn.tiff` }];
      }
      return docsList;
    }
    case entriesTypes["РегистрацияООО"]: {
      let docsList;
      if (ownersCount && ownersCount > 1) {
        docsList = [
          { name: "Форма Р11001", link: `${rootUrl}11001.tiff` },
          {
            name: "Протокол",
            // @ts-ignore
            link: `${rootUrl}Протокол_generated.docx`,
          },
          { name: "Учредительный договор", link: `${rootUrl}Договор.docx` },
        ];
      } else {
        docsList = [
          { name: "Форма Р11001", link: `${rootUrl}11001.tiff` },
          {
            name: "Решение",
            // @ts-ignore
            link: `${rootUrl}Решение_generated.docx`,
          },
        ];
      }
      if (!ustav_checkbox) {
        docsList = [
          ...docsList,

          {
            name: "Устав",
            // @ts-ignore
            link: `${rootUrl}Устав_generated.docx`,
          },
        ];
      }
      if (taxType !== "0") {
        docsList = [...docsList, { name: "Уведомление УСН", link: `${rootUrl}usn.tiff` }];
      }
      return docsList;
    }
    case entriesTypes["ИзмененияИП"]:
      return [{ name: "Форма Р24001", link: `${rootUrl}r24001.tiff` }];
    case entriesTypes["ИзмененияООО"]:
      return [{ name: "Форма Р13014", link: `${rootUrl}13014.tif` }];
    case entriesTypes["ЗакрытиеИП"]:
      return [{ name: "Форма Р26001", link: `${rootUrl}r26001.tiff` }];
    default:
      return [];
  }
};

export const isScene = (scene: SceneTypeRus, currentScene?: SceneType) => {
  switch (scene) {
    case "Заполнение":
      return currentScene === "vipusk1" || currentScene === "vipusk2" || currentScene === "vipusk3";
    case "Проверка СМЭВ":
      return currentScene === "smevWaiting";
    case "Ошибка СМЭВ":
      return currentScene === "smevFailed";
    case "Активация КЭП":
      return currentScene === "smevActivation";
    case "Выпущена КЭП":
      return currentScene && currentScene !== "vipusk1" && currentScene !== "vipusk2" && currentScene !== "vipusk3";
    case "Создание ссылки":
      return currentScene === "vipusk4";
    case "Подписание":
      return currentScene === "fns4";
    case "Подписано":
      return (
        currentScene &&
        currentScene !== "vipusk1" &&
        currentScene !== "vipusk2" &&
        currentScene !== "vipusk3" &&
        currentScene !== "vipusk4" &&
        currentScene !== "fns4"
      );
    case "Готов к отправке":
      return currentScene === "fns5";
    case "Регистрация в ФНС":
      return currentScene === "fns_registration" || currentScene === "fns_pending";
    case "Зарегистрирован":
      return currentScene === "fns_success" || currentScene === "fns_reject";
    case "Отказано":
      return currentScene === "fns_reject";
    default:
      return false;
  }
};

// город ф.з. не должен отправляться на сервер
// поэтому перед отправкой его необходимо вырезать из сформированных данных
export const removeFederalCityFromEntryData = (entryData: Record<string, any>) => {
  if (isFederalCity(entryData["city"])) {
    entryData["city"] = "";
    entryData["city_abbr"] = "";
  }
  if (isFederalCity(entryData["executive_city"])) {
    entryData["executive_city"] = "";
    entryData["executive_city_abbr"] = "";
  }
  if (entryData["executives"]) {
    const executives = JSON.parse(entryData["executives"]);
    if (isFederalCity(executives["city"])) {
      executives["city"] = "";
      executives["city_abbr"] = "";
    }
    entryData["executives"] = JSON.stringify(executives);
  }
  if (entryData["owners"]) {
    const owners: IOwner[] = JSON.parse(entryData["owners"]);
    owners.forEach((it) => {
      //@ts-ignore
      if (isFederalCity(it["city"])) {
        //@ts-ignore
        it["city"] = "";
        //@ts-ignore
        it["city_abbr"] = "";
      }
    });
    entryData["owners"] = JSON.stringify(owners);
  }
};

/* Генерирует адрес в виде строки */
export const getAddressString = (
  state: AppState,
  formValues: Function,
  fieldsNames: Record<string, string>,
  withoutFlat = false
) => {
  const region_abbr = formValues(state, fieldsNames[attributes["Регион (сокр.)"]]);
  const region = formValues(state, fieldsNames[attributes["Наименование региона"]]);
  const nasel_abbr = formValues(state, fieldsNames[attributes["Населенный пункт"]]);
  const naselPunkt = formValues(state, fieldsNames[attributes["Наименование населенного пункта"]]);
  const element = formValues(state, fieldsNames[attributes["Элемент планировочной структуры"]]);
  const element_big = formValues(state, fieldsNames[attributes["Наименование элемента планировочной структуры"]]);
  const street_abbr = formValues(state, fieldsNames[attributes["Элемент улично-дорожной сети"]]);
  const street = formValues(state, fieldsNames[attributes["Наименование элемента улично-дорожной сети"]]);
  const house_abbr = formValues(state, fieldsNames[attributes["Здание/сооружение (первый столбец)"]]);
  const home = formValues(state, fieldsNames[attributes["Номер здания/сооружения (первый столбец)"]]);
  const house_2 = formValues(state, fieldsNames[attributes["Здание/сооружение (второй столбец)"]]);
  const house_num2 = formValues(state, fieldsNames[attributes["Номер здания/сооружения (второй столбец)"]]);
  const house_3 = formValues(state, fieldsNames[attributes["Здание/сооружение (третий столбец)"]]);
  const house_num3 = formValues(state, fieldsNames[attributes["Номер здания/сооружения (третий столбец)"]]);
  const appartment_abbr = formValues(state, fieldsNames[attributes["Квартира/офис"]]);
  const flat = formValues(state, fieldsNames[attributes["Номер квартиры/офиса"]]);
  const pom = formValues(state, fieldsNames[attributes["Помещение"]]);
  const pom_num = formValues(state, fieldsNames[attributes["Номер помещения"]]);

  let finalAddress = "";

  if (region && region_abbr) {
    if (isFederalCity(region)) {
      finalAddress += `, ${region}`;
    } else {
      finalAddress += `, ${region} ${region_abbr}`;
    }
  }

  if (nasel_abbr && naselPunkt) {
    finalAddress += `, ${nasel_abbr} ${naselPunkt}`;
  }

  if (element && element_big) {
    finalAddress += `, ${element} ${element_big}`;
  }

  if (street_abbr && street) {
    finalAddress += `, ${street_abbr} ${street}`;
  }

  if (house_abbr && home) {
    finalAddress += `, ${house_abbr} ${home}`;
  }

  if (house_2 && house_num2) {
    finalAddress += `, ${house_2} ${house_num2}`;
  }

  if (house_3 && house_num3) {
    finalAddress += `, ${house_3} ${house_num3}`;
  }

  if (!withoutFlat) {
    if (appartment_abbr && flat) {
      finalAddress += `, ${appartment_abbr} ${flat}`;
    }

    if (pom && pom_num) {
      finalAddress += `, ${pom} ${pom_num}`;
    }
  }

  finalAddress = finalAddress.replace(",,", ",");
  finalAddress = finalAddress.replace(/^, /, "");

  return finalAddress;
};

export const formatAddressObject = (object: string) => {
  if (!object) {
    return "";
  }

  if (
    /-|\/|\.|\s|^аал$|^арбан$|^аул$|^вал$|^зона$|^парк$|^сад$|^сквер$|^км$|^коса$|^ряд$|^тракт$|^ОНС$|^офис$|^цех$/g.test(
      object
    )
  ) {
    return object;
  }

  if (object === "литер" || object === "литера") {
    return "лит.";
  }

  if (object === "проезд") {
    return "пр-д";
  }

  return `${object}.`;
};
