import { SizeType } from "antd/es/config-provider/SizeContext";

export interface IProps {
  type: string;
  title: string;
  className: string;
  meta: Record<string, any>;
  size: SizeType;
  outlineColor: string;
  onFocus: Function;
  onBlur: Function;
  onChange: Function;
}

export interface IState {
  open: boolean;
  value: string;
}

export const defaultProps = {
  type: "",
  title: "",
  className: "",
  meta: {},
  size: "large",
  outlineColor: "",
};
