import { attributes } from "src/constants";
import { IOwner } from "src/helpers/types/Owner";

// подбирает правильное окончание для элемента ввода кол-ва лет. Используется в форме "ЕИО" для создания ООО
export const parseExecutiveTitle = (val: string) => {
  val = val.replace(/[^0-9\.]/g, "");
  const parsedValue = parseFloat(val);

  const yearsTable: any = {
    "0": "лет",
    "1": "год",
    "2": "года",
    "3": "года",
    "4": "года",
    "5": "лет",
    "6": "лет",
    "7": "лет",
    "8": "лет",
    "9": "лет",
  };

  if (parsedValue >= 11 && parsedValue <= 14) {
    return `${val} лет`;
  }

  return `${val} ${yearsTable[parsedValue % 10] || ""}`;
};

// находит разницу между строками "а" и "b" - не экспоритруется, используется в функциях "parseShortOOO", "parseOOO"
const getDifference = (a: string, b: string) => {
  let i = 0;
  let j = 0;
  let result = "";

  while (j < b.length) {
    if (a[i] !== b[j] || i === a.length) result += b[j];
    else i++;
    j++;
  }
  return result;
};

// не дает ввести дичь в поле "Сокращенное наименование ООО" в заявках "Создание ООО", "Изменение ООО"
export const parseShortOOO = (value: string) => {
  const difference = getDifference("ООО", value);
  return `ООО${difference}`;
};

// не дает ввести дичь в поле "Полное наименование ООО" в заявках "Создание ООО", "Изменение ООО"
export const parseOOO = (value: string) => {
  const difference = getDifference("Общество с ограниченной ответственностью", value);
  return `Общество с ограниченной ответственностью${difference}`;
};

export const parseSum = (sum: string) => {
  return parseFloat(sum.replace(/[^0-9]/g, "")) || 0;
};

// задаёт цвет рамок полей ввода в зависимости от коэф-та распознавания паспортных данных
export const setOutlineColor = (confidence: number) => {
  if (confidence < 0.4) {
    return "red";
  } else if (confidence >= 0.4 && confidence < 0.6) {
    return "orange";
  }
  return "";
};

export const doSetCaretPosition = (oField: any, iCaretPos?: number) => {
  // IE Support
  //@ts-ignore
  if (document.selection) {
    // Set focus on the element
    oField.focus();

    // Create empty selection range
    //@ts-ignore
    var oSel = document.selection.createRange();

    // Move selection start and end to 0 position
    oSel.moveStart("character", -oField.value.length);

    // Move selection start and end to desired position
    oSel.moveStart("character", iCaretPos);
    oSel.moveEnd("character", 0);
    oSel.select();
  }

  // Firefox support
  else if (oField.selectionStart || oField.selectionStart === "0") {
    oField.selectionStart = iCaretPos;
    oField.selectionEnd = iCaretPos;
    oField.focus();
  }
};

// небольшой (ну или большой...) костыль для удаления пробельных символов в начале и конце строки,
// пока в redux-form не работает onBlur :(
export const trimSpaces = (
  data: Record<string, any>,
  trimmedFields = ["Фамилия", "Имя", "Отчество", "Электронная почта", "Место рождения", "Кем выдан паспорт"],
) => {
  if (data[attributes["учредители"]]) {
    data[attributes["учредители"]] = JSON.stringify(
      JSON.parse(data[attributes["учредители"]]).map((owner: IOwner) => {
        const changedFields = {};
        trimmedFields.forEach((field) => {
          //@ts-ignore
          changedFields[attributes[field]] = owner[attributes[field]]?.trim();
        });
        return {
          ...owner,
          ...changedFields,
        };
      }),
    );
  }

  if (data[attributes["ЕИО"]]) {
    const eioData = JSON.parse(data[attributes["ЕИО"]]);
    const changedFields = {};
    trimmedFields.forEach((field) => {
      //@ts-ignore
      changedFields[attributes[field]] = eioData[attributes[field]]?.trim();
    });
    data[attributes["ЕИО"]] = JSON.stringify({
      ...eioData,
      ...changedFields,
    });
  }

  trimmedFields.forEach((field) => {
    data[attributes[field]] = data[attributes[field]]?.trim();
  });
};
