import { attributes } from "src/constants";
import { validateEmail, validatePhone, validateSnils, validateINN, validateOGRN } from "src/helpers";

export default function validate(values: Record<string, any>) {
  // ошибки для валидации
  let errors: Record<string, string> = {};

  errors = validatePhone(attributes["Телефон"], values[attributes["Телефон"]], errors, false);
  errors = validateEmail(attributes["Электронная почта"], values[attributes["Электронная почта"]], errors, false);
  errors = validateSnils(attributes["СНИЛС"], values[attributes["СНИЛС"]], errors, false);
  errors = validateINN(values[attributes["ИНН"]], errors, false);
  errors = validateOGRN(values[attributes["ОГРН"]], errors);

  return errors;
}
